import types from '../constants/route'

const initialState = {
  previousRoute: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.SAVE_PREVIOUS:
  {
    return {
      ...state,
      previousRoute: action.payload
    }
  }

  default:
    return state
  }
}
