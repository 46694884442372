import types from '../constants/workspace'
import _ from 'lodash'

const initialState = {
  origin: {},
  publishingOrigin: {},
  distributionOrigin: {},
  error: null,
  page: 1,
  count: 0,
  // searchedWorkspace: '',
  // publishingPage: 1,
  // distributionPage: 1,
  // publishingCount: 0,
  // distributionCount: 0,
  isGetFetching: false,
  users: {
    items: {},
    isGetFetching: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.WORKSPACE_GET_SUCCESS:
      {
        return {
          ...state,
          origin: _.keyBy(action.payload.data, 'id'),
          page: action.payload.page,
          count: action.payload.count
        }
      }
    case types.WORKSPACE_AMPLIFY_GET:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.WORKSPACE_AMPLIFY_GET_DISTRIBUTION_SUCCESS:
      {
        return {
          ...state,
          isGetFetching: false,
          distributionOrigin: action.payload
        }
      }

    case types.WORKSPACE_AMPLIFY_GET_PUBLISHING_SUCCESS:
      {
        return {
          ...state,
          isGetFetching: false,
          publishingOrigin: action.payload
        }
      }

    case types.WORKSPACE_GET_USERS:
      {
        return {
          ...state,
          users: {
            ...state.users,
            isGetFetching: true
          }
        }
      }

    case types.WORKSPACE_GET_USERS_SUCCESS:
      {
        const data = action.payload

        return {
          ...state,
          users: {
            ...state.users,
            items: _.keyBy(data, 'id'),
            isGetFetching: false
          }
        }
      }

    case types.WORKSPACE_GET_USERS_FAILURE:
      {
        return {
          ...state,
          users: {
            ...state.users,
            isGetFetching: false
          },
          error: action.payload
        }
      }

    case types.WORKSPACE_GET_FAILURE:
    case types.WORKSPACE_AMPLIFY_GET_FAILURE:
      {
        return {
          ...state,
          isGetFetching: false,
          error: action.payload
        }
      }

    // case types.SET_SEARCHED_WORKSPACE:
    //   {
    //     return {
    //       ...state,
    //       searchedWorkspace: action.payload
    //     }
    //   }
    default:
      return state
  }
}