export default {

  GET:                    'SERVER:DASHBOARD_GET',
  GET_SUCCESS:            'DASHBOARD_GET_SUCCESS',
  GET_FAILURE:            'DASHBOARD_GET_FAILURE',

  SET_FILTER:             'SET_FILTER_DASHBOARD',
  RESET_FILTERS:          'RESET_FILTERS_DASHBOARD',
  RESET_FILTER:           'RESET_FILTER_DASHBOARD',
  SET_SORT:               'SET_SORT_DASHBOARD',

  REMOVE_BY_SOCKET:       'REMOVE_BY_SOCKET_DASHBOARD',

  IS_REPEAT_GET:          'IS_REPEAT_GET',

  SOCKET_CAUSE_CREATE:    'SOCKET_CAUSE_CREATE',
  SOCKET_AMPLIFY_CAUSE_CREATE: 'DASHBOARD_SOCKET_AMPLIFY_CAUSE_CREATE',
  SOCKET_AMPLIFY_CAUSE_UPDATE: 'DASHBOARD_SOCKET_AMPLIFY_CAUSE_UPDATE',

  GET_CREATOR_RECOMMENDED_BRIEF: 'GET_CREATOR_RECOMMENDED_BRIEF',
  GET_CREATOR_RECOMMENDED_BRIEF_SUCCESS: 'GET_CREATOR_RECOMMENDED_BRIEF_SUCCESS',
  GET_CREATOR_RECOMMENDED_BRIEF_FAILURE: 'GET_CREATOR_RECOMMENDED_BRIEF_FAILURE',

  GET_AMPLIFY_RECOMMENDED_BRIEF: 'GET_AMPLIFY_RECOMMENDED_BRIEF',
  GET_AMPLIFY_RECOMMENDED_BRIEF_SUCCESS: 'GET_AMPLIFY_RECOMMENDED_BRIEF_SUCCESS',
  GET_AMPLIFY_RECOMMENDED_BRIEF_FAILURE: 'GET_AMPLIFY_RECOMMENDED_BRIEF_FAILURE',

  GET_CREATOR_PITCHES_BRIEF: 'GET_CREATOR_PITCHES_BRIEF',
  GET_CREATOR_PITCHES_BRIEF_SUCCESS: 'GET_CREATOR_PITCHES_BRIEF_SUCCESS',
  GET_CREATOR_PITCHES_BRIEF_FAILURE: 'GET_CREATOR_PITCHES_BRIEF_FAILURE',

  GET_EDITOR_WAITING_BRIEF: 'GET_EDITOR_WAITING_BRIEF',
  GET_EDITOR_WAITING_BRIEF_SUCCESS: 'GET_EDITOR_WAITING_BRIEF_SUCCESS',
  GET_EDITOR_WAITING_BRIEF_FAILURE: 'GET_EDITOR_WAITING_BRIEF_FAILURE',
}
