import React, { useMemo, useCallback } from 'react'
import classNames from 'classnames'

import './Button.css'

const Button = props => {
  const { onClick, title, classes, isDisable } = props
  

  const currentClasses = useMemo(() => ({
    ...Button.defaultProps.classes,
    ...classes
  }), [classes])

  const handleClick = useCallback((...args) => {
    if(!isDisable) {
      onClick(...args)
    }
  }, [isDisable, onClick])

  return (
    <div className={classNames('custom-button-wrapper', currentClasses.wrapper)}>
      <button
        data-target={props['data-target']}
        data-toggle={props['data-toggle']}
        disabled={isDisable}
        className={classNames('custom-button legitRipple', currentClasses.button)}
        onClick={handleClick}>{ title }</button>
    </div>
  )
}

Button.defaultProps = {
  title: 'Button',
  classes: {},
  onClick: () => null,
  isDisable: false
}

export default Button