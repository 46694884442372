import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react'
import _ from 'lodash'

import Button from 'components/ui/Button'
import InputSearch from 'components/ui/InputSearch'
import Modal from 'components/ui/Modal'
import SpinnerLoadData from 'components/ui/SpinnerLoadData'
import { User } from './User'

import { ReactComponent as IconClose } from 'assets/images/icons/icon-close-manage-users.svg'
import { ReactComponent as ManageIcon } from 'assets/images/icons/manage-icon.svg'

import * as workspaceActions from 'store/actions/workspace'
import * as amplifyCausePermissionsActions from 'store/actions/amplifyCausePermissions'

import './ManageUsers.css'
import { useDispatch, useSelector } from 'react-redux'
import { useOnClickOutside } from 'helpers/hooks/useClickOutside'
import { useCallbackWithTimer } from 'helpers/hooks/useCallbackWithTimer'

export const AmplifyManageUsers = (props) => {
  const dispatch = useDispatch()

  const dialogModalRef = useRef()

  const currentWorkspace = useSelector((state) =>
    _.get(state, 'auth.workspace', {})
  )
  const isGetFetchingWorkspaceUsers = useSelector((state) =>
    _.get(state, 'workspace.users.isGetFetching')
  )
  const workspaceUsers = useSelector((state) =>
    _.get(state, 'workspace.users.items')
  )
  const isGetFetchingAmplifyCausePermissions = useSelector((state) =>
    _.get(state, 'amplifyCausePermissions.isGetFetching')
  )
  const amplifyCausePermissions = useSelector((state) =>
    _.get(state, 'amplifyCausePermissions.items')
  )
  const currentUser = useSelector((state) => _.get(state, 'auth.user', null))

  const amplifyCauseId = useSelector((state) =>
    _.get(state, 'amplifyCausePermissions.manageUsersModal.amplifyCauseId')
  )
  const showUsers  = _.filter(workspaceUsers, user => user.amplifyRoles !== 0 && user.id !== currentUser.id)

  const [searchValueUser, setSearcValueUser] = useState(undefined)
  const [newPermissions, setNewPermissions] = useState({})
  const [changedPermissions, setChangedPermissions] = useState([])

  const handleGetWorkspaceUsers = useCallback(
    (_query) => {
      const query = {
        workspaceId: currentWorkspace.id,
        ..._query,
      }
      dispatch(
        workspaceActions.getWorkspaceUsers(query)
      )
    },
    [dispatch, currentWorkspace]
  )

  useEffect(() => {
    handleGetWorkspaceUsers()
  }, [])

  useEffect(() => {
    if (amplifyCauseId) {
      dispatch(
        amplifyCausePermissionsActions.getAmplifyCausePermissions({
          amplifyCauseId,
        })
      )
    }
  }, [dispatch, amplifyCauseId])

  const handleCloseModal = useCallback(() => {
    if (amplifyCauseId) {
      dispatch(amplifyCausePermissionsActions.clearCauseIdManageUserModal())
      setNewPermissions({})
      setChangedPermissions({})
    }
  }, [amplifyCauseId, dispatch])

  const handleUpdatePermissions = useCallback(() => {
    _.size(newPermissions) &&
      dispatch(
        amplifyCausePermissionsActions.createAmplifyCausePermissions(
          newPermissions
        )
      )

    _.size(changedPermissions) &&
      dispatch(
        amplifyCausePermissionsActions.updateAmplifyCausePermissions(
          changedPermissions
        )
      )

    handleCloseModal()
  }, [dispatch, newPermissions, changedPermissions, handleCloseModal])

  const handleSearch = useCallbackWithTimer((value) => {
    handleGetWorkspaceUsers(!_.isEmpty(value) ? { userName: value } : null)
  }, [])

  const handleChangeSearchUsers = useCallback(
    ({ target: { value } }) => {
      setSearcValueUser(value)
      handleSearch(value)
    },
    [handleSearch]
  )

  useOnClickOutside(dialogModalRef, handleCloseModal, [handleCloseModal])

  const handleChooseUser = useCallback(
    (userId, userPermission) => {
      if (!userPermission) {
        setNewPermissions({
          ...newPermissions,
          [userId]: {
            userId,
            grantedBy: currentUser.id,
            permission: 0,
            amplifyCauseId,
            isActive: true,
          },
        })
      } else {
        setChangedPermissions({
          ...changedPermissions,
          [userId]: {
            ...userPermission,
            isActive: true,
          },
        })
      }
    },
    [newPermissions, currentUser, amplifyCauseId, changedPermissions]
  )

  const handleChangeUserPermission = useCallback(
    (userId, userPermission, newValues) => {
      const isNewPermission = _.has(newPermissions, userId)

      if (isNewPermission) {
        const isActiveFalse = _.isEqual(newValues.isActive, false)

        setNewPermissions(
          isActiveFalse
            ? _.omit(newPermissions, userId)
            : {
                ...newPermissions,
                [userId]: {
                  ...userPermission,
                  ...newValues,
                },
              }
        )
      } else {
        setChangedPermissions({
          ...changedPermissions,
          [userId]: {
            ...userPermission,
            ...newValues,
          },
        })
      }
    },
    [newPermissions, changedPermissions]
  )

  const rendererUsers = useMemo(() => {
    const usersIsNotEmpty = !_.includes(
      [_.isEmpty(showUsers), _.isNil(showUsers)],
      true
    )

    if (isGetFetchingWorkspaceUsers || isGetFetchingAmplifyCausePermissions) {
      return <SpinnerLoadData timeDelay="0" isAbsolute={false} />
    } else {
      return usersIsNotEmpty
        ? _.map(showUsers, (user) => {
            const userPermission = _.find(
              {
                ...amplifyCausePermissions,
                ...newPermissions,
                ...changedPermissions,
              },
              (itemPremission) => _.isEqual(itemPremission.userId, user.id)
            )

            return (
              <User
                key={user.id}
                userId={user.id}
                userName={user.userName}
                image={user.image}
                onChooseUser={handleChooseUser}
                userPermission={userPermission}
                onChangeUserPermission={handleChangeUserPermission}
              />
            )
          })
        : null
    }
  }, [
    isGetFetchingAmplifyCausePermissions,
    isGetFetchingWorkspaceUsers,
    showUsers,
    amplifyCausePermissions,
    handleChooseUser,
    newPermissions,
    changedPermissions,
    handleChangeUserPermission,
  ])

  return (
    <Modal
      idModal="manageUsersModal"
      classNameModalDialog="manage-user-modal-dialog"
      dialogRef={dialogModalRef}
    >
      <IconClose
        data-toggle="modal"
        data-target="#manageUsersModal"
        className="manage-user-modal-cross-icon"
        onClick={handleCloseModal}
      />
      <div className="manage-users-modal-header">
        <span className="manage-users-modal-header-title">Manage Users</span>
        <span className="manage-users-modal-header-info">
          Inviting people to the project will allow them to see all
          communication
        </span>
        <InputSearch
          value={searchValueUser}
          onChange={handleChangeSearchUsers}
          standartIcon={false}
          classes={{
            wrapper: 'manage-users-modal-header-search-wrapper',
            inputWrapper: 'manage-users-modal-header-search-input-wrapper',
            input: 'manage-users-modal-header-search-input',
            icon: 'manage-users-modal-header-search-input-icon',
          }}
        />
      </div>
      <div className="manage-users-modal-body">
        <div className="manage-users-modal-mediacom">
          <ManageIcon />
          <span className="manage-users-modal-mediacom-text">{currentWorkspace.name}</span>
        </div>
        <div className="manage-users-modal-users">{rendererUsers}</div>
      </div>
      <div className="manage-user-modal-footer">
        <div className="manage-user-modal-footer-buttons">
          <Button
            data-toggle="modal"
            data-target="#manageUsersModal"
            title="close"
            classes={{
              wrapper: 'manage-user-modal-footer-button-wrapper',
              button: 'manage-user-modal-footer-button',
            }}
            onClick={handleCloseModal}
          />
          <Button
            title="update"
            data-toggle="modal"
            data-target="#manageUsersModal"
            onClick={handleUpdatePermissions}
            classes={{
              wrapper: 'manage-user-modal-footer-button-wrapper',
              button:
                'manage-user-modal-footer-button manage-user-modal-footer-button-update',
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
