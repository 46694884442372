export default {

  GET: 'SERVER:USER_GET',
  GET_SUCCESS: 'USER_GET_SUCCESS',
  GET_FAILURE: 'USER_GET_FAILURE',

  GET_ID: 'SERVER:USER_GET_ID',
  GET_ID_SUCCESS: 'USER_GET_ID_SUCCESS',
  GET_ID_FAILURE: 'USER_GET_ID_FAILURE',

  ACCEPT_TERMS: 'SERVER:USER_ACCEPT_TERMS',
  ACCEPT_TERMS_SUCCESS: 'USER_ACCEPT_TERMS_SUCCESS',
  ACCEPT_TERMS_FAILURE: 'USER_ACCEPT_TERMS_FAILURE',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',
  UPDATE: 'USER_UPDATE',
  SELECT_CONTRACTOR: 'USER_SELECT_CONTRACTOR',  
  UPDATE_USER_BY_SOCKET: 'UPDATE_USER_BY_SOCKET'
}
