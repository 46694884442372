import Joi from 'joi-browser'
import moment from 'moment'

export const validation = (model, rules, data = {}) => {
  const newModel = {...model}
  let isValid = true
  if(!rules) {
    return {
      ...model,
      isValid: undefined
    }
  }
  const rulesName = Object.keys(rules)
  let message = ''
  rulesName.some(rule => {
    isValid = chekByRule(rule, rules, model.value, data)
    message = rules[rule].msg
    return !isValid 
  })

  newModel.isValid = isValid
  newModel.message = message
  return newModel
} 

const chekByRule = (rule, rules, value, data) => {

  if(rule === 'isDateBeforeToday') {
    return moment(value).isAfter(new Date())
  }

  if(rule === 'isCurrentDateBeforeRange') {
    return data.date ? !moment(value).isBefore(data.date) : true
  }

  if(rule === 'isCurrentDateAfterRange') {
    return data.date ? !moment(value).isAfter(data.date) : true
  }
  
  const schema = generateSchema(rule, rules, value)
  if(!schema) {
    return true
  } 

  if(rule === 'isDate') {
    return moment(value).isValid()
  }
  
  const result = Joi.validate(
    {
      validField: value
    },
    schema, 
    (err, value) => {
      return err === null
    }
  )


  return result
}

const generateSchema = (rule, rules, value) => {
  switch (rule) {
  case 'max': {
    const ruleObj = rules[rule]
    const schema = Joi.object().keys({
      validField: Joi.string().max(ruleObj.args),
    })

    return schema
  }
  
  case 'isMaxCause': {
    const schema = Joi.object().keys({
      validField: Joi.string().max(320),
    })
    
    return schema
  }

  case 'length': {
   // TODO: Check this situation 
    if (!value) {
      return null
    }
    const ruleObj = rules[rule]
    const schema = Joi.object().keys({
      validField: Joi.string().max(ruleObj.value),
    })
    
    return schema
  }    

  case 'min': {
    const ruleObj = rules[rule]
    const schema = Joi.object().keys({
      validField: Joi.string().min(ruleObj.args),
    })

    return schema
  }

  case 'len': {
    const ruleObj = rules[rule]
    const schema = Joi.object().keys({
      validField: Joi.string().min(ruleObj.args[0]).max(ruleObj.args[1]).allow(''),
    })

    return schema
  }  

  case 'isEmail': {
    const reg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    const schema = Joi.object().keys({
      validField: Joi.string().regex(reg)
    })

    return schema
  } 
  
  case 'isNumeric': {
      // TODO: Check this situation 
      if (value === null) {
        return null
      }
    const schema = Joi.object().keys({
      validField: Joi.number().allow(''),
    })

    return schema
  }   

  case 'notEmpty': {
    const schema = Joi.object().keys({
      validField: typeof value === 'object' 
        ? Joi.object().min(1)
        : Joi.string().min(1),
    })

    return schema
  } 

  case 'isUUID': {
    const schema = Joi.object().keys({
      validField: Joi.string().guid(),
    })

    return schema
  }  
  
  case 'is': {
    const ruleObj = rules[rule]
    const reg = new RegExp(ruleObj.regExp, 'i')

    const schema = Joi.object().keys({
      validField: Joi.string().regex(reg),
    })

    return schema
  } 

  case 'not': {
    const ruleObj = rules[rule]
    const regExp = ruleObj.regExp.replace('[', '[^')
    const reg = new RegExp(regExp, 'i')
    const schema = Joi.object().keys({
      validField: Joi.string().regex(reg),
    })

    return schema
  }       

  default:
    return null
  }
}

//eslint-disable-next-line
export const regExpUrlValid = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm