import { put, takeEvery } from 'redux-saga/effects'

import types from '../constants/model'

import * as modelActions from '../actions/model'

import backend from 'store/api/feathers'

function* getModel(action) {
  try {
    const data = yield backend.service('model').find() 

    if(data) {
      yield put(modelActions.getModelSuccess(data))
    }

  } catch (e) {
    yield put(modelActions.getModelFailure(e))
    // yield backend.logout()

  }

}

function* modelSaga() {

  yield takeEvery(types.MODEL, getModel)

}

export default {
  modelSaga
}
