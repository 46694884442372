export default {

  SEARCH_GETTY_IMAGE:           'SEARCH_GETTY_IMAGE',
  SEARCH_GETTY_IMAGE_SUCCESS:   'SEARCH_GETTY_IMAGE_SUCCESS',
  SEARCH_GETTY_IMAGE_FAILURE:   'SEARCH_GETTY_IMAGE_FAILURE', 

  CLEAR_GETTY_DATA:             'CLEAR_GETTY_DATA',
  DOWNLOAD_GETTY_IMAGE:         'DOWNLOAD_GETTY_IMAGE',
  START_GETTY_IMAGE_UPLOAD:     'START_GETTY_IMAGE_UPLOAD',
  DOWNLOAD_GETTY_IMAGE_SUCCESS: 'DOWNLOAD_GETTY_IMAGE_SUCCESS',
  DOWNLOAD_GETTY_IMAGE_FAILURE: 'DOWNLOAD_GETTY_IMAGE_FAILURE',
  SET_IMAGE_LIMIT:              'SET_IMAGE_LIMIT',
  SAVE_GETTY_LIMIT:             'SAVE_GETTY_LIMIT',
  GET_GETTY_LIMIT:              'GET_GETTY_LIMIT',
}