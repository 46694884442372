import types from '../constants/causeUser'
import authTypes from '../constants/auth'
import _ from 'lodash'

//validation model
const validationParams = {
  id: {
    value: undefined,
    isValid: undefined,
  },
  tagline: {
    value: '',
    isValid: undefined,
  },
  outline: {
    value: '',
    isValid: undefined,
  },
  brandIntegration: {
    value: '',
    isValid: undefined,
  },
  brandItem: {
    value: undefined,
    isValid: undefined,
  },
  contentLike: {
    value: '',
    isValid: undefined,
  },
  previousContent: {
    value: '',
    isValid: undefined,
  },
  isStar: {
    value: false,
    isValid: undefined
  },
  isHide: {
    value: false,
    isValid: undefined
  },
  causeUserStatus: {
    value: null,
    isValid: undefined
  },
  contractorId: {
    value: null,
    isValid: undefined
  },
  buyerId: {
    value: null,
    isValid: undefined
  },
  causeId: {
    value: null,
    isValid: undefined
  }

}

//flags for check loaded data
const fetching = {
  isGetFetching: false,
  isGetIdFetching: false,
  isUpdateFetching: false
}

const initialState = {
  origin: {},
  originContractor: {},
  edit: {},
  validationEdit: {
    ...validationParams
  },
  validationCreate: {
    ...validationParams
  },
  ...fetching,

  filter: {
    causeUserStatus: [],
    isHide: false,
    isStar: undefined
  },
  list: {
    origin: {},
    page: 0,
    count: 0,
  },
  sort: undefined,
  isEmpty: true,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.GET_SUCCESS:
      {
        return {
          ...state,
          list: {
            ...state.list,
            origin: _.keyBy(action.payload.data, 'id'),
            count: action.payload.count,
            page: action.payload.page
          },
          isGetFetching: false,
          isEmpty: action.payload.isEmpty,
        }
      }

    case types.GET_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetFetching: false
        }
      }

    case types.GET_ID: {
      return {
        ...state,
        edit: {
          ...initialState.edit
        },
        validationEdit: {
          ...initialState.validationEdit
        },
        isGetIdFetching: true
      }
    }

    case types.GET_ID_SUCCESS:
      {
        const payload = action.payload.data

        //data coercion to a validation object
        const toArr = Object.entries(validationParams)
        const nextValidModel = toArr.reduce((obj, item) => {
          const key = item[0]
          const isBoolean = typeof payload[key] === 'boolean'
          obj[key] = {
            value: payload[key] || (isBoolean ? payload[key] : undefined),
            isValid: true
          }

          return obj
        }, {})

        return {
          ...state,
          isGetIdFetching: false,
          edit: {
            causeInfo: payload.causeInfo,
            contractorCauseUser: payload.contractorCauseUser,
            buyerCauseUser: payload.buyerCauseUser
          },
          validationEdit: {
            id: {
              value: payload.id
            },
            ...nextValidModel
          },
          list: {
            ...state.list,
            origin: {
              ...state.list.origin,
              [payload.id]: {
                ...payload
              }
            }
          }
        }

      }

    case types.GET_ID_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetIdFetching: false
        }
      }

    case types.CREATE:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.CREATE_SUCCESS:
      {
        const payload = action.payload
        return {
          ...state,
          origin: payload,
          validationCreate: {
            ...validationParams
          },
          isGetFetching: false
        }
      }

    case types.CREATE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetFetching: false
        }
      }

    case types.UPDATE:
      {
        return {
          ...state,
          isUpdateFetching: true
        }
      }

    case types.UPDATE_SUCCESS:
      {
        const payload = action.payload.data || action.payload
        const toArr = Object.entries(validationParams)
        const nextValidModel = toArr.reduce((obj, item) => {
          const key = item[0]
          const isBoolean = typeof payload[key] === 'boolean'
          obj[key] = {
            value: payload[key] || (isBoolean ? payload[key] : undefined),
            isValid: true
          }
          return obj
        }, {})

        return {
          ...state,
          list: {
            ...state.list,
            origin: {
              ...state.list.origin,
              [payload.id]: {
                ...payload
              }
            },
          },
          origin: {
            ...payload
          },
          validationEdit: {
            id: {
              value: payload.id
            },
            ...nextValidModel
          },
          isUpdateFetching: false
        }
      }

    case types.REMOVE_SUCCESS:
      {
        const newOriginContractor = { ...state.originContractor }
        delete newOriginContractor[action.payload.id]
        const newListOrigin = _.omit(_.cloneDeep(state.list.origin), action.payload.id)
        return {
          ...state,
          originContractor: { ...newOriginContractor },
          validationEdit: {
            ...validationParams
          },
          edit: initialState.edit,
          list: {
            ...state.list,
            origin: newListOrigin,
          },
          origin: {}
        }
      }

    case types.FIND_CAUSE_USER:
      {
        return {
          ...state,
          edit: {
            ...initialState.edit
          },
          validationEdit: {
            ...initialState.validationEdit
          },
          isGetIdFetching: true
        }
      }

    case types.FIND_CAUSE_USER_SUCCESS:
      {
        const payload = action.payload

        if (!payload) {
          return {
            ...state,
            origin: {},
            edit: {},
            validationEdit: {
              ...validationParams
            },
            isGetIdFetching: false
          }
        }

        //data coercion to a validation object
        const toArr = Object.entries(validationParams)
        const nextValidModel = toArr.reduce((obj, item) => {
          const key = item[0]
          const isBoolean = typeof payload[key] === 'boolean'
          obj[key] = {
            value: payload[key] || (isBoolean ? payload[key] : undefined),
            isValid: true
          }

          return obj
        }, {})
        return {
          ...state,
          isGetIdFetching: false,
          origin: {
            ...action.payload
          },
          edit: {
            causeInfo: payload.causeInfo,
            contractorCauseUser: payload.contractorCauseUser,
            buyerCauseUser: payload.buyerCauseUser
          },
          validationEdit: {
            id: {
              value: payload.id
            },
            ...nextValidModel
          },
        }
      }

    case types.FIND_CAUSE_USER_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetIdFetching: false
        }
      }

    case types.VALIDATION:
      {
        const validType = action.payload.isCreate ? 'validationCreate' : 'validationEdit'
        return {
          ...state,
          [validType]: {
            ...state[validType],
            ...action.payload.data
          }
        }
      }

    case types.SET_FILTER:
      {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
          origin: {
            ...initialState.origin
          },
          list: {
            ...state.list,
            page: 0,
          }

        }
      }

    case types.RESET_FILTERS:
      {
        return {
          ...state,
          filter: {
            ...initialState.filter
          }
        }
      }

    case types.SET_SORT:
      {
        return {
          ...state,
          sort: action.payload,
          origin: {
            ...initialState.origin
          }
        }
      }

    case authTypes.SIGNOUT_SUCCESS:
      {
        return {
          ...initialState,
        }
      }

    case types.SET_PAGE:
      {
        return {
          ...state,
          list: {
            page: action.payload
          }
        }
      }

    case types.RESET_VALIDATION_EDIT:
      {
        return {
          ...state,
          validationEdit: {
            ...validationParams
          },
          edit: {}
        }
      }


    case types.RESET_VALIDATION_CREATE:
      {
        return {
          ...state,
          validationCreate: {
            ...validationParams
          }
        }
      }

    default:
      return state
  }
}
