import types from '../constants/amplifyCausePermissions'

export const getAmplifyCausePermissions = query => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_FIND,
  payload: query
})

export const getAmplifyCausePermissionsSuccess = data => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_FIND_SUCCESS,
  payload: data
})

export const getAmplifyCausePermissionsError = error => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_FIND_ERROR,
  payload: error
})

export const createAmplifyCausePermissions = data => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_CREATE,
  payload: data
})

export const createAmplifyCausePermissionsSuccess = data => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_CREATE_SUCCESS,
  payload: data
})

export const createAmplifyCausePermissionsError = error => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_CREATE_ERROR,
  payload: error
})

export const updateAmplifyCausePermissions = data => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE,
  payload: data
})

export const updateAmplifyCausePermissionsSuccess = data => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE_SUCCESS,
  payload: data
})

export const updateAmplifyCausePermissionsError = error => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE_ERROR,
  payload: error
})

export const setCauseIdManageUserModal = id => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID,
  payload: id
})

export const clearCauseIdManageUserModal = () => ({
  type: types.AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID
})