import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import types from '../constants/buyerTodos'
import * as actions from '../actions/buyerTodos'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'

import backend from 'store/api/feathers'


function* get(action) {
  try {
    const { page, filter, pageSize} = action.payload
    const requestPayload = {}
    if (filter && filter.sortBy) {
        requestPayload['sortBy'] = filter.sortBy.value 
    }
    const todosData = yield backend.service(`todos?pageSize=${pageSize}&page=${page}&searchText=${filter && filter.search || ''}`).create(requestPayload)
    if(_.size(todosData)) {
      yield put(actions.getSuccess(todosData))
    }
  } catch (e) {
    renderErrorNotification(e, 'Buyer todos list error')
    yield put(actions.getFailure(e))
  }
}

function* buyerTodoSaga() {
  yield takeEvery(types.GET, get)
}

export default {
    buyerTodoSaga,
}
