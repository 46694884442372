export default {

  CAUSE_GET:                    'SERVER:CAUSE_GET',
  CAUSE_GET_SUCCESS:            'CAUSE_GET_SUCCESS',
  CAUSE_GET_FAILURE:            'CAUSE_GET_FAILURE',

  CAUSE_GET_DASHBOARD:          'SERVER:CAUSE_GET_DASHBOARD',
  CAUSE_GET_DASHBOARD_SUCCESS:  'CAUSE_GET_DASHBOARD_SUCCESS',
  CAUSE_GET_DASHBOARD_FAILURE:  'CAUSE_GET_DASHBOARD_FAILURE',

  CAUSE_GET_TASK:               'SERVER:CAUSE_GET_TASK',
  CAUSE_GET_TASK_SUCCESS:       'CAUSE_GET_TASK_SUCCESS',
  CAUSE_GET_TASK_FAILURE:       'CAUSE_GET_TASK_FAILURE',

  CAUSE_REMOVE:                 'SERVER:CAUSE_REMOVE',
  CAUSE_REMOVE_SUCCESS:         'CAUSE_REMOVE_SUCCESS',
  CAUSE_REMOVE_FAILURE:         'CAUSE_REMOVE_FAILURE',  

  CAUSE_GET_ID:                 'SERVER:CAUSE_GET_ID',
  CAUSE_GET_ID_SUCCESS:         'CAUSE_GET_ID_SUCCESS',
  CAUSE_GET_ID_FAILURE:         'CAUSE_GET_ID_FAILURE',

  CAUSE_EDIT:                   'CAUSE_EDIT',
  VALIDATION:                   'CAUSE_VALIDATION',

  CAUSE_UPDATE:                 'SERVER:CAUSE_UPDATE',
  CAUSE_UPDATE_SUCCESS:         'CAUSE_UPDATE_SUCCESS',
  CAUSE_UPDATE_FAILURE:         'CAUSE_UPDATE_FAILURE',
  
  CAUSE_CREATE:                 'SERVER:CAUSE_CREATE',
  CAUSE_CREATE_SUCCESS:         'CAUSE_CREATE_SUCCESS',
  CAUSE_CREATE_FAILURE:         'CAUSE_CREATE_FAILURE',
  CAUSE_CREATE_CLEAR:           'CAUSE_CREATE_CLEAR',

  CAUSE_HIRE:                   'SERVER:CAUSE_HIRE',
  CAUSE_HIRE_SUCCESS:           'CAUSE_HIRE_SUCCESS',
  CAUSE_HIRE_FAILURE:           'CAUSE_HIRE_FAILURE',

  CAUSE_SEARCH:                 'SERVER:CAUSE_SEARCH',
  CAUSE_SEARCH_SUCCESS:         'CAUSE_SEARCH_SUCCESS',
  CAUSE_SEARCH_FAILURE:         'CAUSE_SEARCH_FAILURE', 

  CAUSE_SEARCH_SELECT:          'CAUSE_SEARCH_SELECT',
  CAUSE_SEARCH_SELECT_RESET:    'CAUSE_SEARCH_SELECT_RESET',
  CAUSE_SET_FILTER:             'CAUSE_SET_FILTER',
  CAUSE_SET_SORT:               'CAUSE_SET_SORT',
  RESET_FILTERS:                'RESET_FILTERS',
  SET_PAGE:                     'CAUSE_SET_PAGE',
  

  ATTACH:                       'CAUSE_ATTACH',
  REMOVE_FILE:                  'CAUSE_REMOVE_FILE',
  RESET_ATTACH_CHANGES:         'CAUSE_RESET_ATTACH_CHANGES',

  UPLOAD:                       'SERVER:CAUSE_UPLOAD',
  UPLOAD_SUCCESS:               'CAUSE_UPLOAD_SUCCESS',
  UPLOAD_FAILURE:               'CAUSE_UPLOAD_FAILURE', 
  
  EDIT_FILE:                    'SERVER:EDIT_FILE',
  EDIT_FILE_SUCCESS:            'EDIT_FILE_SUCCESS',
  EDIT_FILE_FAILURE:            'EDIT_FILE_FAILURE', 

  DOWNLOAD_FILE:                'SERVER:DOWNLOAD_FILE',
  DOWNLOAD_PDF:                 'SERVER:DOWNLOAD_PDF',
  DOWNLOAD_FILE_SUCCESS:        'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE:        'DOWNLOAD_FILE_FAILURE',  

  CAUSE_CREATE_ASSET:           'SERVER:CAUSE_CREATE_ASSET',
  CAUSE_CREATE_ASSET_SUCCESS:   'CAUSE_CREATE_ASSET_SUCCESS',
  CAUSE_CREATE_ASSET_FAILURE:   'CAUSE_CREATE_ASSET_FAILURE',

  CAUSE_CREATE_REQUEST_SUCCESS: 'CAUSE_CREATE_REQUEST_SUCCESS',
  CAUSE_EDIT_REQUEST:           'SERVER:CAUSE_EDIT_REQUEST',
  SELECT_MILESTONE:             'SELECT_MILESTONE',
  UPDATE_SELECT_MILESTONE:      'UPDATE_SELECT_MILESTONE',
  SET_CAUSE_BRAND_INFORMATION:  'SET_CAUSE_BRAND_INFORMATION',
  SET_CAUSE_TARGET_INFORMATION:  'SET_CAUSE_TARGET_INFORMATION',

  GET_TOTAL_VIEW:               'GET_TOTAL_VIEW',
  GET_TOTAL_VIEW_FAILURE:       'GET_TOTAL_VIEW_FAILURE',
  GET_TOTAL_VIEW_SUCCESS:       'GET_TOTAL_VIEW_SUCCESS',

  CLEAR_EDIT_DATA: 'CAUSE_CLEAR_EDIT_DATA',
  CLEAR_ORIGIN: 'CAUSE_ORIGIN',

  CLEAR_DROPZONE_ATTACHMENTS: 'CLEAR_DROPZONE_ATTACHMENTS',
  CLEAR_VAULT_UPLOAD_FILES: 'CLEAR_VAULT_UPLOAD_FILES',

  CAUSE_UPLOAD_PROGRES: 'CAUSE_UPLOAD_PROGRES',

  CAUSE_DELETE_FILE: 'CAUSE_DELETE_FILE',
  CAUSE_DELETE_FILE_SUCCESS: 'CAUSE_DELETE_FILE_SUCCESS',
  CAUSE_DELETE_FILE_FAILURE: 'CAUSE_DELETE_FILE_FAILURE',
  
  CAUSE_CURRENT_UPLOADING_API_CALLS: 'CAUSE_CURRENT_UPLOADING_API_CALLS',
  CAUSE_CLEAR_UPLOADING_API_CALLS: 'CAUSE_CLEAR_UPLOADING_API_CALLS',

  ADD_VAULT_FILES_CAUSE: 'ADD_VAULT_FILES_CAUSE',

  CAUSE_EDIT_DELETED_FILE: 'CAUSE_EDIT_DELETED_FILE',
  CAUSE_CLEAR_EDIT_DELETED_FILE: 'CAUSE_CLEAR_EDIT_DELETED_FILE'
}
