import { put, takeEvery, all } from 'redux-saga/effects'

import PNotify from 'pnotify'

import _ from 'lodash'

import backend from 'store/api/feathers'

import types from 'store/constants/amplifyCausePermissions'
import * as actions from 'store/actions/amplifyCausePermissions'

function* find(action) {
  try {
    const query = action.payload
    const amplifyCausePermissions = yield backend
      .service('amplify-cause-permissions')
      .find({ query })

    if (_.size(amplifyCausePermissions)) {
      yield put(
        actions.getAmplifyCausePermissionsSuccess(amplifyCausePermissions.data)
      )
    }
  } catch (e) {
    yield put(actions.getAmplifyCausePermissionsError(e))
  }
}

function* create(action) {
  try {
    const amplifyCausePermissions = yield all(
      _.map(
        action.payload,
        async (item) =>
          await backend.service('amplify-cause-permissions').create(item)
      )
    )

    if (_.size(amplifyCausePermissions)) {
      // new PNotify({
      //   addclass: 'bg-success alert alert-styled-left',
      //   title: '',
      //   text: 'Users permissions successful update',
      //   hide: true,
      //   buttons: {
      //     closer: true,
      //     sticker: true,
      //   },
      // })

      yield put(actions.createAmplifyCausePermissionsSuccess())
    }
  } catch (e) {
    yield put(actions.createAmplifyCausePermissionsError(e))
  }
}

function* update(action) {
  try {
    const amplifyCausePermissions = yield all(
      _.map(
        action.payload,
        async (item) =>
          await backend
            .service('amplify-cause-permissions')
            .patch(item.id, item)
      )
    )

    if (_.size(amplifyCausePermissions)) {
      yield put(actions.updateAmplifyCausePermissionsSuccess())

      // new PNotify({
      //   addclass: 'bg-success alert alert-styled-left',
      //   title: '',
      //   text: 'Users permissions successful update',
      //   hide: true,
      //   buttons: {
      //     closer: true,
      //     sticker: true,
      //   },
      // })
    }
  } catch (e) {
    yield put(actions.updateAmplifyCausePermissionsError(e))
  }
}

function* amplifyCausePermissionsSaga() {
  yield takeEvery(types.AMPLIFY_CAUSE_PERMISSIONS_FIND, find)
  yield takeEvery(types.AMPLIFY_CAUSE_PERMISSIONS_CREATE, create)
  yield takeEvery(types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE, update)
}

export default {
  amplifyCausePermissionsSaga,
}
