import types from '../constants/chat'

export const getConversation = (conversationId) => ({
  type: types.GET_PARTICIPANTS,
  payload: {conversationId},
})

export const getConversationSuccess = data => ({
  type: types.GET_PARTICIPANTS_SUCCESS,
  payload: data
})

export const getConversationFailure = (err) => ({
  type: types.GET_PARTICIPANTS_FAILURE,
  payload: err
})

export const joinConversation = (conversationId, user) => ({
  type: types.JOIN_CONVERSATION,
  payload: {conversationId, user},
})

export const joinConversationSuccess = data => ({
  type: types.JOIN_CONVERSATION_SUCCESS,
  payload: data
})

export const joinConversationFailure = (err) => ({
  type: types.JOIN_CONVERSATION_FAILURE,
  payload: err
})

export const leaveConversation = (conversationId, userId) => ({
  type: types.LEAVE_CONVERSATION,
  payload: {conversationId, userId},
})

export const leaveConversationSuccess = data => ({
  type: types.LEAVE_CONVERSATION_SUCCESS,
  payload: data
})

export const leaveConversationFailure = (err) => ({
  type: types.LEAVE_CONVERSATION_FAILURE,
  payload: err
})

export const setSelectedConversation = conversationId => ({
  type: types.SET_SELECTED_CONVERSATION,
  payload: conversationId
})

export const setMessages = (data) => {
  return({
    type: types.UNREADED_MESSAGES,
    payload: data
  })
}

