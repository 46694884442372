import types from '../constants/cause'
import { validation } from 'helpers/validation'

export const get = (queryParams, page) => ({
  type: types.CAUSE_GET,
  payload: {
    queryParams,
    page
  }
})

export const getSuccess = ({ data, count }, isNull) => ({
  type: types.CAUSE_GET_SUCCESS,
  payload: {
    data,
    count,
    isNull
  }
})

export const getFailure = (error) => ({
  type: types.CAUSE_GET_FAILURE,
  payload: error
})

export const getDashboard = (queryParams, subName) => ({
  type: types.CAUSE_GET_DASHBOARD,
  payload: {
    queryParams,
    subName
  }
})

export const getDashboardSuccess = ({ data }, subName) => ({
  type: types.CAUSE_GET_DASHBOARD_SUCCESS,
  payload: {
    data,
    subName
  }
})

export const getDashboardFailure = (error) => ({
  type: types.CAUSE_GET_DASHBOARD_FAILURE,
  payload: error
})

export const getTask = (id, queryParams) => ({
  type: types.CAUSE_GET_TASK,
  payload: {
    id,
    queryParams,
  }
})

export const getTaskSuccess = ({ data }) => ({
  type: types.CAUSE_GET_TASK_SUCCESS,
  payload: { ...data }
})

export const getTaskFailure = (error) => ({
  type: types.CAUSE_GET_TASK_FAILURE,
  payload: error
})

export const create = (data, files, history) => {
  const attachments = files && files.length > 0 ? files.filter(item => item.storeId) : []
  return ({
    type: types.CAUSE_CREATE,
    payload: {
      cause: { ...data, attachments },
      history
    }
  })
}

export const createSuccess = (data) => ({
  type: types.CAUSE_CREATE_SUCCESS,
  payload: { ...data }
})

export const createFailure = (error) => ({
  type: types.CAUSE_CREATE_FAILURE,
  payload: error
})

export const createClear = () => ({
  type: types.CAUSE_CREATE_CLEAR,
})

export const select = (data, isCreate) => ({
  type: types.CAUSE_SEARCH_SELECT,
  payload: { data, isCreate }
})

export const resetSelect = (isCreate) => ({
  type: types.CAUSE_SEARCH_SELECT_RESET,
  payload: isCreate
})

export const search = (type, query) => ({
  type: types.CAUSE_SEARCH,
  payload: {
    type, query
  }
})

export const searchSuccess = (data) => ({
  type: types.CAUSE_SEARCH_SUCCESS,
  payload: data
})

export const searchFailure = (error) => ({
  type: types.CAUSE_SEARCH_FAILURE,
  payload: error
})

export const remove = (id) => ({
  type: types.CAUSE_REMOVE,
  payload: id
})

export const removeSuccess = (data) => ({
  type: types.CAUSE_REMOVE_SUCCESS,
  payload: data
})

export const removeFailure = (error) => ({
  type: types.CAUSE_REMOVE_FAILURE,
  payload: error
})

export const getId = (id) => ({
  type: types.CAUSE_GET_ID,
  payload: id
})

export const getIdSuccess = ({ data }, isSocket) => ({
  type: types.CAUSE_GET_ID_SUCCESS,
  payload: {
    data,
    isSocket
  }
})

export const getIdFailure = (error) => ({
  type: types.CAUSE_GET_ID_FAILURE,
  payload: error
})

export const edit = (data) => ({
  type: types.CAUSE_EDIT,
  payload: data,
})

export const setValid = (model, rules, type, isCreate, data) => {
  const newModel = validation(model, rules, data)
  return {
    type: types.VALIDATION,
    payload: {
      data: {
        [type]: { ...newModel }
      },
      isCreate
    },
  }
}

export const setFilter = (queryParams) => ({
  type: types.CAUSE_SET_FILTER,
  payload: queryParams
})

export const setSort = (params) => ({
  type: types.CAUSE_SET_SORT,
  payload: params
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
  payload: null
})

export const update = (data, attachments, query, isPost) => ({
  type: types.CAUSE_UPDATE,
  payload: {
    data: {
      ...data,
      attachments,
      query,
    },
    isPost
  }
})

export const updateSuccess = (data, isSocket) => ({
  type: types.CAUSE_UPDATE_SUCCESS,
  payload: {
    data,
    isSocket
  }
})

export const updateFailure = (error) => ({
  type: types.CAUSE_UPDATE_FAILURE,
  payload: error
})

export const attachFile = (params, isCreate) => {
  return {
    type: types.ATTACH,
    payload: {
      params,
      isCreate
    }
  }
}

export const removeAttach = (params, isCreate) => {
  return {
    type: types.REMOVE_FILE,
    payload: {
      params,
      isCreate
    }
  }
}

export const resetAttachChanges = (files) => {
  return {
    type: types.RESET_ATTACH_CHANGES,
    payload: files,
  }
}

export const editAttach = (params, isCreate) => {
  return {
    type: types.EDIT_FILE,
    payload: {
      params,
      isCreate
    }
  }
}

export const editAttachSuccess = (params) => {
  return {
    type: types.EDIT_FILE_SUCCESS,
    payload: {
      params
    }
  }
}

export const editAttachFailure = (params) => {
  return {
    type: types.EDIT_FILE_FAILURE,
    payload: {
      params
    }
  }
}

export const uploadFile = (params, isCreate) => {
  return {
    type: types.UPLOAD,
    payload: {
      params,
      isCreate
    }
  }
}

export const uploadFileSuccess = (data, preview, isCreate) => {

  return {
    type: types.UPLOAD_SUCCESS,
    payload: {
      data,
      preview,
      isCreate
    }
  }

}


export const uploadFileFailure = (error, preview, type, isCreate) => {

  return {
    type: types.UPLOAD_FAILURE,
    payload: {
      error,
      preview,
      isCreate,
      type
    }
  }

}

export const downloadFile = (id, ref) => ({
  type: types.DOWNLOAD_FILE,
  payload: {
    id,
    ref
  }
})

export const downloadPDF = data => ({
  type: types.DOWNLOAD_PDF,
  payload: {
    data,
  }
})

export const downloadFileSuccess = (data) => ({
  type: types.DOWNLOAD_FILE_SUCCESS,
  payload: data
})

export const downloadFileFailure = (error) => ({
  type: types.DOWNLOAD_FILE_FAILURE,
  payload: error
})

export const requestData = (data) => ({
  type: types.CAUSE_CREATE_REQUEST_SUCCESS,
  payload: data
})

export const editRequest = (data) => ({
  type: types.CAUSE_EDIT_REQUEST,
  payload: data
})

export const selectMilestone = (data) => ({
  type: types.SELECT_MILESTONE,
  payload: data
})

export const updateSelectedMilestone = (data) => ({
  type: types.UPDATE_SELECT_MILESTONE,
  payload: data
})

export const setBrandInformation = (data) => ({
  type: types.SET_CAUSE_BRAND_INFORMATION,
  payload: data
})

export const setTargetInformation = (data) => ({
  type: types.SET_CAUSE_TARGET_INFORMATION,
  payload: data
})

export const setPage = (pageNumber) => ({
  type: types.SET_PAGE,
  payload: pageNumber
})

export const causeHire = (causeHireData, pitchUpdateData) => ({
  type: types.CAUSE_HIRE,
  payload: {
    causeHireData,
    pitchUpdateData
  }
})

export const causeHireSuccess = (data) => ({
  type: types.CAUSE_HIRE_SUCCESS,
  payload: {
    data
  }
})

export const causeHireFailure = (error) => ({
  type: types.CAUSE_HIRE_FAILURE,
  payload: error
})

export const getTotalView = (briefIds) => ({
  type: types.GET_TOTAL_VIEW,
  payload: {
    briefIds
  }
})

export const getTotalViewSuccess = (data) => ({
  type: types.GET_TOTAL_VIEW_SUCCESS,
  payload: data
})

export const getTotalViewFailure = (error) => ({
  type: types.GET_TOTAL_VIEW_FAILURE,
  payload: error
})

export const clearEditData = () => ({
  type: types.CLEAR_EDIT_DATA
})

export const clearOrigin = () => ({
  type: types.CLEAR_ORIGIN
})

export const clearDropzoneAttachments = () => ({
  type: types.CLEAR_DROPZONE_ATTACHMENTS
})

export const clearVaultUploadFiles = () => ({
  type: types.CLEAR_VAULT_UPLOAD_FILES
})

export const updateUploadCount = (countData) => {
  return ({
    type: types.CAUSE_UPLOAD_PROGRES,
    payload: countData
  })
}

export const causeDeleteFile = (data) => ({
  type: types.CAUSE_DELETE_FILE,
  payload: data
})

export const causeDeleteFileSuccess = (data) => ({
  type: types.CAUSE_DELETE_FILE_SUCCESS,
  payload: data
})

export const causeDeleteFileFailure = (error) => ({
  type: types.CAUSE_DELETE_FILE_FAILURE,
  payload: error
})

export const causeCurrentUploadingApiCalls = (data) => ({
  type: types.CAUSE_CURRENT_UPLOADING_API_CALLS,
  payload: data
})

export const causeClearUploadingApiCall = (data) => ({
  type: types.CAUSE_CLEAR_UPLOADING_API_CALLS,
  payload: data
})

export const addVaultUploadFiles = (data) => ({
  type: types.ADD_VAULT_FILES_CAUSE,
  payload: data 
})

export const causeEditDeletedFiles = (data) => ({
  type: types.CAUSE_EDIT_DELETED_FILE,
  payload: data
})

export const clearCauseEditDeletedFiles = () => ({
  type: types.CAUSE_CLEAR_EDIT_DELETED_FILE
})
// CAUSE_UPLOAD_PROGRES