import types from '../constants/product'
import _ from 'lodash'


const initialState = {
  origin: {},
  error: null,
  isGetFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET: {
    return {
      ...state,
      isGetFetching: true
    }
  }
  
  case types.GET_SUCCESS:

  {
    return {
      ...state,
      origin: _.keyBy(action.payload.data, 'id'),
      isGetFetching: false
    }
  }


  case types.GET_FAILURE:

  {
    return {
      ...state,
      error: action.payload,
      isGetFetching: false
    }
  }

  default:
    return state
  }

}