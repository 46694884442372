import { call, put, takeEvery } from 'redux-saga/effects'

import types from '../constants/init'
import moment from 'moment'

import * as initActions from '../actions/init'
import * as modelActions from '../actions/model'

import * as Api from '../api'

function* init(action) {

  try {
    const appStatus = yield call(Api.get, 'status')
    yield put(initActions.initSuccess(appStatus))

    const appLegal = yield call(Api.get, 'legal')
    yield put(initActions.legalSuccess(appLegal))

    yield put(modelActions.getModel())

  } catch (e) {
    yield put(initActions.initFailure(e))
  }
}

function* initSaga() {
  yield takeEvery(types.INIT, init)
}

export default initSaga
