import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/portfolio'
import * as actions from '../actions/portfolio'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* create(action) {

  try {
    const portfolioData = yield backend.service('portfolio').create({
      ...action.payload.data
    })
    if(_.size(portfolioData)) {
      yield put(actions.createSuccess(portfolioData))

    }

  } catch (e) {
    renderErrorNotification(e, 'Create portfolio error')
    yield put(actions.createFailure(e))
  }

}

function* get(action) {

  try {
    const portfolioList = yield backend.service('portfolio').find()
    if(_.size(portfolioList.data)) {
      yield put(actions.getSuccess(portfolioList))  
    }
  
  } catch (e) {
    renderErrorNotification(e, 'Get portfolio list error')
    yield put(actions.getFailure(e))
  }
  
}

function* remove(action) {

  try {
    const portfolioItemRemove = yield backend.service('portfolio').remove(action.payload)
    
    yield put(actions.removeSuccess(portfolioItemRemove))

  } catch (e) {
    renderErrorNotification(e, 'Remove portfolio list error')
    yield put(actions.removeFailure(e))
  }

}

function* portfolioSaga() {
  yield takeEvery(types.CREATE, create)

  yield takeEvery(types.GET, get)

  yield takeEvery(types.REMOVE, remove)
}

export default {
  portfolioSaga,
}
