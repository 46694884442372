import types from '../constants/workroomGuest'

//flags for check loaded data
const fetching = {
  isGetFetching: false,  
  isGetIdFetching: false,
}

const initialState = {
  ...fetching,
  id: {
    value: '',
  },
  commentModel: {
    id: null,
    commentText: '',
    createdAt: null,
    updatedAt: null
  },
  userName: {
    value: ''
  }
}

export default (state = initialState, action) => {

  switch (action.type) {

  case types.GET:
  { 
    return {
      ...state,
      isGetFetching: true
    }    
  }

  case types.GET_SUCCESS:
  {
    return {
      ...state,
      sharedProducts: action.payload.data,
      isGetFetching: false
    }
  }

  case types.GET_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
      isGetFetching: false      
    }
  } 

  case types.GET_ID: {
    return {
      ...state,
      isGetIdFetching: true       
    }
  }

  case types.GET_ID_SUCCESS: 
  {
    return {
      ...state,
      isGetIdFetching: false,
      sharedProduct: action.payload.data
    }
  }

  case types.GET_ID_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
      isGetIdFetching: false
    }
  }

  case types.TEXT_AREA_CHANGE:
  {
    return {
      ...state,
      commentModel: {
        ...state.commentModel,
        commentText: action.payload
      }
    }
  }

  case types.MODAL_CHANGE:
  {
    return {
      ...state,
      userName: {
        ...state.userName,
        value: action.payload
      }
    }
  }
  
  case types.SUBMIT_COMMENT_SUCCESS:
  {
    return {
      ...state,
      sharedProduct: {
        ...state.sharedProduct,
        comments: action.payload.comments
      },
      commentModel: initialState.commentModel
    }
  }

  case types.SUBMIT_COMMENT_FAILURE:
  {
    return {
      ...state,
      error: action.payload
    }
  }
  
  default:

    return state

  }

}
