import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import { post as postAxios, get as getAxios } from 'store/api'

import types from '../constants/vault'
import * as actions from '../actions/vault'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend, { apiUrl } from 'store/api/feathers'

import { removeMultiItems } from 'store/api'

function* get(action) {
  try {
    const { query, page, isInfiniteScroll } = action.payload
    const { count, rows } = yield backend.service('vault').find({
      query: {
        ...query,
        page
      }
    })
    yield put(actions.getSuccess({
      causeList: rows,
      count,
      page,
      isInfiniteScroll
    }))
  } catch (e) {
    renderErrorNotification(e, 'Get vault list error')
    yield put(actions.getFailure(e))
  }
}

function* remove(action) {
  try {
    yield removeMultiItems(apiUrl, action.payload);
    yield put(actions.removeSuccess())
    new PNotify({
      type: 'success',
      title: 'Success',
      text: action.payload && (action.payload.length === 1
        ? 'The file has been successfully deleted'
        : 'The files have been successfully deleted')
    })
  } catch (e) {
    renderErrorNotification(e, 'Delete cause error')
    yield put(actions.removeFailure(e))
  }
}

function* updateTags(action) {
  try {
    const { id, data } = action.payload
    const updatedData = yield backend.service('vault').update(id, data)
    yield put(actions.updateFileTagsSuccess(updatedData))
  } catch (e) {
    renderErrorNotification(e, 'Tags update error')
    yield put(actions.updateFileTagsFailure(e))
  }
}


function* uploadFile(action) {
  const { uri, fileName, isVideo, done, tokenInstance } = action.payload.params
  let formData = null
  const fileExtensionName = fileName.split('.').pop()
  if (isVideo) {
    formData = new FormData()
    formData.append('file', uri)
  } else {
    let _uri = uri;
    if (fileName.includes('.csv')) {
      _uri = 'data:text/csv;base64,'  + _uri.split('base64,').pop();
    }
    formData = new FormData()
    formData.append('uri', _uri)
    formData.append('fileName', fileName)
  }
  formData.append('id', fileName)
  try {
    let uploadData = null
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'File-Type': fileExtensionName,
      },
      cancelToken: tokenInstance[fileName].token,
      onUploadProgress: done,
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   console.log('percentCompleted=====', percentCompleted)
      // }
    }
    if (isVideo) {
      uploadData = yield postAxios('upload-stream', formData, config)
      uploadData.size = uri.size
      uploadData.uri = uri
    } else {
      uploadData = yield postAxios('uploads', formData, config)
    }
    if (_.size(uploadData)) {
      const duplicateData = yield getAxios(`vault?storeId=media/${uploadData.id}`)
      if (duplicateData.length) {
        yield put(actions.uploadFileSuccess({
          uri: {
            id: duplicateData[0].storeId,
            storeId: duplicateData[0].storeId,
            name: duplicateData[0].mediaName || duplicateData[0].name,
            size: uri.size || (duplicateData[0].metaData && duplicateData[0].metaData.ContentLength),
            documentId: duplicateData[0].documentId,
            uploadedFromVault: true,
            isDuplicate: true
          },
          fieldType: action.payload.params.fieldType,
          id: duplicateData[0].storeId,
          storeId: duplicateData[0].storeId,
          size: uri.size || (duplicateData[0].metaData && duplicateData[0].metaData.ContentLength),
        }, action.payload.params.preview, action.payload.isCreate))
      } else {
        yield put(actions.uploadFileSuccess({
          ...uploadData,
          fieldType: action.payload.params.fieldType
        }, action.payload.params.preview, action.payload.isCreate))
        
      }
      if (duplicateData.length) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `This file already exists. Uploaded ${action.payload.params.fileName} from vault`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      } else {
        new PNotify({
          addclass: 'bg-info custom',
          text: `${action.payload.params.fileName} uploaded`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
       }
      yield put(actions.clearUploadingApiCall(fileName))
    }
  } catch (e) {
    const msg = _.get(e, 'response.data.err', e.message)
    yield put(actions.clearUploadingApiCall(fileName))
    if (msg === 'Field value too long') {
      renderErrorNotification({}, `${action.payload.params.fileName} size is too large`)
    } else if (msg === 'Cannot read property \'MISSING_COMMA\' of undefined') {
      renderErrorNotification({}, `${action.payload.params.fileName} size can't be zero`)
    } else {
      if (e.message !== undefined) {
        renderErrorNotification(e, 'Upload file error')
      }
    }
    yield put(actions.uploadFileFailure(e, action.payload.params.preview, action.payload.fieldType, action.payload.isCreate))
  }

}

function* filterVaultFolder(action) {
  try {
    const { id, page, limit, skip, search } = action.payload
    const { rows, count } = yield backend.service('folder').get(id, {
      query: {
        limit,
        skip,
        page,
        search
      }
    })
    yield put(
      actions.filterVaultFolderSuccess({
        causeList: rows,
        count,
        page,
        limit,
        skip
      })
    )
  } catch (e) {
    renderErrorNotification(e, 'Get attachment list error')
    yield put(actions.filterVaultFolderFailure(e))
  }
}


function* getAttachmentBriefList(action) {
  try {
    const { id } = action.payload
    const briefList = yield backend.service('vault').get(id)

    const filteredBriefList = briefList && briefList.filter(item => {
      if (item.amplifyonly && (item.amplifyCauseStatus !== 5)) {
        return item
      } else if ((item.isDeleted === undefined || item.isDeleted === null || item.isDeleted === false)){
        return item
      }
    }
    )

    yield put(actions.getAttachmentBriefListSuccess({
      attachmentBriefList: filteredBriefList,
    }))
  } catch (e) {
    renderErrorNotification(e, 'Get attachment brief list error')
    yield put(actions.getAttachmentBriefListFailure(e))
  }
}

function* deleteFileVault(action) {

  try {
    const { id } = action.payload
    if (id ) {
      const deleteFileData = yield backend.service('delete-file').create({ id })
      if (_.size(deleteFileData)) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `${id} has been deleted`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
        yield put(actions.vaultDeleteFileSuccess(deleteFileData))
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Delete file error')
    yield put(actions.vaultDeleteFileFailure(e))
  }

}

function* vaultSaga() {
  yield takeEvery(types.GET, get)
  yield takeEvery(types.VAULT_REMOVE, remove)
  yield takeEvery(types.UPDATE_FILE_TAGS, updateTags)
  yield takeEvery(types.UPLOAD, uploadFile)
  yield takeEvery(types.FILTER_VAULT_FOLDER, filterVaultFolder)
  yield takeEvery(types.GET_ATTACHMENT_BRIEF_LIST, getAttachmentBriefList)
  yield takeEvery(types.VAULT_DELETE_FILE, deleteFileVault)
}

export default {
  vaultSaga
}