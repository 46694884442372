export default {

  USER_GET_SUCCESS:        'AUTH_USER_GET_SUCCESS',
  USER_GET_FAILURE:        'AUTH_USER_GET_FAILURE',

  SIGNIN:                  'SERVER:SIGNIN',
  SIGNIN_SUCCESS:          'SIGNIN_SUCCESS',
  SIGNIN_FAILURE:          'SIGNIN_FAILURE',

  SIGNOUT:                 'SERVER:SIGNOUT',
  SIGNOUT_SUCCESS:         'SIGNOUT_SUCCESS',
  SIGNOUT_FAILURE:         'SIGNOUT_FAILURE',

  SIGNUP:                  'SERVER:SIGNUP',
  SIGNUP_SUCCESS:          'SIGNUP_SUCCESS',
  SIGNUP_FAILURE:          'SIGNUP_FAILURE',

  RESET_PASSWORD:          'SERVER:RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS:  'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE:  'RESET_PASSWORD_FAILURE',

  UPDATE_PASSWORD:         'SERVER:UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  VERIFY_USER_BY_EMAIL:         'SERVER:VERIFY_USER_BY_EMAIL',
  VERIFY_USER_BY_EMAIL_SUCCESS: 'VERIFY_USER_BY_EMAIL_SUCCESS',
  VERIFY_USER_BY_EMAIL_FAILURE: 'VERIFY_USER_BY_EMAIL_FAILURE',

  INIT_USER_EDIT: 'INIT_USER_EDIT',
  USER_EDIT: 'USER_EDIT', 
  RESET_USER_EDIT: 'RESET_USER_EDIT',

  USER_INFO_UPDATE:         'SERVER:USER_INFO_UPDATE',
  USER_INFO_UPDATE_SUCCESS: 'USER_INFO_UPDATE_SUCCESS',
  USER_INFO_UPDATE_FAILURE: 'USER_INFO_UPDATE_FAILURE',
  
  USER_UPLOAD_PHOTO:         'SERVER:USER_UPLOAD_PHOTO',
  USER_UPLOAD_PHOTO_SUCCESS: 'USER_UPLOAD_PHOTO_SUCCESS',
  USER_UPLOAD_PHOTO_FAILURE: 'USER_UPLOAD_PHOTO_FAILURE',
  
  USER_UPDATE_PASSWORD:         'SERVER:USER_UPDATE_PASSWORD',
  USER_UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
  USER_UPDATE_PASSWORD_FAILURE: 'USER_UPDATE_PASSWORD_FAILURE',

  USER_LOGIN_AS_ADMIN: 'SERVER:USER_LOGIN_AS_ADMIN',
}
