import types from '../constants/vaultFolder'

export const getFolder = (query) => {
  return {
    type: types.GET_FOLDER,
    payload: {
      query,
    },
  }
}

export const getFolderSuccess = (data) => {
  return {
    type: types.GET_FOLDER_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export const getFolderFailure = (error) => ({
  type: types.GET_FOLDER_FAILURE,
  payload: error,
})

export const getAttachments = ({ id }) => {
  return {
    type: types.GET_ATTACHMENTS,
    payload: {
      id,
    },
  }
}

export const getAttachmentsSuccess = (data) => {
  return {
    type: types.GET_ATTACHMENTS_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export const getAttachmentsFailure = (error) => ({
  type: types.GET_ATTACHMENTS_FAILURE,
  payload: error,
})

export const checkFolderExist = (folderName) => {
  return {
    type: types.FOLDER_EXIST,
    payload: {
        folderName,
    },
  }
}

export const checkFolderExistSuccess = (data) => {
  return {
    type: types.FOLDER_EXIST_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export const checkFolderExistFailure = (error) => ({
  type: types.FOLDER_EXIST_FAILURE,
  payload: error,
})

export const clearFolderExist = () => {
  return ({
    type: types.CLEAR_FOLDER_EXIST,
  })
  
}
export const createFolder = (payload) => {
  return {
    type: types.CREATE_FOLDER,
    payload,
  }
}

export const createFolderSuccess = (data) => {
  return {
    type: types.CREATE_FOLDER_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export const createFolderFailure = (error) => ({
  type: types.CREATE_FOLDER_FAILURE,
  payload: error,
})

export const clearVaultFolder = () => ({
  type: types.CLEAR_VAULT_FOLDER,
})

export const setVaultUploadSecondStep = (payload) => ({
  type: types.VAULT_UPLOAD_SECOND_STEP_DATA,
  payload
})
