import types from '../constants/signUp'
import authTypes from '../constants/auth'

const initialState = {
  params: {
    isTerms: false,
    isSubscribe: true,
    isSendTestSettings: false  
  },
  validation: {
    userName: {
      value: '',
      isValid: undefined,
    },
    phoneNumber: {
      value: '',
      isValid: undefined,
    },
    email: {
      value: '',
      isValid: undefined,
    },
    password: {
      value: '',
      isValid: undefined,
    },        
  }  

}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.SET_PARAMS:
  {
    return {
      ...state,
      params: { 
        ...state.params, 
        ...action.payload 
      }
    }
  }

  case types.VALIDATION:

  {

    return {
      ...state,
      validation: { 
        ...state.validation, 
        ...action.payload 
      }
    }

  }

  case authTypes.SIGNOUT_SUCCESS:
  {
    return {
      ...initialState,
    }
  }    

  default:
    return state
  }
}
