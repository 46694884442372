/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useCallback } from 'react'
import Button from 'components/ui/Button'

import { useSelector, useDispatch } from 'react-redux'

import _ from 'lodash'

import {
  get as notificationsGet,
  update as notificationsUpdate,
  remove as notificationsRemove
} from 'store/actions/notification'
import { generateNotificationList } from 'store/selectors/notificationSelector'

import IconNotifications from 'components/ui/IconNotifications'
import SpinnerLoadData from 'components/ui/SpinnerLoadData'

import { NotificationList } from './NotificationList'

export const Notifications = props => {
  const { filter } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      notificationsGet({
        ...filter
      })
    )
  }, [filter])

  const currentUser = useSelector(state => _.get(state, 'auth.user'))
  const notificationList = useSelector(state => generateNotificationList(state))
  const isGetFetching = useSelector(state =>
    _.get(state, 'notification.isGetFetching', true)
  )

  const isHasNewNotifications = useMemo(
    () =>
      !_.isEmpty(
        _.filter(
          _.filter(notificationList, [
            'notificationUser[0].userId',
            currentUser.id
          ]),
          ['notificationUser[0].isNew', true]
        )
      ),
    [notificationList, currentUser.id]
  )

  const handleDeleteNotification = useCallback(
    (notificationuserId, notificationId) => () => {
      dispatch(notificationsRemove(notificationuserId, notificationId))
    },
    [dispatch]
  )

  const handleReadNotification = useCallback(
    id => () => {
      dispatch(
        notificationsUpdate({
          id,
          isNew: false
        })
      )
    },
    [dispatch]
  )

  const handleShowMore = () => {
    window.scrollTo(0, 1500)
  }

  const rendererNotificationList = useMemo(() => {
    return isGetFetching ? (
      <SpinnerLoadData timeDelay="0" isAbsolute={false} />
    ) : (
      <NotificationList
        currentUser={currentUser}
        notificationList={notificationList}
        onDelete={handleDeleteNotification}
        onRead={handleReadNotification}
      />
    )
  }, [isGetFetching, currentUser, notificationList, handleDeleteNotification, handleReadNotification])

  return (
    <li className={`dropdown-notification dropdown`}>
      <a
        id="dropdownNotifications"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        className="dropdown-toggle legitRipple"
      >
        <IconNotifications isHasNewNotifications={isHasNewNotifications} />
      </a>

      <div className="dropdown-menu dropdown-content" aria-labelledby="dropdownNotifications">
        <ul className="media-list dropdown-content-body width-350">
          {rendererNotificationList}
        </ul>
        {/* {
          currentUser.permissions === 2
          && <div className="notification-show-more-button">
          <Button
            classes={{
              wrapper: 'next-form-button-wrapper',
              button: 'next-form-brief-button'
            }}
            title={
              <>Show more</>
            }
            onClick={handleShowMore}
          />
        </div>
        } */}
      </div>
    </li>
  )
}
