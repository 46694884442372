import { useEffect } from 'react'

import _ from 'lodash'

export const useOnClickOutside = (ref, handler, depencies = []) => {
  useEffect(
    () => {

      const types = ['mousedown', 'touchstart']

      const listener = event => {
        if (!ref.current.contains(event.target)) {
          handler(event)
        }
      }

      _.forEach(types, type => document.addEventListener(type, listener))

      return () => _.forEach(types, type => document.removeEventListener(type, listener))
    },

    [ref, handler, ...depencies],
  )
}
