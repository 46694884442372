import { put, takeEvery } from 'redux-saga/effects'
import backend from 'store/api/feathers'

import types from '../constants/chat'
import * as chatActions from '../actions/chat'
import { renderErrorNotification } from 'helpers/tools'

function* getConversation({ payload }) {
  try {
    const data = yield backend.service('chat').get(payload.conversationId)
    if(data) {
      yield put(chatActions.getConversationSuccess(data))
    }
  } catch (e) {
    yield put(chatActions.getConversationFailure(e))
  }
}

function* joinConversation({ payload }) {
  const { conversationId, user } = payload
  try {
    yield backend.service('chat').create(user)
    yield backend.service('chat').patch(conversationId, { userId: user.id })
    const data = yield backend.service('chat').get(conversationId)
    if(data) {
      yield put(chatActions.getConversationSuccess(data))
    }
  } catch (e) {
    renderErrorNotification({}, 'Error join to conversation')
    yield put(chatActions.joinConversationFailure(e))
  }
}

function* leaveConversation({ payload }) {
  try {
    yield backend.service('chat').patch(payload.conversationId, {...payload, flag: 'delete'})
    const data = yield backend.service('chat').get(payload.conversationId) 
    if(data) {
      yield put(chatActions.getConversationSuccess(data))
    }    
  } catch (e) {
    renderErrorNotification({}, 'Error leave to conversation')
    yield put(chatActions.leaveConversationFailure(e))
  }
}

function* chatSaga() {
  yield takeEvery(types.GET_PARTICIPANTS, getConversation)
  yield takeEvery(types.JOIN_CONVERSATION, joinConversation)
  yield takeEvery(types.LEAVE_CONVERSATION, leaveConversation)
}

export default {
  chatSaga
}
