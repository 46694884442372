import types from '../constants/amplifyCause'
import { validation } from 'helpers/validation'

export const get = (page, query) => ({
  type: types.AMPLIFY_CAUSE_GET,
  payload: {
    page,
    query
  }
})

export const getSuccess = (data, page) => ({
  type: types.AMPLIFY_CAUSE_GET_SUCCESS,
  payload: {
    data,
    page
  }
})

export const setOrigin = (data) => ({
  type: types.AMPLIFY_CAUSE_SET_ORIGIN,
  payload: {
    data
  }
})

export const clearOrigin = () => ({
  type: types.AMPLIFY_CAUSE_CLEAR_ORIGIN,
})

export const removeCause = (workspaceId, productIndex) => ({
  type: types.AMPLIFY_CAUSE_REMOVE,
  payload: {
    workspaceId,
    productIndex
  }
})

export const changeCause = (data) => ({
  type: types.AMPLIFY_CAUSE_CHANGE_PRODUCT,
  payload: {
    data
  }
})

export const setValid = (model, rules, type, isCreate, data) => {
  const newModel = validation(model, rules, data)
  return {
    type: types.VALIDATION,
    payload: {
      data: {
        [type]: { ...newModel }
      },
      isCreate
    },
  }
}

export const setEdit = (data) => ({
  type: types.AMPLIFY_CAUSE_SET_EDIT,
  payload: data,
})

export const getFailure = error => ({
  type: types.AMPLIFY_CAUSE_GET_FAILURE,
  payload: error
})

export const setFilter = newFilter => ({
  type: types.AMPLIFY_CAUSE_SET_FILTER,
  payload: newFilter
})

export const resetFilters = () => ({
  type: types.AMPLIFY_CAUSE_RESET_FILTERS
})

export const setPage = page => ({
  type: types.AMPLIFY_CAUSE_SET_PAGE,
  payload: page
})

export const getId = id => ({
  type: types.AMPLIFY_CAUSE_GET_ID,
  payload: id
})

export const getIdSuccess = data => ({
  type: types.AMPLIFY_CAUSE_GET_ID_SUCCESS,
  payload: data
})

export const getIdFailure = error => ({
  type: types.AMPLIFY_CAUSE_GET_ID_FAILURE,
  payload: error
})

export const update = (id, data, done) => ({
  type: types.AMPLIFY_CAUSE_UPDATE,
  payload: {
    id,
    data,
    done
  }
})

export const updateSuccess = (data, isSocket) => ({
  type: types.AMPLIFY_CAUSE_UPDATE_SUCCESS,
  payload: {
    data,
    isSocket
  }
})

export const updateFailure = (error) => ({
  type: types.AMPLIFY_CAUSE_UPDATE_FAILURE,
  payload: error
})

export const clearDeletedProducts = () => {
  return ({
  type: types.AMPLIFY_CAUSE_CLEAR_DELETED_PRODUCTS,
})}