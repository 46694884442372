import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withRouter } from 'react-router'

import NavButtons from './NavButtons'

import * as vaultActions from 'store/actions/vault'
import * as causeUserActions from 'store/actions/causeUser'
import * as causeActions from 'store/actions/cause'
import * as recommendedActions from 'store/actions/recommended'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  causeUserActions: bindActionCreators(causeUserActions, dispatch),
  causeActions: bindActionCreators(causeActions, dispatch),
  recommendedActions: bindActionCreators(recommendedActions, dispatch),
  vaultActions: bindActionCreators(vaultActions, dispatch)
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NavButtons)
)