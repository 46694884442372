export default {

    GET:           'SERVER:WITHOUTEDITOR_GET',
    GET_SUCCESS:   'WITHOUTEDITOR_GET_SUCCESS',
    GET_FAILURE:   'WITHOUTEDITOR_GET_FAILURE',

    SET_FILTER:    'WITHOUTEDITOR_SET_FILTER',
    RESET_FILTER:  'WITHOUTEDITOR_RESET_FILTER',

    SET_PAGE:      'WITHOUTEDITOR_SET_PAGE'
}