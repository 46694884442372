import { put, takeEvery, all, select } from 'redux-saga/effects'

import PNotify from 'pnotify'

import _ from 'lodash'

import backend from 'store/api/feathers'

import types from '../constants/buyerCausePermissions'
import * as actions from '../actions/buyerCausePermissions'

function* find(action) {
  try {
    const query = action.payload.causeId ? action.payload : {
      causeId: action.payload
    }
    const buyerCausePermissions = yield backend
      .service('buyer-cause-permissions')
      .find({ query })

    if (_.size(buyerCausePermissions)) {
      yield put(
        actions.getBuyerCausePermissionsSuccess(buyerCausePermissions.data)
      )
    }
  } catch (e) {
    yield put(actions.getBuyerCausePermissionsError(e))
  }
}

function* create(action) {
  try {
    const buyerCausePermissions = yield all(
      _.map(
        action.payload,
        async (item) =>
          await backend.service('buyer-cause-permissions').create(item)
      )
    )

    if (_.size(buyerCausePermissions)) {
      const { buyerCausePermissions: { manageUsersModal: { buyerCauseId: causeId } } } = yield select();
      yield put(actions.createBuyerCausePermissionsSuccess(causeId))

      // new PNotify({
      //   addclass: 'bg-success alert alert-styled-left',
      //   title: '',
      //   text: 'Users permissions successful update',
      //   hide: true,
      //   buttons: {
      //     closer: true,
      //     sticker: true,
      //   },
      // })
    }
  } catch (e) {
    yield put(actions.createBuyerCausePermissionsError(e))
  }
}

function* update(action) {
  try {
    const buyerCausePermissions = yield all(
      _.map(
        action.payload,
        async (item) =>
          await backend
            .service('buyer-cause-permissions')
            .patch(item.id, item)
      )
    )

    if (_.size(buyerCausePermissions)) {
      const { buyerCausePermissions: { manageUsersModal: { buyerCauseId: causeId } } } = yield select();

      yield put(actions.updateBuyerCausePermissionsSuccess(causeId))
      yield put(actions.clearCauseIdManageUserModal())

      // new PNotify({
      //   addclass: 'bg-success alert alert-styled-left',
      //   title: '',
      //   text: 'Users permissions successful update',
      //   hide: true,
      //   buttons: {
      //     closer: true,
      //     sticker: true,
      //   },
      // })
    }
  } catch (e) {
    yield put(actions.updateBuyerCausePermissionsError(e))
  }
}

function* buyerCausePermissionsSaga() {
  yield takeEvery(types.BUYER_CAUSE_PERMISSIONS_FIND, find)
  yield takeEvery(types.BUYER_CAUSE_PERMISSIONS_CREATE, create)
  yield takeEvery(types.BUYER_CAUSE_PERMISSIONS_UPDATE, update)
  yield takeEvery(types.BUYER_CAUSE_PERMISSIONS_UPDATE_SUCCESS, find)
  yield takeEvery(types.BUYER_CAUSE_PERMISSIONS_CREATE_SUCCESS, find)
}

export default {
  buyerCausePermissionsSaga,
}
