import { put, takeEvery } from 'redux-saga/effects'

import * as actions from '../actions/withoutEditor'
import types from '../constants/withoutEditor'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const page = action.payload.page
    const queryParams = action.payload.queryParams

    const withoutEditorList = yield backend.service('cause-without-editor').find({
      query: {
        ...queryParams,
        sort: 'createdAt',
        page
      }
    })
    yield put(actions.getSuccess(withoutEditorList))
    
  } catch (e) {
    renderErrorNotification(e, 'Get product list error')    
    yield put(actions.getFailure(e))
  }
    
}
    
function* withoutEditorSaga() {
  yield takeEvery(types.GET, get)
}
    
export default {
  withoutEditorSaga,
}
    