import types from '../constants/account'

export const get = (queryParams) => ({
  type: types.GET,
  payload: {
    queryParams
  }
})

export const getSuccess = ({ data }) => ({
  type: types.GET_SUCCESS,
  payload: data
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const editWorkspace = (workspace) => ({
  type: types.EDIT_WORKSPACE,
  payload: {
    workspace
  }
})

export const updateWorkspace = (workspace) => ({
  type: types.UPDATE_WORKSPACE,
  payload: {
    workspace
  }
})

export const updateWorkspaceSuccess = (data) => ({
  type: types.UPDATE_WORKSPACE_SUCCESS,
  payload: {
    workspace: data
  }
})

export const updateWorkspaceFailure = (error) => ({
  type: types.UPDATE_WORKSPACE_FAILURE,
  payload: error
})

export const downloadInvoice = (data) => ({
  type: types.DOWNLOAD_INVOICE,
  payload: data
})

export const downloadInvoiceSuccess = (data) => ({
  type: types.DOWNLOAD_INVOICE_SUCCESS,
  payload: data
})

export const downloadInvoiceFailure = (error) => ({
  type: types.DOWNLOAD_INVOICE_FAILURE,
  payload: error
})

export const setPage = (pageNumber) => ({
  type: types.SET_PAGE,
  payload: pageNumber       
})

export const setFilter = newFilter => ({
  type: types.SET_ACCOUNT_FILTER,
  payload: newFilter
})

export const resetFilters = () => ({
  type: types.RESET_ACCOUNT_FILTER
})
