import types from '../constants/amplifyWorkroom'
import _ from 'lodash'
import moment from 'moment'

const initialState = {
  error: null,
  isGetFetching: false,
  isGetDeletingFile: false,
  isRemoveAttachment: false,
  list: {},
  uploadCountList: [],
  deletedFile: {},
  currentUploadingApiCallData:[],
  amplifyWorkroomVaultUpload: null,
  isGetAmplifyWorkroomVaultUpload: false,
  vaultFilesAmplify: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AMPLIFY_WORKROOM_GET: {
      return {
        ...state,
        isGetFetching: true
      }
    }
    case types.AMPLIFY_WORKROOM_GET_SUCCESS: {
      const data = action.payload
      return {
        ...state,
        isGetFetching: false,
        list: _.keyBy(data, 'id')
      }
    }
    case types.AMPLIFY_WORKROOM_GET_FAILURE: {
      return {
        ...state,
        isGetFetching: false,
        error: action.payload
      }
    }
    case types.AMPLIFY_WORKROOM_UPDATE: {
      return {
        ...state,
        isGetFetching: true
      }
    }
    case types.AMPLIFY_WORKROOM_UPDATE_SUCCESS: {
      const { payload } = action
      return {
        ...state,
        list: {
          ...state.list,
          [payload.id]: payload
        },
        isGetFetching: false
      }
    }
    case types.AMPLIFY_WORKROOM_UPDATE_FAILURE: {
      return {
        ...state,
        isGetFetching: false,
        error: action.payload
      }
    }
    case types.AMPLIFY_WORKROOM_UPLOAD_FILE: {
      const { amplifyWorkroomId, fileName, labelValue, size } = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          [amplifyWorkroomId]: {
            ...state.list[amplifyWorkroomId],
            amplifyWorkroomAttachments: [
              {
                // name: 'Uploading file',
                name: fileName,
                amplifyWorkroomId,
                size,
              },
              ...state.list[amplifyWorkroomId].amplifyWorkroomAttachments,
            ]
          }
        }
      }
    }
    case types.AMPLIFY_WORKROOM_UPLOAD_FILE_SUCCESS: {
      const { amplifyWorkroomId, newAttachment } = action.payload

      const currentAttachments = state.list[amplifyWorkroomId].amplifyWorkroomAttachments
      const index = _.findIndex(currentAttachments, item => !item.id)
      currentAttachments[index] = newAttachment

      if (newAttachment && newAttachment.isDuplicate) {
        currentAttachments[index].documentId = newAttachment.documentId
        currentAttachments[index].uploadedFromVault = true
      }

      return {
        ...state,
        isUploading: false,
        list: {
          ...state.list,
          [amplifyWorkroomId]: {
            ...state.list[amplifyWorkroomId],
            amplifyWorkroomAttachments: currentAttachments
          }
        }
      }
    }
    case types.AMPLIFY_WORKROOM_UPLOAD_FILE_FAILURE: {
      const { amplifyWorkroomId, error } = action.payload

      const currentAttachments = state.list[amplifyWorkroomId].amplifyWorkroomAttachments
      const index = _.findIndex(currentAttachments, item => !item.id)
      delete currentAttachments[index]
      const newAttachments = _.compact(currentAttachments)

      return {
        ...state,
        isUploading: false,
        list: {
          ...state.list,
          [amplifyWorkroomId]: {
            ...state.list[amplifyWorkroomId],
            amplifyWorkroomAttachments: newAttachments
          }
        },
        error
      }
    }
    case types.AMPLIFY_WORKROOM_UPLOAD_URL: {
      return {
        ...state,
        isUploading: true
      }
    }
    case types.AMPLIFY_WORKROOM_UPLOAD_URL_SUCCESS: {
      const { amplifyWorkroomId, newAttachment } = action.payload
      return {
        ...state,
        isUploading: false,
        list: {
          ...state.list,
          [amplifyWorkroomId]: {
            ...state.list[amplifyWorkroomId],
            amplifyWorkroomAttachments: [
              newAttachment,
              ...state.list[amplifyWorkroomId].amplifyWorkroomAttachments,
            ]
          }
        }
      }
    }

    case types.AMPLIFY_WORKROOM_DOWNLOAD_FILE_FAILURE: {
      return {
        ...state,
        error: action.payload
      }
    }

    case types.AMPLIFY_WORKROOM_ATTACHMENT_PERMISSION: {
      const { changedPermissions, workroomId } = action.payload
      const workroom = _.get(state, `list.${workroomId}`)
      
      const newPermissions = _.map(workroom.amplifyWorkroomArticlesPermissions, (permission) => {
        if (_.includes(changedPermissions, permission.id)) {
          return ({
            ...permission,
            isEnable: !permission.isEnable
          })
        }
        return permission
      })

      return {
        ...state,
        list: {
          ...state.list,
          [workroomId]: {
            ...state.list[workroomId],
            amplifyWorkroomArticlesPermissions: newPermissions
          }
        }
      }
    }

    case types.AMPLIFY_WORKROOM_UPLOAD_PROGRES: {
      const _uploadCountList = state.uploadCountList
      const isDataInList = _uploadCountList.findIndex(obj => obj && Object.keys(obj)[0] == Object.keys(action.payload)[0]);
      if (isDataInList > -1) {
        _uploadCountList[isDataInList][Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      } else {
        _uploadCountList.push(action.payload)
      }
      return {
        ...state,
        uploadCountList: [ ..._uploadCountList ]
      }
    }

    case types.AMPLIFY_WORKROOM_DELETE_FILE:
      {
        return {
          ...state,
          isGetDeletingFile: true
        }
      }

    case types.AMPLIFY_WORKROOM_DELETE_FILE_SUCCESS:
      {
        return {
          ...state,
          deletedFile: action.payload,
          isGetDeletingFile: false
        }
      }

    case types.AMPLIFY_WORKROOM_DELETE_FILE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetDeletingFile: false
        }
      }

      case types.CURRENT_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        currentUploadingData.push(action.payload)
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }
  
      case types.CLEAR_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        const currentIndex = currentUploadingData && currentUploadingData.findIndex(item => item && Object.keys(item) && Object.keys(item)[0] === action.payload)
        delete currentUploadingData[currentIndex]
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }

      case types.AMPLIFY_WORKROOM_UPDATE_ATTACHMENT: {
        const { amplifyWorkroomId, attachmentList, isGeneral } = action.payload
        return {
          ...state,
          // isUploading: false,
          list: {
            ...state.list,
            [amplifyWorkroomId]: {
              ...state.list[amplifyWorkroomId],
              [isGeneral ? 'amplifyWorkroomArticlesPermissions': 'amplifyWorkroomAttachments']: attachmentList
            }
          }
        }
      }

      case types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT:
        {
          return {
            ...state,
            isRemoveAttachment: true
          }
        }
  
      case types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_SUCCESS:
        {
          return {
            ...state,
            isRemoveAttachment: false,
          }
        }
  
      case types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_FAILURE:
        {
          return {
            ...state,
            error: action.payload,
            isRemoveAttachment: false
          }
        }

        case types.AMPLIFY_WORKROOM_VAULT_UPLOAD: {
          return {
            ...state,
            isGetAmplifyWorkroomVaultUpload: true
          }
        }
        case types.AMPLIFY_WORKROOM_VAULT_UPLOAD_SUCCESS: {
          const attachmentList = action.payload
          const amplifyWorkroomId = attachmentList && attachmentList[0] && attachmentList[0].amplifyWorkroomId
          const currentAttachments = state.list[amplifyWorkroomId].amplifyWorkroomAttachments
          const attachments = [...currentAttachments, ...attachmentList]
    
          return {
            ...state,
            isGetAmplifyWorkroomVaultUpload: false,
            list: {
              ...state.list,
              [amplifyWorkroomId]: {
                ...state.list[amplifyWorkroomId],
                amplifyWorkroomAttachments: attachments
              }
            }
          }
        }
        case types.AMPLIFY_WORKROOM_VAULT_UPLOAD_FAILURE: {
          return {
            ...state,
            isGetAmplifyWorkroomVaultUpload: false,
            error: action.payload
          }
        }

        case types.ADD_VAULT_FILE_AMPLIFY_WORKROOM: {
          return {
            ...state,
            vaultFilesAmplify: action.payload
          }
        }

        case types.CLEAR_VAULT_FILE_AMPLIFY_WORKROOM: {
          return {
            ...state,
            vaultFilesAmplify: []
          }
        }
    default:
      {
        return state
      }
  }
}