import types from '../constants/chat'

const initialState = {
  selectedConversationId: null,
  conversation: null,
  unreadedMessages: null
}

export default (state = initialState, action) => {

  switch (action.type) {

  case types.GET_PARTICIPANTS_SUCCESS:
    return {
      ...state,
      conversation: {
        ...action.payload,
      }
    }

  case types.GET_PARTICIPANTS_FAILURE:
    return {
      ...state,
    }

  case types.UNREADED_MESSAGES:
    return {
      ...state,
      unreadedMessages: action.payload,
    }
  case types.SET_SELECTED_CONVERSATION:

    return {
      ...state,
      selectedConversationId: action.payload 
    }   

  default:

    return state

  }

}
