import types from '../constants/init'

const initialState = {
  status:         null,
  authStatus:     null,
  serverDateTime: null,
  timeDifference: null,
  legal: {},
}

export default (state = initialState, action) => {

  switch (action.type) {

  case types.INIT_SUCCESS:

    return {
      ...state,
      status:         action.payload.status,
      serverDateTime: action.payload.dateTime,
    }

  case types.INIT_AUTH: {

    return {
      ...state,
      authStatus: true,
    }

  }

  case types.INIT_FAILURE:

    return {
      ...state,
      status:     false,
      authStatus: false,
    }

  case types.LEGAL_SUCCESS:

    return {
      ...state,
      legal: action.payload,
    }

  case types.LEGAL_FAILURE:

    return {
      ...state,
    }

  case types.INIT:
  case types.LEGAL:
  default:

    return state

  }

}
