import types from '../constants/recommended'

export const get = (queryParams, page) => ({
  type: types.GET,
  payload: {
    queryParams,
    page
  }
})

export const getSuccess = ({ data, count }) => ({
  type: types.GET_SUCCESS,
  payload: {
    data,
    count
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const setFilter = (queryParams) => ({
  type: types.SET_FILTER,
  payload: queryParams
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
  payload: null
})

export const setPage = (pageNumber) => ({
  type: types.SET_PAGE,
  payload: pageNumber
})

export const socketCauseCreate = (data) => ({
  type: types.SOCKET_CAUSE_CREATE,
  payload: data
})

export const socketCauseRemoveRecommended = (data) => ({
  type: types.RECOMMENDED_SOCKET_CAUSE_REMOVE,
  payload: data
})