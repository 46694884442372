import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/skills'
import * as actions from '../actions/skills'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const query = action.payload.query

    const skillsList = yield backend.service('skill').find({
      query
    })
    if(_.size(skillsList)) {
      yield put(actions.getSuccess(skillsList))

    }

  } catch (e) {

    yield put(actions.getFailure(e))
  }
}

function* skillsSaga() {
  yield takeEvery(types.GET, get)
}

export default {
  skillsSaga,
}
