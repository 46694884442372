import React from 'react'
import MobileInfoIcon from 'assets/images/mobile-info.png'

import './Mobile.css'

const Mobile = () => {
  return (
      <div className="mobile-view">
          <div className="mobile-view-container">
            <div><img src="/i/logo.png" className="mobile-view-logo" /></div>
            <div className="mobile-view-info-container">
                <div className="mobile-view-info-image">
                        <img src={MobileInfoIcon} alt="MobileInfoIcon" />
                    {/* <div class="mobile-view-circle">
                    </div> */}
                </div>
                <div className="mobile-view-info-content">Please return to your desktop device to continue creating amazing content.</div>
            </div>
          </div>
      </div>
  )
}

export default Mobile