import types from '../constants/feedback'

export const getId = (queryParams) => ({
  type: types.GET_ID,
  payload: {
    queryParams,
  }
})

export const getIdSuccess = ({ rateContractor, feedback, id }) => ({
  type: types.GET_ID_SUCCESS,
  payload: {
    rateContractor,
    feedback,
    id
  }
})

export const getIdFailure = (error) => ({
  type: types.GET_ID_FAILURE,
  payload: error
})

export const edit = (data) => ({
  type: types.EDIT_FORM,
  payload: {
    ...data
  }
})

export const create = (data) => {

  return {
    type: types.CREATE,
    payload: { 
      data
    }
  }
}

export const createSuccess = (data) => {

  return {
    type: types.CREATE_SUCCESS,
    payload: data
  }
}

export const createFailure = (error) => {

  return {
    type: types.CREATE_FAILURE,
    payload: error
  }
}

export const clearFeedbackForm = () => ({
  type: types.CLEAR_FEEDBACK_FORM
})