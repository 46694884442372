import types from '../constants/publicationHistory'

export const get = (query, isAdding) => ({
  type: types.GET,
  payload: { query, isAdding }
})

export const getSuccess = (data) => ({
  type: types.GET_SUCCESS,
  payload: data
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const getNextPageWithAddingSuccess = (data) => ({
  type: types.GET_NEXT_PAGE_WITH_ADDING_SUCCESS,
  payload: data,
})