import types from '../constants/feedback'

const initialState = {
  origin: {},
  feedback: {
    stars: null,
    feedback: '',
    id: null,
    privateComment: '',
  },
  error: null,
  isGetIdFetching: false,
  isCreateFetching: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET_ID:
      {
        return {
          ...state,
          feedback: initialState.feedback,
          
          isGetIdFetching: true
        }
      }

    case types.GET_ID_SUCCESS:
      {
        return {
          ...state,
          feedback: {
            ...state.feedback,
            stars: action.payload.rateContractor,
            feedback: action.payload.feedback,
            id: action.payload.id,
            privateComment: action.payload.privateComment,
          },
          isGetIdFetching: false
        }
      }


    case types.GET_ID_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetIdFetching: false
        }
      }

    case types.EDIT_FORM:
      {
        return {
          ...state,
          feedback: {
            ...state.feedback,
            ...action.payload
          }
        }
      }

    case types.CREATE:
      {
        return {
          ...state,
          isCreateFetching: true,
          feedback: {
            ...action.payload,
          },
        }
      }

    case types.CREATE_SUCCESS:
      {
        return {
          ...state,
          feedback: {
            ...action.payload,
            stars: action.payload.rateContractor
          },
          isCreateFetching: false
        }
      }

    case types.CLEAR_FEEDBACK_FORM:
    {
      return {
        ...state,
        feedback: initialState.feedback
      }
    }

    case types.CREATE_FAILURE:
      {
        return {
          ...state,
          isCreateFetching: false
        }
      }

    default:
      return state
  }

}