import types from '../constants/workroom'
import authTypes from '../constants/auth'

//validation model
const validationParams = {
  id: {
    value: '',
    isValid: undefined,
  },
  contractorId: {
    value: '',
    isValid: undefined,
  },
  buyerId: {
    value: '',
    isValid: undefined,
  },
  workItems: {
    value: [],
    isValid: undefined,
  },
  workroomItems: {
    value: null,
    isValid: undefined,
  },
  workItemsBuyer: {
    value: [],
    isValid: undefined,
  },
  milestone: {
    value: {},
    isValid: undefined,
  },
  feedback: {
    value: '',
    isValid: undefined,
  },
  privateComment: {
    value: '',
    isValid: undefined,
  },
  causeId: {
    value: null,
    isValid: undefined,
  },
  userEmail: {
    value: null,
    isValid: undefined
  },
  userName: {
    value: null,
    isValid: undefined
  },
  editorArticleId: {
    value: null,
    isValid: undefined,
  },
  buyerArticleId: {
    value: null,
    isValid: undefined,
  },
  contractorArticleId: {
    value: null,
    isValid: undefined,
  },
  attachments: [],
  usersWithAccess: [],
  templateInfo: {
    value: null,
    isValid: undefined,
  },
  isWorkroomTinyEditor: {
    value: false,
  },
}

//flags for check loaded data
const fetching = {
  isGetIdFetching: false,
  isGetDeletingFile: false
}

const initialState = {
  error: null,
  validationEdit: {
    ...validationParams
  },
  info: {},
  ...fetching,
  designUrl: {},
  isFileUploadSuccess: false,
  selectedArticle: undefined,
  uploadCountList: [],
  deletedFile: {},
  currentUploadingApiCallData: [],
  vaultFiles: [],
  vaultUploadModalFiles: [],
  gettyImageDownloadCount: {
    buyer: 0,
    creator: 0,
    editor: 0
  }
}

export default (state = initialState, action) => {

  switch (action.type) {
    case types.WORKROOM_GET_ID:
      {
        return {
          ...state,
          validationEdit: {
            ...initialState.validationEdit
          },
          info: {},
          isGetIdFetching: true
        }
      }

    case types.WORKROOM_GET_ID_SUCCESS:
      {
        const payload = action.payload
        if (!(payload && payload[0])) {
          return {
            ...state,
            validationEdit: {
              ...initialState.validationEdit
            },
            info: {
              ...initialState.info
            }
          }
        }

        //data coercion to a validation object
        const toArr = Object.entries(validationParams)
        const data = payload[0] || {}
        const nextValidModel = toArr.reduce((obj, item) => {

          const key = item[0]
          obj[key] = {
            value: data[key],
            isValid: true
          }

          return obj
        }, {})

        
        const workroomItemsValue = nextValidModel && nextValidModel.workroomItems.value && Object.values(nextValidModel.workroomItems.value) 
        const workroomItemsKey = nextValidModel && nextValidModel.workroomItems.value && Object.keys(nextValidModel.workroomItems.value) 

        nextValidModel.workroomItems.value[workroomItemsKey[0]] = { ...workroomItemsValue[0], productTemplate: nextValidModel && nextValidModel.templateInfo && nextValidModel.templateInfo.value}
        return {
          ...state,
          validationEdit: {
            ...nextValidModel
          },
          info: {
            causeWorkroom: {
              ...data.causeWorkroom
            },
            buyerWorkroom: {
              ...data.buyerWorkroom
            },
            contractorWorkroom: {
              ...data.contractorWorkroom
            },
            guestComments: {
              ...data.workroomGuest
            }
          },
          isGetIdFetching: false
        }
      }

    case types.WORKROOM_GET_ID_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetIdFetching: false
        }
      }

    case types.WORKROOM_UPDATE:
      {
        return {
          ...state,
          isFileUploadSuccess: false
        }
      }
    case types.WORKROOM_UPDATE_SUCCESS:
    case types.SKIP_STEPS_SUCCESS:
      {
        const nextItem = action.payload
        const { genreId, contentCategoryId, productCardId } = nextItem
        const productInfoId = genreId + contentCategoryId + productCardId
        return {
          ...state,
          validationEdit: {
            ...state.validationEdit,
            workroomItems: {
              ...state.validationEdit.workroomItems,
              value: {
                ...state.validationEdit.workroomItems.value,
                [productInfoId]: {
                  ...state.validationEdit.workroomItems.value[productInfoId],
                  workItems: {
                    ...state.validationEdit.workroomItems.value[productInfoId].workItems,
                    [nextItem.id]: nextItem
                  }
                }
              }
            }
          }
        }
      }

    case types.VALIDATION:
      {
        return {
          ...state,
          validationEdit: {
            ...state.validationEdit,
            ...action.payload.data
          }
        }

      }

    case authTypes.SIGNOUT_SUCCESS:
      {
        return {
          ...initialState,
        }
      }

    case types.UPLOAD:
      {
        return {
          ...state,
          isFileUploadSuccess: false
        }
      }

    case types.UPLOAD_SUCCESS:
      {
        const { data, productInfo, articleId } = action.payload
        const { id, contentCategoryId, genreId } = productInfo
        const productInfoId = genreId + contentCategoryId + id
        const currentWorkItem = productInfo.workItems[articleId]
        const prevAttachments = [...state.validationEdit.workroomItems.value[productInfoId].workItems[articleId].attachments]
        const preview = data.preview
        const index = prevAttachments.findIndex(element => element.preview === preview)

        if (prevAttachments[index]) {
          prevAttachments[index].isFetching = false
          prevAttachments[index].isError = false
          prevAttachments[index].storeId = data.id
          prevAttachments[index].mediaName = data.fileName
          prevAttachments[index].attachmentInfo = data.attachmentInfo || null
        }

        if (data && data.isDuplicate) {
          prevAttachments[index].documentId = data.documentId
          prevAttachments[index].uploadedFromVault = true
          prevAttachments[index].isDuplicate = true
        }

        const nextItem = { ...currentWorkItem, attachments: prevAttachments }

        return {
          ...state,
          validationEdit: {
            ...state.validationEdit,
            workroomItems: {
              ...state.validationEdit.workroomItems,
              value: {
                ...state.validationEdit.workroomItems.value,
                [productInfoId]: {
                  ...state.validationEdit.workroomItems.value[productInfoId],
                  workItems: {
                    ...state.validationEdit.workroomItems.value[productInfoId].workItems,
                    [articleId]: nextItem
                  }
                }
              }
            }
          },
          // isFileUploadSuccess: true
        }
      }

    case types.UPLOAD_FAILURE:
      {
        const { data, productInfo, currentIndex } = action.payload
        const { id } = productInfo
        let prevAttachments = [...state.validationEdit.workroomItems.value[id].workItems[currentIndex].attachments]
        const preview = data.preview
        const index = prevAttachments.findIndex((element) => element.preview === preview)

        prevAttachments.splice(index, 1)
        const nextValue = { ...state.validationEdit.workroomItems.value[id] }
        const nextItem = [...nextValue.workItems]
        nextItem[currentIndex] = { ...nextItem[currentIndex], attachments: prevAttachments }

        return {
          ...state,
          validationEdit: {
            ...state.validationEdit,
            workroomItems: {
              ...state.validationEdit.workroomItems,
              value: {
                ...state.validationEdit.workroomItems.value,
                [id]: {
                  ...state.validationEdit.workroomItems.value[id],
                  workItems: nextItem
                }
              }
            }
          }
        }
      }

    case types.UPLOAD_DESIGN_SUCCESS:
      {
        return {
          ...state,
          designUrl: action.payload.data
        }
      }

    case types.INVITE_PERSON_SUCCESS:
      {
        return {
          ...state,
          validationEdit: {
            ...state.validationEdit,
            userEmail: {}
          }
        }
      }

    case types.SKIP_STEPS_FAILURE: {
      return {
        ...state,
        error: action.payload
      }
    }

    case types.WORKROOM_SELECTED_ARTICLE: {
      return {
        ...state,
        selectedArticle: action.payload
      }
    }

    case types.WORKROOM_UPLOAD_PROGRES: {

      const _uploadCountList = state.uploadCountList
      const isDataInList = _uploadCountList.findIndex(obj => obj && Object.keys(obj)[0] == Object.keys(action.payload)[0]);
      if (isDataInList > -1) {
        _uploadCountList[isDataInList][Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      } else {
        _uploadCountList.push(action.payload)
      }

      return {
        ...state,
        uploadCountList: [ ..._uploadCountList ]
      }
    }

    case types.WORKROOM_DELETE_FILE:
      {
        return {
          ...state,
          isGetDeletingFile: true
        }
      }

    case types.WORKROOM_DELETE_FILE_SUCCESS:
      {
        return {
          ...state,
          deletedFile: action.payload,
          isGetDeletingFile: false
        }
      }

    case types.WORKROOM_DELETE_FILE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetDeletingFile: false
        }
      }

      case types.CURRENT_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        currentUploadingData.push(action.payload)
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }
  
      case types.CLEAR_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        const currentIndex = currentUploadingData && currentUploadingData.findIndex(item => item && Object.keys(item) && Object.keys(item)[0] === action.payload)
        delete currentUploadingData[currentIndex]
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }

      case types.GET_GETTY_IMAGE_DOWNLOAD_COUNT: {
        return {
          ...state,
        }
      }

      case types.GET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS: {
        return {
          ...state,
          gettyImageDownloadCount: action.payload
        }
      }

      case types.GET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE: {
        return {
          ...state,
          error: action.payload
        }
      }

      case types.SET_GETTY_IMAGE_DOWNLOAD_COUNT: {
        return {
          ...state,
        }
      }

      case types.SET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS: {
        return {
          ...state,
          gettyImageDownloadCount: action.payload
        }
      }

      case types.SET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE: {
        return {
          ...state,
          error: action.payload
        }
      }

      // case types.ADD_VAULT_FILES_UPLOAD_MODAL: {
      //   return {
      //     ...state,
      //     vaultUploadModalFiles: action.payload
      //   }
      // }

    default:
      return state
  }
}
