import types from '../constants/analytics'

export const getGraphData = data => ({
  type: types.GET_GRAPH_DATA,
  payload: data
})

export const getGraphDataSuccess = (data) => ({
  type: types.GET_GRAPH_DATA_SUCCESS,
  payload: data
})

export const getGraphDataFailure = error => ({
  type: types.GET_GRAPH_DATA_FAILURE,
  payload: error
})

export const getGeographicWorldData = data => ({
  type: types.GET_GEOGRAPHIC_WORLD_DATA,
  payload: data
})

export const getGeographicWorldDataSuccess = data => ({
  type: types.GET_GEOGRAPHIC_WORLD_DATA_SUCCESS,
  payload: data
})

export const getGeographicWorldDataFailure = error => ({
  type: types.GET_GEOGRAPHIC_WORLD_DATA_FAILURE,
  payload: error
})

export const getGenderAgeData = data => ({
  type: types.GET_GENDER_AGE_DATA,
  payload: data
})

export const getGenderAgeDataSuccess = data => ({
  type: types.GET_GENDER_AGE_DATA_SUCCESS,
  payload: data
})

export const getGenderAgeDataFailure = error => ({
  type: types.GET_GENDER_AGE_DATA_FAILURE,
  payload: error
})

export const getDeviceData = data => ({
  type: types.GET_DEVICE_DATA,
  payload: data
})

export const getDeviceDataSuccess = data => ({
  type: types.GET_DEVICE_DATA_SUCCESS,
  payload: data
})

export const getDeviceDataFailure = error => ({
  type: types.GET_DEVICE_DATA_FAILURE,
  payload: error
})

export const getDeviceTimeOnPageData = data => ({
  type: types.GET_DEVICE_TIME_ON_PAGE_DATA,
  payload: data
})

export const getDeviceTimeOnPageDataSuccess = data => ({
  type: types.GET_DEVICE_TIME_ON_PAGE_DATA_SUCCESS,
  payload: data
})

export const getDeviceTimeOnPageDataFailure = error => ({
  type: types.GET_DEVICE_TIME_ON_PAGE_DATA_FAILURE,
  payload: error
})

export const setLocationData = data => ({
  type: types.SET_LOCATION_DATA,
  payload: data
})

export const getTimeOnPageData = data => ({
  type: types.GET_TIME_ON_PAGE_DATA,
  payload: data
})

export const getTimeOnPageDataSuccess = data => ({
  type: types.GET_TIME_ON_PAGE_DATA_SUCCESS,
  payload: data
})

export const getTimeOnPageDataFailure = error => ({
  type: types.GET_TIME_ON_PAGE_DATA_FAILURE,
  payload: error
})

export const getCountUniqueUsersData = data => ({
  type: types.GET_COUNT_UNIQUE_USERS_DATA,
  payload: data
})

export const getCountUniqueUsersDataSuccess = data => ({
  type: types.GET_COUNT_UNIQUE_USERS_DATA_SUCCESS,
  payload: data
})

export const getCountUniqueUsersDataFailure = error => ({
  type: types.GET_COUNT_UNIQUE_USERS_DATA_FAILURE,
  payload: error
})

export const getBuySurveyData = data => ({
  type: types.GET_BUY_SURVEY_DATA,
  payload: data
})

export const getBuySurveyDataSuccess = (data) => ({
  type: types.GET_BUY_SURVEY_DATA_SUCCESS,
  payload: data
})

export const getBuySurveyDataFailure = error => ({
  type: types.GET_SHARE_SURVEY_DATA_FAILURE,
  payload: error
})

export const getLearnMoreSurveyData = data => ({
  type: types.GET_LEARN_MORE_SURVEY_DATA,
  payload: data
})

export const getLearnMoreSurveyDataSuccess = (data) => ({
  type: types.GET_LEARN_MORE_SURVEY_DATA_SUCCESS,
  payload: data
})

export const getLearnMoreSurveyDataFailure = error => ({
  type: types.GET_LEARN_MORE_SURVEY_DATA_FAILURE,
  payload: error
})

export const getShareSurveyData = data => ({
  type: types.GET_SHARE_SURVEY_DATA,
  payload: data
})

export const getShareSurveyDataSuccess = (data) => ({
  type: types.GET_SHARE_SURVEY_DATA_SUCCESS,
  payload: data
})

export const getShareSurveyDataFailure = error => ({
  type: types.GET_SHARE_SURVEY_DATA_FAILURE,
  payload: error
})