import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import types from '../constants/vaultCause'
import * as actions from '../actions/vaultCause'
import { renderErrorNotification } from 'helpers/tools'
import { push } from 'react-router-redux'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend, { apiUrl } from 'store/api/feathers'
import { appAnalytics, EVENTS } from 'helpers/appAnalytics'

function* post(action) {
  try {
    const newCause = yield backend.service('cause').create({ ...action.payload.data })
    if (_.size(newCause)) {
      yield put(push('/dashboard'))
      yield put(actions.postBriefSuccess(newCause))
      appAnalytics.track(EVENTS.VAULT_BRIEF_POST_SUCCESS, {causeId: newCause.id})
    }
  } catch (e) {
    renderErrorNotification(e, 'Post Brief error')
    yield put(actions.postBriefFailure(e))
  }
}

function* vaultCauseSaga() {
  yield takeEvery(types.POST_BRIEF, post)
}

export default {
  vaultCauseSaga
}