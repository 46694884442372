import React, { useState, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'

import { ReactComponent as IconBack } from 'assets/images/icons/back.svg'
import { ReactComponent as IconInternal } from 'assets/images/icons/internal-brief.svg'
import { ReactComponent as IconInviteOnly } from 'assets/images/icons/invite-brief.svg'
import { ReactComponent as IconPrivate } from 'assets/images/icons/private-brief.svg'
import { ReactComponent as IconPublic } from 'assets/images/icons/public-brief.svg'
import { ReactComponent as IconClose } from 'assets/images/close_icon.svg'
import { ReactComponent as IconInternalInvite } from 'assets/images/icons/interal-brief.svg'
import { ReactComponent as IconPublicPrivate } from 'assets/images/icons/private-public-brief.svg'

import './ModalSelectType.css'

const typesPrivate = {
  public: 'public',
  private: 'private',
  internal: 'internal',
  invite: 'invite'
}

const wrapperIcon = icon => (
  <div className="modal-select-brief-type-types-icon-wrapper">{icon}</div>
)

const buttonClassName = 'modal-select-brief-type-body-content-button'

const getClassNamesButtons = paramsButton => {
  const { isActive, propsButton } = paramsButton
  return classNames(_.get(propsButton, 'classes.button'), buttonClassName, {
    'modal-select-brief-type-button-is-active': isActive
  })
}

const renderContent = paramsButton => {
  const { icon, propsButton, text, info, onClick, isActive } = paramsButton
  const closeModalButtonProps = isActive
    ? {
      'data-toggle': 'modal',
      'data-target': '#createBriefModal'
    }
    : {}

  return (
    <React.Fragment>
      {/* {icon} */}
      <span>{icon}</span>
      <Button
        {...propsButton}
        {...closeModalButtonProps}
        classes={{
          button: getClassNamesButtons(paramsButton)
        }}
        title={text}
        onClick={onClick(isActive)}
      />
      {/* <span className="modal-select-brief-type-body-content-info">{info}</span> */}
    </React.Fragment>
  )
}

const CreateModal = props => {
  const [type, setType] = useState(null)

  useEffect(() => {
    window.addEventListener('click', function(e){   
      if (!(document.getElementById('createBriefModal').contains(e.target))){
        setType(null)
      }
    });
  }, [])

  const handleOnSelectType = useCallback((briefType) => {
    const { validation, causeActions, validRules, routerActions } = props
    causeActions.setValid(
      {
        ...validation['causeType'],
        value: briefType
      },
      validRules['causeType'],
      'causeType',
      true
    )
    routerActions.push('/create-brief')
    setTimeout(() => setType(null), 200)
  }, [type])

  const handleOnClickBack = useCallback(() => setType(null), [])

  const handleOnClickButton = type => isActive => () => {
    setType(type)
    if (isActive) {
      handleOnSelectType(type)
      setType(null)
    }
  }

  const handleClickCloseModal = useCallback(() => {
    setType(null)
  }, [])

  const paramsButtons = {
    public: {
      text: 'Public project',
      onClick: handleOnClickButton(typesPrivate.public),
      icon: wrapperIcon(
        <IconPublic className="modal-select-brief-type-types-icon" />
      ),
      info: 'Would you like to make your brief public or private?',
      subInfo: 'Public briefs are visible to all creators in the marketplace, private briefs are visible to invited creators only'
    },
    private: {
      text: 'Private project',
      onClick: handleOnClickButton(typesPrivate.private),
      icon: wrapperIcon(
        <IconPrivate className="modal-select-brief-type-types-icon" />
      ),
      info: 'What type of creator would you like to use?',
      subInfo: 'Invite only projects will only be visible to creators you invite to view'
   },
    internal: {
      text: 'Internal creator',
      onClick: handleOnClickButton(typesPrivate.internal),
      icon: wrapperIcon(
        <IconInternal className="modal-select-brief-type-types-icon" />
      ),
      info: 'What type of creator would you like to use?',
      subInfo: 'Invite only projects will only be visible to creators you invite to view'
    },
    invite: {
      text: 'Invite Only project',
      onClick: handleOnClickButton(typesPrivate.invite),
      icon: wrapperIcon(
        <IconInviteOnly className="modal-select-brief-type-types-icon" />
      ),
      info: 'What type of creator would you like to use?',
      subInfo: 'Invite only projects will only be visible to creators you invite to view'
    }
  }

  const getButtonsParams = () => {
    
    switch (type) {
    case typesPrivate.public: {
      return {
        leftButton: {
          ...paramsButtons.public,
          isActive: true
        },
        rightButton: {
          ...paramsButtons.private,
          isActive: true
        }
      }
    }

    case typesPrivate.private: {
      return {
        leftButton: {
          ...paramsButtons.internal,
          isActive: true,
        },
        rightButton: {
          ...paramsButtons.invite,
          isActive: true,
        }
      }
    }

    case typesPrivate.internal: {
      return {
        leftButton: {
          ...paramsButtons.internal,
          isActive: true
        },
        rightButton: {
          ...paramsButtons.invite
        }
      }
    }

    case typesPrivate.invite: {
      return {
        leftButton: {
          ...paramsButtons.internal
        },
        rightButton: {
          ...paramsButtons.invite,
          // isActive: true
        }
      }
    }

    default: {
      return {
        leftButton: {
          ...paramsButtons.public,
          isActive: true
        },
        rightButton: {
          ...paramsButtons.private
        },
        info: 'Would you like to make your brief public or private?'
      }
    }
    }
  }

  const isPrivateType = _.includes(
    [typesPrivate.internal, typesPrivate.invite, typesPrivate.private],
    type
  )

  const bodyText = isPrivateType
    ? 'What type of creator would you like to use?'
    : 'Would you like to make your brief public or private?'

  const currentButtonsParams = getButtonsParams()

  return (
    <React.Fragment>
      <Modal
        idModal="createBriefModal"
        classNameModalDialog="modal-select-brief-type-dialog"
      >
        <div className="modal-select-brief-type-wrapper">
          <div className="modal-select-brief-type-header">
          {isPrivateType && (
            <Button
              title={<IconBack />}
              classes={{
                button: 'modal-select-brief-type-body-content-button',
                wrapper: 'modal-select-brief-type-back-button'
              }}
              onClick={handleOnClickBack}
            />
          )}
            <Button
              title={<IconClose
                // className="cross-icon"
                />}
              data-toggle="modal"
              data-target="#createBriefModal"
              onClick={handleClickCloseModal}
              classes={{
                wrapper: 'modal-select-brief-type-header-cross-wrapper',
                button: 'modal-select-brief-type-header-cross'
              }}
            />
            <span
              className="modal-select-brief-type-header-title"
              style={
                ((type === 'private') || (type === 'internal') || (type === 'invite'))
                ? { marginTop: '-34px' }
                : { marginTop: 'auto' }
              }
            >
              {
              ((type === 'private') || (type === 'internal') || (type === 'invite'))
              ? 'Private'
              : 'Project visibility'
              // Post a new brief
              }
            </span>
            {/*typeIsBelongsPrivateTypes && (
              <div className="back-wrapper">
                <i
                  className="icon-arrow-left8"
                  title="Back"
                  onClick={handleOnClickBack}
                />
                <span>Back</span>
              </div>
            )*/}
          </div>
          <div className="modal-select-brief-type-body">
          <div>{((type === 'private') || (type === 'internal') || (type === 'invite')) ? <IconInternalInvite /> : <IconPublicPrivate />}</div>
          <span className="modal-select-brief-type-body-text">{paramsButtons[type || 'public'] && paramsButtons[type || 'public'].info}</span>
            {/* <span className="modal-select-brief-type-body-text">{bodyText}</span> */}
            <div className="modal-select-brief-type-body-content">
              <div className="modal-select-brief-type-body-content-item">
                {renderContent(currentButtonsParams.leftButton)}
              </div>
              {/* <div className="line" /> */}
              <div className="modal-select-brief-type-body-content-item">
                {renderContent(currentButtonsParams.rightButton)}
              </div>
            </div>
            <span className="modal-select-brief-type-body-subinfo">{paramsButtons[type || 'public'] && paramsButtons[type || 'public'].subInfo}</span>

          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CreateModal
