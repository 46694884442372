import React from 'react'

import './Splash.css'

export default () => (
  <section className="splash text-center">
    Fabulate loading
    &nbsp;&nbsp;&nbsp;
    <i className="icon-spinner4 spinner"></i>
  </section>
)
