import io from 'socket.io-client'
import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import { store } from 'store/store'
import * as causeActions from 'store/actions/cause'
// import * as amplifyCauseActions from 'store/actions/amplifyCause'
import * as causeUserActions from 'store/actions/causeUser'
import * as userActions from 'store/actions/user'
import * as workroomActions from 'store/actions/workroom'
import * as amplifyWorkroomActions from 'store/actions/amplifyWorkroom'
import * as amplifyCauseActions from 'store/actions/amplifyCause'
import * as amplifyCausePermissionsActions from 'store/actions/amplifyCausePermissions'
import * as notificationActions from 'store/actions/notification'
import * as dashboardActions from 'store/actions/dashboard'
import * as initActions from 'store/actions/init'
import * as recommendedActions from 'store/actions/recommended'

import notificationRender from 'components/pageComponents/notificationsList/notificationRender.js'

import _ from 'lodash'

const apiUrl = `${process.env['REACT_APP_API_SCHEME']}://` +
  `${process.env['REACT_APP_API_HOST']}:${process.env['REACT_APP_API_PORT']}`

export const socket = io(apiUrl, {})

socket.on('connect', () => {
  console.log('socket - connected', socket.id);
})

const connectUser = (user) => {
  socket.emit('user', user, () => {
    console.log('socket - user connected')
  })
}

export default (user, history) => {

  connectUser(user)

  socket.on('disconnect', () => {
    console.log('socket - disconnected');
    socket.emit('logout', user)
  })

  socket.on('connect', () => {
    connectUser(user)
  })

  socket.on('CAUSE_CREATE_SUCCESS', data => {
    const currentUser = store.getState().auth.user
    const recommended = store.getState().recommended
    const dashboard = store.getState().dashboard
    if (_.get(recommended, 'list.page') === 0
      && _.get(currentUser, 'permissions') === 3
      && data.causeType === "public") {
      store.dispatch(recommendedActions.socketCauseCreate(data))
    }
    if (_.get(recommended, 'list.page') === 0
      && _.get(currentUser, 'permissions') === 3
      && _.get(dashboard, 'recommendedBriefs.data'
        && data.causeType === "public")) {
      store.dispatch(dashboardActions.socketCauseCreate(data))
    }

    store.dispatch(causeActions.createSuccess(data))
  })

  socket.on('CAUSE_REMOVE_SUCCESS', data => {
    if (data) {
      store.dispatch(causeActions.removeSuccess(data))
      store.dispatch(dashboardActions.removeBySocket(data))
      store.dispatch(recommendedActions.socketCauseRemoveRecommended(data))
    }
  })

  socket.on('CAUSE_UPDATE_SUCCESS', data => {

    const currentUser = store.getState().auth.user
    const isNonHiredUser = currentUser && currentUser.permissions === 3 && data.hiredUserId && (data.hiredUserId !== currentUser.id)
    const path = history.location.pathname.split('/')

    if (isNonHiredUser && path[1] === 'brief-show' && path[2] === data.id) {
      new PNotify({
        addclass: 'bg-warning',
        title: 'Warning',
        text: 'The brief has been assigned to another creator!',
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },
      })

      history.push('/new-briefs')
    }

    if (path[1] === 'brief-show' && path[2] === data.id) {
      store.dispatch(causeActions.getIdSuccess({ data }))
    }
  })

  socket.on('AMPLIFY_CAUSE_UPDATE_SUCCESS', data => {
    const path = history.location.pathname.split('/')

    if (path[1] === 'amplify-brief-show' && path[2] === data.id) {
      store.dispatch(amplifyCauseActions.updateSuccess(data))
    }
  })

  socket.on('WORKROOM_UPDATE_SUCCESS', data => {
    const path = history.location.pathname.split('/')
    const workroomData = data.data[0] || {}
    const causeId = workroomData.causeId

    if (path[1] === 'brief-show' && path[2] === causeId) {
      store.dispatch(workroomActions.getIdSuccess(data))
    }
  })

  socket.on('AMPLIFY_WORKROOM_UPDATE_SUCCESS', data => {
    const path = history.location.pathname.split('/')
    const causeId = data.amplifyCauseId

    if (path[1] === 'amplify-brief-show' && path[2] === causeId) {
      store.dispatch(amplifyWorkroomActions.updateSuccess(data))
    }
  })

  socket.on('NEW_INVITE', data => {
    store.dispatch(causeActions.createSuccess(data.cause.data))
    store.dispatch(causeActions.getIdSuccess(data.cause, true))
  })

  socket.on('NEW_REQUEST', data => {
    const path = history.location.pathname.split('/')
    if (path[1] === 'brief-show' && path[2] === data.cause.id && user.permissions === 2) {
      store.dispatch(causeActions.createSuccess(data.cause))
      store.dispatch(causeActions.getIdSuccess({ data: data.cause }, true))
    }
  })

  socket.on('NEW_HIRED_USER', data => {
    store.dispatch(causeActions.createSuccess(data.data))
  })

  socket.on('USER_UPDATE_REQUEST_SUCCESS', data => {
    const path = history.location.pathname.split('/')
    if (path[1] === 'brief-show' && path[2] === data.request.causeId) {
      store.dispatch(userActions.update(data.user, false, true))
    }
  })

  socket.on('UPDATE_USER_BY_SOCKET', data => {
    if (user.id === data.id) {
      store.dispatch(userActions.updateUserBySocket(data))
    }
  })

  socket.on('CAUSE_USER_UPDATE_SUCCESS', data => {
    const path = history.location.pathname.split('/')
    if (path[1] === 'brief-show' && path[2] === data.data.causeId && !data.data.isDeleted) {
      store.dispatch(causeUserActions.updateSuccess(data))
      if ((user && user.permissions === 3) || (path[1] === 'pitch' && path[2] === data.data.id)) {
        store.dispatch(causeUserActions.getIdSuccess(data))
      }
    }
  })

  socket.on('CAUSE_USER_REMOVE', data => {
    const path = history.location.pathname.split('/')
    if (path[1] === 'brief-show' && path[2] === data.data.causeId && user.permissions === 2) {
      store.dispatch(causeUserActions.removeSuccess(data))
      store.dispatch(causeActions.getIdSuccess(data.causeData, true))
    }
  })

  socket.on('NOTIFICATION', data => {
    // if (data && data.data.notificationType !== 30) {
      store.dispatch(notificationActions.updateSuccess(data.data))
      if (data.isCreate) {
        const modelNotification = notificationRender(data.data, user)
        if (Object.keys(modelNotification).length) {
          const tabNumber = modelNotification.tab || ''
        const notice = new PNotify({
          addclass: `${modelNotification.color}`,
          // addclass: `bg-${modelNotification.color}`,
          text: modelNotification.body,
          title: (modelNotification.title || ''),
          hide: true,
          buttons: {
            closer: true,
            sticker: true,
          },
        })

        const renderPathame = () => {
          return modelNotification.linkTo
            ? `/${modelNotification.linkTo}${data.data.notificationType === 27 ? '' : `/${data.data.linkId}`}`
            : `/brief-show/${data.data.linkId}`
        }

        if (modelNotification.isRedirect) {
          notice.get().on('click', e => {
            if (history && e.target.lastChild !== null) {
              let searchText = '';
              if (tabNumber === 4 && (modelNotification.shortText && modelNotification.shortText.includes('New pitch'))) {
                searchText = '?tab=pitches'
              } else if (tabNumber === 5 && (modelNotification.shortText && (modelNotification.shortText.includes('Step changed') || (modelNotification.shortText.includes('Now your content is ready'))))) {
                searchText = '?tab=workroom'
              }
              history.push({
                pathname: renderPathame(),
                search: modelNotification.linkTo ? searchText : '',
                state: { tab: tabNumber }
              })
            }
          })
        }
        }
      }
    // }
  })

  socket.on('LEGAL_GET', data => {
    store.dispatch(initActions.legalSuccess(data));
  })

  socket.on('REFRESH_AMPLIFY_CAUSE_PERMISSIONS', data => {
    if (data.userId === user.id) {
      store.dispatch(amplifyCausePermissionsActions.getAmplifyCausePermissions({
        amplifyCauseId: data.amplifyCauseId
      }))
    }
  })

  socket.on('SOCKET:CREATE_AMPLIFY_BRIEF', data => {
    const recommended = store.getState().recommended
    if (_.get(recommended, 'list.page') === 0 && _.includes(data.users, user.id)) {
      store.dispatch(dashboardActions.socketAmplifyCauseCreate(data.amplifyCause))
    }
  })

  socket.on('SOCKET:UPDATE_CAUSE_AMPLIFY_BRIEF', data => {
    if (_.includes(data.users, user.id)) {
      store.dispatch(dashboardActions.socketAmplifyCauseUpdate(data.amplifyCause))
    }
  })

}
