import _ from 'lodash'

export const briefsFilterSearch = [
  {
    name: 'Test one',
    value: 0
  },
  {
    name: 'Test two',
    value: 0
  },
  {
    name: 'Test three',
    value: 0
  }
]

export const selectFieldBriefFormOne = {
  causeIndustry: [
    {
      "value": 0,
      "name": "Alcohol"
    },
    {
      "value": 1,
      "name": "Automotive"
    },
    {
      "value": 2,
      "name": "Aviation"
    },
    {
      "value": 3,
      "name": "Banking"
    },
    {
      "value": 4,
      "name": "Beauty"
    },
    {
      "value": 5,
      "name": "Beauty Services"
    },
    {
      "value": 6,
      "name": "Books"
    },
    {
      "value": 7,
      "name": "Business"
    },
    {
      "value": 8,
      "name": "Charity"
    },
    {
      "value": 9,
      "name": "Communications"
    },
    {
      "value": 10,
      "name": "Consumer Packaged Goods"
    },
    {
      "value": 11,
      "name": "Consumer Technology"
    },
    {
      "value": 12,
      "name": "Culture and Arts"
    },
    {
      "value": 13,
      "name": "Ecommerce"
    },
    {
      "value": 14,
      "name": "Education"
    },
    {
      "value": 15,
      "name": "Electronics"
    },
    {
      "value": 16,
      "name": "Energy and Utilities"
    },
    {
      "value": 17,
      "name": "Entertainment"
    },
    {
      "value": 18,
      "name": "Environment"
    },
    {
      "value": 19,
      "name": "Farming"
    },
    {
      "value": 20,
      "name": "Fashion"
    },
    {
      "value": 21,
      "name": "Finance"
    },
    {
      "value": 22,
      "name": "Financial Services"
    },
    {
      "value": 23,
      "name": "Fitness"
    },
    {
      "value": 24,
      "name": "Food & Beverage"
    },
    {
      "value": 25,
      "name": "Gambling"
    },
    {
      "value": 26,
      "name": "Government"
    },
    {
      "value": 27,
      "name": "Healthcare"
    },
    {
      "value": 28,
      "name": "Home and Garden"
    },
    {
      "value": 29,
      "name": "Home Furnishing"
    },
    {
      "value": 30,
      "name": "Hospitality"
    },
    {
      "value": 31,
      "name": "Industrial"
    },
    {
      "value": 32,
      "name": "Insurance"
    },
    {
      "value": 33,
      "name": "Investment"
    },
    {
      "value": 34,
      "name": "IT Services"
    },
    {
      "value": 35,
      "name": "Media"
    },
    {
      "value": 36,
      "name": "Music"
    },
    {
      "value": 37,
      "name": "Parenting"
    },
    {
      "value": 38,
      "name": "Pharmaceutical"
    },
    {
      "value": 39,
      "name": "Politics"
    },
    {
      "value": 40,
      "name": "Quick Service Restaurants"
    },
    {
      "value": 41,
      "name": "Real Estate"
    },
    {
      "value": 42,
      "name": "Restaurants"
    },
    {
      "value": 43,
      "name": "Retail"
    },
    {
      "value": 44,
      "name": "Sports"
    },
    {
      "value": 45,
      "name": "Technology"
    },
    {
      "value": 46,
      "name": "Transport"
    },
    {
      "value": 47,
      "name": "Travel"
    }
  ],
  causeContent: [
    {
      "value": 0,
      "name": "Alcohol"
    },
    {
      "value": 1,
      "name": "Automotive"
    },
    {
      "value": 2,
      "name": "Aviation"
    },
    {
      "value": 3,
      "name": "Banking"
    },
    {
      "value": 4,
      "name": "Beauty"
    },
    {
      "value": 5,
      "name": "Beauty Services"
    },
    {
      "value": 6,
      "name": "Books"
    },
    {
      "value": 7,
      "name": "Business"
    },
    {
      "value": 8,
      "name": "Charity"
    },
    {
      "value": 9,
      "name": "Communications"
    },
    {
      "value": 10,
      "name": "Consumer Packaged Goods"
    },
    {
      "value": 11,
      "name": "Consumer Technology"
    },
    {
      "value": 12,
      "name": "Culture and Arts"
    },
    {
      "value": 13,
      "name": "Ecommerce"
    },
    {
      "value": 14,
      "name": "Education"
    },
    {
      "value": 15,
      "name": "Electronics"
    },
    {
      "value": 16,
      "name": "Energy and Utilities"
    },
    {
      "value": 17,
      "name": "Entertainment"
    },
    {
      "value": 18,
      "name": "Environment"
    },
    {
      "value": 19,
      "name": "Farming"
    },
    {
      "value": 20,
      "name": "Fashion"
    },
    {
      "value": 21,
      "name": "Finance"
    },
    {
      "value": 22,
      "name": "Financial Services"
    },
    {
      "value": 23,
      "name": "Fitness"
    },
    {
      "value": 24,
      "name": "Food & Beverage"
    },
    {
      "value": 25,
      "name": "Gambling"
    },
    {
      "value": 26,
      "name": "Government"
    },
    {
      "value": 27,
      "name": "Healthcare"
    },
    {
      "value": 28,
      "name": "Home and Garden"
    },
    {
      "value": 29,
      "name": "Home Furnishing"
    },
    {
      "value": 30,
      "name": "Hospitality"
    },
    {
      "value": 31,
      "name": "Industrial"
    },
    {
      "value": 32,
      "name": "Insurance"
    },
    {
      "value": 33,
      "name": "Investment"
    },
    {
      "value": 34,
      "name": "IT Services"
    },
    {
      "value": 35,
      "name": "Media"
    },
    {
      "value": 36,
      "name": "Music"
    },
    {
      "value": 37,
      "name": "Parenting"
    },
    {
      "value": 38,
      "name": "Pharmaceutical"
    },
    {
      "value": 39,
      "name": "Politics"
    },
    {
      "value": 40,
      "name": "Quick Service Restaurants"
    },
    {
      "value": 41,
      "name": "Real Estate"
    },
    {
      "value": 42,
      "name": "Restaurants"
    },
    {
      "value": 43,
      "name": "Retail"
    },
    {
      "value": 44,
      "name": "Sports"
    },
    {
      "value": 45,
      "name": "Technology"
    },
    {
      "value": 46,
      "name": "Transport"
    },
    {
      "value": 47,
      "name": "Travel"
    }
  ],
  causeContentType: [
    {
      name: 'Brand Journalism',
      value: 0
    },
    {
      name: 'Advertorial',
      value: 1,
    },
    {
      name: 'Editorial',
      value: 2,
    }
  ],
  causeMarketing: [
    {
      name: 'Awareness',
      value: 0,
    },
    {
      name: 'Lead Generation',
      value: 1
    },
    {
      name: 'Sales',
      value: 2,
    },
    {
      name: 'Consideration',
      value: 3
    }
  ],
  causeAudienceGender: [
    // {
    //   name: 'Male and Female',
    //   value: 0
    // },
    {
      name: 'Male',
      value: 0
    },
    {
      name: 'Female',
      value: 1
    }
  ],
  causeAudienceAge: [
    {
      name: 'Under 18',
      value: 4
    },
    {
      name: '18-24',
      value: 0
    },
    {
      name: '25-39',
      value: 1
    },
    {
      name: '40-54',
      value: 2
    },
    {
      name: '55+',
      value: 3
    },
  ],
  causeAudienceLocationCountry: [
    {
      name: 'Afghanistan',
      value: 0
    },
    {
      name: 'Albania',
      value: 1
    },
    {
      name: 'Algeria',
      value: 2
    },
    {
      name: 'American Samoa',
      value: 3,
    },
    {
      name: 'Andorra',
      value: 4
    },
    {
      name: 'Angola',
      value: 5
    },
    {
      name: 'Anguilla',
      value: 6
    },
    {
      name: 'Antarctica',
      value: 7
    },
    {
      name: 'Antigua and Barbuda',
      value: 8
    },
    {
      name: 'Argentina',
      value: 9,
    },
    {
      name: 'Armenia',
      value: 10
    },
    {
      name: 'Aruba',
      value: 11
    },
    {
      name: 'Australia',
      value: 12,
    },
    {
      name: 'Azerbaijan',
      value: 13
    },
    {
      name: 'Bahamas',
      value: 14
    },
    {
      name: 'Bahrain',
      value: 15
    },
    {
      name: 'Bangladesh',
      value: 16
    },
    {
      name: 'Barbados',
      value: 17
    },
    {
      name: 'Belarus',
      value: 18
    },
    {
      name: 'Belgium',
      value: 19
    },
    {
      name: 'Belize',
      value: 20
    },
    {
      name: 'Benin',
      value: 21
    },
    {
      name: 'Bermuda',
      value: 22
    },
    {
      name: 'Bhutan',
      value: 23
    },
    {
      name: 'Bolivia',
      value: 24
    },
    {
      name: 'Bosnia and Herzegowina',
      value: 25
    },
    {
      name: 'Botswana',
      value: 26
    },
    {
      name: 'Bouvet Island',
      value: 27
    },
    {
      name: 'Brazil',
      value: 28
    },
    {
      name: 'British Indian Ocean Territory',
      value: 29
    },
    {
      name: 'Brunei Darussalam',
      value: 30
    },
    {
      name: 'Bulgaria',
      value: 31
    },
    {
      name: 'Burkina Faso',
      value: 32
    },
    {
      name: 'Burundi',
      value: 33
    },
    {
      name: 'Cambodia',
      value: 34
    },
    {
      name: 'Cameroon',
      value: 35
    },
    {
      name: 'Canada',
      value: 36
    },
    {
      name: 'Cape Verde',
      value: 37
    },
    {
      name: 'Canada',
      value: 38
    },
    {
      name: 'Cayman Islands',
      value: 39
    },
    {
      name: 'Central African Republic',
      value: 40
    },
    {
      name: 'Chad',
      value: 41
    },
    {
      name: 'Chile',
      value: 42
    },
    {
      name: 'China',
      value: 43
    },
    {
      name: 'Christmas Island',
      value: 44
    },
    {
      name: 'Cocos (Keeling) Islands',
      value: 45
    },
    {
      name: 'Colombia',
      value: 46
    },
    {
      name: 'Comoros',
      value: 47
    },
    {
      name: 'Congo',
      value: 48
    },
    {
      name: 'Congo, the Democratic Republic of the',
      value: 49
    },
    {
      name: 'Cook Islands',
      value: 50
    },
    {
      name: 'Rica>Costa Rica',
      value: 51
    },
    {
      name: 'Cote d\'Ivoire',
      value: 52
    },
    {
      name: 'Croatia (Hrvatska)',
      value: 53
    },
    {
      name: 'Cuba',
      value: 54
    },
    {
      name: 'Cyprus',
      value: 55
    },
    {
      name: 'Republic>Czech Republic',
      value: 56
    },
    {
      name: 'Denmark',
      value: 57
    },
    {
      name: 'Djibouti',
      value: 58
    },
    {
      name: 'Dominica',
      value: 59
    },
    {
      name: 'Republic>Dominican Republic',
      value: 60
    },
    {
      name: 'Timor>East Timor',
      value: 61
    },
    {
      name: 'Ecuador',
      value: 62
    },
    {
      name: 'Egypt',
      value: 63
    },
    {
      name: 'Salvador>El Salvador',
      value: 64
    },
    {
      name: 'Guinea>Equatorial Guinea',
      value: 65
    },
    {
      name: 'Eritrea',
      value: 66
    },
    {
      name: 'Estonia',
      value: 67
    },
    {
      name: 'Ethiopia',
      value: 68
    },
    {
      name: 'Falkland Islands (Malvinas)',
      value: 69
    },
    {
      name: 'Faroe Islands',
      value: 70
    },
    {
      name: 'Fiji',
      value: 71
    },
    {
      name: 'Finland',
      value: 72
    },
    {
      name: 'France',
      value: 73
    },
    {
      name: 'Metropolitan>France, Metropolitan',
      value: 74
    },
    {
      name: 'French Guiana',
      value: 75
    },
    {
      name: 'French Polynesia',
      value: 76
    },
    {
      name: 'Southern Territories>French Southern Territories',
      value: 77
    },
    {
      name: 'Gabon',
      value: 78
    },
    {
      name: 'Gambia',
      value: 79
    },
    {
      name: 'Georgia',
      value: 80
    },
    {
      name: 'Germany',
      value: 81
    },
    {
      name: 'Ghana',
      value: 82
    },
    {
      name: 'Gibraltar',
      value: 83
    },
    {
      name: 'Greece',
      value: 84
    },
    {
      name: 'Greenland',
      value: 85
    },
    {
      name: 'Grenada',
      value: 86
    },
    {
      name: 'Guadeloupe',
      value: 87
    },
    {
      name: 'Guam',
      value: 88
    },
    {
      name: 'Guatemala',
      value: 89
    },
    {
      name: 'Guinea',
      value: 90
    },
    {
      name: 'Guinea-Bissau',
      value: 91
    },
    {
      name: 'Guyana',
      value: 92
    },
    {
      name: 'Haiti',
      value: 93
    },
    {
      name: 'Heard and Mc Donald Islands',
      value: 94
    },
    {
      name: 'Holy See (Vatican City State)',
      value: 95
    },
    {
      name: 'Honduras',
      value: 96
    },
    {
      name: 'Hong Kong',
      value: 97
    },
    {
      name: 'Hungary',
      value: 98
    },
    {
      name: 'Iceland',
      value: 99
    },
    {
      name: 'India',
      value: 100
    },
    {
      name: 'Indonesia',
      value: 101
    },
    {
      name: 'Iran (Islamic Republic of)',
      value: 102
    },
    {
      name: 'Iraq',
      value: 103
    },
    {
      name: 'Ireland',
      value: 104
    },
    {
      name: 'Israel',
      value: 105
    },
    {
      name: 'Italy',
      value: 106
    },
    {
      name: 'Jamaica',
      value: 107
    },
    {
      name: 'Japan',
      value: 108
    },
    {
      name: 'Jordan',
      value: 109
    },
    {
      name: 'Kazakhstan',
      value: 110
    },
    {
      name: 'Kenya',
      value: 111
    },
    {
      name: 'Kiribati',
      value: 112
    },
    {
      name: 'Korea, Democratic People\'s Republic of',
      value: 113
    },
    {
      name: 'Korea, Republic of',
      value: 114
    },
    {
      name: 'Kuwait',
      value: 115
    },
    {
      name: 'Kyrgyzstan',
      value: 116
    },
    {
      name: 'Lao People\'s Democratic Republic',
      value: 117
    },
    {
      name: 'Latvia',
      value: 118
    },
    {
      name: 'Lebanon',
      value: 119
    },
    {
      name: 'Lesotho',
      value: 120
    },
    {
      name: 'Liberia',
      value: 121
    },
    {
      name: 'Libyan Arab Jamahiriya',
      value: 122
    },
    {
      name: 'Liechtenstein',
      value: 123
    },
    {
      name: 'Lithuania',
      value: 124
    },
    {
      name: 'Luxembourg',
      value: 125
    },
    {
      name: 'Macau',
      value: 126
    },
    {
      name: 'Macedonia, The Former Yugoslav Republic of',
      value: 127
    },
    {
      name: 'Madagascar',
      value: 128
    },
    {
      name: 'Malawi',
      value: 129
    },
    {
      name: 'Malaysia',
      value: 130
    },
    {
      name: 'Maldives',
      value: 131
    },
    {
      name: 'Mali',
      value: 132
    },
    {
      name: 'Malta',
      value: 133
    },
    {
      name: 'Islands>Marshall Islands',
      value: 134
    },
    {
      name: 'Martinique',
      value: 135
    },
    {
      name: 'Mauritania',
      value: 136
    },
    {
      name: 'Mauritius',
      value: 137
    },
    {
      name: 'Mayotte',
      value: 138
    },
    {
      name: 'Mexico',
      value: 139
    },
    {
      name: 'Micronesia, Federated States of',
      value: 140
    },
    {
      name: 'Moldova, Republic of',
      value: 141
    },
    {
      name: 'Monaco',
      value: 142
    },
    {
      name: 'Mongolia',
      value: 143
    },
    {
      name: 'Montserrat',
      value: 144
    },
    {
      name: 'Morocco',
      value: 145
    },
    {
      name: 'Mozambique',
      value: 146
    },
    {
      name: 'Myanmar',
      value: 147
    },
    {
      name: 'Namibia',
      value: 148
    },
    {
      name: 'Nauru',
      value: 149
    },
    {
      name: 'Nepal',
      value: 150
    },
    {
      name: 'Netherlands',
      value: 151
    },
    {
      name: 'Netherlands Antilles',
      value: 152
    },
    {
      name: 'New Caledonia',
      value: 153
    },
    {
      name: 'New Zealand',
      value: 154
    },
    {
      name: 'Nicaragua',
      value: 155
    },
    {
      name: 'Niger',
      value: 156
    },
    {
      name: 'Nigeria',
      value: 157
    },
    {
      name: 'Niue',
      value: 158
    },
    {
      name: 'Norfolk Island',
      value: 159
    },
    {
      name: 'Northern Mariana Islands',
      value: 160
    },
    {
      name: 'Norway',
      value: 161
    },
    {
      name: 'Oman',
      value: 162
    },
    {
      name: 'Pakistan',
      value: 163
    },
    {
      name: 'Palau',
      value: 164
    },
    {
      name: 'Panama',
      value: 165
    },
    {
      name: 'Papua New Guinea',
      value: 166
    },
    {
      name: 'Paraguay',
      value: 167
    },
    {
      name: 'Peru',
      value: 168
    },
    {
      name: 'Philippines',
      value: 169
    },
    {
      name: 'Pitcairn',
      value: 170
    },
    {
      name: 'Poland',
      value: 171
    },
    {
      name: 'Portugal',
      value: 172
    },
    {
      name: 'Puerto Rico',
      value: 173
    },
    {
      name: 'Qatar',
      value: 174
    },
    {
      name: 'Reunion',
      value: 175
    },
    {
      name: 'Romania',
      value: 176
    },
    {
      name: 'Russian Federation',
      value: 177
    },
    {
      name: 'Rwanda',
      value: 178
    },
    {
      name: 'Saint Kitts and Nevis ',
      value: 179
    },
    {
      name: 'Saint LUCIA',
      value: 180
    },
    {
      name: 'Saint Vincent and the Grenadines',
      value: 181
    },
    {
      name: 'Samoa',
      value: 182
    },
    {
      name: 'San Marino',
      value: 183
    },
    {
      name: 'Sao Tome and Principe ',
      value: 184
    },
    {
      name: 'Saudi Arabia',
      value: 185
    },
    {
      name: 'Senegal',
      value: 186
    },
    {
      name: 'Seychelles',
      value: 187
    },
    {
      name: 'Sierra Leone',
      value: 188
    },
    {
      name: 'Singapore',
      value: 189
    },
    {
      name: 'Slovakia (Slovak Republic)',
      value: 190
    },
    {
      name: 'Slovenia',
      value: 191
    },
    {
      name: 'Solomon Islands',
      value: 192
    },
    {
      name: 'Somalia',
      value: 193
    },
    {
      name: 'South Africa',
      value: 194
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      value: 195
    },
    {
      name: 'Spain',
      value: 196
    },
    {
      name: 'Sri Lanka',
      value: 197
    },
    {
      name: 'St. Helena',
      value: 198
    },
    {
      name: 'St. Pierre and Miquelon',
      value: 199
    },
    {
      name: 'Sudan',
      value: 200
    },
    {
      name: 'Suriname',
      value: 201
    },
    {
      name: 'Svalbard and Jan Mayen Islands',
      value: 202
    },
    {
      name: 'Swaziland',
      value: 203
    },
    {
      name: 'Sweden',
      value: 204
    },
    {
      name: 'Switzerland',
      value: 205
    },
    {
      name: 'Syrian Arab Republic',
      value: 206
    },
    {
      name: 'Taiwan, Province of China',
      value: 207
    },
    {
      name: 'Tajikistan',
      value: 208
    },
    {
      name: 'Tanzania, United Republic of',
      value: 209
    },
    {
      name: 'Thailand',
      value: 210
    },
    {
      name: 'Togo',
      value: 211
    },
    {
      name: 'Tokelau',
      value: 212
    },
    {
      name: 'Tonga',
      value: 213
    },
    {
      name: 'Trinidad and Tobago',
      value: 214
    },
    {
      name: 'Tunisia',
      value: 215
    },
    {
      name: 'Turkey',
      value: 216
    },
    {
      name: 'Turkmenistan',
      value: 217
    },
    {
      name: 'Turks and Caicos Islands',
      value: 218
    },
    {
      name: 'Tuvalu',
      value: 219
    },
    {
      name: 'Uganda',
      value: 220
    },
    {
      name: 'Ukraine',
      value: 221
    },
    {
      name: 'United Arab Emirates',
      value: 222
    },
    {
      name: 'United Kingdom',
      value: 223
    },
    {
      name: 'United States',
      value: 224
    },
    {
      name: 'United States Minor Outlying Islands',
      value: 225
    },
    {
      name: 'Uruguay',
      value: 226
    },
    {
      name: 'Uzbekistan',
      value: 227
    },
    {
      name: 'Vanuatu',
      value: 228
    },
    {
      name: 'Venezuela',
      value: 229
    },
    {
      name: 'Viet Nam',
      value: 230
    },
    {
      name: 'Virgin Islands (British)',
      value: 231
    },
    {
      name: 'Virgin Islands (U.S.)',
      value: 232
    },
    {
      name: 'Wallis and Futuna Islands',
      value: 233
    },
    {
      name: 'Western Sahara',
      value: 234
    },
    {
      name: 'Yemen',
      value: 235
    },
    {
      name: 'Yugoslavia',
      value: 236
    },
    {
      name: 'Zambia',
      value: 237
    },
    {
      name: 'Zimbabwe',
      value: 238
    },
  ],
  causeAudienceLocation: [
    {
      name: 'NSW',
      value: 0
    },
    {
      name: 'QLD',
      value: 1
    },
    {
      name: 'SA',
      value: 2
    },
    {
      name: 'TAS',
      value: 3,
    },
    {
      name: 'VIC',
      value: 4
    },
    {
      name: 'WA',
      value: 5
    },
    {
      name: 'ACT',
      value: 6
    },
    {
      name: 'NT',
      value: 7
    },
  ],
  causeWriter: [
    {
      name: 'Original Author',
      value: 0
    },
    {
      name: 'Ghost writer',
      value: 1
    }
  ],
  causeType: [
    {
      name: 'Public',
      value: 'public',
      iconOptions: {
        gradientFrom: '#34D1C2',
        gradientTo: '#0DBDA3',
        colorText: '#1DC5AF',
        text: 'Public',
        xText: '36',
      }
    },
    {
      name: 'Internal',
      value: 'internal',
      iconOptions: {
        gradientFrom: '#FFB296',
        gradientTo: '#FE7A96',
        colorText: '#fe9496',
        text: 'Internal',
        xText: '30',
      }
    },
    {
      name: 'Invite Only',
      value: 'invite',
      iconOptions: {
        gradientFrom: '#78BDF5',
        gradientTo: '#1487E2',
        colorText: '#4fa6ec',
        text: 'Invite Only',
        xText: '24',
      }
    }
  ],
  amplifyCauseType: [
    {
      name: 'Public',
      value: 0,
      iconOptions: {
        gradientFrom: '#34D1C2',
        gradientTo: '#0DBDA3',
        colorText: '#1DC5AF',
        text: 'Public',
        xText: '36',
      }
    },
    {
      name: 'Publishing',
      value: 1,
      iconOptions: {
        gradientFrom: '#1A89E3',
        gradientTo: '#1A89E3',
        colorText: '#1A89E3',
        text: 'Publishing',
        xText: '25',
      }
    },
    {
      name: 'Distribution',
      value: 2,
      iconOptions: {
        gradientFrom: '#1A89E3',
        gradientTo: '#1A89E3',
        colorText: '#1A89E3',
        text: 'Distribution',
        xText: '20',
      }
    },
  ]
}

export const selectFieldBriefFormThree = {
  causeFormat: [
    {
      name: 'Short Article - 800 words(1400$ AUD)',
      value: 1400,
    },
    {
      name: 'Middle Article - 1600 words(2800$ AUD)',
      value: 2800
    },
    {
      name: 'Long Article - 3200 words(5600$ AUD)',
      value: 5600,
    },
  ],
  causeFormatQty: [
    {
      name: '1',
      value: 0,
    },
    {
      name: '2',
      value: 1
    },
    {
      name: '3',
      value: 2,
    },
    {
      name: '4',
      value: 3,
    },
    {
      name: '5',
      value: 4,
    },
    {
      name: '6',
      value: 5,
    },
  ],
  causeOtherServices: [
    {
      name: 'Infographics ($700 AUD)',
      value: 700,
    },
    {
      name: 'Infographics ($900 AUD)',
      value: 900
    },
  ],
  causeOtherServicesQty: [
    {
      name: '1',
      value: 0,
    },
    {
      name: '2',
      value: 1
    },
    {
      name: '3',
      value: 2,
    },
    {
      name: '4',
      value: 3,
    },
    {
      name: '5',
      value: 4,
    },
    {
      name: '6',
      value: 5,
    }
  ],

}

export const settings = {
  competencies: [
    {
      name: 'Auto',
      value: 0
    },
    {
      name: 'Banking & Superannuation',
      value: 1
    },
    {
      name: 'Beauty & Fashion',
      value: 2
    },
    {
      name: 'Business & Finance',
      value: 3
    },
    {
      name: 'Data Security',
      value: 4
    },
    {
      name: 'Diet & Fitness',
      value: 5
    },
    {
      name: 'DIY & Interior Design',
      value: 6
    },
    {
      name: 'Education',
      value: 7
    },
    {
      name: 'Film, TV & Entertainment',
      value: 8
    },
    {
      name: 'Food & Restaurants',
      value: 9
    },
    {
      name: 'Health and Wellbeing',
      value: 10
    },
    {
      name: 'Home Design and Décor',
      value: 11
    },
    {
      name: 'Internet of Things',
      value: 12
    },
    {
      name: 'Lifestyle',
      value: 13
    },
    {
      name: 'Media and Communications',
      value: 14
    },
    {
      name: 'Medicine & Pharmaceuticals',
      value: 15
    },
    {
      name: 'Recipes & Nutrition',
      value: 16
    },
    {
      name: 'Parenting',
      value: 17
    },
    {
      name: 'Personal Finance',
      value: 18
    },
    {
      name: 'Real Estate',
      value: 19
    },
    {
      name: 'Retail',
      value: 20
    },
    {
      name: 'Science and Technology',
      value: 21
    },
    {
      name: 'Small Business',
      value: 22
    },
    {
      name: 'Sports',
      value: 23
    },
    {
      name: 'Travel',
      value: 24
    }
  ],

  experienceContractor: [
    {
      name: 'No experience',
      value: 0
    },
    {
      name: 'Some experience',
      value: 1
    },
    {
      name: 'Professional',
      value: 2
    },
  ]
}

export const causeStatus = {
  0: {
    name: 'Draft',
    value: 0,
    color: 'draft',
    border: 'slate-300'
  },
  1: {
    name: 'Selecting',
    value: 1,
    color: 'selecting',
    border: 'success'
  },
  2: {
    name: 'progress',
    value: 2,
    color: 'in-progress',
    border: 'teal'
  },
  3: {
    name: 'Completed',
    value: 3,
    color: 'completed',
    border: 'info-300'
  },
  4: {
    name: 'Canceled',
    value: 4,
    color: 'bg-danger',
    border: 'danger'
  }
}

export const amplifyCauseStatus = {
  0: {
    name: 'Active',
    value: 0,
    color: 'selecting',
    border: 'success'
  },
  1: {
    name: 'Awaiting Content',
    value: 1,
    color: 'in-progress',
    border: 'teal'
  },
  2: {
    name: 'Content Ready',
    value: 2,
    color: 'in-progress',
    border: 'teal'
  },
  3: {
    name: 'Completed',
    value: 3,
    color: 'completed',
    border: 'info-300'
  },
  4: {
    name: 'Archived',
    value: 4,
    color: 'draft',
    border: 'slate-300'
  },
  5: {
    name: 'Declined',
    value: 5,
    color: 'bg-danger',
    border: 'danger'
  },
}

export const causeUserStatus = {
  0: {
    name: 'Invite',
    value: 0,
    color: 'bg-info-300'
  },
  1: {
    name: 'Invite Accepted',
    value: 1,
    color: 'bg-info'
  },
  2: {
    name: 'Invite Declined',
    value: 2,
    color: 'bg-info-300'
  },
  3: {
    name: 'Pitch',
    value: 3,
    color: 'bg-success-300'
  },
  4: {
    name: 'Pitch Accepted',
    value: 4,
    color: 'bg-success'
  },
  5: {
    name: 'Pitch Declined',
    value: 5,
    color: 'bg-success-300'
  }
}

export const milestoneStatus = {
  0: {
    name: 'Pending',
    value: 0,
    color: 'bg-success',
    border: 'success'
  },
  1: {
    name: 'In progress',
    value: 1,
    color: 'bg-teal',
    border: 'teal'
  },
  2: {
    name: 'Testing',
    value: 2,
    color: 'bg-danger',
    border: 'danger'
  },
  3: {
    name: 'Accept',
    value: 3,
    color: 'bg-info-300',
    border: 'info-300'
  },
  4: {
    name: 'Decline',
    value: 4,
    color: 'bg-pink-400',
    border: 'pink-400'
  }
}

export const routConst = {
  'dashboard': {
    name: 'Dashboard',
  },
  'new-briefs': {
    name: 'New Briefs'
  },
  'messages': {
    name: 'Messages'
  },
  'brief': {
    name: 'My Jobs',
  },
  'brief-show': {
    name: 'Brief',
  },
  'create-brief': {
    name: 'New brief',
  },
  'brief-edit': {
    name: 'Brief edit',
  },
  'user-profile': {
    name: 'My profile',
  },
  'pitch': {
    name: 'Pitch',
  },
  'contractor': {
    name: 'Contractor',
  },
  'buyer': {
    name: 'Buyer',
  },
  'account': {
    name: 'Account'
  },
  'shared-products': {
    name: 'Shared products'
  },
  'help': {
    name: 'Help'
  },
  'calendar': {
    name: 'Calendar'
  }
}

export const sortBy = {
  causeDueDate: {
    name: 'Due Date',
    value: 'causeDueDate'
  },
  createdAt: {
    name: 'Created date',
    value: 'createdAt'
  },
  causeStatus: {
    name: 'Brief status',
    value: 'causeStatus'
  },
  causeName: {
    name: 'Brief name',
    value: 'causeName'
  },

}

export const sortByContractor = {
  updatedAt: {
    name: 'Last Update',
    value: 'updatedAt'
  },
  causeDueDate: {
    name: 'Due Date',
    value: 'causeDueDate'
  }
}

export const sortByCauseUser = {
  causeUserStatus: {
    name: 'Pitch status',
    value: 'causeUserStatus'
  },
  createdAt: {
    name: 'Created date',
    value: 'createdAt'
  },
  causeDueDate: {
    name: 'Brief date',
    value: 'causeDueDate'
  },
}

export const workroomStatus = {
  'work': {
    value: 0,
    name: 'In progress'
  },
  'review': {
    value: 1,
    name: 'Review by buyer'
  },
  'decline': {
    value: 2,
    name: 'Decline'
  },
  'accept': {
    value: 3,
    name: 'Accept'
  },
}

export const workroomStatusList = _.values(workroomStatus)

export const roles = [
  {
    name: 'Super admin',
    value: 0
  },
  {
    name: 'Admin',
    value: 1
  },
  {
    name: 'Buyer',
    value: 2
  },
  {
    name: 'Creator',
    value: 3
  },
  {
    name: 'Editor',
    value: 4
  },
  {
    name: 'Guest',
    value: 5
  },
  {
    name: 'Amplify',
    value: 6,
  },
]

export const briefStepNames = [
  'Project overview',
  'Campaign objectives',
  'Campaign details',
  'References',
  'Amplify Section',
  'Confirm and publish',
]

export const productType = [
  {
    name: 'Blog Article',
    value: 0
  },
  {
    name: 'Video Article',
    value: 1
  },
  {
    name: 'Graphic Article',
    value: 2
  }
]

export const portfolioType = [
  {
    name: 'Link to video',
    value: 0
  },
  {
    name: 'Link to site',
    value: 1
  }
]

export const pnotifyValue = {
  isStar: 'You have saved this pitch to your favourites',
  isHide: 'You have hidden this pitch from view.',
  isAward(contractorName) {
    return (
      `You have awarded ${contractorName} this job! Head to the workroom to get started`
    )
  },
  isDecline: 'You have declined this pitch.'
}

export const experienceFieldName = [
  'productTemplate',
  'productTemplateProfessional',
  'productTemplatePlatinum'
]

// NOTE: This flag is needed to work invite correctly.
export const inviteHC = '&hc/;'

// eslint-disable-next-line no-multi-str
export const fileExtensions = 'image/*, audio/*, video/*,\
.doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,\
.ppt, .pptx, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation,\
.xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,\
.pdf, .txt, .csv, .xlsm, .mkv, .mp4, .mov, .avi, .flv, .webm, .rtf'

export const userPermission = {
  admin: 1,
  buyer: 2,
  contractor: 3,
  editor: 4,
  guest: 5,
  amplify: 6
}

export const causeBiline = {
  false: 'Original Author',
  true: 'Ghost Written'
}

export const languages = [
  {
    name: 'Afrikanns',
    value: 0
  },
  {
    name: 'Albanian',
    value: 1
  },
  {
    name: 'Arabic',
    value: 2
  },
  {
    name: 'Armenian',
    value: 3
  },
  {
    name: 'Basque',
    value: 4
  },
  {
    name: 'Bengali',
    value: 5
  },
  {
    name: 'Bulgarian',
    value: 6
  },
  {
    name: 'Catalan',
    value: 7
  },
  {
    name: 'Cambodian',
    value: 8
  },
  {
    name: 'Chinese (Mandarin)',
    value: 9
  },
  {
    name: 'Croation',
    value: 10
  },
  {
    name: 'Czech',
    value: 11
  },
  {
    name: 'Danish',
    value: 12
  },
  {
    name: 'Dutch',
    value: 13
  },
  {
    name: 'English',
    value: 14
  },
  {
    name: 'Estonian',
    value: 15
  },
  {
    name: 'Fiji',
    value: 16
  },
  {
    name: 'Finnish',
    value: 17
  },
  {
    name: 'French',
    value: 18
  },
  {
    name: 'Georgian',
    value: 19
  },
  {
    name: 'German',
    value: 20
  },
  {
    name: 'Greek',
    value: 21
  },
  {
    name: 'Gujarati',
    value: 22
  },
  {
    name: 'Hebrew',
    value: 23
  },
  {
    name: 'Hindi',
    value: 24
  },
  {
    name: 'Hungarian',
    value: 25
  },
  {
    name: 'Icelandic',
    value: 26
  },
  {
    name: 'Indonesian',
    value: 27
  },
  {
    name: 'Irish',
    value: 28
  },
  {
    name: 'Italian',
    value: 29
  },
  {
    name: 'Japanese',
    value: 30
  },
  {
    name: 'Javanese',
    value: 31
  },
  {
    name: 'Korean',
    value: 32
  },
  {
    name: 'Latin',
    value: 33
  },
  {
    name: 'Latvian',
    value: 34
  },
  {
    name: 'Lithuanian',
    value: 35
  },
  {
    name: 'Macedonian',
    value: 36
  },
  {
    name: 'Malay',
    value: 37
  },
  {
    name: 'Malayalam',
    value: 38
  },
  {
    name: 'Maltese',
    value: 39
  },
  {
    name: 'Maori',
    value: 40
  },
  {
    name: 'Marathi',
    value: 41
  },
  {
    name: 'Mongolian',
    value: 42
  },
  {
    name: 'Nepali',
    value: 43
  },
  {
    name: 'Norwegian',
    value: 44
  },
  {
    name: 'Persian',
    value: 45
  },
  {
    name: 'Polish',
    value: 46
  },
  {
    name: 'Portuguese',
    value: 47
  },
  {
    name: 'Punjabi',
    value: 48
  },
  {
    name: 'Quechua',
    value: 49
  },
  {
    name: 'Romanian',
    value: 50
  },
  {
    name: 'Russian',
    value: 51
  },
  {
    name: 'Samoan',
    value: 52
  },
  {
    name: 'Serbian',
    value: 53
  },
  {
    name: 'Slovak',
    value: 54
  },
  {
    name: 'Slovenian',
    value: 55
  },
  {
    name: 'Spanish',
    value: 56
  },
  {
    name: 'Swahili',
    value: 57
  },
  {
    name: 'Swedish ',
    value: 58
  },
  {
    name: 'Tamil',
    value: 59
  },
  {
    name: 'Tatar',
    value: 60
  },
  {
    name: 'Telugu',
    value: 61
  },
  {
    name: 'Thai',
    value: 62
  },
  {
    name: 'Tibetan',
    value: 63
  },
  {
    name: 'Tonga',
    value: 64
  },
  {
    name: 'Turkish',
    value: 65
  },
  {
    name: 'Ukranian',
    value: 66
  },
  {
    name: 'Urdu',
    value: 67
  },
  {
    name: 'Uzbek',
    value: 68
  },
  {
    name: 'Vietnamese',
    value: 69
  },
  {
    name: 'Welsh',
    value: 70
  },
  {
    name: 'Xhosa',
    value: 71
  },
]

export const availabilityValues = [
  {
    name: 'Fultime',
    value: 0
  },
  {
    name: 'Parttime',
    value: 1
  }
]

export const workroomTypes = [
  {
    name: 'Written',
    value: 0,
    path: '/create-brief'
  },
  {
    name: 'Design',
    value: 1,
    path: '/create-design-brief'
  },
  {
    name: 'Audio',
    value: 2,
    path: '/create-audio-brief'
  },
  {
    name: 'Video',
    value: 3,
    path: '/create-video-brief'
  }
]

export const typesSkipWorkroom = {
  'edit': {
    info: 'Start proof reading on this article',
    titleButton: 'Editing',
    title: 'editing',
    text: 'By skipping ahead you will miss out on the option of having your creator make additional changes to copy and images.',
  },
  'publish': {
    info: 'Take this article straight to publishing',
    titleButton: 'Publish',
    title: 'Publishing',
    text: 'By skipping ahead you will miss out on the option of having your creator make additional changes to copy and images and your work will not be proof read by an editor.'
  }
}

export const ITEM_PER_PAGE_VAULT = 12;
export const ITEM_PER_PAGE_CAUSEUSERLIST = 4;

export const slidersBriefForms = [
  {
    labels: {
      leftLabel: 'Traditional',
      rightLabel: 'Modern'
    },
    configSlider: {
      min: 0,
      max: 100,
      step: 1
    },
    name: 'causeContentTraditional'
  },
  {
    labels: {
      leftLabel: 'Mature',
      rightLabel: 'Youthful'
    },
    configSlider: {
      min: 0,
      max: 100,
      step: 1
    },
    name: 'causeContentMature'
  },
  {
    labels: {
      leftLabel: 'Feminine',
      rightLabel: 'Masculine'
    },
    configSlider: {
      min: 0,
      max: 100,
      step: 1
    },
    name: 'causeContentFeminine'
  },
  {
    labels: {
      leftLabel: 'Playful',
      rightLabel: 'Sophisticated'
    },
    configSlider: {
      min: 0,
      max: 100,
      step: 1
    },
    name: 'causeContentPlayful'
  }
]

export const workspaceTypesValues = {
  default: 0,
  publishing: 1,
  distribution: 2
}

export const amplifyCausePermissions = {
  0: {
    value: 0,
    name: 'Owner'
  },
  1: {
    value: 1,
    name: 'Editor'
  },
  2: {
    value: 2,
    name: 'Read Only'
  }
}

export const buyerCausePermissions = {
  0: {
    value: 0,
    name: 'Owner'
  },
  1: {
    value: 1,
    name: 'Editor'
  },
  2: {
    value: 2,
    name: 'Read Only'
  }
}

export const buyerPermissions = {
  owner: 0,
  editor: 1,
  readonly: 2
}

export const MAX_FILE_SIZE = process.env['REACT_APP_MAX_FILE_SIZE'] * 1024 * 1024;

export const MAX_VIDEO_FILE_SIZE = 500 * 1024 * 1024;

export const getVideoType = (file) => {
  const videoList = ['mkv', 'mp4', 'mov', 'avi', 'flv', 'webm']
  let isVideo = false
  videoList.map((item) => {
    if ((file && file.name && file.name.toLowerCase().includes(item)) || (file && file.type && file.type.toLowerCase().includes(item))) {
      isVideo = true
    }
  })

  return isVideo
}
export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

export const findUploadCount = (list, name) => {
  const data = list.findIndex(obj => obj && Object.keys(obj)[0] === name)
  const uploadItem = list[data]
  return (uploadItem && uploadItem[name]) || 0
}

export const alreadyUploadFilteredList  = (newList, oldList, cb) => {
  const filteredList = newList.map(item => {
    const isAlreadyUploaded = oldList && oldList.find(item1 => ((item1.name === item.name) || (item1.mediaName === item.name)))
    if (isAlreadyUploaded) {
      cb(item.name || item.mediaName)
    } else {
      return item
    }
  })

  const data = filteredList && filteredList.filter(item => item !== undefined)

  return data
}

export const gettyCount = (countData, currentUser) => {
  const user = roles.filter(item => item.value === currentUser.permissions)
  const _gettyImageCount = countData
  ? countData[(user[0].name).toLowerCase()]
  : 0

  return _gettyImageCount
}