import types from '../constants/publicationHistory'
import _ from 'lodash'

const initialState = {
  list: {
    origin: {},
    page: 1,
  },
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET_SUCCESS:
  {
    const { data, count, page } =  action.payload
    return {
      ...state,
      list: {
        ...state.list,
        origin: {
          ..._.keyBy(data, 'id')
        },
        count,
        page
      }
    }
  }

  case types.GET_NEXT_PAGE_WITH_ADDING_SUCCESS: 
  {
    const { data, page , count } = action.payload
    return {
      ...state,
      list: {
        ...state.list,
        origin: {
          ...state.list.origin,
          ..._.keyBy(data, 'id'),
        },
        page,
        count
      }
    }
  }

  case types.GET_FAILURE:
  case types.GET_NEXT_PAGE_WITH_ADDING_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
    }
  }
  default:
    return state
  }
}
