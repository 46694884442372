import { put, takeEvery, select } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import _ from 'lodash'

import types from '../constants/product'
import * as actions from '../actions/product'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

// import { getTranslate } from 'react-localize-redux'

// function* translate() {
//   let store = yield select()
//   const translate = getTranslate(store.locale)
//   return translate
// }

function* get(action) {
  const location = (state) => state.router.location.pathname
  const pathname = yield select(location)
  try {
    const productList = yield backend.service('product').find({
      query: action.payload.queryParams
    })
    if (!productList.count && pathname === '/create-brief') {
      yield put(push('/dashboard'))
    }
    if(_.size(productList)) {
      yield put(actions.getSuccess(productList))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get product list error')
    yield put(actions.getFailure(e))
  }

}

function* productSaga() {
  yield takeEvery(types.GET, get)
}

export default {
  productSaga,
}
