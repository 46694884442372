export default {

  GET:                             'SERVER:RECOMMENDED_GET',
  GET_SUCCESS:                     'RECOMMENDED_GET_SUCCESS',
  GET_FAILURE:                     'RECOMMENDED_GET_FAILURE',

  SET_FILTER:                      'RECOMMENDED_SET_FILTER',
  RESET_FILTERS:                   'RECOMMENDED_RESET_FILTERS',

  SET_PAGE:                        'RECOMMENDED_SET_PAGE',

  SOCKET_CAUSE_CREATE:             'SOCKET_CAUSE_CREATE',
  RECOMMENDED_SOCKET_CAUSE_REMOVE: 'RECOMMENDED_SOCKET_CAUSE_REMOVE',
}