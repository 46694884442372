/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

import classNames from 'classnames'

import './SpinnerLoadData.css'

class SpinnerLoadData extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isShow: false
    }
  }

  componentDidMount() {
    const { timeDelay = 2000 } = this.props
    this.timer = setTimeout(() => {
      this.show()
    }, timeDelay)
  }

  show = () => {
    this.setState({ isShow: true })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  render() {
    const { isAbsolute, isForTable, isContractorPermissions } = this.props
    if (!this.state.isShow) {
      return null
    }
    return isForTable ? (
      <tr className="spiner-tr-wrapper" style={{ height: isContractorPermissions ? '400px' : 'auto' }}>
        <td colSpan="100%">
          <div className={classNames('theme_corners theme_corners_with_text', {
            'spiner-center-absolute': isAbsolute,
            'spiner-center-relative': !isAbsolute
          })}>
            <a className="pace_progress" data-progress-text="60%" data-progress="60" />
            <a className="pace_activity" style={{ backgroundColor: '#777' }} />
          </div>
        </td>
      </tr>
    ) : (
      <div className={classNames('theme_corners theme_corners_with_text', {
        'spiner-center-absolute': isAbsolute,
        'spiner-center-relative': !isAbsolute
      })}>
        <div className="pace_progress" data-progress-text="60%" data-progress="60">
        </div>
        <div className="pace_activity" style={{ backgroundColor: '#777' }}>
        </div>
      </div>
    )
  }
}

SpinnerLoadData.defaultProps = {
  isAbsolute: true,
  isForTable: false
}

export default SpinnerLoadData
