import types from '../constants/account'
import authTypes from '../constants/auth'

const fetching = {
  isGetFetching: false,
}

const initialState = {
  origin: {},
  invoices: {
    list: {},
    page: 0,
    count: 0,
  },
  filter: {},
  causes: {},
  workspace: {},
  xeroUser: null,
  xeroOrganization: null,
  nearestInvoice: null,
  error: null,
  xeroInvoiceLink: null,
  ...fetching,
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET:
  {
    return {
      ...state,
      isGetFetching: true
    }
  }

  case types.GET_SUCCESS:
  {
    const { invoices, count, ...rest } = action.payload
    return {
      ...state,
      ...rest,
      isGetFetching: false,
      invoices: {
        ...state.invoices,
        count,
        list: {
          ...invoices,
        }
      },
    }
  }

  case types.GET_FAILURE:
  {
    return {
      ...state,
      isGetFetching: false,
      error: action.payload,
    }
  }

  case types.DOWNLOAD_INVOICE_SUCCESS:
  {
    return {
      ...state,
      xeroInvoiceLink: action.payload
    }
  }

  case types.DOWNLOAD_INVOICE_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
    }
  }
  
  case types.UPDATE_WORKSPACE_SUCCESS:
  case types.EDIT_WORKSPACE:
  case authTypes.USER_GET_SUCCESS:
  {
    const workspace = action.payload.workspace

    return {
      ...state,
      workspace
    }
  }

  case types.SET_PAGE:
  {
    return {
      ...state,
      invoices: {
        ...state.invoices,
        page: action.payload
      }
    }
  }

  case types.SET_ACCOUNT_FILTER:
      {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload
          },
          list: {
            ...state.list,
            page: 0,
            count: 0,
          },
        }
      }
    case types.RESET_ACCOUNT_FILTER:
      {
        return {
          ...state,
          filter: {},
          list: {
            ...state.list,
            page: 0
          }
        }
      }

  default:
    return state
  }

}