export default {

  GET: 'SERVER:CAUSE_USER_GET',
  GET_SUCCESS: 'CAUSE_USER_GET_SUCCESS',
  GET_FAILURE: 'CAUSE_USER_GET_FAILURE',

  GET_ID: 'SERVER:CAUSE_USER_GET_ID',
  GET_ID_SUCCESS: 'CAUSE_USER_GET_ID_SUCCESS',
  GET_ID_FAILURE: 'CAUSE_USER_GET_ID_FAILURE',

  CREATE: 'SERVER:CAUSE_USER_CREATE',
  CREATE_SUCCESS: 'CAUSE_USER_CREATE_SUCCESS',
  CREATE_FAILURE: 'CAUSE_USER_CREATE_FAILURE',

  REMOVE: 'SERVER:CAUSE_USER_REMOVE',
  REMOVE_SUCCESS: 'CAUSE_USER_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'CAUSE_USER_REMOVE_FAILURE',

  UPDATE: 'SERVER:CAUSE_USER_UPDATE',
  UPDATE_SUCCESS: 'CAUSE_USER_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CAUSE_USER_UPDATE_FAILURE',

  FIND_CAUSE_USER: 'SERVER:FIND_CAUSE_USER',
  FIND_CAUSE_USER_SUCCESS: 'FIND_CAUSE_USER_SUCCESS',
  FIND_CAUSE_USER_FAILURE: 'FIND_CAUSE_USER_FAILURE',

  CAUSE_USER_EDIT: 'CAUSE_USER_EDIT',
  VALIDATION: 'CAUSE_USER_VALIDATION',
  SET_FILTER: 'CAUSE_USER_SET_FILTER',
  RESET_FILTERS: 'CAUSE_USER_RESET_FILTERS',
  SET_SORT: 'CAUSE_USER_SET_SORT',
  SET_PAGE: 'CAUSE_USER_SET_PAGE',
  RESET_VALIDATION_EDIT: 'RESET_VALIDATION_EDIT',
  RESET_VALIDATION_CREATE: 'RESET_VALIDATION_CREATE'

}
