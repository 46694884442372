import { put, takeEvery } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import _ from 'lodash'

import * as causeActions from 'store/actions/cause'

import types from '../constants/router'

function* routerPush(action) {
  yield put(push(action.payload))
}

function* locationChange(action) {
  const { location, action: routerAction, isFirstRendering } = action.payload
  if (
    _.includes(location.pathname, '/brief-show/') &&
    _.isEqual(routerAction, 'PUSH') &&
    isFirstRendering
  ) {
    yield put(causeActions.clearEditData())
  }
}

function* routerSaga() {
  yield takeEvery(types.PUSH, routerPush)
  yield takeEvery('@@router/LOCATION_CHANGE', locationChange)
}

export default routerSaga