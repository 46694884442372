import types from '../constants/buyerTodos'

export const get = (data) => ({
    type: types.GET,
    payload: data
})

export const getSuccess = (data) => ({
    type: types.GET_SUCCESS,
    payload: data
})

export const getFailure = (error) => ({
    type: types.GET_FAILURE,
    payload: error
})

export const setFilter = (data) => ({
    type: types.SET_FILTER,
    payload: data
})

export const setPage = (data) => ({
    type: types.SET_PAGE,
    payload: data
})

export const resetFilters = () => ({
    type: types.RESET_FILTER,
})
