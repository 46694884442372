import types from '../constants/amplifyCause'
import _ from 'lodash'
import { validationParams as causeValidationParams } from './cause'
import moment from 'moment'


const initialState = {
  error: null,
  isGetFetching: false,
  list: {
    origin: {},
    page: 0,
    count: 0,
  },
  origin: {},
  edit: {},
  filter: {},
  deletedProducts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AMPLIFY_CAUSE_GET: {
      return {
        ...state,
        isGetFetching: true
      }
    }
    case types.AMPLIFY_CAUSE_GET_SUCCESS: {
      const { data: { count, rows }, page } = action.payload
      return {
        ...state,
        isGetFetching: false,
        list: {
          origin: _.keyBy(rows, 'id'),
          page,
          count
        }
      }
    }
    case types.AMPLIFY_CAUSE_GET_ID:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }
    case types.AMPLIFY_CAUSE_GET_ID_SUCCESS:
      {
        const payload = action.payload
        const causeInfo = payload.amplifyCauseInfo
        const toArr = Object.entries(causeValidationParams)
        const nextValidModel = _.reduce(toArr, (obj, item) => {
          const key = item[0]
          obj[key] = {
            ...causeValidationParams[key],
            value: !_.isNil(causeInfo[key]) ? causeInfo[key] : causeValidationParams[key].value,
            isValid: true
          }
          return obj
        }, {})
        return {
          ...state,
          isGetFetching: false,
          origin: payload,
          causeValidationEdit: {
            id: {
              value: causeInfo.id
            },
            ...causeValidationParams,
            ...nextValidModel
          }
        }
      }

    case types.VALIDATION:
      {
        const validType = action.payload.isCreate ? 'validationCreate' : 'validationEdit'
        return {
          ...state,
          [validType]: {
            ...state[validType],
            ...action.payload.data
          }
        }
      }

    case types.AMPLIFY_CAUSE_SET_ORIGIN:
      {
        const payload = action.payload.data
        const amplifyWorkspaceId = _.get(payload.organization, 'id', null)
        const oldAmplifyCauseWorkspaceProducts = _.get(state.origin[amplifyWorkspaceId], 'amplifyProducts', [])
        return {
          ...state,
          origin: {
            ...state.origin,
            [amplifyWorkspaceId]: {
              amplifyProducts: [
                ...oldAmplifyCauseWorkspaceProducts,
                payload.product,
              ],
              workspaceType: payload.organization.workspaceType,
              organizationName: payload.organization.name,
              deletedProducts: state.deletedProducts
            }
          }
        }
      }

    case types.AMPLIFY_CAUSE_CHANGE_PRODUCT: {
      const payload = action.payload.data
      const newProduct = payload.product
      const newIndex = payload.index
      const amplifyWorkspaceId = _.get(payload.product, 'workspaceId', null)
      const amplifyProducts = [...state.origin[amplifyWorkspaceId].amplifyProducts]
      amplifyProducts[newIndex] = newProduct
      // Adding campaignStart & campaignDeadline Date in Amplify Cause
      let campaignStart = null
      let campaignDeadline = null

      // Getting start Date & end Date from previous amplify products 
      _.map(amplifyProducts, (product) => {
        const startDate = _.get(product, 'startDate', null)
        if (campaignStart > startDate || _.isNil(campaignStart)) {
          campaignStart = startDate
        }
      })

      _.map(amplifyProducts, (product) => {
        const endDate = _.get(product, 'endDate', null)
        if (campaignDeadline < endDate || _.isNil(campaignDeadline)) {
          campaignDeadline = endDate
        }
      })

      // Comparing Dates with new Product
      const startDate = _.get(newProduct, 'startDate', null)
      if (campaignStart > startDate || _.isNil(campaignStart)) {
        campaignStart = startDate
      }
      const endDate = _.get(newProduct, 'endDate', null)
      if (campaignDeadline < endDate || _.isNil(campaignDeadline)) {
        campaignDeadline = endDate
      }

      return {
        ...state,
        origin: {
          ...state.origin,
          [amplifyWorkspaceId]: {
            ...state.origin[amplifyWorkspaceId],
            amplifyProducts: amplifyProducts,
            campaignStart,
            campaignDeadline
          }
        }
      }
    }

    case types.AMPLIFY_CAUSE_CLEAR_ORIGIN:
      {
        return {
          ...state,
          origin: {},
        }
      }

    case types.AMPLIFY_CAUSE_REMOVE:
      {
        const amplifyWorkspaceId = action.payload.workspaceId
        const productIndex = action.payload.productIndex
        const causes = { ...state.origin }
        const deletedCauseProducts = causes[amplifyWorkspaceId].amplifyProducts.splice(productIndex, 1).pop()

        const oldDeletedCauseProducts = state.deletedProducts
        // Add product in deleted list in case of update only
        if (deletedCauseProducts.amplifyCauseProductId) {
          oldDeletedCauseProducts.push(deletedCauseProducts)
        }
        //Todo: update campaignStartDate & CampaignEndDate after removing a product

        return {
          ...state,
          origin: causes,
          deletedProducts: oldDeletedCauseProducts
        }
      }

    case types.AMPLIFY_CAUSE_SET_EDIT:
      {
        const editAmplifyCauses = _.reduce(action.payload, (acc, item) => {
          return {
            ...acc,
            ...item
          }
        }, {})
        return {
          ...state,
          origin: editAmplifyCauses
        }
      }

    case types.AMPLIFY_CAUSE_UPDATE:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.AMPLIFY_CAUSE_UPDATE_SUCCESS:
      {
        const { data } = action.payload
        return {
          ...state,
          isGetFetching: false,
          origin: data
        }
      }
    case types.AMPLIFY_CAUSE_SET_FILTER:
      {
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload
          },
          list: {
            ...state.list,
            page: 0,
            count: 0,
          },
        }
      }
    case types.AMPLIFY_CAUSE_RESET_FILTERS:
      {
        return {
          ...state,
          filter: {},
          list: {
            ...state.list,
            page: 0
          }
        }
      }
    case types.AMPLIFY_CAUSE_SET_PAGE:
      {
        return {
          ...state,
          list: {
            ...state.list,
            page: action.payload
          }
        }
      }

      case types.AMPLIFY_CAUSE_CLEAR_DELETED_PRODUCTS:
        {
          return {
            ...state,
            deletedProducts: []
          }
        }

    default:
      {
        return state
      }
  }
}
