import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/publicationHistory'
import * as actions from '../actions/publicationHistory'

import backend from 'store/api/feathers'

function* get(action) {
  const { query, isAdding } = action.payload
  try {
    const publicationHistoryList = yield backend.service('publication-history').find({
      query
    })
    if(_.size(publicationHistoryList)) {
      const page = _.get(query, 'page', 1)
      if (isAdding) {
        yield put(actions.getNextPageWithAddingSuccess({ ...publicationHistoryList, page }))
      } else {
        yield put(actions.getSuccess({ ...publicationHistoryList, page }))
      }
    }
  } catch (e) {
    yield put(actions.getFailure(e))
  }
}

function* publicaionHistorySaga() {
  yield takeEvery(types.GET, get)
}

export default {
  publicaionHistorySaga,
}
