import ModalSelectType from './ModalSelectType'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getTranslate } from 'react-localize-redux'

import * as causeActions from 'store/actions/cause'
import * as routerActions from 'store/actions/router'

import { getField } from 'store/selectors/modelSelectors'

const mapStateToProps = (state, props) => {
  const validRules = getField(state, Object.keys(state.cause.validationCreate))
  return {
    translate: getTranslate(state.locale),
    validation: state.cause.validationCreate,
    validRules,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    causeActions: bindActionCreators(causeActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalSelectType))

