import types from '../constants/buyerCausePermissions'

export const getBuyerCausePermissions = query => ({
  type: types.BUYER_CAUSE_PERMISSIONS_FIND,
  payload: query
})

export const getBuyerCausePermissionsSuccess = data => ({
  type: types.BUYER_CAUSE_PERMISSIONS_FIND_SUCCESS,
  payload: data
})

export const getBuyerCausePermissionsError = error => ({
  type: types.BUYER_CAUSE_PERMISSIONS_FIND_ERROR,
  payload: error
})

export const createBuyerCausePermissions = data => ({
  type: types.BUYER_CAUSE_PERMISSIONS_CREATE,
  payload: data
})

export const createBuyerCausePermissionsSuccess = data => ({
  type: types.BUYER_CAUSE_PERMISSIONS_CREATE_SUCCESS,
  payload: data
})

export const createBuyerCausePermissionsError = error => ({
  type: types.BUYER_CAUSE_PERMISSIONS_CREATE_ERROR,
  payload: error
})

export const updateBuyerCausePermissions = data => ({
  type: types.BUYER_CAUSE_PERMISSIONS_UPDATE,
  payload: data
})

export const updateBuyerCausePermissionsSuccess = data => ({
  type: types.BUYER_CAUSE_PERMISSIONS_UPDATE_SUCCESS,
  payload: data
})

export const updateBuyerCausePermissionsError = error => ({
  type: types.BUYER_CAUSE_PERMISSIONS_UPDATE_ERROR,
  payload: error
})

export const setCauseIdManageUserModal = id => ({
  type: types.BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID,
  payload: id
})

export const clearCauseIdManageUserModal = () => ({
  type: types.BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID
})