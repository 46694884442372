import React from 'react'
import ReactDOM from 'react-dom'
//import registerServiceWorker from './utils/registerServiceWorker'
import {
  unregister
} from './utils/registerServiceWorker';

import { Provider } from 'react-redux'
import { store, history } from 'store/store'
import { ConnectedRouter } from 'connected-react-router'

import App from './components/app/App'
import Spinner from './components/ui/Spinner'
import './locales' // multilanguage

unregister();

const spinnerWrapper = document.createElement('div')
document.body.appendChild(spinnerWrapper)
ReactDOM.render(
  <Spinner store={store} />,
  spinnerWrapper
)

ReactDOM.render(
  (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  ),
  document.getElementById('root')
)

//* https://dao-tech.atlassian.net/jira/software/projects/IMPR/issues/IMPR-20?filter=myopenissues
//* https://github.com/facebook/create-react-app/issues/1910#issuecomment-355245212

// registerServiceWorker()
