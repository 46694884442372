import { createSelector } from 'reselect'
import _ from 'lodash'
import moment from 'moment'

export const getChat = (state) => state && state.chat.unreadedMessages
export const getNotification = (state) => state && state.notification.origin
export const getAuth = (state) => state && state.auth

export const generateNotificationList = createSelector(
  [getChat, getNotification, getAuth],
  (chat, notification, auth) => {
    const currentUser = auth && auth.user ? auth.user : {}
    const filteredNotification = _.size(notification) ? _.values(notification).reduce((obj, itemNotification) => {
      //NOTE: notificationUser associate
      const nuInfo = itemNotification.notificationUser
      //NOTE: get current user info
      let notificationUserItem
      _.forEach(nuInfo, user => {
        if(user.userId === currentUser.id) {
          notificationUserItem = {...user}
        }
      })
      if(notificationUserItem) {
        obj.push({
          ...itemNotification,
          type: 'notification'
        })
      }
      return obj
    }, []) : []
    
    const filteredChat = _.map(chat, chatItem => {
      return {
        ...chatItem.lastMessage,
        type: 'message'
      }
    })

    const notificationsList = _.concat(filteredNotification, filteredChat)
    const orderNotificationsList = notificationsList.sort((listItemA, listItemB) => {
      const dateA = listItemA.type === 'message' ? listItemA.timestamp : moment(listItemA.createdAt).format('x')
      const dateB = listItemB.type === 'message' ? listItemB.timestamp : moment(listItemB.createdAt).format('x')
      if (dateA > dateB) {
        return -1;
      }
        
      return 1;
    })

    return orderNotificationsList
  }
)