export default {
  GET: 'SERVER:VAULT_GET',
  GET_SUCCESS: 'VAULT_GET_SUCCESS',
  GET_FAILURE: 'VAULT_GET_FAILURE',

  ADD_SELECTED_FILE: 'ADD_SELECTED_FILE',
  REMOVE_SELECTED_FILE: 'REMOVE_SELECTED_FILE',
  CLEAR_LIST: 'CLEAR_LIST',

  VAULT_REMOVE: 'SERVER:VAULT_REMOVE',
  VAULT_REMOVE_SUCCESS: 'VAULT_REMOVE_SUCCESS',
  VAULT_REMOVE_FAILURE: 'VAULT_REMOVE_FAILURE',

  UPDATE_FILE_TAGS: 'UPDATE_FILE_TAGS',
  UPDATE_FILE_TAGS_SUCCESS: 'UPDATE_FILE_TAGS_SUCCESS',
  UPDATE_FILE_TAGS_FAILURE: 'UPDATE_FILE_TAGS_FAILURE',

  REMOVE_BRIEF_ATTACHMENT: 'REMOVE_BRIEF_ATTACHMENT',
  CLEAR_BRIEF_ATTACHMENT: 'CLEAR_BRIEF_ATTACHMENT',

  ATTACH:                       'VAULT_UPLOAD_ATTACH',
  REMOVE_FILE:                  'VAULT_UPLOAD_REMOVE_FILE',
  RESET_ATTACH_CHANGES:         'VAULT_UPLOAD_RESET_ATTACH_CHANGES',

  UPLOAD:                       'SERVER:VAULT_UPLOAD',
  UPLOAD_SUCCESS:               'VAULT_UPLOAD_SUCCESS',
  UPLOAD_FAILURE:               'VAULT_UPLOAD_FAILURE',
  
  UPLOAD_ERROR:                 'UPLOAD_ERROR',
  UPLOAD_ERROR_SUCCESS:         'UPLOAD_ERROR_SUCCESS',
  UPLOAD_ERROR_FAILURE:         'UPLOAD_ERROR_FAILURE',

  CLEAR_VAULT_UPLOAD:           'CLEAR_VAULT_UPLOAD',
  
  // EDIT_FILE:                    'SERVER:EDIT_FILE',
  // EDIT_FILE_SUCCESS:            'EDIT_FILE_SUCCESS',
  // EDIT_FILE_FAILURE:            'EDIT_FILE_FAILURE', 

  FILTER_VAULT_FOLDER:          'FILTER_VAULT_FOLDER',
  FILTER_VAULT_FOLDER_SUCCESS:          'FILTER_VAULT_FOLDER_SUCCESS',
  FILTER_VAULT_FOLDER_FAILURE:          'FILTER_VAULT_FOLDER_FAILURE',

  SET_SELECTED_FOLDER:          'SET_SELECTED_FOLDER',

  GET_ATTACHMENT_BRIEF_LIST:    'GET_ATTACHMENT_BRIEF_LIST',
  GET_ATTACHMENT_BRIEF_LIST_SUCCESS: 'GET_ATTACHMENT_BRIEF_LIST_SUCCESS',
  GET_ATTACHMENT_BRIEF_LIST_FAILURE: 'GET_ATTACHMENT_BRIEF_LIST_FAILURE',


  VAULT_UPLOAD_PROGRES: 'VAULT_UPLOAD_PROGRES',

  VAULT_DELETE_FILE: 'VAULT_DELETE_FILE',
  VAULT_DELETE_FILE_SUCCESS: 'VAULT_DELETE_FILE_SUCCESS',
  VAULT_DELETE_FILE_FAILURE: 'VAULT_DELETE_FILE_FAILURE',

  CURRENT_UPLOADING_API_CALLS: 'CURRENT_UPLOADING_API_CALLS',
  CLEAR_UPLOADING_API_CALLS: 'CLEAR_UPLOADING_API_CALLS',

  CLEAR_VAULT_LIST: 'CLEAR_VAULT_LIST',
  ADD_VAULT_MODAL_SELECTED_FILES: 'ADD_VAULT_MODAL_SELECTED_FILES'
}