import types from '../constants/auth'
import userTypes from '../constants/user'
import accountTypes from '../constants/account'

const initialState = {
  accessToken: null,
  user:        null,
  workspace:     null,
  error:       null,
  edit: {},
  isSignInFetching: false
}

export default (state = initialState, action) => {

  switch (action.type) {

  case types.INIT_USER_EDIT:

  {
    return {
      ...state,
      edit: {
        ...state.user
      },
    }

  }

  case types.USER_EDIT:

  {
    return {
      ...state,
      edit: {
        ...state.edit,
        ...action.payload
      }
    }

  }  

  case types.RESET_USER_EDIT:

  {
    return {
      ...state,
      edit: {
        ...state.user,
      }
    }

  } 

  case types.USER_INFO_UPDATE_SUCCESS:

  {

    return {
      ...state,
      user: action.payload,
      edit: {
        ...action.payload
      }
    }

  }


  case types.USER_GET_SUCCESS:

  {
    const user = {...action.payload}
    delete user.workspace
    const workspace = action.payload.workspace

    return {
      ...state,
      user,
      workspace
    }

  }

  case accountTypes.UPDATE_WORKSPACE_SUCCESS:

  {
    const workspace = action.payload.workspace
    
    return {
      ...state,
      workspace
    }

  }

  case types.USER_UPLOAD_PHOTO_SUCCESS:

  {
    
    return {
      ...state,
      user: action.payload,
      edit: action.payload
    }

  }  

  case types.SIGNIN:

  {
    return {
      ...state,
      isSignInFetching: true
    }

  }  

  case types.SIGNIN_SUCCESS:

  {
    return {
      ...state,
      accessToken: action.payload,
      isSignInFetching: false
    }

  }

  case types.SIGNIN_FAILURE: {
    return {
      ...state,
      error: action.payload.error,
      isSignInFetching: false
    }
  }

  case types.SIGNOUT_SUCCESS:

  {
    return {
      ...initialState,
    }

  }


  case types.SIGNOUT_FAILURE:

  {

    return {
      ...state,
      error: action.payload.error,
    }

  }
  

  case types.SIGNUP_FAILURE:
  case types.RESET_PASSWORD_FAILURE:
  case types.VERIFY_USER_BY_EMAIL_FAILURE: 
  case types.USER_INFO_UPDATE_FAILURE: 
  
  {
    return {
      ...state,
      error: action.payload.error,
    }
  }

  case types.UPDATE_PASSWORD_SUCCESS:
  {

    return {
      ...state,
      //accessToken: action.payload.accessToken,
    }

  }

  case userTypes.UPDATE_USER_BY_SOCKET:
  {
    const nextAuth = {
      user: {
        ...state.user,
        ...action.payload
      },
      edit: {
        ...state.edit,
        ...action.payload
      } 
    }

    return {
      ...state,
      ...nextAuth,     
    }

  }  

  default:

    return state

  }

}
