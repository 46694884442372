import types from '../constants/template'
import _ from 'lodash'

const validationParams = {
  templateName: {
    value: '',
    isValid: undefined,
  },         
}

const initialState = {
  origin: {},
  filter: {},
  error: null,
  tasksCreate: [],
  tasksEdit: [],
  validationEdit: {   
    ...validationParams                                               
  },
  validationCreate: {   
    ...validationParams                                               
  },  
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case types.GET_SUCCESS:

  {
    return {
      ...state,
      origin: _.keyBy(action.payload.data, 'id'),
    }

  }

  case types.CREATE_SUCCESS:

  {
    return {
      ...state,
      origin: {
        ...state.origin,
        [action.payload.id]: {
          ...action.payload
        },
      },
      tasksCreate: initialState.tasksCreate,
      validationCreate: {
        ...initialState.validationCreate
      }
    }

  }

  case types.REMOVE_SUCCESS:
  {
    const newOrigin = {...state.origin}
    delete newOrigin[action.payload.id]

    return {
      ...state,
      origin: {...newOrigin}
    }
  }  

  case types.ADD_TASK:

  {
    const nameField = action.payload.isCreate ? 'tasksCreate' : 'tasksEdit'
    const nextTasks = state[nameField].slice()

    nextTasks.push({...action.payload.data})

    return {
      ...state,
      [nameField]: nextTasks
    }

  }
  
  case types.UPDATE_TASK:

  {
    const nameField = action.payload.isCreate ? 'tasksCreate' : 'tasksEdit'
    const nextTasks = state[nameField].slice()

    nextTasks[action.payload.index] = {
      ...action.payload.data
    }

    return {
      ...state,
      [nameField]: nextTasks
    }

  }  
  
  case types.REMOVE_TASK:

  {
    const nameField = action.payload.isCreate ? 'tasksCreate' : 'tasksEdit'
    const nextTasks = state[nameField].slice()

    nextTasks.splice(action.payload.id, 1)

    return {
      ...state,
      [nameField]: nextTasks
    }

  }
  
  case types.VALIDATION:

  {
    const validType = action.payload.isCreate ? 'validationCreate' : 'validationEdit'
    return {
      ...state,
      [validType]: { 
        ...state[validType], 
        ...action.payload.data 
      }
    }

  }   

  case types.GET_ID_SUCCESS:  
  {
  
    const payload = action.payload.data

    return {
      ...state,
      origin: {
        ...state.origin, 
        [payload.id]: payload
      },
      tasksEdit: payload.templateTask,
      validationEdit: {   
        ...state.validationEdit,
        templateName: {
          value: payload.templateName,
          isValid: true
        },
        id: {
          value: payload.id
        }                                               
      },
    }
  }
  
  case types.UPDATE_SUCCESS:
  {
    return {
      ...state,
      origin: {
        ...state.origin,
        [action.payload.id]: {
          ...action.payload
        }
      }
    }
  }  

  case types.CREATE_FAILURE:

  {

    return {
      ...state,
      error: action.payload,
    }

  }

  default:
    return state
  }
}