import types from '../constants/workroomGuest'

export const get = (queryParams, page) => {
  return {
    type: types.GET,
    payload: {
      queryParams,
      page
    }
  }
}

export const getSuccess = ( data) => {
  
  return {
    type: types.GET_SUCCESS,
    payload: data
  }

}

export const getFailure = (error) => {

  return {
    type: types.GET_FAILURE,
    payload: error
  }

}

export const getId = (id) => {
  return {
    type: types.GET_ID,
    payload: id
  }

}

export const getIdSuccess = (data) => {

  return {
    type: types.GET_ID_SUCCESS,
    payload: data
  }

}

export const getIdFailure = (error) => {

  return {
    type: types.GET_ID_FAILURE,
    payload: error
  }

}

export const textAreaChange = (data) => {
  
  return {
    type: types.TEXT_AREA_CHANGE,
    payload: data
  }

}

export const modalChange = (data) => {

  return {
    type: types.MODAL_CHANGE,
    payload: data
  }

}

export const submitComment = (data) => {

  return {
    type: types.SUBMIT_COMMENT,
    payload: data
  }

}

export const submitCommentSuccess = (data) => {
  return {
    type: types.SUBMIT_COMMENT_SUCCESS,
    payload: data
  }

}

export const submitCommentFailure = (error) => {

  return {
    type: types.SUBMIT_COMMENT_FAILURE,
    payload: error
  }

}
