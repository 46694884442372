import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import notFoundImg from 'assets/images/ne_res.svg'
import React from 'react'
import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'
import { now } from 'moment'


export const generatingObjectFromArray = (arr, validation) => {
  return _.reduce(arr, (acc, item) => {
    return {...acc,
      [`${item}`]: validation[item].value  }
  }, {})
}

export const formattingQuery = query => {
  const keys = Object.keys(query)
  const newQuery = keys.reduce((obj, item) => {
    if(query[item]) {
      obj[item] = typeof query[item] === 'string'
        ? query[item].trim()
        : query[item]
    } else {
      obj[item] = undefined
    }
    return obj
  }, {})
  return newQuery
}

export const formattingValidObj = (validation = {}, userPermissions) => {
  const toArr = Object.entries(validation)
  const formattedObj = toArr.reduce((obj, item) => {
    const key = item[0]
    const value = item[1].value
    const permissions = item[1].permissions
    if(permissions && (_.findIndex(permissions, item => item === userPermissions) === -1)) {
      return obj
    }
    if(value !== undefined) {
      obj[key] = value
    }
    if(Array.isArray(value) && !_.size(value)) {
      obj[key] = null
    }
    return obj
  }, {})
  return formattedObj
}

export const checkValidObj = (obj = {}) => {
  const arr = Object.entries(obj)
  let result
  arr.some(item => {
    const value = item[1]
    result = value.isValid === false
    return result
  })
  return !result
}

export const calculateSum = (validation, user, workspace) => {
  const checks = {
    causeContentBoosterBundle: validation.causeContentBoosterBundle.value ? 319 : 0,
    causeEcpertHelp: validation.causeEcpertHelp.value ? 99 : 0,
    causeGetSooner: validation.causeGetSooner.value ? 199 : 0,
    causeNonDisclosure: validation.causeNonDisclosure.value ? 99 : 0,
  }

  let sum = checks.causeContentBoosterBundle + checks.causeEcpertHelp + checks.causeGetSooner + checks.causeNonDisclosure
  sum = parseFloat(sum)
  if(_.size(validation.products.value)) {
    _.forEach(validation.products.value, productItem => {
      const price = getProductPrice(productItem, user, validation.causeType.value)
      const count = productItem ? productItem.count : 0
      if(parseFloat(price * count)) {
        sum += parseFloat(price) * count
      }
    })
  }
  return parseFloat(sum).toFixed(2) || 0
}

export const calculatePercent = (item) => {
  if(!_.has(item, 'workroomCause.milestone.productTemplate')) {
    return 0
  }
  const productCount = _.size(item.products) || 1
  const persentProduct = 100 / productCount
  return _.reduce(item.workroomCause.workroomItems, (percent, item) =>{
    const productTemplate = item.productTemplate || {}
    const stepsCount = _.size(productTemplate.templateTask)
    const workItem = _.size(item.workItems)
    const itemWeight = persentProduct / (stepsCount*workItem)
    _.forEach(item.workItems, workItem => {
      percent += itemWeight * workItem.milestoneStatus
    })
    return Math.ceil(percent)
  }, 0)
}

export const calculateCommission = (sum, user = {}, workspace = {}) => {
  const { commission: userCommission, permissions } = user
  const { commission: workspaceCommission } = workspace
  const commission = workspaceCommission != null ? workspaceCommission : userCommission

  switch (permissions) {
  case 2: return (sum * (1 + commission/100))
  case 3: return (sum * (1 - commission/100))
  default: return sum
  }
}

export const getProductPrice = (productItem, currentUser = {}, causeType, isBuyerUnlimited = false) => {
  const { permissions } = currentUser
  // const fabulateCommission = _.get(productItem, 'productCard[0].fabulateCommission', 0) / 100
  const defaultPrice = _.get(productItem, 'defaultPrice', 0)
  const isInternal = _.includes('internal', causeType)
  const unlimitedPrice = _.defaultTo(_.get(productItem, 'productCard[0].unlimitedPrice'), 0)
  if (_.size(productItem && productItem.productCard)) {
    switch (permissions) {
    case 2: {
      const buyerPrice = _.get(productItem, 'productCard[0].buyerPrice')
      // const price = (buyerPrice || defaultPrice) * (1 + fabulateCommission)

      // for check if invite only brief is unlimited
      const price = (isInternal || isBuyerUnlimited) ? unlimitedPrice : (buyerPrice || defaultPrice)

      return parseFloat(price.toFixed(2))
    }
    case 3: {
      const contractorPrice = _.get(productItem, 'productCard[0].contractorPrice')
      // const price = (contractorPrice || defaultPrice) * (1 - fabulateCommission)
      const price = isInternal ? unlimitedPrice : (contractorPrice || defaultPrice)

      return parseFloat(price.toFixed(2))
    }
    default: {
      return parseFloat(defaultPrice.toFixed(2))
    }
    }
  }
  return parseFloat(defaultPrice.toFixed(2))
}

export const getBriefSum = (causeProducts, currentUser, causeType, isBuyerUnlimited) => {
  return _.reduce(causeProducts, (sum, currentProduct) => {
    const count = currentProduct ? currentProduct.count : 0
    return sum + getProductPrice(currentProduct, currentUser, causeType, isBuyerUnlimited) * count
  }, 0)
}


export const clearLocalStorage = () => {
  const localStorageKeys = _.keys(window.localStorage)
  const jwtName = 'feathers-jwt'
  // const helpHero = '__HLP_DEBUG__'
  const talkJs = 'talkjs:desktop_notify'

  _.forEach(localStorageKeys, keyName => {
    // && helpHero !== keyName
    if(jwtName !== keyName && talkJs !== keyName ) {
      window.localStorage.removeItem(keyName)
    }
  })
}

export const notFound = () => (
  <div className="text-center p-20">
    <img src={notFoundImg} alt="Not found"/>
  </div>
)

export const getTimeRange = (filterCount) => (typeof filterCount === 'number' ? 
  `this_${filterCount}_days` :
  {end: `${filterCount.dateTo}T23:59:00.00`, start: `${filterCount.dateFrom}T00:00:00.000`})

// NOTE: генерация pdf на основе полученной модели
export const downloadPdf = async (docDefinition, docName) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs

  // NOTE: download the PDF
  pdfMake.createPdf(docDefinition).download(docName)
}

export const calcPitchModel = item => _.size(item.causeUserCause) && {
  all: _.size(item.causeUserCause.filter(itemPitch =>
    itemPitch.causeUserStatus === 1 || itemPitch.causeUserStatus > 2)),
  decline: _.size(item.causeUserCause.filter(itemPitch =>
    itemPitch.causeUserStatus === 5)),
  active: _.size(item.causeUserCause.filter(itemPitch =>
    itemPitch.causeUserStatus !== 5 && !itemPitch.isHide)),
}

export const renderNotification = (type, text) => {
  new PNotify({
    type,
    text
  })
}

export const renderErrorNotification = (e, defaultErrorText = null) => {
  const message = _.get(e, 'response.data.err', e.message || defaultErrorText) 

  new PNotify({
    addclass: 'bg-danger',
    title: 'Error',
    text: message,
    hide: true,
    buttons: {
      closer: true,
      sticker: true,
    },       
  })
}

export const getDataFromValidation = (object, fields = []) =>
  _.pick(object, ['isValid', 'message', 'value', ...fields])

export const dueDateСomparison = datetime => {
  const dueDateTime = new Date(datetime)

  return dueDateTime.getTime() > now()
}

export const getAmplifyBriefPrice = (
  amplifyWorkspaceProductInfo,
  targetRole,
  // buyerPriceKey,
  // commission
) =>
// (acc, item) => acc + item[targetRole]+ (( buyerPriceKey && commission && item[buyerPriceKey]) && (item[buyerPriceKey] * (commission/100)) || 0),

  _.reduce(
    amplifyWorkspaceProductInfo,
      (acc, item) => acc + item[targetRole],
    0
  )

export const setStartPageBriefItem = (locationSearch, isAmplify = false) => {
  const params = new URLSearchParams(locationSearch)
    const currentTab = params.get('tab')
  const _location = `?tab=${currentTab}`
  if(isAmplify) {
    switch (_location) {
      case '?tab=workroom':
        return 1
      default:
        return 0
    }
  } else {
    switch (_location) {
      case '?tab=creators':
        return 1
      case '?tab=my-pitch':
        return 3
      case '?tab=pitches':
        return 4
      case '?tab=workroom':
        return 5
      case '?tab=feedback':
        return 6
      default:
        return 0
    }
  }
}  