import types from '../constants/model'

const initialState = {}

export default (state = initialState, action) => {

  switch (action.type) {

  case types.MODEL_SUCCESS:
    return {
      ...state,
      ...action.payload.data,
    }

  case types.MODEL_FAILURE:
    return {
      ...state,
    }

  default:

    return state

  }

}
