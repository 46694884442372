import types from '../constants/user'
import authTypes from '../constants/auth'

import _ from 'lodash'

const fetching = {
  isGetFetching: false,  
  isGetIdFetching: false,
}

const initialFilter = {
  page: 1,
  permissions: 3,    
  competencies: [],
  experienceContractor: [],
  skills: [],
  userName: undefined,
  invited: undefined,
  rate: null,
}

const initialState = {
  origin: {},
  buyer: {},
  contractor: {},
  user: {},
  error: null,
  filterContractor: initialFilter,
  contractorSelected: {},

  ...fetching
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET:
  {
    return {
      ...state,
      isGetFetching: true
    }

  }

  case types.GET_SUCCESS:
  {
    const { data, isBuyer, count, query } = action.payload
    
    const { page, ...queryData } = query
    const subStore = isBuyer ? 'buyer' : 'contractor'
    const users = _.keyBy(data, 'id')
    const nextContractorSelected = {}
    const isQuery = _.some(queryData, item => {
      if (_.isArray(item)) {
        return !_.isEmpty(item)
      }
      return !_.isNil(item)
    })
    const userData = isQuery && page === 1
      ? {
        ...users
      }
      : {
        ...users
      }
    return {
      ...state,
      isGetFetching: false,
      [subStore]: {
        users: userData,
        count
      },
      ...nextContractorSelected
    }

  }

  case types.GET_FAILURE:
  {

    return {
      ...state,
      error: action.payload,
      isGetFetching: false
    }

  }

  case types.GET_ID:
  {
    return {
      ...state,
      isGetIdFetching: true
    }

  }

  case types.GET_ID_SUCCESS:
  {

    const payload = action.payload.data
    return {
      ...state,
      isGetIdFetching: false,
      user: {
        ...state.user, 
        ...payload
      },
    }

  }

  case types.GET_ID_FAILURE:
  {

    return {
      ...state,
      error: action.payload,
      isGetIdFetching: false
    }

  }

  case types.UPDATE:

  {
    const subStore = action.payload.isBuyer ? 'buyer' : 'contractor'
    const payload = action.payload.data
    const isSocket = action.payload.isSocket
    let nextContractor = {}

    if(!isSocket && !action.payload.isBuyer) {
      nextContractor = {
        contractorSelected: {
          ...payload
        }
      }
    }
    
    if(!action.payload.isBuyer 
      && payload.id === state.contractorSelected.id 
      && isSocket
    ) {
      nextContractor = {
        contractorSelected: {
          ...state.contractorSelected,
          ...payload
        }
      }
    }

    return {
      ...state,
      [subStore]: {
        ...state[subStore], 
        [payload.id]: payload
      },
      ...nextContractor
    }

  }  

  case types.SET_FILTER:

  {
    return {
      ...state,
      filterContractor: { 
        ...state.filterContractor,
        ...action.payload 
      }
    }

  }

  case types.RESET_FILTER:
  {
    return {
      ...state,
      filterContractor: initialFilter
    }
  }

  case types.SELECT_CONTRACTOR:

  {

    return {
      ...state,
      contractorSelected: { 
        ...action.payload 
      }
    }

  } 
  
  case types.UPDATE_USER_BY_SOCKET: 
  {
    const payload = action.payload
    let nextUsers = {
      origin: {
        ...state.origin
      },
      buyer: {
        ...state.buyer
      },
      contractor: {
        ...state.contractor
      },            
    }

    if(state.origin[payload.id]) {
      nextUsers = {
        ...nextUsers,
        origin: {
          ...state.origin,
          [payload.id]: {
            ...state.origin[payload.id],
            ...payload
          }
        },
      }
    }

    if(state.buyer[payload.id]) {
      nextUsers = {
        ...nextUsers,
        buyer: {
          ...state.buyer,
          [payload.id]: {
            ...state.buyer[payload.id],
            ...payload
          }
        },
      }
    }  
    
    if(state.contractor[payload.id]) {
      nextUsers = {
        ...nextUsers,
        contractor: {
          ...state.contractor,
          [payload.id]: {
            ...state.contractor[payload.id],
            ...payload
          }
        },
      }
    }
    
    if(state.contractorSelected.id === payload.id) {
      nextUsers = {
        ...nextUsers,
        contractorSelected: {
          ...state.contractorSelected,
          ...payload
        }
      }
    }
    
    return {
      ...state,
      ...nextUsers          
    }
  }

  case authTypes.SIGNOUT_SUCCESS:
  {
    return {
      ...initialState,
    }
  }  

  case types.ACCEPT_TERMS:
  case types.ACCEPT_TERMS_FAILURE:
  case types.ACCEPT_TERMS_SUCCESS:
  default:

    return state

  }

}
