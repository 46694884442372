import types from '../constants/withoutEditor'

export const get = (queryParams, page) => ({
    type: types.GET,
    payload: {
        queryParams,
        page
    }
})

export const getSuccess = ({ data, count }) => ({
    type: types.GET_SUCCESS,
    payload: {
        data,
        count
    }
})

export const getFailure = (error) => ({
    type: types.GET_FAILURE,
    payload: error
})

export const setFilter = (params) => ({
    type: types.SET_FILTER,
    payload: params
})

export const resetFilter = () => ({
    type: types.RESET_FILTER,
    payload: null
  })

export const setPage = (pageNumber) => ({
    type: types.SET_PAGE,
    payload: pageNumber
})