import types from '../constants/notification'

export const get = queryParams => ({
  type: types.NOTIFICATION_GET,
  payload: {
    queryParams,
  }
})

export const getSuccess = ({ data, count }) => ({
  type: types.NOTIFICATION_GET_SUCCESS,
  payload: {
    data,
    count,
  }
})

export const getFailure = (error) => ({
  type: types.NOTIFICATION_GET_FAILURE,
  payload: error
})

export const update = (data) => ({
  type: types.NOTIFICATION_UPDATE,
  payload: {
    data
  }
})

export const updateSuccess = (data) => ({
  type: types.NOTIFICATION_UPDATE_SUCCESS,
  payload: data
})

export const updateFailure = (error) => ({
  type: types.NOTIFICATION_UPDATE_FAILURE,
  payload: error
})

export const remove = (notificationUserId, notificationId) => ({
  type: types.NOTIFICATION_REMOVE,
  payload: {
    notificationUserId, notificationId
  }
})

export const removeSuccess = (data) => ({
  type: types.NOTIFICATION_REMOVE_SUCCESS,
  payload: data
})

export const removeFailure = (error, notificationId) => ({
  type: types.NOTIFICATION_REMOVE_FAILURE,
  payload: {
    error, notificationId
  }
})

export const setNotificationPage = (data) => {
  return ({
    type: types.NOTIFICATION_SET_PAGE,
    payload: data
  })
}