import types from '../constants/workroom'
import { validation } from 'helpers/validation'

export const getId = (query) => ({
  type: types.WORKROOM_GET_ID,
  payload: query
})

export const getIdSuccess = ({data}) => ({
  type: types.WORKROOM_GET_ID_SUCCESS,
  payload: data
})

export const getIdFailure = (error) => ({
  type: types.WORKROOM_GET_ID_FAILURE,
  payload: error
})

export const update = (data) => ({
  type: types.WORKROOM_UPDATE,
  payload: data
})

export const updateSuccess = (data) => ({
  type: types.WORKROOM_UPDATE_SUCCESS,
  payload: data
})

export const updateFailure = (error) => ({
  type: types.WORKROOM_UPDATE_FAILURE,
  payload: error
})

export const edit = (data) => ({
  type: types.WORKROOM_EDIT,
  payload: data,
})

export const setValid = (model, rules, type) => {
  const newModel = validation(model, rules)
  return {
    type: types.VALIDATION,
    payload: {
      data: {
        [type]: { ...newModel }
      },
    },
  }
}


export const uploadFile = (params, productInfo, articleId, causeId, causeInfo, attachmentInfo) => {
  console.log('upload files===action====', params, productInfo, articleId, causeId, causeInfo, attachmentInfo )
  return {
    type: types.UPLOAD,
    payload: {
      params,
      productInfo,
      articleId,
      causeId,
      causeInfo,
      attachmentInfo
    }
  }
}

export const uploadFileSuccess = (data, productInfo, articleId) => {
  return {
    type: types.UPLOAD_SUCCESS,
    payload: { 
      data,
      productInfo,
      articleId
    }
  }
}


export const uploadFileFailure = (data, productInfo, articleId) => {
  return {
    type: types.UPLOAD_FAILURE,
    payload: {
      data,
      productInfo,
      articleId
    }
  }
}

export const uploadDesign = (file, designId) => {
  return {
    type: types.UPLOAD_DESIGN,
    payload: {
      file,
      id: designId
    }
  }
}

export const uploadDesignSuccess = (url) => {
  return {
    type: types.UPLOAD_DESIGN_SUCCESS,
    payload: url
  }
}

export const uploadDesignFailure = () => {
  return {
    type: types.UPLOAD_DESIGN_FAILURE,
  }
}


export const downloadFile = (id, ref) => ({
  type: types.DOWNLOAD_FILE,
  payload: {
    id,
    ref
  }
})

export const downloadFileSuccess = () => ({
  type: types.DOWNLOAD_FILE_SUCCESS
})

export const downloadFileFailure = (error) => ({
  type: types.DOWNLOAD_FILE_FAILURE,
  payload: error
})

export const invitePerson = (data) => ({
  type: types.INVITE_PERSON,
  payload: data
})

export const invitePersonSuccess = () => ({
  type: types.INVITE_PERSON_SUCCESS
})

export const invitePersonFailure = () => ({
  type: types.INVITE_PERSON_FAILURE
})

export const skipSteps = data => ({
  type: types.SKIP_STEPS,
  payload: data
})

export const skipStepsSuccess = data => ({
  type: types.SKIP_STEPS_SUCCESS,
  payload: data
})

export const skipStepsFailure = error => ({
  type: types.SKIP_STEPS_FAILURE,
  payload: error
})

export const updateWorkoom = data => ({
  type: types.UPDATE_WORKROOM_ENTITY,
  payload: data,
})

export const updateSuccessWorkroom = data => ({
  type: types.UPDATE_WORKROOM_ENTITY_SUCCESS,
  payload: data,
})

export const setSelectedArticle = data => ({
  type: types.WORKROOM_SELECTED_ARTICLE,
  payload: data,
})

export const updateUploadCount = (countData) => {
  return ({
    type: types.WORKROOM_UPLOAD_PROGRES,
    payload: countData
  })
}


export const workroomDeleteFile = (data) => ({
  type: types.WORKROOM_DELETE_FILE,
  payload: data
})

export const workroomDeleteFileSuccess = (data) => ({
  type: types.WORKROOM_DELETE_FILE_SUCCESS,
  payload: data
})

export const workroomDeleteFileFailure = (error) => ({
  type: types.WORKROOM_DELETE_FILE_FAILURE,
  payload: error
})


export const workroomCurrentUploadingApiCalls = (data) => ({
  type: types.CURRENT_UPLOADING_API_CALLS,
  payload: data
})

export const workroomClearUploadingApiCall = (data) => ({
  type: types.CLEAR_UPLOADING_API_CALLS,
  payload: data
})

export const getGettyImageDownloadCount = (data) => ({
  type: types.GET_GETTY_IMAGE_DOWNLOAD_COUNT,
  payload: data
})

export const getGettyImageDownloadCountSuccess = (data) => ({
  type: types.GET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS,
  payload: data
})

export const getGettyImageDownloadCountFailure = (error) => ({
  type: types.GET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE,
  payload: error
})

export const setGettyImageDownloadCount = (data) => ({
  type: types.SET_GETTY_IMAGE_DOWNLOAD_COUNT,
  payload: data
})

export const setGettyImageDownloadCountSuccess = (data) => ({
  type: types.SET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS,
  payload: data
})

export const setGettyImageDownloadCountFailure = (error) => ({
  type: types.SET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE,
  payload: error
})

// export const addVaultFilesWorkroom = (data) => ({
//   type: types.ADD_VAULT_FILES_UPLOAD_MODAL,
//   payload: data
// })