import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import _ from 'lodash'
  import { post as postAxios, get as getAxios } from 'store/api'

import types from '../constants/cause'
import * as actions from '../actions/cause'
import * as amplifyCauseActions from '../actions/amplifyCause'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend from 'store/api/feathers'


import { createDatasetQuery } from 'helpers/analytics.js'
import { getDataFromServer } from 'store/sagas/analytics.js'
import { appAnalytics, EVENTS } from 'helpers/appAnalytics'

export const apiUrl = `${process.env['REACT_APP_API_HOST']}`

function* get(action) {

  try {
    const queryParams = action.payload.queryParams
    const page = action.payload.page
    const causeList = yield backend.service('cause').find({
      query: {
        ...queryParams,
        page
      },
    })
    yield put(actions.getSuccess(causeList))
  } catch (e) {
    renderErrorNotification(e, 'Get causes list error')
    yield put(actions.getFailure(e))
  }

}

function* getDashboard(action) {

  try {
    const causeList = yield backend.service('cause').find({
      query: action.payload.queryParams
    })
    yield put(actions.getDashboardSuccess(causeList, action.payload.subName, _.size(causeList)))
  } catch (e) {
    renderErrorNotification(e, 'Get causes list error')
    yield put(actions.getDashboardFailure(e))
  }

}

function* getTask(action) {

  try {
    const causeTask = yield backend.service('task').get(action.payload.id, {
      query: action.payload.queryParams
    })
    yield put(actions.getTaskSuccess(causeTask))
  } catch (e) {
    renderErrorNotification(e, 'Get task error')
    yield put(actions.getTaskFailure(e))
  }

}


function* create(action) {
  const getAmplifyCauses = (state) => state.amplifyCause.origin
  const amplifyCauses = yield select(getAmplifyCauses)
  try {
    const cause = { ...action.payload.cause }
    // const history = { ...action.payload.history }

    cause.amplifyCauses = amplifyCauses

    const newCause = yield backend.service('cause').create({ ...cause })
    if (_.size(newCause)) {
      yield put(actions.createSuccess(newCause))
      appAnalytics.track(EVENTS.BRIEF_POST_SUCCESS, {causeId: newCause.id})
      _.size(amplifyCauses) && appAnalytics.track(EVENTS.AMPLIFY_BRIEF_POST_SUCCESS)

      yield put(actions.resetSelect(true))
      yield put(amplifyCauseActions.clearOrigin())
      yield put(push('/dashboard'))
      // const notice = new PNotify({
      //   addclass: 'bg-teal',
      //   text: `Your Brief "${newCause.causeName}" has been ${newCause.causeStatus === 0 ? 'saved.' : 'saved and posted on marketplace.'}`,
      //   buttons: {
      //     closer: true,
      //     sticker: true,
      //   },
      // })
      // notice.get().on('click', e => {
      //   if (history && e.target.lastChild !== null) {
      //     history.push(`/brief-show/${newCause.id}`)
      //   }
      // })
    }
  } catch (e) {
    renderErrorNotification(e, 'Create brief error')
    yield put(actions.createFailure(e))
  }

}

function* uploadFile(action) {
  const { uri, fileName, isVideo, done, tokenInstance } = action.payload.params
  const fileExtensionName = fileName.split('.').pop()
  let formData = null
  if (isVideo) {
    formData = new FormData()
    formData.append('file', uri)
  } else {
    let _uri = uri;
    if (fileName.includes('.csv')) {
      _uri = 'data:text/csv;base64,'  + _uri.split('base64,').pop();
    }
    formData = new FormData()
    formData.append('uri', _uri)
    formData.append('fileName', fileName)
  }
  formData.append('id', fileName)
  try {
    let uploadData = null
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'File-Type': fileExtensionName,
      },
      cancelToken: tokenInstance && tokenInstance[fileName] && tokenInstance[fileName].token,
      onUploadProgress: done
      // onUploadProgress: function(progressEvent) {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   console.log('percentCompleted=====', percentCompleted)
      // }
    }
    if (isVideo === true) {
      uploadData = yield postAxios('upload-stream', formData, config)
      uploadData.size = uri.size
      uploadData.uri = 'qwert wer sad r'
    } else if (isVideo === false) {
      uploadData = yield postAxios('uploads', formData, config)
    }

    if (_.size(uploadData)) {
      const duplicateData = yield getAxios(`vault?storeId=media/${uploadData.id}`)
      
      yield put(actions.causeClearUploadingApiCall(fileName))
      if (duplicateData.length) {
        yield put(actions.uploadFileSuccess({
          file: {
            id: duplicateData[0].storeId,
            storeId: duplicateData[0].storeId,
            name: duplicateData[0].mediaName,
            size: uri.size || (duplicateData[0].metaData && duplicateData[0].metaData.ContentLength),
            documentId: duplicateData[0].documentId,
            uploadedFromVault: true,
            isDuplicate: true
          },
          fieldType: action.payload.params.fieldType,
          id: duplicateData[0].storeId,
          storeId: duplicateData[0].storeId,
          size: uri.size || (duplicateData[0].metaData && duplicateData[0].metaData.ContentLength),
        }, action.payload.params.preview, action.payload.isCreate))
      } else {
        yield put(actions.uploadFileSuccess({
          ...uploadData,
          file: uri,
          fieldType: action.payload.params.fieldType
        }, action.payload.params.preview, action.payload.isCreate))
      }
      // yield put(actions.uploadFileSuccess({
      //   ...uploadData,
      //   file: uri,
      //   fieldType: action.payload.params.fieldType
      // }, action.payload.params.preview, action.payload.isCreate))
      if (duplicateData.length) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `This file already exists. Uploaded ${action.payload.params.fileName} from vault`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      } else {
        new PNotify({
          addclass: 'bg-info custom',
          text: `${action.payload.params.fileName} uploaded`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      }

    }
  } catch (e) {
    yield put(actions.causeClearUploadingApiCall(fileName))

    const msg = _.get(e, 'response.data.err', e.message)
    if (msg === 'Field value too long') {
      renderErrorNotification({}, `${action.payload.params.fileName} size is too large`)
    } else if (msg === 'Cannot read property \'MISSING_COMMA\' of undefined') {
      renderErrorNotification({}, `${action.payload.params.fileName} size can't be zero`)
    } else {
      if (e.message !== undefined) {
        renderErrorNotification(e, 'Upload file error')
      }
    }
    yield put(actions.uploadFileFailure(e, action.payload.params.preview, action.payload.fieldType, action.payload.isCreate))
  }

}

function* search(action) {

  try {
    const searchData = yield backend.service(`search/${action.payload.type}`).find({ query: action.payload.query })
    if (_.size(searchData)) {
      const data = {
        [action.payload.type]: searchData.data.rows
      }
      yield put(actions.searchSuccess(data))
    }
  } catch (e) {
    renderErrorNotification(e, 'Not found')
    yield put(actions.searchFailure(e))
  }

}

function* getId(action) {

  try {
    const id = action.payload
    const cause = yield backend.service('cause').get(id)
    if (_.size(cause)) {
      yield put(actions.getIdSuccess(cause))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get cause error')
    yield put(actions.getIdFailure(e))
  }

}

function* remove(action) {
  try {
    const id = action.payload
    const deleteWorkspace = yield backend.service('cause').remove(id)
    if (_.size(deleteWorkspace)) {
      yield put(actions.removeSuccess(deleteWorkspace))
      appAnalytics.track(EVENTS.BRIEF_DELETE_SUCCESS, {causeId: id})
    }
    // new PNotify({
    //   type: 'success',
    //   title: 'Success',
    //   text: 'You successfully deleted brief'
    // })
  } catch (e) {
    renderErrorNotification(e, 'Delete cause error')
    yield put(actions.removeFailure(e))
  }
}

function* update(action) {

  try {
    let { id, causeStatus } = action.payload.data
    const isPost = action.payload.isPost
    const updateCause = yield backend.service('cause').patch(
      id,
      action.payload.data,
      {
        query: action.payload.data.query
      }
    )
    if (_.size(updateCause)) {
      yield put(actions.updateSuccess(updateCause))
      // Cause is cancelled
      if(causeStatus === 4){
        appAnalytics.track(EVENTS.BRIEF_CANCEL_SUCCESS, {causeId: id})
      }
      if (isPost) {
        new PNotify({
          addclass: 'bg-teal',
          text: `Your Brief "${updateCause.causeName}" was successfully posted in the marketplace.`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      } else if (action.payload.data.editorId) {
        new PNotify({
          addclass: 'bg-success',
          title: 'Success!',
          text: `You have successfully become the editor for "${updateCause.causeName}"`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      } else {
        if (causeStatus !== 4) {
          new PNotify({
            addclass: 'bg-info',
            text: 'Brief updated',
            buttons: {
              closer: true,
              sticker: true,
            },
          })
        }
        yield put(amplifyCauseActions.clearOrigin())
        yield put(actions.getId(id))
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Update cause error')
    yield put(actions.updateFailure(e))
  }

}

function* causeHire(action) {
  try {
    const { causeHireData, pitchUpdateData } = action.payload
    const updateCause = yield backend.service('cause').patch(
      causeHireData.id,
      causeHireData
    )
    if (_.size(updateCause)) {
      const causeUserUpdate = yield backend.service('causeUser').patch(
        pitchUpdateData.id,
        pitchUpdateData
      )
      new PNotify({
        addclass: 'bg-info',
        text: 'Brief updated',
        buttons: {
          closer: true,
          sticker: true,
        },
      })
      if (_.size(causeUserUpdate) && (pitchUpdateData && pitchUpdateData.pnotifyValue)) {
        new PNotify({
          addclass: 'bg-success',
          title: 'Success!',
          text: pitchUpdateData.pnotifyValue,
          hide: true,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      }
      yield put(actions.causeHireSuccess(updateCause))
      const {id, hiredUserId}  = updateCause
      appAnalytics.track(EVENTS.BRIEF_AWARD_SUCCESS, {causeId: id, hiredUserId})
    }
  } catch (e) {
    renderErrorNotification(e, 'Update cause error')
    yield put(actions.updateFailure(e))
  }

}

function* editAttach(action) {

  try {
    const id = action.payload.params
    const editFileData = yield backend.service('media').remove(id, action.payload)
    if (_.size(editFileData)) {
      yield put(actions.editAttachSuccess())
    }
  } catch (e) {
    renderErrorNotification(e, 'Remove file error')
    yield put(actions.editAttachFailure(e))
  }

}

function* downloadFile(action) {

  try {
    const id = action.payload.id
    const aRef = action.payload.ref
    const downloadFileData = yield backend.service('download').get(id)
    if (downloadFileData.uri) {
      const separateUri = downloadFileData.uri.split(',')
      //NOTE: get document type
      const mimeString = separateUri[0].split(':')[1].split(';')[0]
      //NOTE: line decoding. bring to the initial state, so that you can work with blob
      const byteString = atob(separateUri[1])

      const ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      const bb = new Blob([ia], { type: mimeString })
      const urlStr = URL.createObjectURL(bb)

      aRef.setAttribute('href', urlStr)
      aRef.setAttribute('download', downloadFileData.id)
      aRef.setAttribute('type', 'application/octet-stream')
      aRef.click()
      yield put(actions.downloadFileSuccess())
    }
  } catch (e) {
    renderErrorNotification(e, 'Download file error')
    yield put(actions.downloadFileFailure(e))
  }
}

function* downloadPDF(action) {
  try {
    const { html, causeId, productName, briefName } = action.payload.data
    const data = {
      causeId,
      productName,
      briefName,
      html,
    }
    // Getting the link to pdf file. Used create cause get method feathers not allowed data collection
    const downloadPDFData = yield backend.service('api2pdf').create(data)

    const url = downloadPDFData.pdf
    let a = document.createElement('a');
    a.href = url;
    a.click();

    yield put(actions.downloadFileSuccess())
  } catch (e) {
    renderErrorNotification(e, 'Download PDF error')
    yield put(actions.downloadFileFailure(e))
  }
}

function* getTotalViewsCause(briefId) {
  const query = createDatasetQuery('count-brief-shown-dataset', briefId, 'this_12_months')
  const result = yield call(getDataFromServer, query)

  return ({
    id: briefId,
    result
  })
}


function* getTotalView(action) {
  try {
    const { briefIds } = action.payload

    const results = yield all(
      _.map(briefIds, briefId => call(getTotalViewsCause, briefId))
    )

    yield all(_.map(results, ({ result, id }) => {
      if (Array.isArray(result) && result.length) {
        const totalViews = _.reduce(result, (count, timeframeItem) => count + timeframeItem.value, 0)
        return put(actions.getTotalViewSuccess({
          id,
          value: totalViews
        }))
      }
    }))
  } catch (e) {
    renderErrorNotification(e, 'Analytics system error')
    yield put(actions.getTotalViewFailure(e))
  }
}

function* causeDeleteFile(action) {

  try {
    const { id } = action.payload
    if (id) {
      const deleteFileData = yield backend.service('delete-file').create({ id })
      if (_.size(deleteFileData)) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `${id} has been deleted`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
        yield put(actions.causeDeleteFileSuccess(deleteFileData))
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Remove file error')
    yield put(actions.causeDeleteFileFailure(e))
  }

}

function* causeSaga() {
  yield takeEvery(types.CAUSE_GET, get)
  yield takeEvery(types.CAUSE_GET_DASHBOARD, getDashboard)
  yield takeEvery(types.CAUSE_GET_ID, getId)
  yield takeEvery(types.CAUSE_REMOVE, remove)
  yield takeEvery(types.CAUSE_UPDATE, update)
  yield takeEvery(types.CAUSE_CREATE, create)
  yield takeEvery(types.CAUSE_SEARCH, search)
  yield takeEvery(types.UPLOAD, uploadFile)
  yield takeEvery(types.DOWNLOAD_FILE, downloadFile)
  yield takeEvery(types.DOWNLOAD_PDF, downloadPDF)
  yield takeEvery(types.EDIT_FILE, editAttach)
  yield takeEvery(types.CAUSE_GET_TASK, getTask)
  yield takeEvery(types.CAUSE_HIRE, causeHire)
  yield takeEvery(types.GET_TOTAL_VIEW, getTotalView)
  yield takeEvery(types.CAUSE_DELETE_FILE, causeDeleteFile)
}

export default {
  causeSaga
}
