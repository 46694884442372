import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/gettyApi'
import * as actions from '../actions/gettyApi'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'


function* searchGettyImage(action) {
  try {
    const searchData = yield backend.service(`getty-search?imageName=${action.payload.searchName}&pagination=${action.payload.paginationPage}`).find(action.payload)    
    if(_.size(searchData)) {
      if (searchData.result_count === 0) {
        yield put(actions.searchGettyImageSucces(null))
      } else {
        yield put(actions.searchGettyImageSucces(searchData))
      }
    }
  } catch (e) {
    yield put(actions.searchGettyImageFailure(e))
  }
}

function* downloadGettyImage(action) {
  try {
    const { gettyData, cb } = action.payload
    if(!_.isEmpty(gettyData)) {
      const uploadedImgInfo = yield backend.service('getty-images').create(gettyData)
      if (_.each(uploadedImgInfo , item => item.status === 200)) {
        yield put(actions.downloadGettyImageSucces(uploadedImgInfo))
        yield cb(uploadedImgInfo)
      }
    } else {
      yield put(actions.downloadGettyImageSucces([]))
      cb()
    }
  } catch (e) {
    renderErrorNotification(e, 'Problem with Getty search system, please, try again.')
    yield put(actions.downloadGettyImageFailure())
  }
}

function* getGettyLimit({action}) {
  try {
    const findLimitStatus = yield backend.service('getty-limit').get('rzYPR0R7WL6YtHPtPXixLo')
    yield put(actions.saveGettyLimit(_.get(findLimitStatus, 'gettyLimit', '')))
    if (action) {
      if (action.resolve && Number(action.countImage) <= Number(findLimitStatus.gettyLimit)) {
        action.resolve()
      }
      if (action.resolve && Number(action.countImage) > Number(findLimitStatus.gettyLimit)) {
        action.reject(_.get(findLimitStatus, 'gettyLimit', ''))
      }
    }
  } catch (e) {
    if (e.code === 500 && e.type === 'FeathersError') {
      yield put(actions.saveGettyLimit(''))
    } else {
      renderErrorNotification(e, 'Error retrieving data')
    }
  }
}

function* gettySaga() {
  yield takeEvery(types.SEARCH_GETTY_IMAGE, searchGettyImage)
  yield takeEvery(types.DOWNLOAD_GETTY_IMAGE, downloadGettyImage)
  yield takeEvery(types.GET_GETTY_LIMIT, getGettyLimit)
}

export default {
  gettySaga
}
