import _, { findLastIndex } from 'lodash'
import moment from 'moment'

export const createDatasetQuery = (templateName, briefId, timeframe) => ({
  datasetName: templateName,
  timeframe,
  indexBy: briefId
})

const flatingResult = data => _.flatMap(data, itemResult => itemResult.value)

const groupByArticleId = (data, flating = true) => {
  return _.groupBy(flating ? flatingResult(data) : data, 'page.article_id')
}

const mergeWithForNumbers = (data, value) =>
  _.mergeWith(data, value, (objValue, srcValue) => {
    if (_.isNumber(objValue) && _.isNumber(srcValue)) {
      return objValue + srcValue
    }
  })

const calcSumsForGraphData = (data, withSum = true) => {
  const sumForArticles = _.mapValues(
    groupByArticleId(data, false),
    itemGroupedByArticleId => {
      const groupedByHour = _.mapValues(
        _.groupBy(itemGroupedByArticleId, 'time.local.hour'),
        itemGroupedByTimeHour => {
          return _.reduce(
            itemGroupedByTimeHour,
            (sum, value) => {
              return sum + value.result
            },
            0
          )
        }
      )
      const sum = _.sum(_.values(groupedByHour))
      return withSum ? {
        ...groupedByHour,
        sum
      } : {
        ...groupedByHour,
      }
    }
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>
      _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value),
    null
  )

  return { sumForArticles, sumForBrief }
}

export const changeGraphDataResponseResult = result => {
  const data = flatingResult(result)
  return calcSumsForGraphData(data)
}

const agesIndexes = ['<18', '18-25', '25-39', '40-54', '>55']

export const changeGenderAgeResponseResultForGender = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupedByArticleId =>
      _.mapValues(
        _.groupBy(itemGroupedByArticleId, 'user.gender'),
        itemGroupedByGender =>
          _.reduce(itemGroupedByGender, (sum, value) => sum + value.result, 0)
      )
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>
      {
        return _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value)
      },
    null
  )

  return {
    sumForArticles,
    sumForBrief
  }

}

export const changeSurveyDataWantToBuy = result => {

  let wantToBuy = { '1': [], '2': [], '3': [], '4': [], '5': []}
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupedByArticleId =>
     {
       
       _.mapValues(
        _.groupBy(itemGroupedByArticleId, 'user.wantToBuy.value'),
        itemGroupedByWantToBuy =>
          {
            _.mapValues(itemGroupedByWantToBuy, (sum, value) => {

              const objKey = Object.values(sum['user.wantToBuy.value'])[0]
              wantToBuy[objKey].push(sum.result)
              // return Object.values(sum['user.wantToBuy.value'])[0]
            }, 0)
          }
      )
      return wantToBuy
     }
  )
  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>  {
      return _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value)
    },
    null
  )

  return {
    sumForArticles,
    sumForBrief
  }

}

export const changeSurveyDataWantToLearnMore = result => {

  let wantToLearnMore = { '1': [], '2': [], '3': [], '4': [], '5': []}
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupedByArticleId =>
     {
       _.mapValues(
        _.groupBy(itemGroupedByArticleId, 'user.wantToLearnMore.value'),
        itemGroupedByWantToBuy =>
          {
            _.mapValues(itemGroupedByWantToBuy, (sum, value) => {

              const objKey = Object.values(sum['user.wantToLearnMore.value'])[0]
              wantToLearnMore[objKey].push(sum.result)
              // return Object.values(sum['user.wantToBuy.value'])[0]
            }, 0)
          }
      )
      return wantToLearnMore
     }
  )
  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>  {
      return _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value)
    },
    null
  )

  return {
    sumForArticles,
    sumForBrief
  }

}

export const changeSurveyDataWantToShare = result => {

    let wantToShare = { '1': [], '2': [], '3': [], '4': [], '5': []}
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupedByArticleId =>
     {
       _.mapValues(
        _.groupBy(itemGroupedByArticleId, 'user.wantToShare.value'),
        itemGroupedByWantToBuy =>
          {
            _.mapValues(itemGroupedByWantToBuy, (sum, value) => {

              const objKey = Object.values(sum['user.wantToShare.value'])[0]
              wantToShare[objKey].push(sum.result)
              // return Object.values(sum['user.wantToBuy.value'])[0]
            }, 0)
          }
      )
      return wantToShare
     }
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>  {
      return _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value)
    },
    null
  )

  return {
    sumForArticles,
    sumForBrief
  }

}

export const changeGenderAgeResponseResultForAge = result => {

  const ageResult = {
    'Under 18': { male: 0, female: 0 },
    '18-25': { male: 0, female: 0 },
    '25-39': { male: 0, female: 0 },
    '40-54': { male: 0, female: 0 },
    'Over 55': { male: 0, female: 0 },
  }

  let sumForArticles = _.mapValues(groupByArticleId(result), itemGroupedByArticleId =>
    _.mapValues(
      _.groupBy(
        // itemGroupedByArticleId, obj =>  agesIndexes[obj['user.age']]
        itemGroupedByArticleId, 'user.age'
      ),
      itemGroupedByGender => {
          _.mapValues(itemGroupedByGender, (obj) => {
            if (obj.result) {
              ageResult[obj['user.age']][obj['user.gender']] = ageResult[obj['user.age']][obj['user.gender']] + obj.result
            }
          }, 0)
          return ageResult
        }
    )
  )
  
  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) => {
      return _.isNil(sum) ? _.cloneDeep(value) : { ...value }
    },
    null
    )

  return {
    sumForArticles,
    sumForBrief
  }
}

export const changeGeographicWorldDataResponseResult = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupedByArticleId => {
      const groupedByCountry = _.groupBy(
        itemGroupedByArticleId,
        'ip_geo_info.country'
      )
      return _.mapValues(groupedByCountry, itemGeoupedByCountry =>
        _.reduce(itemGeoupedByCountry, (sum, value) => sum + value.result, 0)
      )
    }
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>
      _.isNil(sum)
        ? _.cloneDeep(value)
        : _.mergeWith(sum, value, (objValue, srcValue) => {
          const isNilObjValue = _.isNil(objValue)
          const isNilSrcValue = _.isNil(srcValue)
          if (isNilObjValue && !isNilSrcValue) {
            return srcValue
          }

          if (!isNilObjValue && isNilSrcValue) {
            return objValue
          }

          if (isNilObjValue && isNilSrcValue) {
            return 0
          }

          if (_.isNumber(objValue) && _.isNumber(srcValue)) {
            return objValue + srcValue
          }
        }),
    null
  )

  return {
    sumForBrief,
    sumForArticles
  }
}

export const changeDeviceCountResult = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemArticleResults => {
      const groupedByDeviceType = _.groupBy(
        itemArticleResults,
        'parsed_user_agent.device.type'
      )

      const devicesCounts = _.mapValues(
        groupedByDeviceType,
        itemGroupedByDeviceType =>
          _.reduce(
            itemGroupedByDeviceType,
            (sum, value) => sum + value.result,
            0
          )
      )

      const sumDevicesCounts = _.sum(_.values(devicesCounts))

      return {
        ...devicesCounts,
        sum: sumDevicesCounts
      }
    }
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>
      _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value),
    null
  )

  return {
    sumForBrief,
    sumForArticles
  }
}

export const changeDeviceTimeOnPageResult = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    itemGroupByArticleId => {
      return _.mapValues(
        _.groupBy(itemGroupByArticleId, 'parsed_user_agent.device.type'),
        itemGroupByDeviceType =>
          _.reduce(itemGroupByDeviceType, (sum, value) => sum + value.result, 0)
      )
    }
  )

  const sumForBrief = _.reduce(
    sumForArticles,
    (sum, value) =>
      _.isNil(sum) ? _.cloneDeep(value) : mergeWithForNumbers(sum, value),
    null
  )

  return {
    sumForArticles,
    sumForBrief
  }
}

const changeDataForWeekends = data => {
  const groupedByArticleId = groupByArticleId(data, false)

  const sumForArticles = _.mapValues(
    groupedByArticleId,
    itemGroupedByArticleId =>
      _.reduce(itemGroupedByArticleId, (sum, value) => sum + value.result, 0)
  )

  const sumForBrief = _.sum(_.values(sumForArticles))

  return {
    sumForArticles,
    sumForBrief
  }
}

const sumForHomeFn = data =>
  _.sum(_.concat(_.slice(data, 20), _.slice(data, 0, 5)))
const sumForWorkFn = data => _.sum(_.slice(data, 7, 16))
const sumForCommuteFn = data =>
  _.sum(_.concat(_.slice(data, 5, 7), _.slice(data, 16, 20)))

const calcSumForType = (sumHoursForArticles, sumHoursForBrief, fnSum) => {
  const valuesHoursForBrief = _.values(sumHoursForBrief)

  const sumForArticles = _.mapValues(
    sumHoursForArticles,
    itemSumHoursForArticles => {
      const itemValues = _.values(itemSumHoursForArticles)
      return fnSum(itemValues)
    }
  )

  const sumForBrief = fnSum(valuesHoursForBrief)

  return {
    sumForArticles,
    sumForBrief
  }
}

const changeDataForWeekdays = data => {
  const {
    sumForArticles: sumHoursForArticles,
    sumForBrief: sumHoursForBrief
  } = calcSumsForGraphData(data, false)

  return ({
    home: calcSumForType(sumHoursForArticles, sumHoursForBrief, sumForHomeFn),
    work: calcSumForType(sumHoursForArticles, sumHoursForBrief, sumForWorkFn),
    commute: calcSumForType(sumHoursForArticles, sumHoursForBrief, sumForCommuteFn)
  })
}

export const changeGraphDataResponseResultForLocation = result => {
  const reducedByWeekdaysAndWeekends = _.reduce(
    result,
    (sum, valueResult) => {
      const dayWeek = moment(valueResult.timeframe.start).weekday()
      const currentFieldForConcat = _.includes([0, 6], dayWeek) ? 'weekends' : 'weekdays'
      return  {
        ...sum,
        [currentFieldForConcat]: _.concat(sum[currentFieldForConcat], valueResult.value)
      }
    },
    {
      weekends: [],
      weekdays: []
    }
  )

  const leisure = changeDataForWeekends(reducedByWeekdaysAndWeekends.weekends)
  const weekdaysDatas = changeDataForWeekdays(
    reducedByWeekdaysAndWeekends.weekdays
  )

  return {
    leisure,
    ...weekdaysDatas
  }
}

export const changeTimeOnPageDataResponseResult = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    valueItemGrouped =>
      _.reduce(
        valueItemGrouped,
        (sum, value) => {
          return sum + value.result
        },
        0
      )
  )

  const sumForBrief = _.sum(_.values(sumForArticles))

  return {
    sumForArticles,
    sumForBrief
  }
}

export const changeCountUniqueUsersResponseResult = result => {
  const sumForArticles = _.mapValues(
    groupByArticleId(result),
    valueItemGrouped =>
      _.reduce(
        valueItemGrouped,
        (sum, value) => {
          return sum + value.result
        },
        0
      )
  )

  const sumForBrief = _.sum(_.values(sumForArticles))

  return {
    sumForArticles,
    sumForBrief
  }
}

export const formatSurveyRating = (data) => {
  const rating = {'1': 0, '2': 0, '3': 0, '4':0, '5':0}
  const _data = data && Object.values(data)
  _data && _data.map((item, i )=> {
    rating[`${i+1}`] = item.length && item.reduce((accumulator, currentValue) => accumulator + currentValue) || 0
 })
  return rating
}
 

export const getRatingAverageData = (list, index) => {
  let sum = 0;
  let test = list.map(item => {
    return Object.values(item)[0]
  })

  let sumTest = 0
  let totalResponses = 0
  list.forEach((item) => {
    const [rating, noOfresponse] = Object.entries(item)
    sumTest = sumTest + rating[1] * Number(rating[0])
    totalResponses = totalResponses + rating[1]

  })

  sum = sum + test.length && test.reduce((accumulator, currentValue) => accumulator + currentValue) || 0
  const findListIndex = list.findIndex(obj => Object.keys(obj)[0] === String(index))
  const data = {
    average: totalResponses && (sumTest / totalResponses).toFixed(1),  // parseInt(sum / list.length),
    rateCount: list && index && list[findListIndex] && Object.values(list[findListIndex])[0] || 0,
    singleRateCount: list && index && list[findListIndex] && (Object.values(list[findListIndex])[0] / sum * 100) || 0,
    feedbackResponses: sum
  }
  return data
}