import types from '../constants/vaultFolder'

const fetching = {
  isFolderListFetching: false,
  isAttachmentsFetching: false,
  isFolderExistFetching: false,
  isCreateFolderFetching: false,
}

const initialState = {
  ...fetching,
  folderList: [],
  attachmentList: [],
  folderExists: null,
  createFolder: null,
  error: null,
  origin: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_FOLDER: {
      return {
        ...state,
        isFolderListFetching: true,
      }
    }
    case types.GET_FOLDER_SUCCESS: {
      const { folderList } = action.payload
      return {
        ...state,
        isFolderListFetching: false,
        folderList,
      }
    }
    case types.GET_FOLDER_FAILURE: {
      return {
        ...state,
        isFolderListFetching: false,
        error: action.payload,
      }
    }
    case types.GET_ATTACHMENTS: {
      return {
        ...state,
        isAttachmentsFetching: true,
      }
    }
    case types.GET_ATTACHMENTS_SUCCESS: {
      const { attachmentList } = action.payload
      return {
        ...state,
        isAttachmentsFetching: false,
        attachmentList,
      }
    }
    case types.GET_ATTACHMENTS_FAILURE: {
      return {
        ...state,
        isAttachmentsFetching: false,
        error: action.payload,
      }
    }
    case types.FOLDER_EXIST: {
      return {
        ...state,
        isFolderExistFetching: true,
      }
    }
    case types.FOLDER_EXIST_SUCCESS: {
      const { folderExists } = action.payload
      return {
        ...state,
        isFolderExistFetching: false,
        folderExists,
      }
    }
    case types.FOLDER_EXIST_FAILURE: {
      return {
        ...state,
        isFolderExistFetching: false,
        error: action.payload,
      }
    }
    case types.CREATE_FOLDER: {
      return {
        ...state,
        isCreateFolderFetching: true,
      }
    }
    case types.CREATE_FOLDER_SUCCESS: {
      const { createFolder } = action.payload
      return {
        ...state,
        isCreateFolderFetching: false,
        createFolder,
      }
    }
    case types.CREATE_FOLDER_FAILURE: {
      return {
        ...state,
        isCreateFolderFetching: false,
        error: action.payload,
      }
    }
    case types.CLEAR_VAULT_FOLDER: {
      return {
        ...initialState
      }
    }
    case types.CLEAR_FOLDER_EXIST: {
      return {
        ...state,
        folderExists: null
      }
    }

    case types.VAULT_UPLOAD_SECOND_STEP_DATA: {
      return {
        ...state,
        origin: { ...action.payload }
      }
    }
    default:
      return state
  }
}
