import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/profile'
import * as actions from '../actions/profile'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const profileData = yield backend.service('profile').create({
      ...action.payload.params
    })
    if(_.size(profileData.data)) {
      const feedbacks = (profileData && profileData.data && profileData.data.feedbacks.sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime())) || []
      profileData.data.feedbacks = feedbacks
      yield put(actions.getSuccess(profileData))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get profile data error')
    yield put(actions.getFailure(e))
  }

}

function* profileSaga() {
  yield takeEvery(types.GET, get)
}

export default {
  profileSaga,
}
