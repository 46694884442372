import React from 'react'

import { Spinner } from 'react-redux-spinner'
import './Spinner.css'

import PropTypes from 'prop-types'

class MySpinner extends React.Component {

  getChildContext() {
    return { store: this.props.store }
  }

  render() {
    return (
      <Spinner />
    )
  }
}

MySpinner.childContextTypes = {
  store: PropTypes.object.isRequired
}

export default MySpinner
