export default {

  GET_PARTICIPANTS:                    'SERVER:GET_PARTICIPANTS',
  GET_PARTICIPANTS_SUCCESS:            'GET_PARTICIPANTS_SUCCESS',
  GET_PARTICIPANTS_FAILURE:            'GET_PARTICIPANTS_FAILURE',

  JOIN_CONVERSATION:                    'SERVER:JOIN_CONVERSATION',
  JOIN_CONVERSATION_SUCCESS:            'JOIN_CONVERSATION_SUCCESS',
  JOIN_CONVERSATION_FAILURE:            'JOIN_CONVERSATION_FAILURE',
  
  LEAVE_CONVERSATION:                    'SERVER:LEAVE_CONVERSATION',
  LEAVE_CONVERSATION_SUCCESS:            'LEAVE_CONVERSATION_SUCCESS',
  LEAVE_CONVERSATION_FAILURE:            'LEAVE_CONVERSATION_FAILURE',  

  UNREADED_MESSAGES:                     'UNREADED_MESSAGES',

  SET_SELECTED_CONVERSATION:           'SET_SELECTED_CONVERSATION'
}
