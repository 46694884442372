import React, { Component } from 'react'

class AppError extends Component {
  render() {

    return (
      <div className="text-center content-group">
        <h1 className="error-title">Offline</h1>
        <h5>Sorry, our website is temporary offline</h5>
      </div>
    )
  }
}

export default AppError
