import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames'

import _ from 'lodash'

import { ReactComponent as SearchIconBlue } from 'assets/images/icons/search-icon-blue.svg'
import { ReactComponent as SearchIcon } from 'assets/images/icons/search-icon.svg'

import './InputSearch.css'

export const InputSearch = props => {
  const {
    onChange,
    onSearch,
    value,
    classes,
    placeholder,
    inputRef,
    label,
    standartIcon
  } = props

  const currentClasses = {
    ...InputSearch.defaultProps.classes,
    ...classes
  }

  const handleClickSearch = useCallback(() => {
    onSearch(value)
  }, [onSearch, value])

  const handlePressEnter = useCallback(
    ({ key }) => {
      if (_.isEqual(key, 'Enter')) {
        handleClickSearch()
      }
    },
    [handleClickSearch]
  )

  const classNameIcon = useMemo(() => classNames('search-input-icon', currentClasses.icon), [currentClasses])

  return (
    <div className={classNames('search-wrapper', currentClasses.wrapper)}>
      {label && <span className="search-input-label">{label}</span>}
      <div
        className={classNames('search-input-wrapper', currentClasses.inputWrapper)}
      >
        {standartIcon ? (
          <SearchIcon className={classNameIcon} onClick={handleClickSearch} />
        ) : (
            <SearchIconBlue className={classNameIcon} onClick={handleClickSearch} />
          )}
        <input
          ref={inputRef}
          className={classNames('search-input', currentClasses.input)}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyPress={handlePressEnter}
        />
      </div>
    </div>
  )
}

InputSearch.defaultProps = {
  value: '',
  onChange: () => null,
  onSearch: () => null,
  classes: {},
  inputRef: null,
  label: null
}

export default InputSearch
