import React, { memo, useMemo } from 'react'

import Button from 'components/ui/Button'

const paramsCloseModal = {
  'data-toggle': 'modal',
  'data-target': '#legalModal',
}

export const LegalButtons = memo((props) => {
  const {
    onClickContinue,
    onClickCancel,
    titleContinue,
    isCancelCloseModal,
    isContinueCloseModal,
    isDisableContinue
  } = props

  const paramsCancelButton = useMemo(
    () =>
      isCancelCloseModal
        ? paramsCloseModal
        : {},
    [isCancelCloseModal]
  )

  const paramsContinueButton = useMemo(() => isContinueCloseModal ? paramsCloseModal : {}, [isContinueCloseModal])

  return (
    <div className="legal-modal-footer">
      <Button
        title="CANCEL"
        classes={{
          wrapper: 'legal-modal-footer-button-wrapper',
          button: 'legal-modal-footer-button',
        }}
        onClick={onClickCancel}
        {...paramsCancelButton}
      />
      <Button
        title={titleContinue}
        classes={{
          wrapper: 'legal-modal-footer-button-wrapper',
          button:
            'legal-modal-footer-button legal-modal-footer-button-continue',
        }}
        onClick={onClickContinue}
        isDisable={isDisableContinue}
        {...paramsContinueButton}
      />
    </div>
  )
})

LegalButtons.defaultProps = {
  titleContinue: 'CONTINUE',
  isCancelCloseModal: true,
  isContinueCloseModal: true,
  isDisableContinue: false
}
