import _ from 'lodash'

import types from '../constants/amplifyCausePermissions'

const initialState = {
  items: {},
  create: {},
  update: {},
  manageUsersModal: {
    amplifyCauseId: null
  },
  isGetFetching: false,
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AMPLIFY_CAUSE_PERMISSIONS_FIND: {
      return {
        ...state,
        isGetFetching: true,
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_FIND_SUCCESS: {
      return {
        ...state,
        items: _.keyBy(action.payload, 'userId'),
        isGetFetching: false,
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_CREATE_SUCCESS: {
      return {
        ...state,
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE_SUCCESS: {
      return {
        ...state,
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID: {
      return {
        ...state,
        manageUsersModal: {
          ...state.manageUsersModal,
          amplifyCauseId: action.payload
        }
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID: {
      return {
        ...state,
        manageUsersModal: {
          ...state.manageUsersModal,
          amplifyCauseId: initialState.manageUsersModal.amplifyCauseId
        }
      }
    }

    case types.AMPLIFY_CAUSE_PERMISSIONS_FIND_ERROR:
    case types.AMPLIFY_CAUSE_PERMISSIONS_CREATE_ERROR:
    case types.AMPLIFY_CAUSE_PERMISSIONS_UPDATE_ERROR:
    {
      return {
        ...state,
        isGetFetching: false,
        error: action.payload,
      }
    }

    default:
      return state
  }
}
