export default {
    AMPLIFY_CAUSE_GET:                    'SERVER:AMPLIFY_CAUSE_GET',
    AMPLIFY_CAUSE_GET_SUCCESS:            'AMPLIFY_CAUSE_GET_SUCCESS',
    AMPLIFY_CAUSE_GET_FAILURE:            'AMPLIFY_CAUSE_GET_FAILURE',

    AMPLIFY_CAUSE_GET_ID:                 'SERVER:AMPLIFY_CAUSE_GET_ID',
    AMPLIFY_CAUSE_GET_ID_SUCCESS:         'AMPLIFY_CAUSE_GET_ID_SUCCESS',
    AMPLIFY_CAUSE_GET_ID_FAILURE:         'AMPLIFY_CAUSE_GET_ID_FAILURE',

    AMPLIFY_CAUSE_UPDATE:                 'SERVER:AMPLIFY_CAUSE_UPDATE',
    AMPLIFY_CAUSE_UPDATE_SUCCESS:         'AMPLIFY_CAUSE_UPDATE_SUCCESS',
    AMPLIFY_CAUSE_UPDATE_FAILURE:         'AMPLIFY_CAUSE_UPDATE_FAILURE',

    AMPLIFY_CAUSE_SET_FILTER:             'AMPLIFY_CAUSE_SET_FILTER',
    AMPLIFY_CAUSE_RESET_FILTERS:          'AMPLIFY_CAUSE_RESET_FILTERS',
    AMPLIFY_CAUSE_SET_PAGE:               'AMPLIFY_CAUSE_SET_PAGE',

    VALIDATION:                           'AMPLIFY_CAUSE_VALIDATION',
    AMPLIFY_CAUSE_SET_EDIT:               'AMPLIFY_CAUSE_SET_EDIT',
    AMPLIFY_CAUSE_SET_ORIGIN:             'AMPLIFY_CAUSE_SET_ORIGIN',
    AMPLIFY_CAUSE_CLEAR_ORIGIN:           'AMPLIFY_CAUSE_CLEAR_ORIGIN',
    AMPLIFY_CAUSE_REMOVE:                 'AMPLIFY_CAUSE_REMOVE',
    AMPLIFY_CAUSE_CHANGE_PRODUCT:         'AMPLIFY_CAUSE_CHANGE_PRODUCT',
    AMPLIFY_CAUSE_CLEAR_DELETED_PRODUCTS: 'AMPLIFY_CAUSE_CLEAR_DELETED_PRODUCTS'
}