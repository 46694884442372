import types from '../constants/vaultCause'

export const postBrief = (data) => {
  return ({
    type: types.POST_BRIEF,
    payload: {
      data
    }
  })
}

export const postBriefSuccess = data => {
  return ({
    type: types.POST_BRIEF_SUCCESS,
    payload: {
      ...data
    }
  })
}

export const postBriefFailure = error => ({
  type: types.POST_BRIEF_FAILURE,
  payload: error
})