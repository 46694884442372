import React, { useMemo, useCallback } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'

const Checkbox = props => {
  const { name, checked, required, onCheck, onChange, id } = props

  const classNameChecked = useMemo(() => classNames({ checked: checked }), [
    checked
  ])

  const handleClick = useCallback(
    e => {
      e.stopPropagation()
      onCheck(name)
    },
    [onCheck, name]
  )

  const handleChange = useCallback(
    e => {
      onChange(e)
    },
    [onChange]
  )

  return (
    <div className="checkbox mt-0 mb-0 position-static">
      <div className="checker">
        <span className={classNameChecked}>
          <input
            type="checkbox"
            className="styled"
            onClick={handleClick}
            checked={checked}
            onChange={handleChange}
            required={required}
            id={id}
          />
        </span>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  onCheck: PropTypes.func,
  onChange: PropTypes.func,
  id: PropTypes.any
}

Checkbox.defaultProps = {
  required: false,
  onCheck: () => null,
  onChange: () => null
}

export default Checkbox
