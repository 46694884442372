export default {

  GET:              'SERVER:WORKROOM_GUEST_GET',
  GET_SUCCESS:      'WORKROOM_GUEST_GET_SUCCESS',
  GET_FAILURE:      'WORKROOM_GUEST_GET_FAILURE',

  GET_ID:           'SERVER:WORKROOM_GUEST_GET_ID',
  GET_ID_SUCCESS:   'WORKROOM_GUEST_GET_ID_SUCCESS',
  GET_ID_FAILURE:   'WORKROOM_GUEST_GET_ID_FAILURE',

  TEXT_AREA_CHANGE: 'WORKROOM_GUEST_TEXT_AREA_CHANGE',
  MODAL_CHANGE:     'WORKROOM_GUEST_MODAL_CHANGE',

  SUBMIT_COMMENT:   'WORKROOM_GUEST_SUBMIT_COMMENT',
  SUBMIT_COMMENT_SUCCESS: 'WORKROOM_GUEST_SUBMIT_COMMENT_SUCCESS',
  SUBMIT_COMMENT_FAILURE: 'WORKROOM_GUEST_SUBMIT_COMMENT_FAILURE'

}
