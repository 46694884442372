import types from '../constants/analytic'

export const download = (data) => ({
  type: types.DOWNLOAD,
  payload: {...data}
})

export const downloadSuccess = () => ({
  type: types.DOWNLOAD_SUCCESS,
})

export const downloadFailure = () => ({
  type: types.DOWNLOAD_FAILURE,
})