import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import * as actions from '../actions/recommended'
import types from '../constants/recommended'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const page = action.payload.page
    const queryParams = action.payload.queryParams
    const recommendedList = yield backend.service('recommended').find({
      query: {
        ...queryParams,
        page
      }
    })
    if (_.size(recommendedList)) {
      yield put(actions.getSuccess(recommendedList))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get product list error')
    yield put(actions.getFailure(e))
  }

}

function* recommendedSaga() {
  yield takeEvery(types.GET, get)
}

export default {
  recommendedSaga,
}
