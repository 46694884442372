import types from '../constants/vault'
import _ from 'lodash'

const initialState = {
  isGetFetching: false,
  causeList: [],
  selectedFiles: [],
  isRemoveSuccess: false,
  page: 1,
  count: null,
  error: null,
  origin: null,
  selectedFileDetailsForBrief: [],
  attachmentsCreate: [],
  erroredAttachmentsCreate: [],
  selectedFolder: '',
  attachmentBriefList: [],
  isAttachmentBriefListFetching: false,
  uploadCountList: [],
  isGetDeletingFile: false,
  deletedFile: {},
  currentUploadingApiCallData: [],
  vaultModalSelectedFiles: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET: {
      return {
        ...state,
        isGetFetching: true,
      }
    }
    case types.GET_SUCCESS: {
      const { causeList, count, page, isInfiniteScroll } = action.payload
      return {
        ...state,
        isGetFetching: false,
        causeList: isInfiniteScroll ? [...state.causeList, ...causeList] : causeList,
        count,
        page,
      }
    }
    case types.GET_FAILURE: {
      return {
        ...state,
        isGetFetching: false,
        error: action.payload,
      }
    }
    case types.VAULT_REMOVE: {
      return {
        ...state,
        isRemoveSuccess: false,
      }
    }
    case types.VAULT_REMOVE_SUCCESS: {
      return {
        ...state,
        isRemoveSuccess: true,
      }
    }
    case types.VAULT_REMOVE_FAILURE: {
      return state
    }
    case types.ADD_SELECTED_FILE: {
      const selectedFileDetails = state.causeList.filter(
        (item) => item.documentId === action.payload
      )[0]
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload],
        selectedFileDetailsForBrief: [
          ...state.selectedFileDetailsForBrief,
          selectedFileDetails,
        ],
      }
    }
    case types.REMOVE_SELECTED_FILE: {
      const updatedArr = state.selectedFiles.filter(
        (item) => item !== action.payload
      )
      const updatedListForBrief = state.selectedFileDetailsForBrief.filter(
        (item) => item.documentId !== action.payload
      )
      return {
        ...state,
        selectedFiles: updatedArr,
        selectedFileDetailsForBrief: updatedListForBrief,
      }
    }
    case types.CLEAR_LIST: {
      return {
        ...state,
        selectedFiles: [],
        selectedFileDetailsForBrief: [],
      }
    }
    case types.REMOVE_BRIEF_ATTACHMENT: {
      const updatedListForBrief = state.selectedFileDetailsForBrief.filter(
        (item) => item.documentId !== action.payload
      )
      return {
        ...state,
        selectedFileDetailsForBrief: updatedListForBrief,
      }
    }
    case types.CLEAR_BRIEF_ATTACHMENT: {
      return {
        ...state,
        selectedFileDetailsForBrief: [],
      }
    }
    case types.UPDATE_FILE_TAGS: {
      return {
        ...state,
      }
    }
    case types.UPDATE_FILE_TAGS_SUCCESS: {
      // updating tags for that particular document
      const updatedData = action.payload[1][0]
      const updatedList = state.causeList.map((item) => {
        if (item.documentId === updatedData.documentId)
          return { ...item, tags: updatedData.tags }
        return item
      })
      return {
        ...state,
        causeList: updatedList,
      }
    }
    case types.UPDATE_FILE_TAGS_FAILURE: {
      return {
        ...state,
      }
    }
    case types.ATTACH: {
      const attachments = 'attachmentsCreate'
      const prevAttachments = state[attachments]
      const params = action.payload.params
      return {
        ...state,
        [attachments]: prevAttachments.concat(params),
      }
    }

    case types.REMOVE_FILE: {
      const { isError } = action.payload;
      const attachments = isError ? 'erroredAttachmentsCreate' : 'attachmentsCreate'
      const prevAttachments = state[attachments].slice()
      const params = action.payload.params
      const index = prevAttachments.findIndex((element, index) => {
        return element.preview === params || element.id === params
      })

      prevAttachments.splice(index, 1)

      return {
        ...state,
        [attachments]: prevAttachments,
      }
    }

    case types.RESET_ATTACH_CHANGES: {
      const attachments = 'attachmentsCreate'

      return {
        ...state,
        [attachments]: action.payload,
      }
    }

    case types.UPLOAD: {
      const attachments = 'attachmentsCreate'
      const prevAttachments = state[attachments].slice()
      const params = action.payload.params
      const index = prevAttachments.findIndex((element, index) => {
        return element.preview === params.preview
      })
      prevAttachments[index] = {
        name: prevAttachments[index].name,
        preview: prevAttachments[index].preview,
        fieldType: prevAttachments[index].fieldType,
        size: prevAttachments[index].fileSize,
        isFetching: true,
        isError: false,
      }

      return {
        ...state,
        [attachments]: prevAttachments,
      }
    }
    case types.UPLOAD_SUCCESS: {
      const attachments = 'attachmentsCreate'
      const prevAttachments = state[attachments].slice()
      const preview = action.payload.preview
      const index = prevAttachments.findIndex((element) => {
        return element.preview === preview
      })

      if(prevAttachments[index]) {
        prevAttachments[index].isFetching = false
        prevAttachments[index].isError = false
        prevAttachments[index].storeId = action.payload.data.id
        prevAttachments[index].size = action.payload.data.size
      }

      if (action.payload.data && action.payload.data.uri && action.payload.data.uri.isDuplicate) {
        prevAttachments[index].documentId = action.payload.data.uri.documentId
        prevAttachments[index].uploadedFromVault = true
      }


      return {
        ...state,
        [attachments]: prevAttachments,
      }
    }
    case types.UPLOAD_FAILURE: {
      const attachments = 'attachmentsCreate'
      const prevAttachments = state[attachments].slice()
      const preview = action.payload.preview
      const index = prevAttachments.findIndex((element, index) => {
        return element.preview === preview
      })

      if (prevAttachments[index]) {
        prevAttachments[index].isFetching = false
        prevAttachments[index].isError = true
      }

      const copyPrevAttachments = _.filter(
        prevAttachments,
        (item) => item.isError === false
      )

      return {
        ...state,
        [attachments]: copyPrevAttachments,
      }
    }
    case types.UPLOAD_ERROR: {
      const attachments = 'erroredAttachmentsCreate'
      const prevAttachments = state[attachments]
      const params = action.payload.params
      return {
        ...state,
        [attachments]: prevAttachments.concat(params),
      }
    }

    case types.CLEAR_VAULT_UPLOAD: {
      return {
        ...state,
        attachmentsCreate: [],
        erroredAttachmentsCreate: [],
      }
    }

    case types.FILTER_VAULT_FOLDER: {
      return {
        ...state,
        isGetFetching: true,
      }
    }
    case types.FILTER_VAULT_FOLDER_SUCCESS: {
      const { causeList, page, count } = action.payload
      return {
        ...state,
        isGetFetching: false,
        causeList,
        page,
        count
      }
    }
    case types.FILTER_VAULT_FOLDER_FAILURE: {
      return {
        ...state,
        isGetFetching: false,
        error: action.payload,
      }
    }

    case types.SET_SELECTED_FOLDER: {
      return {
        ...state,
        selectedFolder: action.payload
      }
    }

    case types.GET_ATTACHMENT_BRIEF_LIST: {
      return {
        ...state,
        isAttachmentBriefListFetching: true,
      }
    }
    case types.GET_ATTACHMENT_BRIEF_LIST_SUCCESS: {
      const { attachmentBriefList } = action.payload
      return {
        ...state,
        isAttachmentBriefListFetching: false,
        attachmentBriefList,
      }
    }
    case types.GET_ATTACHMENT_BRIEF_LIST_FAILURE: {
      return {
        ...state,
        isAttachmentBriefListFetching: false,
        error: action.payload,
      }
    }
    case types.VAULT_UPLOAD_PROGRES: {
      const _uploadCountList = state.uploadCountList
      const isDataInList = _uploadCountList.findIndex(obj => obj && Object.keys(obj)[0] == Object.keys(action.payload)[0]);
      if (isDataInList > -1 && Object.keys(action.payload)) {
        _uploadCountList[isDataInList][Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      } else {
        _uploadCountList.push(action.payload)
      }
      return {
        ...state,
        uploadCountList: _uploadCountList
      }
    }

    case types.VAULT_DELETE_FILE:
      {
        return {
          ...state,
          isGetDeletingFile: true
        }
      }

    case types.VAULT_DELETE_FILE_SUCCESS:
      {
        return {
          ...state,
          deletedFile: action.payload,
          isGetDeletingFile: false
        }
      }

    case types.VAULT_DELETE_FILE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetDeletingFile: false
        }
      }
    case types.CURRENT_UPLOADING_API_CALLS: {
      const currentUploadingData = state.currentUploadingApiCallData
      currentUploadingData.push(action.payload)
      return {
        ...state,
        currentUploadingApiCallData: currentUploadingData
      }
    }

    case types.CLEAR_UPLOADING_API_CALLS: {
      const currentUploadingData = state.currentUploadingApiCallData
      const currentIndex = currentUploadingData && currentUploadingData.findIndex(item => item && Object.keys(item) && Object.keys(item)[0] === action.payload)
      delete currentUploadingData[currentIndex]
      return {
        ...state,
        currentUploadingApiCallData: currentUploadingData
      }
    }

    case types.CLEAR_VAULT_LIST: {
      return {
        ...state,
        causeList: []
      }
    }

    case types.ADD_VAULT_MODAL_SELECTED_FILES: {
      return {
        ...state,
        vaultModalSelectedFiles: action.payload
      }
    }

    default:
      return state
  }
}
