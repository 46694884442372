import React, {
  memo,
  useState,
  useCallback,
  useRef,
  useMemo
} from 'react'
import classNames from 'classnames'
import uuidv1 from 'uuid/v1'
import _ from 'lodash'
import './select.css'
import { useOnClickOutside } from 'helpers/hooks/useClickOutside'

import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg'

const Arrow = memo(props => {
  const { isOpen, arrowColor } = props

  return (
    <div className="select-arrow-wrapper">
      <ArrowIcon
        className={classNames('select-arrow', 'icon-arrow-down22', arrowColor, {
          'arrow-open': isOpen
        })}
      />
    </div>
  )
})

const SelectedItem = memo(props => {
  const {
    readOnly,
    value,
    placeholder,
    cantReset,
    onDelete,
    onSelect,
    data,
    isMultiple,
    customValueClass = ''
  } = props
  const deleteItem = e => {
    onDelete ? onDelete(data) : onSelect(null, e)
  }
  const valueClass = classNames('select2-selection__rendered', customValueClass, {
    'select2-selection__placeholder': !value,
    'multiple-text-decoration': isMultiple
  })
  return (
    <>
      <span className={valueClass}>{value || placeholder}</span>
      <div className="select-left-container">
        {!readOnly && !cantReset && value && (
          <span
            className="select2-selection__clear cursor-pointer"
            onClick={e => {
              e.stopPropagation()
              deleteItem(e)
            }}
          >
            ×
          </span>
        )}
      </div>
    </>
  )
})

const Select = memo(props => {
  const {
    items,
    value,
    placeholder,
    isSearch,
    cantReset,
    onSelect,
    onSearch,
    keyValue,
    readOnly,
    isBlock,
    isInput,
    inputChange,
    inputName,
    selectFieldName,
    withLoadMore,
    loadMore,
    classes,
    isShowNothingFound,
    label,
    withArrow,
    opened,
    changeOpened,
    onDelete,
    isMultiple,
    arrowColor
  } = props
  const [isOpen, setOpen] = useState(false)
  const refDropdown = useRef(null)
  const isNotPropsOpened = useMemo(
    () => _.every([opened, changeOpened], _.isNil),
    [opened, changeOpened]
  )

  const currentIsOpenOptions = useMemo(() => {
    return isNotPropsOpened
      ? {
          onChangeOpen: setOpen,
          isOpen
        }
      : {
          onChangeOpen: changeOpened,
          isOpen: opened
        }
  }, [isNotPropsOpened, changeOpened, opened, isOpen])

  const currentClasses = {
    ...Select.defaultProps.classes,
    ...classes
  }

  const renderSelectedValue = () => {
    if (isInput) {
      return (
        <input
          className={classNames('form-control', classes.input)}
          type="text"
          name={inputName}
          onChange={e => inputChange && inputChange(e)}
          value={value || ''}
        />
      )
    }
    return _.isArray(value) ? (
      !!_.size(value) &&
        _.map(value, item => (
          <div className="multiple-item" key={`multible_selector_${item.id || uuidv1()}`}>
            <SelectedItem
              value={item[selectFieldName]}
              data={item[keyValue]}
              rendererLabel={rendererLabel}
              readOnly={readOnly}
              currentClasses={currentClasses}
              placeholder={placeholder}
              cantReset={cantReset}
              onDelete={onDelete}
              customValueClass={classes.customValueClass}
              isMultiple
              onSelect={onSelect}
            />
          </div>
        ))
    ) : (
      <SelectedItem
        value={value}
        rendererLabel={rendererLabel}
        readOnly={readOnly}
        currentClasses={currentClasses}
        placeholder={placeholder}
        cantReset={cantReset}
        customValueClass={classes.customValueClass}
        onSelect={onSelect}
      />
    )
  }
  const handleChangeOpen = useCallback(() => {
    if (!isBlock) {
      currentIsOpenOptions.onChangeOpen(!currentIsOpenOptions.isOpen)
    }
  }, [isBlock, currentIsOpenOptions])

  useOnClickOutside(
    refDropdown,
    () => {
      currentIsOpenOptions.onChangeOpen(false)
    },
    [currentIsOpenOptions.onChangeOpen]
  )

  const rendererLabel = useMemo(() => {
    return label ? <div className="select2-label">{label}</div> : null
  }, [label])

  const rendererArrow = useMemo(
    () => (withArrow ? <Arrow arrowColor={arrowColor} isOpen={currentIsOpenOptions.isOpen} /> : null),
    [currentIsOpenOptions.isOpen, withArrow]
  )
  return (
    <div
      className={classNames(
        'select-container',
        { open: currentIsOpenOptions.isOpen, 'with-label': label },
        classes.container
      )}
      ref={refDropdown}
    >
      <div className="full-width" onClick={!readOnly ? handleChangeOpen : undefined}>
        <span className="select2 select2-container select2-container--default select2-container--below full-width">
          {rendererLabel}
          <span
            className={classNames(
              'select2-selection--single',
              {
                'select2-selection': !isMultiple,
                'border_bottom_none': readOnly,
                'select2-selection__arrow': !readOnly
              },
              currentClasses.selection,
              currentClasses.selectorBorder,
            )}
          >
            {renderSelectedValue()}
            {isMultiple && !readOnly &&  <span className="multiple-text-decoration ml-5">{placeholder} </span>}
            {!readOnly && rendererArrow}
          </span>
          <span className="dropdown-wrapper" />
        </span>
      </div>

      {isShowNothingFound || _.size(items) ? (
        <span className="dropdown-menu select2-container--open full-width">
          <span className="select2-dropdown select2-dropdown--above select-list-container_wrapper">
            <span>
              {isSearch && (
                <span className="select2-search select2-search--dropdown">
                  <input
                    className="select2-search__field"
                    type="search"
                    onChange={onSearch}
                    onClick={e => e.stopPropagation()}
                    autoFocus
                  />
                </span>
              )}
              <span className="select2-results">
                <ul
                  className={classNames(
                    'select2-results__options',
                    currentClasses.selectOptions
                  )}
                  role="tree"
                  id="select2-state-search-results"
                >
                  {_.size(items) ? (
                    _.map(items, (item, i) => (
                      <li
                        key={`select_${keyValue || i}_${item.id ||
                          item.name ||
                          i}`}
                        className="select2-results__option"
                        role="treeitem"
                        onClick={e => {
                          if (!item.isNotClosedSelect) {
                            handleChangeOpen()
                          }
                          onSelect(item)
                        }}
                      >
                        {item[selectFieldName || 'name']}
                      </li>
                    ))
                  ) : (
                    <span className="select-results__none">Nothing found</span>
                  )}
                  {props.children}
                  {withLoadMore && (
                    <span className="select-load-more" onClick={loadMore}>
                      Load more
                    </span>
                  )}
                </ul>
              </span>
            </span>
          </span>
        </span>
      ) : null}
    </div>
  )
})

Select.defaultProps = {
  classes: {
    selection: ''
  },
  isShowNothingFound: true,
  withArrow: true,
  isMultiple: false,
  selectFieldName: 'name'
}

export default Select
