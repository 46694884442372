import React, {useMemo, useCallback, Fragment, useState } from 'react'

import Button from 'components/ui/Button'

import { ReactComponent as AddIcon } from 'assets/images/icons/add-navigation.svg'

import { renderNotification } from 'helpers/tools'

import './PostJob.css'
import { appAnalytics, EVENTS } from 'helpers/appAnalytics'

import PostBriefModal from 'components/ui/PostBriefModal'

const PostJob = props => {

    const { routerActions, isUnlimitedWorkspace, isAccountComplete, isDistribution } = props

    const [show, setShow] = useState(false)
    const [propsWrapper, setPropsWrapper] = useState({})

    const handleClick = useCallback(() => {
      const _propsWrapper = isUnlimitedWorkspace && isAccountComplete ? {
        'data-toggle':"modal",
        'data-target':'#createBriefModal'
        } : {}
      setPropsWrapper(_propsWrapper)
      setShow(true)
    }, [isUnlimitedWorkspace, isAccountComplete, show])


    const handleContentBrief = () => {
      setShow(false)
      if(isAccountComplete) {
        appAnalytics.track(EVENTS.BRIEF_POST_REQUEST)
        !isUnlimitedWorkspace && routerActions.push('/create-brief')
      } else {
        renderNotification(
          'error',
          'Your account setup is incomplete, please contact support@fabulate.com.au'
        )
      }
    }

    const handleDistributionBrief = () => {
      setShow(false)
      if (isAccountComplete) {
        appAnalytics.track(EVENTS.VAULT_BRIEF_POST_REQUEST)
        routerActions.push("/post-brief")
      } else {
        renderNotification(
          'error',
          'Your account setup is incomplete, please contact support@fabulate.com.au'
        )
      }
    }
    const rendererContent = useMemo(() => {
      // const propsWrapper = isUnlimitedWorkspace && isAccountComplete ? {
      //   'data-toggle':"modal",
      //   'data-target':'#createBriefModal'
      // } : {}

      return (
        <div
        // {...propsWrapper}
        >
          <Button
              title={
                <Fragment>
                  <span>Post a brief</span>
                  <AddIcon />
                </Fragment>
              }
              classes={{
                wrapper: 'dashboard-briefs-post-job-button-wrapper',
                button: 'dashboard-briefs-post-job-button'
              }}
              onClick={
                () => {
                  if (isDistribution) {
                    handleDistributionBrief()
                  } else {
                    handleClick()
                  }
                }
              }
            />
            {
              show && (
                <PostBriefModal
                  show={show}
                  setShow={setShow}
                  handleContentBrief={handleContentBrief}
                  propsWrapper={propsWrapper}
                  handleDistributionBrief={handleDistributionBrief}
                />
              )
            }
        </div>
      )
    }, [isAccountComplete, isUnlimitedWorkspace, propsWrapper, show, isDistribution])
  
  return (
    <div>
      {rendererContent}
    </div>
  )
}


PostJob.defaultProps = {
  isDistribution: false
}


export default PostJob