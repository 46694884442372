import { 
  put, 
  takeEvery,
} from 'redux-saga/effects'

import { push } from 'react-router-redux'

import _ from 'lodash'

import types from '../constants/template'
import * as actions from '../actions/template'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const templateList = yield backend.service('template').find({
      query: action.payload.queryParams
    })
    if(_.size(templateList)) {
      yield put(actions.getSuccess(templateList))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get causes list error')
    yield put(actions.getFailure(e))
  }

}


function* create(action) {

  try {
    const createTemplate = yield backend.service('template').create({
      ...action.payload
    })

    if(_.size(createTemplate)) {

      yield put(actions.createSuccess(createTemplate))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get templates list error')
    yield put(actions.createFailure(e))
    yield put(push('/template'))
  }

}

function* remove(action) {

  try {

    const id = action.payload

    const deleteTemplate = yield backend.service('template').remove(id)

    if(_.size(deleteTemplate)) {
      yield put(actions.removeSuccess(deleteTemplate))
    }

  } catch (e) {
    renderErrorNotification(e, 'Delete template error')
    yield put(actions.removeFailure(e))
  }

}


function* getId(action) {
  try {
    const id = action.payload

    const template = yield backend.service('template').get(id)
    if(_.size(template)) {

      yield put(actions.getIdSuccess(template))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get template error')
    yield put(actions.getIdFailure(e))
  }
}

function* update(action) {
  try {

    let { id } = action.payload

    const updateWorkspace = yield backend.service('template').patch(id, action.payload)

    if(_.size(updateWorkspace)) {

      yield put(actions.updateSuccess(updateWorkspace))

      new PNotify({
        addclass: 'bg-info',
        title: 'Information',
        text: 'Brief updated',
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },      
      })      
    }

  } catch (e) {
    renderErrorNotification(e, 'Update cause error')
    yield put(actions.updateFailure(e))
  }

}


function* templateSaga() {

  yield takeEvery(types.CREATE, create)
  yield takeEvery(types.GET, get)
  yield takeEvery(types.REMOVE, remove)
  yield takeEvery(types.GET_ID, getId)
  yield takeEvery(types.UPDATE, update)

}

export default {
  templateSaga,
}
