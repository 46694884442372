export default {

  GET_ID:          'SERVER:FEEDBACK_GET_ID',
  GET_ID_SUCCESS:  'FEEDBACK_GET_ID_SUCCESS',
  GET_ID_FAILURE:  'FEEDBACK_GET_ID_FAILURE',


  CREATE:           'SERVER:FEEDBACK_CREATE',
  CREATE_SUCCESS:   'FEEDBACK_CREATE_SUCCESS',
  CREATE_FAILURE:   'FEEDBACK_CREATE_FAILURE',

  EDIT_FORM:        'FEEDBACK_EDIT_FORM',

  CLEAR_FEEDBACK_FORM: 'FEEDBACK_CLEAR_FORM'
}