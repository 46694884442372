import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import _ from 'lodash'

import Header from 'components/app/Header'
import Footer from 'components/app/Footer'

const routes = ['sign-in', 'reset', 'restore', 'verify-email', 'login-as-admin']

class Login extends Component {
  componentDidMount() {
    const { location, routeActions, history } = this.props

    if (location.pathname === '/') {
      history.push('/sign-in')
    }

    const mainPath = location.pathname.split('/')[1]
    const previousRoute = location.pathname + location.search

    if (!_.includes(routes, mainPath)) {
      routeActions.savePrevious(previousRoute)
    }

  }

  render() {
    return (
      <div className="login__wrapper">
        <Header />
        {this.props.children}
        <Footer />
      </div>
    )
  }
}

export default withRouter(Login)
