import { causeStatus } from "helpers/params"

export default (notification, currentUser, isNoName) => {
  if (notification.name) {
    switch (notification.name) {
      case 0: {
        return {
          body: `Finished Item ${notification.params.itemIndex} ${isNoName ? '' : 'in brief "' + notification.params.causeName + '"'
            }`,
          icon: 'icon-file-check',
          color: 'primary',
          shortText: 'Item finished',
          isRedirect: true
        }
      }
      case 'PITCH_ACCEPT_CREATOR': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-user-check',
          color: notification.params.color,
          shortText: 'Pitch accepted',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 'PITCH_ACCEPT_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-user-check',
          color: notification.params.color,
          shortText: 'Pitch accepted',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PITCH_POSTED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-mail5',
          color: notification.params.color,
          shortText: 'New pitch',
          isRedirect: true,
          tab: 4,
        }
      }
  
      case 'CREATOR_INVITED_CREATOR': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-mail5',
          color: notification.params.color,
          shortText: 'New invite',
          isRedirect: true,
          tab: 3,
        }
      }
  
      case 4: {
        if (!notification.params.notificationText) {
          const result =
            notification.params.result === 2
              ? 'Waiting to approve'
              : notification.params.result === 1
                ? 'Accepted'
                : 'Declined'
  
          return {
            body: `The job ${isNoName ? '' : 'in "' + notification.params.causeName + '"'
              } has been updated. Status - ${result}.\n Click here to check it out.`,
            icon:
              notification.params.result === 2
                ? 'icon-stairs-down'
                : 'icon-stairs-up',
            color: (notification.params.result === 2 || notification.params.result === 1) ? 'success' : 'warning',
            shortText: 'Step changed',
            // NOTE: Tab = 5 for workroom tab on brief page.
            tab: 5,
            isRedirect: true
            // linkTo: `brief-show/${notification.linkId}`
          }
        } else {
          const result =
            notification.params.result === 2
              ? notification.params.notificationText.firstText
              : notification.params.result === 1
                ? notification.params.notificationText.firstText
                : notification.params.notificationText.secondText
  
          return {
            body: result,
            icon:
              notification.params.result === 2
                ? 'icon-stairs-up'
                : notification.params.result === 1
                  ? 'icon-stairs-up'
                  : 'icon-stairs-down',
            color: 'success',
            shortText: 'Step changed',
            // NOTE: Tab = 5 for workroom tab on brief page.
            tab: 5,
            isRedirect: true
            // linkTo: `brief-show/${notification.linkId}`
          }
        }
      }
  
      case 5: {
        const status =
          causeStatus[
          notification.params.causeStatus <= 4
            ? notification.params.causeStatus
            : 0
          ]
        if (status.value === 4 && status.name === "Canceled") {
          return {
            title: 'Success',
            body: `Your brief is canceled successfully`,
            icon: 'icon-stack-text',
            color: 'green-500',
            shortText: 'Status changed',
            isRedirect: true
          }
        } else {
          return {
            body: `The job ${isNoName ? '' : 'in "' + notification.params.causeName + '"'
              } has been updated. Status - ${status.name}.\n Click here to check it out.`,
            icon: 'icon-stack-text',
            color: 'orange',
            shortText: 'Status changed',
            linkTo: `brief-show/${notification.linkId}`,
            isRedirect: true
          }
        }
      }
  
      case 'BRIEF_COMPLETED_CREATOR': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-file-check',
          shortText: 'Item finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }      
      }
  
      case 'BRIEF_COMPLETED_CREATOR': {
  
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-file-check',
          shortText: 'Item finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'BRIEF_COMPLETED_BUYER': {
  
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-file-check',
          shortText: 'Item finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PITCH_DECLINED_CREATOR': {
  
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'Pitch declined',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 8: {
        return {
          body: `Congratulations! Your pitch has been successfully awarded by ${notification.params.buyerName} for the job "${notification.params.causeName}". Click here to get started in the workroom`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Pitch won',
          // NOTE: Tab = 5 for workroom tab on brief page, for contractor.
          tab: 5,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'NEW_COMMENT': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'New comment',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 10: {
        return {
          body: `This is an automatic Fabulate reminder of the "${notification.params.causeName}" brief.\n
        The content for "${notification.params.causeName}" is due in 24 hours.\n
        Please contact support@fabulate.com.au if you are unable to meet the deadline.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'Pitch reminder',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'WORKROOM_STARTED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Intro to the workroom',
          tab: 5,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}?tab=workroom`
        }
      }
  
      case 'BRIEF_READY_EDITOR': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'New brief is ready to be picked up',
          linkTo: `brief-show/${notification.linkId}?tab=workroom`,
          isRedirect: true
        }
      }
  
      case 'DISTRIBUTION_BRIEF_RECEIEVED_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'New brief is ready to be picked up',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_ACCEPT_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 15: {
        return {
          body: `This is an automatic Fabulate message.\n Unfortunately your selected distribution partner has declined to publish your content for "${notification.params.causeName}\n".
        The publisher has provided the following reason:\n
        "${notification.params.declineReason}" \n
        Contact support@fabulate.com.au for assistance in selecting another partner for publishing. \n
        Click to open`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Brief was declined',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'DISTRIBUTION_BRIEF_POSTED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'New brief is ready to be picked up',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_ACCEPT_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 18: {
        return {
          body: `Unfortunately your chosen distribution partner has declined to publish your content for "${notification.params.causeName}".\n
        The publisher has provided the below reason:\n
        "${notification.params.declineReason}"\n
        Contact support@fabulate.com.au for assistance in selecting another partner for publishing.\n
        Click to open`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Brief was declined',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 20: {
        return {
          body: `This is an automatic Fabulate message.\n A file has been uploaded to the distribution workroom for the job "${notification.params.causeName}".
        Click here to see it.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New Upload is ready in the distribution workroom',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 21: {
        return {
          body: `This is an automatic Fabulate message.\n A file has been uploaded to the distribution workroom for the job "${notification.params.causeName}".
        Click here to see it.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New Upload is ready in the workroom',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'AMPLIFY_BRIEF_COMPLETED_BUYER': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'A piece of content has been marked as finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 25: {
        return {
          body: `A distribution campaign for job "${notification.params.causeName}" has been marked as complete.
        If you are using Fabulate analytics, ypu can view the campaign performance from your dashboard. Otherwise request a final report from the publisher who will upload to the distribution workroom. 
        Click here to return to the distribution workroom.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'A piece of content has been marked as finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 26: {
        return {
          title: 'Content attached for distribution',
          body: `By "${notification.params.buyerName}" for "${notification.params.causeName}". Click here to view it in the distribution workroom.`,
        //   body: `The content for job "${notification.params.buyerCauseName}" is ready for distribution. It has automatically been attached to the distribution workroom.
        // Click here to get started.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 27: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          tab: 5,
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'BRIEF_COMPLETED_CREATOR': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Your brief\'s pitch has been updated',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
      case 'BRIEF_COMPLETED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Your brief\'s pitch has been updated',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 'BRIEF_POSTED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'Brief has been created',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PITCH_REMOVED_CREATOR': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Pitch has been removed',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 'PITCH_REMOVED_BUYER': {
        return {
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Creator removed pitch',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 33: {
        return {
          body: `You have cancelled the job "${notification.params.causeName}" and is no longer visible to creators.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Buyer canceled brief',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 'DISTRIBUTION_BRIEF_ACCEPT_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      } 
      
      case 'DISTRIBUTION_BRIEF_ACCEPT_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_ACCEPT_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
        
  
      case 36: {
        return {
          title: 'Declined publishing job',
          body: 'You have declined this publishing job. Your feedback and reasoning has been passed onto the client.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'DISTRIBUTION_BRIEF_REJECT_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 38: {
        return {
          title: 'Assets uploaded',
          body: `"${notification.params.username}" has uploaded assets to "${notification.params.causeName}". Click here to get started.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      
      case 39: {
        return {
          title: 'Assets successfully uploaded',
          body: `The publisher has been notified of your uploaded assets. You will be notified by email when your test link is ready.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 40: {
        return {
          title: 'Test link submitted',
          body: 'Your test link has been sent to the client. You will be notified by email with any client feedback.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 41: {
        return {
          title: 'Test link uploaded',
          body: `${notification.params.username} has uploaded a test link for "${notification.params.causeName}". click here to review.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 42: {
        return {
          title: 'Test approved',
          body: `${notification.params.username} has approved your test link for "${notification.params.causeName}". Click here for next steps.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 43: {
        return {
          title: 'Test link approved',
          body: 'You have approved the test link. The publisher has been notified and is in the process of setting your content live. You will be notified by email when your content has been set live by the publisher.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 44: {
        return {
          title: 'Content job set live',
          body: 'Your content job has been set live. Don\'t forget to supply the client with a live link of the content on your site.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 45: {
        return {
          title: 'Campaign set live',
          body: 'Check with the publisher for a live link to see your content in all its glory.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 'BRIEF_CANCELLED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'BRIEF_COMPLETED_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          isRedirect: true,
          linkTo: `amplify-brief-show/${notification.linkId}`
        }
      }
  
      case 'BRIEF_DELETED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: false
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'OWNER_PERMISSION_GRANTED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'OWNER_PERMISSION_GRANTED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'EDIT_PERMISSION_GRANTED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'EDIT_PERMISSION_GRANTED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'READ_PERMISSION_GRANTED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'READ_PERMISSION_GRANTED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'OWNER_PERMISSION_REVOKED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'OWNER_PERMISSION_REVOKED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'EDIT_PERMISSION_REVOKED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'EDIT_PERMISSION_REVOKED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'READ_PERMISSION_REVOKED_GRANTED_BY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'READ_PERMISSION_REVOKED_GRANTED_TO': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      // case 46: {
      //   return {
      //     title: 'Job marked as finished',
      //     body: 'You have indicated that this job has delivered in full. \n\nAll files and reports are still available for download via the distribution workroom.',
      //     icon: 'icon-stack-text',
      //     color: 'blue',
      //     shortText: '',
      //     linkTo: `amplify-brief-show/${notification.linkId}`
      //   }
      // }
  
      case 'AMPLIFY_WORKROOM_BUYER': {
        return {
          body: notification.params.bodyText,
          color: (notification.params.color && `bg-${notification.params.color}`) || 'success',
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 'AMPLIFY_WORKROOM_AMPLIFY': {
        return {
          body: notification.params.bodyText,
          color: (notification.params.color && `bg-${notification.params.color}`) || 'danger',
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 62: {
        return {
          title: 'Job marked as finished',
          body: 'You have indicated that this job has delivered in full. \n\n All files and reports are still available for download via the distribution workroom.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 63: {
        return {
          title: 'Job marked as finished',
          body: 'You have indicated that this job has delivered in full. \n\n All files and reports are still available for download via the distribution workroom."',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 'DISTRIBUTION_BRIEF_REJECT_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_REJECT_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_RECEIVED_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 66: {
        return {
          title: 'New publishing job',
          body: `${notification.params.buyerName} has posted ${notification.params.causeName} and selected your brand as part of their order. \n\nClick here to check it out.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_REJECT_AMPLIFY': {
        return {
          title: notification.params.title,
          body: notification.body,
          color: notification.params.color,
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'PUBLISHING_BRIEF_POSTED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'New brief is ready to be picked up',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'CONTENT_WORKROOM_BUYER': {
  
        return {
          body: notification.params.bodyText,
          color: (notification.params.color && `bg-${notification.params.color}`)|| 'success',
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 'CONTENT_WORKROOM_CREATOR': {
        return {
          body: notification.params.bodyText,
          color: (notification.params.color && `bg-${notification.params.color}`)|| 'danger',
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 'CREATOR_INVITED_BUYER': {
        return {
          title: notification.params.title,
          body: notification.body,
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-mail5',
          color: notification.params.color,
          shortText: 'New invite',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true,
          tab: 3
        }
      }
  
      case 'PITCH_DECLINED_BUYER': {
  
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          shortText: 'Pitch declined',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 'CONTENT_WORKROOM_EDITOR': {
        return {
          body: notification.body,
          color: (notification.params.color && `bg-${notification.params.color}`)|| 'danger',
          icon: 'icon-stack-text',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 'PITCH_POSTED_CREATOR': {
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          // shortText: 'Brief has been created',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 'WORKROOM_COMPLETED_BUYER': {
        console.log('workroom completed buyer====', notification)
        return {
          title: notification.params.title,
          body: notification.body,
          icon: 'icon-stack-text',
          color: notification.params.color,
          // shortText: 'Brief has been created',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      default: {
        return {}
      }
    }
  } else {

    switch (notification.notificationType) {
      case 0: {
        return {
          body: `Finished Item ${notification.params.itemIndex} ${isNoName ? '' : 'in brief "' + notification.params.causeName + '"'
            }`,
          icon: 'icon-file-check',
          color: 'primary',
          shortText: 'Item finished',
          isRedirect: true
        }
      }
      case 1: {
        let body = currentUser.permissions === 3
          // ? `Accepted pitch ${isNoName ? '' : 'in "' + notification.params.causeName + '"'
          // } by ${notification.params.buyerName}`
          ? `By ${notification.params.buyerName} for ${notification.params.causeName}`
          // : `You have awarded a pitch for "${notification.params.causeName}"`;
          : `You have awarded this brief to "${notification.params.contractorName}"`;
  
        let title = currentUser.permissions === 3 ? 'You have been awarded a job' : 'Success!'
        return {
          title: title,
          body: body,
          icon: 'icon-user-check',
          color: 'success',
          shortText: 'Pitch accepted',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 2: {
        return {
          title: 'New pitch!',
          body: `From ${notification.params.contractorName} for ${notification.params.causeName}`,
          // body: `You have received a pitch from ${notification.params.contractorName
          //   } for job ${isNoName ? '' : '"' + notification.params.causeName + '"'}`,
          icon: 'icon-mail5',
          color: 'blue',
          shortText: 'New pitch',
          // NOTE: Tab = 4 for pitch tab on brief page, for buyer.
          tab: 4,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 3: {
        return {
          title: "You've been invited to pitch",
          body: `By ${notification.params.ownerName} for ${notification.params.causeName}`,
          // body: `You've been invited by ${notification.params.ownerName
          //   } to submit a pitch for the job ${isNoName ? '' : '"' + notification.params.causeName + '"'
          //   }`,
          icon: 'icon-mail5',
          color: 'blue',
          shortText: 'New invite',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 4: {
        if (!notification.params.notificationText) {
          const result =
            notification.params.result === 2
              ? 'Waiting to approve'
              : notification.params.result === 1
                ? 'Accepted'
                : 'Declined'
  
          return {
            body: `The job ${isNoName ? '' : 'in "' + notification.params.causeName + '"'
              } has been updated. Status - ${result}.\n Click here to check it out.`,
            icon:
              notification.params.result === 2
                ? 'icon-stairs-down'
                : 'icon-stairs-up',
            color: (notification.params.result === 2 || notification.params.result === 1) ? 'success' : 'warning',
            shortText: 'Step changed',
            // NOTE: Tab = 5 for workroom tab on brief page.
            tab: 5,
            isRedirect: true
            // linkTo: `brief-show/${notification.linkId}`
          }
        } else {
          const result =
            notification.params.result === 2
              ? notification.params.notificationText.firstText
              : notification.params.result === 1
                ? notification.params.notificationText.firstText
                : notification.params.notificationText.secondText
  
          return {
            body: result,
            icon:
              notification.params.result === 2
                ? 'icon-stairs-up'
                : notification.params.result === 1
                  ? 'icon-stairs-up'
                  : 'icon-stairs-down',
            color: 'success',
            shortText: 'Step changed',
            // NOTE: Tab = 5 for workroom tab on brief page.
            tab: 5,
            isRedirect: true
            // linkTo: `brief-show/${notification.linkId}`
          }
        }
      }
  
      case 5: {
        const status =
          causeStatus[
          notification.params.causeStatus <= 4
            ? notification.params.causeStatus
            : 0
          ]
        if (status.value === 4 && status.name === "Canceled") {
          return {
            title: 'Success',
            body: `Your brief is canceled successfully`,
            icon: 'icon-stack-text',
            color: 'green-500',
            shortText: 'Status changed',
            isRedirect: true
          }
        } else {
          return {
            body: `The job ${isNoName ? '' : 'in "' + notification.params.causeName + '"'
              } has been updated. Status - ${status.name}.\n Click here to check it out.`,
            icon: 'icon-stack-text',
            color: 'orange',
            shortText: 'Status changed',
            linkTo: `brief-show/${notification.linkId}`,
            isRedirect: true
          }
        }
      }
  
      case 6: {
  
        return {
          body: `Job ${notification.params.causeName} has been marked as complete.`,
          icon: 'icon-file-check',
          color: 'orange',
          shortText: 'Item finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 7: {
  
        return {
          title: 'Pitch declined',
          body: `${notification.params.contractorName} has been notified that they were unsuccessful for this project.`,
          // body: `Your pitch has been unsuccessful for "${notification.params.causeName}", For feedback, please reach out to the client for more detail.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Pitch declined',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 8: {
        return {
          body: `Congratulations! Your pitch has been successfully awarded by ${notification.params.buyerName} for the job "${notification.params.causeName}". Click here to get started in the workroom`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Pitch won',
          // NOTE: Tab = 5 for workroom tab on brief page, for contractor.
          tab: 5,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 9: {
        return {
          body: `There is a new comment for the job "${notification.params.causeName}". Click here to view it.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New comment',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 10: {
        return {
          body: `This is an automatic Fabulate reminder of the "${notification.params.causeName}" brief.\n
        The content for "${notification.params.causeName}" is due in 24 hours.\n
        Please contact support@fabulate.com.au if you are unable to meet the deadline.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'Pitch reminder',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 11: {
        return {
          title: 'Brief started',
          body: 'Head to the workroom to keep track of your job.',
          // body: `This is an automatic Fabulate notification of “${notification.params.causeName}” brief.\n Welcome to the Workroom.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Intro to the workroom',
          tab: 5,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}?tab=workroom`
        }
      }
  
      case 12: {
        return {
          body: `This is an automatic Fabulate message.\n
        "${notification.params.causeName}" is ready for editing.
        Click to accept the job and start editing the "${notification.params.causeName}".`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New brief is ready to be picked up',
          linkTo: `brief-show/${notification.linkId}?tab=workroom`,
          isRedirect: true
        }
      }
  
      case 13: {
        return {
          title: 'New distribution job',
          body: `"${notification.params.buyerName}" has posted "${notification.params.causeName}" and selected your brand as part of their order. Click here to check it out.`,
          //   body: `This is an automatic Fabulate message.\n You have received a new distribution job request, "${notification.params.causeName}" from ${notification.params.buyerName}.
          // Click here to view the job request`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief is ready to be picked up',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 14: {
        return {
          title: 'Accepted publishing job',
          body: `"${notification.params.username}" has been notified that you have accepted this published job.\n\nContent is being created\nYou will be notified by email when the content is ready for you to review.`,
          //   body: `This is an automatic Fabulate message.\n You have accepted the job "${notification.params.causeName}" for distribution.
          // You will be notified again when content is ready to be published.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 15: {
        return {
          body: `This is an automatic Fabulate message.\n Unfortunately your selected distribution partner has declined to publish your content for "${notification.params.causeName}\n".
        The publisher has provided the following reason:\n
        "${notification.params.declineReason}" \n
        Contact support@fabulate.com.au for assistance in selecting another partner for publishing. \n
        Click to open`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Brief was declined',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 16: {
        return {
          linkTo: `amplify-brief-show/${notification.linkId}`,
          title: 'Distribution job posted',
          body: `You have requested distribution as part of your job, "${notification.params.causeName}".
        Your selected distribution partner has been notified and they will confirm their acceptance of your brief shortly."`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief is ready to be picked up',
          isRedirect: true
        }
      }
  
      case 17: {
        return {
          title: 'Publishing package confirmed',
          body: `By ${notification.params.amplifyOrganization} for ${notification.params.causeName}`,
          // body: `Your selected distribution partner has accepted your job ${notification.params.causeName}. When you content is finalised, it will automatically appear in the distribution workroom ready for next stages of publishing.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 18: {
        return {
          body: `Unfortunately your chosen distribution partner has declined to publish your content for "${notification.params.causeName}".\n
        The publisher has provided the below reason:\n
        "${notification.params.declineReason}"\n
        Contact support@fabulate.com.au for assistance in selecting another partner for publishing.\n
        Click to open`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Brief was declined',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 20: {
        return {
          body: `This is an automatic Fabulate message.\n A file has been uploaded to the distribution workroom for the job "${notification.params.causeName}".
        Click here to see it.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New Upload is ready in the distribution workroom',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 21: {
        return {
          body: `This is an automatic Fabulate message.\n A file has been uploaded to the distribution workroom for the job "${notification.params.causeName}".
        Click here to see it.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'New Upload is ready in the workroom',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 24: {
        return {
          body: `A distribution campaign for job "${notification.params.causeName}" has been marked as complete.
        If you are using Fabulate analytics, you can view the performance from your dashboard. Otherwise request a final report via the distribution workroom from the publisher. 
        Click here to return to the distribution workroom`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'A piece of content has been marked as finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 25: {
        return {
          body: `A distribution campaign for job "${notification.params.causeName}" has been marked as complete.
        If you are using Fabulate analytics, ypu can view the campaign performance from your dashboard. Otherwise request a final report from the publisher who will upload to the distribution workroom. 
        Click here to return to the distribution workroom.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'A piece of content has been marked as finished',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 26: {
        return {
          title: 'Content attached for distribution',
          body: `By "${notification.params.buyerName}" for "${notification.params.causeName}". Click here to view it in the distribution workroom.`,
        //   body: `The content for job "${notification.params.buyerCauseName}" is ready for distribution. It has automatically been attached to the distribution workroom.
        // Click here to get started.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 27: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          tab: 5,
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 28: {
        return {
          title: 'Content complete',
          body: `Your content by ${notification.params.creatorUserName} for "${notification.params.causeName}" is complete and has been uploaded into the distribution workroom`,
          // body: `This is an automatic Fabulate message.\n Your content is now ready for the job "${notification.params.causeName}" and it has been automatically attached to the distribution workroom.\n
          // Click here to get started.`,
          icon: 'icon-stack-text',
          color: 'orange',
          shortText: 'Your brief\'s pitch has been updated',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 30: {
        return {
          title: 'Success',
          body: 'You have posted a brief',
          // body: `You have successfully created the job "${notification.params.causeName}" and creators have been alerted. You will receive notifications when creators have pitched for your job.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Brief has been created',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 31: {
        return {
          body: `Your pitch has been removed for brief "${notification.params.causeName}"`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Pitch has been removed',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 32: {
        return {
          body: `"${notification.params.creatorName}" has withdrawn their pitch for the job "${notification.params.causeName}".`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Creator removed pitch',
          linkTo: `brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 33: {
        return {
          body: `You have cancelled the job "${notification.params.causeName}" and is no longer visible to creators.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Buyer canceled brief',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        };
      }
  
      case 34: {
        return {
          title: 'Distribution package confirmed',
          body: `By ${notification.params.amplifyOrganization} for ${notification.params.causeName}`,
          // body: `Your selected distribution partner has accepted your job ${notification.params.causeName}. When you content is finalised, it will automatically appear in the distribution workroom ready for next stages of publishing.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      } 
      
      case 35: {
        return {
          title: 'Accepted distribution job',
          body: `"${notification.params.buyerName}" has been notified that you have accepted this distribution job.\n\nContent is being created\n You will be notified by email when the content is ready for you to review.`,
        //   body: `This is an automatic Fabulate message.\n You have accepted the job "${notification.params.causeName}" for distribution.
        // You will be notified again when content is ready to be published.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 36: {
        return {
          title: 'Declined publishing job',
          body: 'You have declined this publishing job. Your feedback and reasoning has been passed onto the client.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 37: {
        return {
          title: 'Declined distribution job',
          body: 'You have declined this distribution job. Your feedback and reasoning has been passed on to the client.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 38: {
        return {
          title: 'Assets uploaded',
          body: `"${notification.params.username}" has uploaded assets to "${notification.params.causeName}". Click here to get started.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief was accepted',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      
      case 39: {
        return {
          title: 'Assets successfully uploaded',
          body: `The publisher has been notified of your uploaded assets. You will be notified by email when your test link is ready.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 40: {
        return {
          title: 'Test link submitted',
          body: 'Your test link has been sent to the client. You will be notified by email with any client feedback.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 41: {
        return {
          title: 'Test link uploaded',
          body: `${notification.params.username} has uploaded a test link for "${notification.params.causeName}". click here to review.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 42: {
        return {
          title: 'Test approved',
          body: `${notification.params.username} has approved your test link for "${notification.params.causeName}". Click here for next steps.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 43: {
        return {
          title: 'Test link approved',
          body: 'You have approved the test link. The publisher has been notified and is in the process of setting your content live. You will be notified by email when your content has been set live by the publisher.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 44: {
        return {
          title: 'Content job set live',
          body: 'Your content job has been set live. Don\'t forget to supply the client with a live link of the content on your site.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 45: {
        return {
          title: 'Campaign set live',
          body: 'Check with the publisher for a live link to see your content in all its glory.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
      case 46: {
        return {
          title: 'Success!',
          body: 'You have cancelled this brief',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 47: {
        return {
          title: 'Success!',
          body: 'You have deleted this brief',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: false
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 48: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have successfully made ${notification.params.grantedTo} the owner of "${notification.params.causeName}"`
          : `You have been made the owner of "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 49: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have successfully given ${notification.params.grantedTo} edit access for "${notification.params.causeName}"`
          : `You have been given edit access for "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 50: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have successfully given ${notification.params.grantedTo} read only access for "${notification.params.causeName}"`
          : `You have been given read only access for "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 51: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have removed ${notification.params.grantedTo} owner access for "${notification.params.causeName}"`
          : `You have been removed owner access for "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 52: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have removed ${notification.params.grantedTo} from having edit access for "${notification.params.causeName}"`
          : `You have been removed from having edit access for "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
  
          // body: `you have removed ${notification.params.buyerName} from having edit access for ${notification.params.causeName}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 53: {
        return {
          title: 'Updated user permissions',
          body: `${notification.params.entity && notification.params.entity === 'GRANTED_BY'
          ? `You have removed ${notification.params.grantedTo} from having read only access for "${notification.params.causeName}"`
          : `You have been removed from having read only access for "${notification.params.causeName}" by ${notification.params.grantedBy}`}`,
  
          // body: `you have removed ${notification.params.buyerName} from having read only access for ${notification.params.causeName}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: '',
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      // case 46: {
      //   return {
      //     title: 'Job marked as finished',
      //     body: 'You have indicated that this job has delivered in full. \n\nAll files and reports are still available for download via the distribution workroom.',
      //     icon: 'icon-stack-text',
      //     color: 'blue',
      //     shortText: '',
      //     linkTo: `amplify-brief-show/${notification.linkId}`
      //   }
      // }
  
      case 60: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: notification.params.color || 'success',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 61: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: notification.params.color || 'danger',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 62: {
        return {
          title: 'Job marked as finished',
          body: 'You have indicated that this job has delivered in full. \n\n All files and reports are still available for download via the distribution workroom.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 63: {
        return {
          title: 'Job marked as finished',
          body: 'You have indicated that this job has delivered in full. \n\n All files and reports are still available for download via the distribution workroom."',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 64: {
        return {
          title: 'Distribution package decline',
          body: `By ${notification.params.amplifyOrganization} for ${notification.params.causeName}\n\n${notification.params.declineReason}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 65: {
        return {
          title: 'Publishing Package declined',
          body: `By ${notification.params.amplifyOrganization} for ${notification.params.causeName}\n\n${notification.params.declineReason}`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 66: {
        return {
          title: 'New publishing job',
          body: `${notification.params.buyerName} has posted ${notification.params.causeName} and selected your brand as part of their order. \n\nClick here to check it out.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 67: {
        return {
          title: 'Declined publishing job',
          body: 'You have declined this publishing job. Your feedback and reasoning has been passed onto the client.',
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Now your content is ready',
          linkTo: `amplify-brief-show/${notification.linkId}`,
          isRedirect: true
        }
      }
  
      case 68: {
        return {
          linkTo: `amplify-brief-show/${notification.linkId}`,
          title: 'Publishing job posted',
          body: `You have requested distribution as part of your job, "${notification.params.causeName}".
        Your selected distribution partner has been notified and they will confirm their acceptance of your brief shortly."`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'New brief is ready to be picked up',
          isRedirect: true
        }
      }
  
      case 70: {
  
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: notification.params.color || 'success',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 71: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: notification.params.color || 'danger',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      case 72: {
        return {
          title: 'Success!',
          body: 'You have sent an invite to the selected creator.',
          icon: 'icon-mail5',
          color: 'blue',
          shortText: 'New invite',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 73: {
  
        return {
          title: 'Pitch declined',
          body: `Your pitch has been unsuccessful for "${notification.params.causeName}", For feedback, please reach out to the client for more detail.`,
          icon: 'icon-stack-text',
          color: 'blue',
          shortText: 'Pitch declined',
          // NOTE: Tab = 3 for pitch tab on brief page, for contractor.
          tab: 3,
          isRedirect: true
          // linkTo: `brief-show/${notification.linkId}`
        }
      }
  
      case 74: {
        return {
          body: `${notification.params.bodyText}`,
          icon: 'icon-stack-text',
          color: notification.params.color || 'danger',
          shortText: 'Now your content is ready',
          linkTo: `brief-show/${notification.linkId}`,
          tab: 5,
          isRedirect: true
        }
      }
  
      default: {
        return {}
      }
    }
  
  }

  return {}

}
