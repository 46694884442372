import { put, takeEvery } from 'redux-saga/effects'
import {select} from 'redux-saga/effects'

import _ from 'lodash'
import axios from 'axios'

import types from '../constants/workroom'
import * as actions from '../actions/workroom'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'
import { post as postAxios, get as getAxios } from 'store/api'

import { appAnalytics, EVENTS } from 'helpers/appAnalytics'
import backend from 'store/api/feathers'
export const apiUrl = `${process.env['REACT_APP_API_HOST']}`

function* getId(action) {
  try {
    const query = action.payload
    let workroom = yield backend.service('workroom').find({
      query
    })
    if(_.size(workroom)) {

      // const templateId = workroom.data && workroom.data[0]
      //   && workroom.data[0].workroomItems
      //   && workroom.data[0].workroomItems[Object.keys(workroom.data[0].workroomItems)[0]]
      //   && workroom.data[0].workroomItems[Object.keys(workroom.data[0].workroomItems)[0]].productTemplate
      //   && workroom.data[0].workroomItems[Object.keys(workroom.data[0].workroomItems)[0]].productTemplate.id

      // const templateData = yield backend.service(`template/${templateId}`).find()
      
      // if (_.size(templateData)) {
      //   let workroomData = workroom.data && workroom.data[0]
      //   workroomData.workroomItems[Object.keys(workroomData.workroomItems)[0]].productTemplate = templateData.data

      //   yield put(actions.getIdSuccess({ count: workroom.count, data: [workroomData]}))
      // } else {
      // }
      yield put(actions.getIdSuccess(workroom))
    }

  } catch (e) {
    renderErrorNotification(e, 'Get workroom error')
    yield put(actions.getIdFailure(e))
  }
}

function* updateWorkroom(action) {

  try {
    const { data } = action.payload

    const updatedWorkroom = yield backend.service('workroom/update').patch(data.id, {...data})

    if(_.size(updatedWorkroom)) {
      yield put(actions.updateSuccessWorkroom(updatedWorkroom))
    }

  } catch (e) {
    renderErrorNotification(e, 'Update workroom error')
    yield put(actions.updateFailure(e))
  }
}

function* update(action) {

  try {
    const { data, type, designSignedUrl, isAttachmentDelete} = action.payload
    const state = yield select();
    const { isGetIdFetching } = state.workroom
    let updateWorkItem = null
    if ((!type || type === 0) && (!isGetIdFetching)) {
      const _data = { ...data }
      const attachments = _data.attachments.filter(item => item.isFetching === false)
      _data.attachments = attachments
      if (_data.getyImageCount) {
        delete _data.getyImageCount
      }
      updateWorkItem = yield backend.service('article').patch(_data.id, {..._data})
      _data.status === 3 && appAnalytics.track(EVENTS.CONTENT_WORKROOM_COMPLETED, {articleName: _data.articleName})
    } else {
      if(!_.isEmpty(designSignedUrl)) {
        const imageBlob = yield axios.get(data.value, {
          responseType: 'blob'
        })

        var options = {
          method: 'PUT',
          url: designSignedUrl.signedUrl,
          data: imageBlob.data,
          headers: {
            'Content-Type': 'image/jpeg',
          },
          transformRequest: [(data, headers) => {
            delete headers.Authorization
            return data
          }],
        }

        yield axios(options)
      }

      const designData = {...data, currentImg: designSignedUrl.url}

      updateWorkItem = yield backend.service('design').patch(designData.id, {...designData})
    }
    if(_.size(updateWorkItem) && !isAttachmentDelete) {
      const _updateWorkItem = { ...updateWorkItem }
        // const attachmentList = [..._updateWorkItem.attachments]
        // // const uniqAttachments = attachmentList.filter(function({preview}) {
        // // return (!this[preview] && (this[preview] = preview))
        // // }, {})
        // const uniqAttachments = attachmentList.filter(function(item) {
        //   return item
        // }, {})
        // _updateWorkItem.attachments = uniqAttachments
        _updateWorkItem.attachments = data.attachments
        yield put(actions.updateSuccess(_updateWorkItem))
      }
   
  } catch (e) {
    renderErrorNotification(e, 'Update workroom error')
    yield put(actions.updateFailure(e))
  }
}

function* uploadFile(action) {

  const { params, productInfo, articleId, causeId, causeInfo, attachmentInfo } = action.payload
  const { tokenInstance } = params
  let formData = new FormData()
  if (params.isVideo) {
    // formData = new FormData()
    formData.append('file', params.uri)
  } else {

  let _uri = params.uri;
  if (params.fileName.includes('.csv')) {
    _uri = 'data:text/csv;base64,'  + _uri.split('base64,').pop();
  }

  // const formData = new FormData()
  formData.append('uri', _uri)
  formData.append('causeId', causeId)
  formData.append('fileName', params.fileName)
  }
  formData.append('id', params.fileName)

  const fileExtensionName = params.fileName.split('.').pop()

  try {
    const state = yield select();

    const isBuyer = state.auth && state.auth.user && state.auth.user.permissions && (state.auth.user.permissions === 2)
    let uploadData = null
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'File-Type': fileExtensionName,
      },
      cancelToken: tokenInstance[params.fileName].token,
      onUploadProgress: params.done
      // onUploadProgress: function(progressEvent) {
      //   done(progressEvent, params.fileName)
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   console.log('percentCompleted=====', percentCompleted)
      // }
    } 
    if (params.isVideo) {
      uploadData = yield postAxios('upload-stream', formData, config)
      uploadData.size = params.uri.size
      uploadData.uri = params.uri
    } else {
      uploadData = yield postAxios('uploads', formData, config)
    }
    uploadData.attachmentInfo = attachmentInfo

    if (_.size(uploadData)) {
      const duplicateData = isBuyer ? yield getAxios(`vault?storeId=media/${uploadData.id}`) : []

      yield put(actions.workroomClearUploadingApiCall(params.fileName))
      if (duplicateData.length) {
        yield put(actions.uploadFileSuccess({
          // ...uploadData,
          id: duplicateData[0].storeId,
          storeId: duplicateData[0].storeId,
          fieldType: params.fieldType,
          preview: params.preview,
          fileName: params.fileName,
          documentId: duplicateData[0].documentId,
          uploadedFromVault: true,
          isDuplicate: true,
          attachmentInfo: attachmentInfo
        }, productInfo, articleId))
      } else {
        yield put(actions.uploadFileSuccess({
          ...uploadData,
          fieldType: params.fieldType,
          preview: params.preview,
          fileName: params.fileName
        }, productInfo, articleId))
      }
      
      if (duplicateData.length) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `This file already exists. Uploaded ${action.payload.params.fileName} from vault`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      } else {
      new PNotify({
        addclass: 'select2-result-repository__title bg-info ',
        text: `${action.payload.params.fileName} uploaded`,
        buttons: {
          closer: true,
          sticker: true,
        },          
      })
    }

    yield backend.service('article/attachment').create({
      id: articleId, // attachment id
      attachment: duplicateData.length 
      ? ({
        // ...uploadData,
        id: duplicateData[0].storeId,
        storeId: duplicateData[0].storeId,
        fieldType: params.fieldType,
        preview: params.preview,
        mediaName: params.fileName,
        documentId: duplicateData[0].documentId,
        uploadedFromVault: true,
        isDuplicate: true,
        attachmentInfo: attachmentInfo,
        user: state.auth && state.auth.user && state.auth.user.id || ''
      })
      : (
      {
        fieldType: 0,
        isError: false,
        isFetching: false,
        mediaName: params.fileName,
        preview: params.preview,
        storeId: uploadData.id,
        attachmentInfo: attachmentInfo,
        user: state.auth && state.auth.user && state.auth.user.id || ''
      }
      
      ),
      causeId: causeId,
      causeName: causeInfo.causeName,
      // causeCreatedDate: ,

    })
    }
  } catch (e) {
    const msg = _.get(e, 'response.data.err', e.message)
    yield put(actions.workroomClearUploadingApiCall(params.fileName))
    if(msg === 'Field value too long') {
      renderErrorNotification({}, `${action.payload.params.fileName} size is too large`)
    } else if(msg === 'Cannot read property \'MISSING_COMMA\' of undefined') {
      renderErrorNotification({}, `${action.payload.params.fileName} size can't be zero`)
    } else {
      if (e.message !== undefined) {
        renderErrorNotification(e, 'Upload File error')
      }
    }
    yield put(actions.uploadFileFailure({
      error: e,
      preview: params.preview,
      fieldType: params.fieldType
    }, productInfo, articleId))
  }
}

function* uploadDesign(action) {
  try {
    const signedUrl = yield backend.service('design').find({
      query: {
        ...action.payload
      }
    })
    yield put(actions.uploadDesignSuccess(signedUrl))
  } catch(err) {
    renderErrorNotification({}, 'The error occured during the picture uploading. Please, contact support')
    yield put(actions.uploadDesignFailure(err))
  }
}
//NOTE: Generator function doesn't exports well
export const uploadImgTimymce = async ({ uri, causeId, fileName }) => {
  const formData = new FormData()
  formData.append('uri', uri)
  formData.append('causeId', causeId)
  formData.append('fileName', fileName)
  formData.append('id', fileName)
  try {
    return await backend.service('uploads').create(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      }
    })
  } catch (e) {
    renderErrorNotification(e, 'The error occured during the picture uploading. Please, contact support')
  }
}

function* downloadFile(action) {
  try {
    const id  = action.payload.id
    const aRef = action.payload.ref
    const downloadFileData = yield backend.service('download').get(id)

    if(downloadFileData.uri) {

      aRef.setAttribute('href', downloadFileData.uri)
      aRef.setAttribute('download', downloadFileData.id)
      aRef.click()

      yield put(actions.downloadFileSuccess())
    }

  } catch (e) {
    renderErrorNotification(e, 'Download file error')
    yield put(actions.downloadFileFailure(e))
  }
}

function* invitePerson(action) {
  try {
    yield backend.service('workroom-guest').create(action.payload)
    new PNotify({
      addclass: 'bg-info break-word',
      title: 'Invite sent',
      text: 'We\'ve successfully sent invite to email you choosed',
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },
    })
    yield put(actions.invitePersonSuccess())
  } catch (e) {
    switch(e.message) {
    case 'Validation error: emailNotValid': {
      return (
        renderErrorNotification({}, 'Sorry, the email is incorrect')
      )
    }
    default: {
      renderErrorNotification({}, 'Somenting went wrong')
    }
    }
    yield put(actions.invitePersonFailure())
  }
}

function* skipSteps(action) {
  try {
    const data = action.payload
    const updateWorkItem = yield backend.service('article').patch(data.id, {...data})

    if(updateWorkItem) {
      yield put(actions.skipStepsSuccess(updateWorkItem))
      data.status === 3 && appAnalytics.track(EVENTS.CONTENT_WORKROOM_COMPLETED, {articleName: data.articleName})
    }
  } catch(e) {
    yield actions.skipStepsFailure(e)
  }
}

function* workroomDeleteFile(action) {

  try {
    const { id, done } = action.payload
    if (id) {
      const deleteFileData = yield backend.service('delete-file').create({ id })
      if (_.size(deleteFileData)) {
        new PNotify({
          addclass: 'bg-info custom',
          text: `${id} has been deleted`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
        done(undefined, true)
        yield put(actions.workroomDeleteFileSuccess(deleteFileData))
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Remove file error')
    yield put(actions.workroomDeleteFileFailure(e))
  }

}

function* setGettyImageDownloadCount(action) {
  try {
    const data = yield backend.service('article/gettyImagecount').create(action.payload)
    if (_.size(data)) {
      yield put(actions.setGettyImageDownloadCountSuccess(data))
    }
  } catch (e) {
    yield put(actions.setGettyImageDownloadCountFailure(e))
  }
}


function* getGettyImageDownloadCount(action) {
  try {
    const { id, cb } = action.payload
    const data = yield backend.service(`article/gettyImageCount/${id}`).find()
    if (_.size(data)) {

      yield put(actions.getGettyImageDownloadCountSuccess(data))
      cb && cb(data)
    }
  } catch (e) {
    yield put(actions.getGettyImageDownloadCountFailure(e))
  }
}


function* workroomSaga() {
  yield takeEvery(types.WORKROOM_GET_ID, getId)
  yield takeEvery(types.WORKROOM_UPDATE, update)
  yield takeEvery(types.UPLOAD, uploadFile)
  yield takeEvery(types.UPLOAD_DESIGN, uploadDesign)
  yield takeEvery(types.DOWNLOAD_FILE, downloadFile)
  yield takeEvery(types.INVITE_PERSON, invitePerson)
  yield takeEvery(types.SKIP_STEPS, skipSteps)
  yield takeEvery(types.UPDATE_WORKROOM_ENTITY, updateWorkroom)
  yield takeEvery(types.WORKROOM_DELETE_FILE, workroomDeleteFile)
  yield takeEvery(types.SET_GETTY_IMAGE_DOWNLOAD_COUNT, setGettyImageDownloadCount)
  yield takeEvery(types.GET_GETTY_IMAGE_DOWNLOAD_COUNT, getGettyImageDownloadCount)
}

export default {
  workroomSaga
}

