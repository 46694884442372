import React, { useMemo, Fragment, memo } from 'react'

import _ from 'lodash'

import { MessageNotification } from './MessageNotification'
import { DefaultNotification } from './DefaultNotification'

export const NotificationList = memo(props => {
  const { notificationList, currentUser, onDelete, onRead } = props

  const isEmptyList = useMemo(() => _.isEmpty(notificationList), [
    notificationList
  ])

  return (
    <Fragment>
      {isEmptyList ? (
        <li className="text-center">
          <span>No notifications yet</span>
        </li>
      ) : (
        _.map(notificationList, (notificationItem, index) => {
          const Notification =
            notificationItem.type === 'message'
              ? MessageNotification
              : DefaultNotification
          const key = notificationItem.id || notificationItem.senderId + index
          return (
            <Notification key={key} {...notificationItem} currentUser={currentUser} onDelete={onDelete} onRead={onRead} />
          )
        })
      )}
    </Fragment>
  )
})
