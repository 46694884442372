import { put, takeEvery, select } from 'redux-saga/effects'

import _ from 'lodash'
import classNames from 'classnames'

import types from '../constants/causeUser'
import * as actions from '../actions/causeUser'
import * as userActions from '../actions/user'
import * as causeActions from '../actions/cause'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend from 'store/api/feathers'
import { appAnalytics, EVENTS } from 'helpers/appAnalytics'

function* get(action) {
  try {
    const queryParams = action.payload.queryParams
    const page = action.payload.page
    const causeId = queryParams ? queryParams.causeId : null

    const pitchList = yield backend.service('causeUser').find({
      query: {
        page,
        ...queryParams,
      },
    })
    
    yield put(actions.getSuccess(pitchList, causeId, page))
  } catch (e) {
    renderErrorNotification(e, 'Get pitches list error')
    yield put(actions.getFailure(e))
  }
}

function* getId(action) {
  try {
    const causeUser = yield backend.service('causeUser').get(action.payload)
    if (_.size(causeUser.data)) {
      yield put(actions.getIdSuccess(causeUser))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get pitch error')
    yield put(actions.getIdFailure(e))
  }
}

function* create(action) {
  try {
    const causeUserCreate = yield backend.service('causeUser').create(
      action.payload.data,
      {
        query: {
          ...action.payload.query
        }
      }
    )

    if (_.size(causeUserCreate)) {
      const store = yield select()
      const contractor = store.user.contractor[causeUserCreate.causeUser.contractorId]
      const cause = causeUserCreate.cause
      if (_.size(cause)) {
        yield put(causeActions.getIdSuccess({ ...cause }))
      }

      if (contractor) {
        contractor.causeUserContractor = [{
          ...causeUserCreate.causeUser
        }]
        yield put(userActions.update({ ...contractor }, false))
      }

      yield put(actions.createSuccess(causeUserCreate.causeUser))
      const { causeUserStatus } =  action.payload.data

      const causeUserStatusIsInvite = _.isEqual(causeUserStatus, 0)

      if (!causeUserStatusIsInvite) {
        new PNotify({
          addclass: classNames('bg-success alert alert-styled-left', {
            'post-pitch-success': !causeUserStatusIsInvite,
          }),
          title: 'Pitch submitted',
          text: 'Client has been notified of your idea. You will receive an email regarding next steps.',
          hide: true,
          buttons: {
            closer: true,
            sticker: true,
          },
          icon: 'icon-checkmark3',
        })
        appAnalytics.track(EVENTS.PITCH_POST_SUCCESS, {causeId: cause.data.id})
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Create pitch error')
    yield put(actions.createFailure(e))
  }
}

function* update(action) {
  try {
    const data = action.payload.data
    const causeUserUpdate = yield backend.service('causeUser').patch(data.id, data)
    const pnotifyText = action.payload.pnotifyText

    if (_.size(causeUserUpdate)) {
      yield put(actions.updateSuccess(causeUserUpdate))

      if (pnotifyText) {
        new PNotify({
          addclass: 'bg-success',
          title: 'Success!',
          text: pnotifyText,
          hide: true,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Update pitch error')
    yield put(actions.updateFailure(e))
  }
}

function* remove(action) {
  try {
    const causeUserRemove = yield backend.service('causeUser').remove(action.payload.id)

    if (_.size(causeUserRemove)) {
      const contractor = action.payload.user
      const cause = causeUserRemove.data

      if(contractor) {
        delete contractor.causeUserContractor
        yield put(userActions.update({ ...contractor }, false))
      }

      if (_.size(cause)) {
        yield put(causeActions.getIdSuccess(cause))
      }

      yield put(actions.removeSuccess(causeUserRemove))
    }
  } catch (e) {
    renderErrorNotification(e, 'Remove pitch error')
    yield put(actions.removeFailure(e))
  }
}

function* findPitch(action) {
  try {
    const causeUser = yield backend.service('causeUser').find({
      query: action.payload
    })
    if (causeUser.data) {
      yield put(actions.findPitchSuccess(causeUser.data[0]))
    }
  } catch(e) {
    yield put(actions.findPitchFailure(e))
  }
}


function* causeUserSaga() {
  yield takeEvery(types.GET, get)
  yield takeEvery(types.GET_ID, getId)
  yield takeEvery(types.CREATE, create)
  yield takeEvery(types.REMOVE, remove)
  yield takeEvery(types.UPDATE, update)
  yield takeEvery(types.FIND_CAUSE_USER, findPitch)
}

export default {
  causeUserSaga,
}
