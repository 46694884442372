import types from '../constants/analytics'

const fetchingStates = {
  isGetFetching: true,
  error: null
}

const initialState = {
  australiaData: {},
  australiaCityData: {},
  genderAgeData: {
    ...fetchingStates
  },
  graphData: {
    ...fetchingStates,
    data: {
      sumAll: 0,
    }
  },
  geographicWorldData: {
    ...fetchingStates
  },
  deviceData: {
    ...fetchingStates
  },
  locationData: {
    ...fetchingStates
  },
  timeOnPageData: {
    ...fetchingStates
  },
  countUniqueUsersData: {
    ...fetchingStates
  },
  surveyData: {
    ...fetchingStates,
    buy: null,
    learnMore: null,
    share: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET_GENDER_AGE_DATA:
  {
    return {
      ...state,
      genderAgeData: {
        ...state.genderAgeData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_GENDER_AGE_DATA_SUCCESS: 
  {
    return {
      ...state,
      genderAgeData: {
        ...state.genderAgeData,
        data: action.payload,
        isGetFetching: false,
      },
    }
  }


  case types.GET_GRAPH_DATA: {
    return {
      ...state,
      graphData: {
        ...state.graphData,
        isGetFetching: true,
        error: null
      },
      locationData: {
        ...state.locationData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_GRAPH_DATA_SUCCESS:
  {
    return {
      ...state,
      graphData: {
        ...state.graphData,
        data: action.payload,
        isGetFetching: false
      },
    }
  }

  case types.GET_GEOGRAPHIC_WORLD_DATA:
  {
    return {
      ...state,
      geographicWorldData: {
        ...state.geographicWorldData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_GEOGRAPHIC_WORLD_DATA_SUCCESS:
  {
    return {
      ...state,
      geographicWorldData: {
        ...state.geographicWorldData,
        isGetFetching: false,
        data: action.payload
      }
    }
  }

  case types.GET_DEVICE_DATA: 
  {
    return {
      ...state,
      deviceData: {
        ...state.deviceData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_COUNT_UNIQUE_USERS_DATA:
  {
    return {
      ...state,
      countUniqueUsersData: {
        ...state.countUniqueUsersData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_TIME_ON_PAGE_DATA:
  {
    return {
      ...state,
      timeOnPageData: {
        ...state.timeOnPageData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_DEVICE_DATA_SUCCESS:
  {
    return {
      ...state,
      deviceData: {
        ...state.deviceData,
        data: action.payload,
        isGetFetching: false,
        error: null
      }
    }
  }

  case types.SET_LOCATION_DATA:
  {
    return {
      ...state,
      locationData: {
        ...state.locationData,
        data: action.payload,
        isGetFetching: false,
        error: null
      }
    }
  }

  case types.GET_TIME_ON_PAGE_DATA_SUCCESS:
  {
    return {
      ...state,
      timeOnPageData: {
        ...state.timeOnPageData,
        data: action.payload,
        isGetFetching: false,
        error: null
      }
    }
  }

  case types.GET_COUNT_UNIQUE_USERS_DATA_SUCCESS:
  {
    return {
      ...state,
      countUniqueUsersData: {
        ...state.countUniqueUsersData,
        data: action.payload,
        isGetFetching: false,
        error: null
      }
    }
  }

  case types.GET_COUNT_UNIQUE_USERS_DATA_FAILURE: {
    return {
      ...state,
      countUniqueUsersData: {
        ...state.countUniqueUsersData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }

  case types.GET_DEVICE_TIME_ON_PAGE_DATA_FAILURE:
  {
    return {
      ...state,
      timeOnPageData: {
        ...state.timeOnPageData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }



  case types.GET_DEVICE_DATA_FAILURE:
  {
    return {
      ...state,
      deviceData: {
        ...state.deviceData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }

  case types.GET_GEOGRAPHIC_WORLD_DATA_FAILURE:
  {
    return {
      ...state,
      geographicWorldData: {
        ...state.geographicWorldData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }

  case types.GET_GENDER_AGE_DATA_FAILURE:
  {
    return {
      ...state,
      genderAgeData: {
        ...state.genderAgeData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }

  
  case types.GET_GRAPH_DATA_FAILURE:
  {
    return {
      ...state,
      graphData: {
        ...state.graphData,
        isGetFetching: false,
        error: action.payload
      },
      locationData: {
        ...state.locationData,
        isGetFetching: false,
        error: action.payload
      }
    }
  }

  case types.GET_BUY_SURVEY_DATA:
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_BUY_SURVEY_DATA_SUCCESS: 
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        buy: action.payload,
        isGetFetching: false,
      },
    }
  }

  case types.GET_LEARN_MORE_SURVEY_DATA:
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_LEARN_MORE_SURVEY_DATA_SUCCESS: 
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        learnMore: action.payload,
        isGetFetching: false,
      },
    }
  }

  case types.GET_SHARE_SURVEY_DATA:
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        isGetFetching: true,
        error: null
      }
    }
  }

  case types.GET_SHARE_SURVEY_DATA_SUCCESS: 
  {
    return {
      ...state,
      surveyData: {
        ...state.surveyData,
        share: action.payload,
        isGetFetching: false,
      },
    }
  }

  case types.GET_BUY_SURVEY_DATA_FAILURE:
    {
      return {
        ...state,
        surveyData: {
          ...state.surveyData,
          isGetFetching: false,
          error: action.payload
        }
      }
    }

  case types.GET_LEARN_MORE_SURVEY_DATA_FAILURE:
    {
      return {
        ...state,
        surveyData: {
          ...state.surveyData,
          isGetFetching: false,
          error: action.payload
        }
      }
    }

    case types.GET_SHARE_SURVEY_DATA_FAILURE:
      {
        return {
          ...state,
          surveyData: {
            ...state.surveyData,
            isGetFetching: false,
            error: action.payload
          }
        }
      }

  default:
    return state
  }
}