import types from '../constants/gettyApi'
import _ from 'lodash'

const initialState = {
  isFetching: false,
  foundPictures: [],
  imageLimit: '',
}

export default (state = initialState, action) => {

  switch (action.type) {
  case types.SEARCH_GETTY_IMAGE:
  {
    return {
      ...state,
      imageName: action.payload,
      foundPictures: [],
      isFetching: true
    }
  }

  case types.SEARCH_GETTY_IMAGE_SUCCESS:
  {
    return {
      ...state,
      foundPictures: action.payload,
      isFetching: false,
    }
  }

  case types.DOWNLOAD_GETTY_IMAGE:
  {
    return {
      ...state,
      isFetching: true,
    }
  }

  case types.DOWNLOAD_GETTY_IMAGE_SUCCESS:
  { 
    const images = _.keyBy(action.payload, 'id')

    return {
      ...state,
      isFetching: false,
      uploadedToS3: images
    }
  }

  case types.DOWNLOAD_GETTY_IMAGE_FAILURE:
  {
    return {
      ...state,
      isFetching: false,
    }
  }


  case types.SEARCH_GETTY_IMAGE_FAILURE:
  {
    return {
      ...state,
      isFetching: false,
    }
  }

  case types.CLEAR_GETTY_DATA:
  {
    return {
      ...state,
      foundPictures: [],
      isFetching: false,
    }
  }

  case types.SET_IMAGE_LIMIT:
  {
    return {
      ...state,
      imageLimit: action.payload,
    }
  }

  case types.SAVE_GETTY_LIMIT:
  { 
    return {
      ...state,
      imageLimit: action.payload,
    }
  }

  default:
    return state
  }
}
