import types from '../constants/auth'

export const signIn = (email, password, accessToken = null) => {

  const authorization = !accessToken ? {
    strategy: 'local',
    email,
    password,
  } : {
    strategy: 'jwt',
    accessToken,
  }

  return {
    type: types.SIGNIN,
    payload: authorization,
  }
}

export const signInSuccess = (accessToken) => {

  return {
    type: types.SIGNIN_SUCCESS,
    payload: accessToken,
  }

}

export const getUserSuccess = (user) => {

  return {
    type: types.USER_GET_SUCCESS,
    payload: user
  }

}

export const signInFailure = (error) => {

  return {
    type: types.SIGNIN_FAILURE,
    payload: error,
  }

}

export const signOut = () => {

  return {
    type: types.SIGNOUT,
    payload: null,
  }

}

export const signOutSuccess = () => {

  return {
    type: types.SIGNOUT_SUCCESS,
    payload: null,
  }

}

export const signOutFailure = (error) => {

  return {
    type: types.SIGNOUT_FAILURE,
    payload: error,
  }

}

export const resetPassword = email => ({
  type: types.RESET_PASSWORD,
  payload: email,
})

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload: null,
})

export const resetPasswordFailure = error => ({
  type: types.RESET_PASSWORD_FAILURE,
  payload: error,
})

export const updatePassword = (id, password) => ({
  type: types.UPDATE_PASSWORD,
  payload: { id, password }
})

export const updatePasswordSuccess = (data) => {

  return({
    type: types.UPDATE_PASSWORD_SUCCESS,
    payload: data,
  })

}

export const updatePasswordFailure = error => ({
  type: types.UPDATE_PASSWORD_FAILURE,
  payload: error,
})



export const signUp = (newUser) => {
  return {
    type: types.SIGNUP,
    payload: {...newUser},
  }
}

export const signUpSuccess = () => {
  return {
    type: types.SIGNUP_SUCCESS,
    payload: null
  }
}

export const signUpFailure = (error) => {
  return {
    type: types.SIGNUP_FAILURE,
    payload: error,
  }
}

export const verifyUserMail = (id) => {
  return {
    type: types.VERIFY_USER_BY_EMAIL,
    payload: { id },
  }
}

export const verifyUserMailSuccess = () => {
  return {
    type: types.VERIFY_USER_BY_EMAIL_SUCCESS,
    payload: null
  }
}

export const verifyUserMailFailure = (error) => {
  return {
    type: types.VERIFY_USER_BY_EMAIL_FAILURE,
    payload: error,
  }
}

export const initEdit = () => {
  return {
    type: types.INIT_USER_EDIT,
  }
}

export const edit = (data) => {
  return {
    type: types.USER_EDIT,
    payload: data,    
  }
}

export const resetEdit = () => ({
  type: types.RESET_USER_EDIT,
  payload: null,
})

export const updateUserInfo = (data) => {
  return {
    type: types.USER_INFO_UPDATE,
    payload: data,    
  }
}

export const updateUserInfoSuccess = (data) => {
  return {
    type: types.USER_INFO_UPDATE_SUCCESS,
    payload: data,    
  }
}

export const updateUserInfoFailure = (error) => {
  return {
    type: types.USER_INFO_UPDATE_FAILURE,
    payload: error,    
  }
}

export const uploadUserPhoto = (url) => {
  return {
    type: types.USER_UPLOAD_PHOTO,
    payload: { url },
  }

}

export const uploadUserPhotoSuccess = (data) => {

  return {
    type: types.USER_UPLOAD_PHOTO_SUCCESS,
    payload: { ...data },
  }

}

export const uploadUserPhotoFailure = (error) => {

  return {
    type: types.USER_UPLOAD_PHOTO_FAILURE,
    payload: error,
  }

}

export const updateUserPassword = (data) => {
  return {
    type: types.USER_UPDATE_PASSWORD,
    payload: data,
  }

}

export const updateUserPasswordSuccess = () => {

  return {
    type: types.USER_UPDATE_PASSWORD_SUCCESS,
  }

}

export const updateUserPasswordFailure = (error) => {

  return {
    type: types.USER_UPDATE_PASSWORD_FAILURE,
    payload: error,
  }

}

export const loginAsAdmin = (adminAccessKey) => ({
  type: types.USER_LOGIN_AS_ADMIN,
  payload: adminAccessKey,
})

