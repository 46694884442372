import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import types from '../constants/account'
import * as actions from '../actions/account'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'

import backend from 'store/api/feathers'


function* get(action) {
  try {
    const queryParams = action.payload.queryParams
    const page = action.payload.queryParams.page

    const query = {
      ...queryParams,
        page,
    }
    const accountData = yield backend.service('account').find({
      query
    })
    if(_.size(accountData.data)) {
      yield put(actions.getSuccess(accountData))
    }
  } catch (e) {
    renderErrorNotification({}, 'Contact to Fabulate Support')
    yield put(actions.getFailure(e))
  }
}

function* updateWorkspace(action) {
  try {
    const workspace = action.payload.workspace
    const workspaceData = yield backend.service('workspace').patch(
      workspace.id,
      {
        ...workspace
      }
    )
    if(_.size(workspaceData)) {
      yield put(actions.updateWorkspaceSuccess(workspaceData))

      new PNotify({
        addclass: 'bg-success alert alert-styled-left',
        title: '',
        text: 'Your account details had been updated success!',
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },
      })
    }
    
  } catch (e) {
    renderErrorNotification(e, 'Update workspace data error')
    yield put(actions.updateWorkspaceFailure(e))
  }
}

function* downloadInvoice(action) {
  try {
    const invoiceLink = yield backend.service('download-xero').create(action.payload)
    if (_.size(invoiceLink)) {
      yield put(actions.downloadInvoiceSuccess(invoiceLink))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get account data error')
    yield put(actions.downloadInvoiceFailure(e))
  }
}

function* accountSaga() {
  yield takeEvery(types.GET, get)
  yield takeEvery(types.UPDATE_WORKSPACE, updateWorkspace)
  yield takeEvery(types.DOWNLOAD_INVOICE, downloadInvoice)
}

export default {
  accountSaga,
}
