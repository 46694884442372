import React, { Component, Suspense, lazy } from 'react'

import 'utils/jquery'

import 'font-awesome/css/font-awesome.css'
import 'normalize.css'
import 'assets/css/roboto.css'
import 'assets/css/sfpro.css'
import 'assets/css/icons/icomoon/styles.css'
import 'assets/css/bootstrap.css'
import 'assets/css/core.css'
import 'assets/css/components.css'
import 'assets/css/colors.css'
import 'assets/css/app.css'
import 'assets/css/main/main.css'
import 'assets/css/main/mediaquery.css'

import ripple from 'limitless/ripple'
import { roles } from 'helpers/params'

import AppLayout from 'components/layout/App'
import LoginLayout from 'components/layout/Login'
import SpinnerLoadData from 'components/ui/SpinnerLoadData'
import _ from 'lodash'

// import initHelpHero from 'helphero'

import LegalModal from './LegalModal'

const LoggedApp = lazy(() => import('components/routing/LoggedApp'))
const NotLoggedApp = lazy(() => import('components/routing/NotLoggedApp'))

// const helphero = process.env['REACT_APP_HELPHERO'] ? initHelpHero(process.env['REACT_APP_HELPHERO']) : null

class App extends Component {
  componentDidMount() {
    const { currentUser, productActions } = this.props
    ripple.init()

    if (currentUser) {
      productActions.get({})
    }

    // if (currentUser) {
    //   const roleName = roles[currentUser.permissions]
    //   helphero && helphero.identify(currentUser.id, {
    //     name: currentUser.email,
    //     role: roleName ? roleName.name : 'Guest'
    //   })
    //   if (currentUser.permissions === 3) {
    //     this.insertZendesk()
    //   }
    //   productActions.get({})
    // } else {
    //   helphero && helphero.anonymous()
    // }
  }

  componentDidUpdate() {
    ripple.init()
  }

  // insertZendesk = () => {
  //   const { REACT_APP_ZENDISK_KEY } = process.env
  //   const zendesk = document.createElement('script')
  //   zendesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${REACT_APP_ZENDISK_KEY}`
  //   zendesk.id = 'ze-snippet'
  //   document.head.appendChild(zendesk)
  // }

  render() {
    const {
      accessToken,
      loggedUser,
      currentUser,
      history,
      chatActions,
      notificationList,
      notificationActions,
      messagesCount,
      location,
      hasProducts,
      currentWorkspace,
      routerActions
    } = this.props
    
    const isLogged = accessToken && loggedUser
    const shouldZendiskBeHidden = !_.includes(location.pathname, '/brief-show/')

    return (
      <Suspense fallback={<SpinnerLoadData />}>
        {isLogged ? (
          <AppLayout
            currentWorkspace={currentWorkspace}
            currentUser={currentUser}
            history={history}
            chatActions={chatActions}
            notificationList={notificationList}
            notificationActions={notificationActions}
            messagesCount={messagesCount}
            hasProducts={hasProducts}
            routerActions={routerActions}
          >
            {shouldZendiskBeHidden &&
              window.zE &&
              window.zE.hide &&
              window.zE.hide()}
            <LegalModal />
            <LoggedApp />
          </AppLayout>
        ) : (
          <LoginLayout>
            <NotLoggedApp />
          </LoginLayout>
        )}
      </Suspense>
    )
  }
}

export default App
