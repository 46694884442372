import { put, takeEvery } from 'redux-saga/effects'

import types from '../constants/analytic'
import * as actions from '../actions/analytic'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* download(action) {
  try {
    const analytics = yield backend.service('analytics-download').create({...action.payload})

    const file = Buffer.from(analytics.data)
    const blob = new Blob([file], {type: ''})

    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.download = 'script_tag.xlsx'
    anchor.click()
    window.URL.revokeObjectURL(url)

    yield put(actions.downloadSuccess())
  } catch (e) {
    renderErrorNotification(e, 'Download error')
    yield put(actions.downloadFailure())
  }
}

function* analyticSaga() {
  yield takeEvery(types.DOWNLOAD, download)
}

export default {
  analyticSaga,
}