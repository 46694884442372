import { put, takeEvery } from 'redux-saga/effects'
// import _ from 'lodash'

import types from '../constants/vaultFolder'
import * as actions from '../actions/vaultFolder'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend, { apiUrl } from 'store/api/feathers'
import { all } from 'tinymce'

// import { removeMultiItems } from 'store/api'

function* getFolderList(action) {
  try {
      
      const { query } = action.payload
      const data = yield backend.service('folder').find({ query })
    yield put(
      actions.getFolderSuccess({
        folderList: data,
      })
    )
  } catch (e) {
    renderErrorNotification(e, 'Get folder list error')
    yield put(actions.getFolderFailure(e))
  }
}

function* getAttachmentList(action) {
  try {
    const { id } = action.payload
    const { rows } = yield backend.service('folder').get(id)
    yield put(
      actions.getAttachmentsSuccess({
        attachmentList: rows,
      })
    )
  } catch (e) {
    renderErrorNotification(e, 'Get attachment list error')
    yield put(actions.getAttachmentsFailure(e))
  }
}

function* checkFolderExist(action) {
  try {
    const { folderName } = action.payload
    const { isAvailable } = yield backend.service('folder').create({
      folderName
    })
    yield put(
      actions.checkFolderExistSuccess({
        folderExists: isAvailable
      })
    )
  } catch (e) {
    renderErrorNotification(e, 'Check folder exist error')
    yield put(actions.checkFolderExistFailure(e))
  }
}

function* createFolder(action) {
  try {
    const { listing, folderName } = action.payload
    const data = yield backend.service('vault').create(listing)
    yield put(
      actions.createFolderSuccess({
        createFolder: data,
      })
    )
    new PNotify({
      addclass: 'bg-success',
      title: '',
      text: `${listing.length} ${listing.length > 1 ? 'files' : 'file'} has been successfully uploaded in ${folderName}`,
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },
    })
  } catch (e) {
    renderErrorNotification(e, 'Get folder list error')
    yield put(actions.createFolderFailure(e))
  }
}

function* vaultFolderSaga() {
  yield takeEvery(types.GET_FOLDER, getFolderList)
  yield takeEvery(types.GET_ATTACHMENTS, getAttachmentList)
  yield takeEvery(types.FOLDER_EXIST, checkFolderExist)
  yield takeEvery(types.CREATE_FOLDER, createFolder)
}

export default {
  vaultFolderSaga,
}
