import $ from 'utils/jquery'

import 'legit-ripple/dist/ripple.min.js'

const defaultSettings = {
  dragging: false,
  adaptPos: false,
  scaleMode: false,
}

const rippleEffect = () => {

  // Ripple effect
  $('.btn:not(.disabled):not(.multiselect.btn-default):not(.bootstrap-select .btn-default):not(.file-drag-handle)')
    .ripple(defaultSettings)

  $('.navigation li:not(.disabled) a').ripple(defaultSettings)

  $('.nav > li:not(.disabled) > a').ripple(defaultSettings)

  $('.sidebar-user-material-menu > a').ripple(defaultSettings)

  $('.sidebar-user-material-content > a').ripple(defaultSettings)

  $('.select2-selection--single[class*=bg-]').ripple(defaultSettings)

  $('.breadcrumb-elements > li:not(.disabled) > a').ripple(defaultSettings)

  $('.wizard > .actions a').ripple(defaultSettings)

  $('.ui-button:not(.ui-dialog-titlebar-close)').ripple(defaultSettings)

  $('.ui-tabs-anchor:not(.ui-state-disabled)').ripple(defaultSettings)

  $('.plupload_button:not(.plupload_disabled)').ripple(defaultSettings)

  $('.fc-button').ripple(defaultSettings)

  $('.pagination > li:not(.disabled) > a').ripple(defaultSettings)

  $('.pagination > li:not(.disabled) > span').ripple(defaultSettings)

  $('.pager > li:not(.disabled) > a').ripple(defaultSettings)

  $('.pager > li:not(.disabled) > span').ripple(defaultSettings)

  // Unbind ripple in Datepaginator
  $('.dp-item, .dp-nav, .sidebar-xs .sidebar-main .navigation > li > a')
    .ripple({
      unbind: true
    })

}

export default {

  init: () => {

    rippleEffect()

  }

}
