import { initialize, addTranslationForLanguage } from 'react-localize-redux'
import { store } from 'store/store'

import en from './translations/en'
import ru from './translations/ru'

const languages = ['en', 'ru']

store.dispatch(initialize(languages, { defaultLanguage: 'en' }))

store.dispatch(addTranslationForLanguage(en, 'en'))

store.dispatch(addTranslationForLanguage(ru, 'ru'))
