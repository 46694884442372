export default {

  NOTIFICATION_GET: 'SERVER:NOTIFICATION_GET',
  NOTIFICATION_GET_SUCCESS: 'NOTIFICATION_GET_SUCCESS',
  NOTIFICATION_GET_FAILURE: 'NOTIFICATION_GET_FAILURE',

  NOTIFICATION_UPDATE: 'SERVER:NOTIFICATION_UPDATE',
  NOTIFICATION_UPDATE_SUCCESS: 'NOTIFICATION_UPDATE_SUCCESS',
  NOTIFICATION_UPDATE_FAILURE: 'NOTIFICATION_UPDATE_FAILURE',

  NOTIFICATION_REMOVE: 'NOTIFICATION_REMOVE',
  NOTIFICATION_REMOVE_SUCCESS: 'NOTIFICATION_REMOVE_SUCCESS',
  NOTIFICATION_REMOVE_FAILURE: 'NOTIFICATION_REMOVE_FAILURE',

  NOTIFICATION_SET_PAGE: 'NOTIFICATION_SET_PAGE'
}
