import React, { Component } from 'react'

class Logout extends Component {

  render() {

    let { authActions } = this.props

    return (
      <a
        onClick={authActions.signOut} 
        href="https://www.fabulate.com.au/"
        // target="_blank"
      >
        Logout
      </a>
    )

  }

}

export default Logout
