import React, { PureComponent } from 'react'
import $ from 'utils/jquery'

class Modal extends PureComponent {

  static close() {

    $('.modal').modal('hide')

  }

  static showModal(modalId, backdrop = 'static') {

    $('#' + modalId).modal({
      backdrop,
      keyboard: false,
    })

  }

  render() {
    const { classNameModalDialog, dialogRef, disableBackdrop, idModal, children } = this.props
    return (
      <div id={idModal} className="modal fade" tabIndex="-1" data-backdrop={disableBackdrop ? 'false' : 'true'}>
        <div className={`modal-dialog modal-lg ${classNameModalDialog}`} ref={dialogRef} >
          {children}
        </div>
      </div>
    )
  }
}

Modal.defaultProps = {
  classNameModalDialog: '',
  dialogRef: null,
}

export default Modal
