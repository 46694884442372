import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'
import backend from 'store/api/feathers'

import { renderErrorNotification } from 'helpers/tools'
import types from '../constants/workspace'
import * as actions from '../actions/workspace'

function* get(action) {
  try {
    const { page } = action.payload
    const workspaceList = yield backend.service('workspace').find({
      query: {
        ...action.payload
      }
    })

    if (_.size(workspaceList)) {
      yield put(actions.getSuccess(workspaceList, page))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get organizations list error')
    yield put(actions.getFailure(e))
  }
}

function* getAmplify(action) {
  try {
    const { publishingPage, distributionPage } = action.payload
    const { publishing, distribution } = yield backend.service('amplify-workspace').find({
      query: {
        ...action.payload,
      }
    })

    if (_.size(publishing)) {
      yield put(actions.getPublishingSuccess({
        ...publishing,
        publishingPage
      }))
    }
    if (_.size(distribution)) {
      yield put(actions.getDistributionSuccess({
        ...distribution,
        distributionPage
      }))
    }
  } catch (e) {
    yield put (action.getAmplifyFailure(e))
  }
}

function* getWorkspaceUser(action) {
  try {
    const {query, cb} = action.payload
    const workspaceUsers = yield backend.service('user').find({ query })
    if(_.size(workspaceUsers.data)) {
      yield put(actions.getWorkspaceUsersSuccess(workspaceUsers.data))
      cb && cb()
    }
  } catch (e) {
    yield put (actions.getWorkspaceUsersFailure(e))
  }
}

function* workspaceSaga() {
  yield takeEvery(types.WORKSPACE_GET, get)
  yield takeEvery(types.WORKSPACE_AMPLIFY_GET, getAmplify)
  yield takeEvery(types.WORKSPACE_GET_USERS, getWorkspaceUser)
}

export default {
  workspaceSaga
}
