/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo/Fabulate_black_transparent.png'
import classNames from 'classnames'

class Header extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isMenuMobile: false
    }
  }

  menuOpen () {
    const {isMenuMobile} = this.state
    this.setState({isMenuMobile: !isMenuMobile})
  }

  
  render() {
    const {isMenuMobile} = this.state
    const headerMobile = classNames({
      'header__mobile-visible': isMenuMobile,
      'header__mobile-hidden': !isMenuMobile
    })

    return (
      <header className="header bg-white">
        <div className="container header__container pl-20 pr-20 w-100">
          <div className="row">
            <div className="col-xs-1 col-sm-1">
              <div className="signin__logo mt-15">
                <Link to="/">
                  <img src={logo} alt="fabulate_logo"/>
                </Link>
              </div>
            </div>
            <div className="col-xs-11 col-sm-11 p-10">
              <nav className="header__nav">
                <ul className="header__list">
                </ul>
                <ul className="header__list">
                  <li><Link to="/sign-in">login</Link></li>
                </ul>
              </nav>
              <div className="header__menu-mobile mb-15" onClick = {() => this.menuOpen()}>
                <div className="header__menu-box">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={headerMobile}>
          <nav className="header__mobile-nav">
            <ul className="header__mobile-list header__list">
              <li><a>create content</a></li>
              <li><a>find work</a></li>
              <li><a>how it works</a></li>
            </ul>
            <ul className="header__mobile-list header__list">
              <li><Link to="/sign-in">login</Link></li>
              <li><button className="header__btn">post a project</button></li>
            </ul>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header