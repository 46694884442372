import { put, take, fork, cancel, takeEvery, select } from 'redux-saga/effects'
import _ from 'lodash'

import types from '../constants/auth'
import * as authActions from '../actions/auth'
import * as initActions from '../actions/init'
import { push } from 'react-router-redux'
import { getTranslate } from 'react-localize-redux'
import { store } from '../store'
import { socket } from 'store/realtime'

import backend from 'store/api/feathers'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'
import { appAnalytics } from '../../helpers/appAnalytics'

function* translate() {
  let store = yield select()
  const translate = getTranslate(store.locale)
  return translate
}

function* authorization(data) {
  const location = (state) => state.router.location.pathname
  const response = yield backend.authenticate(data)

  const payload = yield backend.passport.verifyJWT(response.accessToken)

  if(_.size(payload)) {
    const user = yield backend.service('user').get(payload.userId)
    yield put(authActions.signInSuccess(response.accessToken))

    yield put(authActions.getUserSuccess(user))

    if (_.size(user)) {
      const {id, email, workspace, userName} = user;
      const { name} = workspace || {};

      appAnalytics.identifyUser(email, {
        name: userName,
        userId: id,
        workspaceId: workspace && workspace.id || '',
        workspaceName: name || '',
      })

    }
      
    //select - get data from store
    const pathname = yield select(location)
    const previousRoute = store.getState().route.previousRoute

    if (previousRoute) {
      yield put(push(previousRoute))
    } else if(pathname === '/sign-in') {
      user.permissions === 5
        ? yield put(push('/shared-products'))
        : yield put(push('/dashboard'))
    }

  }
}

function* authorize(action) {

  try {
    yield authorization(action.payload)

  } catch (e) {
    const translateFunc = yield translate() 
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: _.get(e, 'message',translateFunc('saga.signInErr')),
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },         
    })

    if(e.code === 401) {
      yield put(authActions.signOut())
    }
        

    yield put(authActions.signInFailure(e))

  }

  yield put(initActions.initAuth())

}

function* signInSaga() {
  while (true) {

    const actionLogin = yield take(types.SIGNIN)

    const task = yield fork(authorize, actionLogin)

    const action = yield take([types.SIGNOUT, types.SIGNIN_FAILURE])
    if (action.type === types.SIGNOUT) {
      const user = store.getState().auth.user

      yield cancel(task)
      socket.emit('logout', user)
      yield backend.logout()
      yield put(authActions.signOutSuccess())
      yield put(push('/sign-in'))

    }

  }

}

function* resetPassword(action) {
  const translateFunc = yield translate() 
  try {

    yield backend.service('restore').create(action.payload)

    new PNotify({
      addclass: 'bg-info',
      title: translateFunc('saga.infoTitle'),
      text: translateFunc('saga.sentInstructions'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.resetPasswordSuccess())

    yield put(push('/sign-in'))

  } catch (e) {

    PNotify.removeAll()
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: translateFunc('saga.email404'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.resetPasswordFailure(e))

  }

}

function* resetPasswordSaga() {

  yield takeEvery(types.RESET_PASSWORD, resetPassword)

}

function* updatePassword(action) {
  const translateFunc = yield translate() 
  try {

    const data = yield backend.service('restore').patch(
      action.payload.id,
      action.payload.password,
    )

    new PNotify({
      addclass: 'bg-info',
      title: translateFunc('saga.infoTitle'),
      text: translateFunc('saga.passwordUpdate'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.updatePasswordSuccess(data))

    yield put(push('/sign-in'))

  } catch (e) {

    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: _.get(e, 'message', translateFunc('saga.restoreErr')),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.updatePasswordFailure(e))

  }

}

function* updatePasswordSaga() {

  yield takeEvery(types.UPDATE_PASSWORD, updatePassword)

}

function* signUp(action) {
  const translateFunc = yield translate() 
  try {
    
    yield backend.service('user').create(
      {
        ...action.payload
      }
    )
    // const text = translate('saga.verify')
    new PNotify({
      addclass: 'bg-info',
      title: translateFunc('saga.infoTitle'),
      text: translateFunc('saga.signUp'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.signUpSuccess())
    yield put(push('/sign-in'))

  } catch (e) {
    const translateFunc = yield translate() 
    let err = e

    if(e && e.errors && e.errors.length > 0) {
      err = e.errors[0]
    }

    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: _.get(err, 'response.data.err', translateFunc(`userError.${err.message}`)),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },   
    })

    yield put(authActions.signUpFailure(err))

  }

}

function* signUpSaga() {
  yield takeEvery(types.SIGNUP, signUp)

}

function* verifyUserMail(action) {
  const translateFunc = yield translate() 
  try {
    // yield call(Api.get, `verify/${action.payload.id}`)
    yield backend.service('verify').get(action.payload.id)

    new PNotify({
      addclass: 'bg-info',
      title: translateFunc('saga.infoTitle'),
      text: translateFunc('saga.verify'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.verifyUserMailSuccess())

    setTimeout(500)

    yield put(push('/sign-in'))

  } catch (e) {

    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: translateFunc('saga.verifyErr'),
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.verifyUserMailFailure(e))

  }

}

function* verifyUserMailSaga() {
  yield takeEvery(types.VERIFY_USER_BY_EMAIL, verifyUserMail)

}

function* updateUserInfo(action) {
  const translateFunc = yield translate() 
  try {
    let store = yield select()
    const currentUser = store.auth && store.auth.user ? store.auth.user : {}

    delete action.payload.email
    const updateUser = yield backend.service('user').patch(action.payload.id, action.payload)

    if(_.size(updateUser)) {

      yield put(authActions.updateUserInfoSuccess(updateUser))

      if(currentUser.image === action.payload.image) {
        new PNotify({
          addclass: 'bg-info',
          title: translateFunc('saga.infoTitle'),
          text: 'Profile updated',
          hide: true,
          buttons: {
            closer: true,
            sticker: true,
          },             
        })
      }
    }
  } catch (e) {
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: e.message || 'Update error',
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },         
    })

    yield put(authActions.updateUserInfoFailure(e))
    yield put(authActions.resetEdit())

  }

}

function* updateUserInfoSaga() {
  yield takeEvery(types.USER_INFO_UPDATE, updateUserInfo)

}

function* uploadUserPhoto(action) {
  const translateFunc = yield translate() 
  const formData = new FormData()
  formData.append('uri', action.payload.url)

  try {
    const uploadPhoto = yield backend.service('upload-user').create(formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      }
    })

    if(_.size(uploadPhoto)) {

      yield put(authActions.uploadUserPhotoSuccess(uploadPhoto))
    }


  } catch (e) {
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: 'Upload photo',
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.uploadUserPhotoFailure(e))

  }

}

function* uploadUserPhotoSaga() {
  yield takeEvery(types.USER_UPLOAD_PHOTO, uploadUserPhoto)

}

function* updateUserPassword(action) {
  const translateFunc = yield translate() 
  try {

    const updateUser = yield backend.service('user').patch(action.payload.id, action.payload)

    if(_.size(updateUser)) {

      yield put(authActions.updateUserPasswordSuccess(updateUser))

      new PNotify({
        addclass: 'bg-info',
        title: translateFunc('saga.infoTitle'),
        text: 'Password updated',
        hide: true,
        buttons: {
            closer: true,
            sticker: true,
        },           
      })
    }

  } catch (e) {
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: 'Update password error',
      hide: true,
      buttons: {
          closer: true,
          sticker: true,
      },         
    })

    yield put(authActions.updateUserPasswordFailure(e))

  }

}

function* updateUserPasswordSaga() {
  yield takeEvery(types.USER_UPDATE_PASSWORD, updateUserPassword)

}

function* signInAsAdmin(action) {
  const translateFunc = yield translate()

  try {
    yield authorization({key:action.payload, isAdmin: true, strategy: 'custom'})
    yield put(push('/'))

  } catch (e) {
    new PNotify({
      addclass: 'bg-danger',
      title: translateFunc('saga.errorTitle'),
      text: 'Login error',
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },
    })
  }
}

function* signInAsAdminSaga() {
  yield takeEvery(types.USER_LOGIN_AS_ADMIN, signInAsAdmin)
}

export default {
  signInSaga,
  signUpSaga,
  resetPasswordSaga,
  updatePasswordSaga,
  verifyUserMailSaga,
  updateUserInfoSaga,
  uploadUserPhotoSaga,
  updateUserPasswordSaga,
  signInAsAdminSaga,
}
