import { put, takeEvery, call, all, fork, select } from 'redux-saga/effects'

import backend from 'store/api/feathers'

import types from '../constants/analytics'
import * as actions from '../actions/analytics'

import {
  createDatasetQuery,
  changeGraphDataResponseResult,
  changeGeographicWorldDataResponseResult,
  changeDeviceCountResult,
  changeDeviceTimeOnPageResult,
  changeGraphDataResponseResultForLocation,
  changeTimeOnPageDataResponseResult,
  changeCountUniqueUsersResponseResult,
  changeGenderAgeResponseResultForAge,
  changeGenderAgeResponseResultForGender,
  changeSurveyDataWantToBuy,
  changeSurveyDataWantToLearnMore,
  changeSurveyDataWantToShare
} from 'helpers/analytics.js'

export function* getDataFromServer(queryKeen, onlyResult = true) {
  const workspaceId = yield select(state => state.auth.workspace.id)
  const response = yield backend.service('analytics-get-data').create({
    workspaceId,
    queryKeen
  })

  const { result, metadata } = response

  if (onlyResult) {
    return result
  } else {
    return {
      result,
      metadata
    }
  }
}

function* getlocationDataFromGraphData(result) {
  const changedResultForLocation = changeGraphDataResponseResultForLocation(
    result
  )

  yield put(actions.setLocationData(changedResultForLocation))
}

function* getGraphData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery(
      'count-brief-graph-dataset',
      briefId,
      filterTimeframe
    )

    const graphData = yield call(getDataFromServer, query)

    const changedResults = changeGraphDataResponseResult(graphData)

    yield fork(getlocationDataFromGraphData, graphData)

    yield put(actions.getGraphDataSuccess(changedResults))
  } catch (e) {
    yield put(actions.getGraphDataFailure(e))
  }
}

function* getGeographicWorldData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery(
      'count-geographic-world-dataset',
      briefId,
      filterTimeframe
    )

    const geographicWorldData = yield call(
      getDataFromServer,
      query
    )

    yield put(
      actions.getGeographicWorldDataSuccess(
        changeGeographicWorldDataResponseResult(geographicWorldData)
      )
    )
  } catch (e) {
    yield put(actions.getGeographicWorldDataFailure(e))
  }
}

function* getGenderAgeData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('count-brief-gender-age-dataset', briefId, filterTimeframe)

    const genderAgeData = yield call(getDataFromServer, query)

    const resultChangesData = yield all([
      call(changeGenderAgeResponseResultForGender, genderAgeData),
      call(changeGenderAgeResponseResultForAge, genderAgeData)
    ])

    yield put(
      actions.getGenderAgeDataSuccess({
        gender: resultChangesData[0],
        age: resultChangesData[1],
      })
    )
  } catch (e) {
    yield put(actions.getGenderAgeDataFailure(e))
  }
}

function* getDeviceData(action) {
  try {

    const { briefId, filterTimeframe } = action.payload

    const queryDeviceCount = createDatasetQuery(
      'count-brief-device-dataset',
      briefId,
      filterTimeframe
    )

    const queryDeviceTimeOnPage = createDatasetQuery(
      'sum-time-on-page-ms-dataset-devices',
      briefId,
      filterTimeframe
    )

    const result = yield all([
      call(getDataFromServer, queryDeviceCount),
      call(getDataFromServer, queryDeviceTimeOnPage)
    ])

    yield put(
      actions.getDeviceDataSuccess({
        deviceCount: changeDeviceCountResult(result[0]),
        deviceTimeOnPage: changeDeviceTimeOnPageResult(result[1])
      })
    )
  } catch (e) {
    yield put(actions.getDeviceDataFailure(e))
  }
}

function* getTimeOnPageData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('sum-time-on-page-ms-dataset', briefId, filterTimeframe)

    const result = yield call(getDataFromServer, query)

    yield put(
      actions.getTimeOnPageDataSuccess(
        changeTimeOnPageDataResponseResult(result)
      )
    )
  } catch (e) {
    yield put(actions.getTimeOnPageDataFailure(e))
  }
}

function* getCountUniqueUsersData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('count-unique-users-dataset', briefId, filterTimeframe)

    const result = yield call(getDataFromServer, query)

    yield put(
      actions.getCountUniqueUsersDataSuccess(
        changeCountUniqueUsersResponseResult(result)
      )
    )
  } catch (error) {
    yield put(actions.getCountUniqueUsersDataFailure(error))
  }
}

function* getBuySurveyData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('user-wants-to-buy-dataset', briefId, filterTimeframe)

    const buySurveyData = yield call(getDataFromServer, query)

    const resultChangesData = yield all([
      call(changeSurveyDataWantToBuy, buySurveyData),
    ])
    yield put(
      actions.getBuySurveyDataSuccess(resultChangesData)
    )
  } catch (e) {
    yield put(actions.getBuySurveyDataFailure(e))
  }
}

function* getLearnMoreSurveyData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('user-wants-to-learn-more-dataset', briefId, filterTimeframe)

    const learnMoreSurveydata = yield call(getDataFromServer, query)

    const resultChangesData = yield all([
      call(changeSurveyDataWantToLearnMore, learnMoreSurveydata),
    ])

    yield put(
      actions.getLearnMoreSurveyDataSuccess(resultChangesData)
    )
  } catch (e) {
    yield put(actions.getLearnMoreSurveyDataFailure(e))
  }
}

function* getShareSurveyData(action) {
  try {
    const { briefId, filterTimeframe } = action.payload

    const query = createDatasetQuery('user-wants-to-share-dataset', briefId, filterTimeframe)

    const shareSurveyData = yield call(getDataFromServer, query)

    const resultChangesData = yield all([
      call(changeSurveyDataWantToShare, shareSurveyData),
    ])

    yield put(
      actions.getShareSurveyDataSuccess(resultChangesData)
    )
  } catch (e) {
    yield put(actions.getShareSurveyDataFailure(e))
  }
}



function* analyticsSaga() {
  yield takeEvery(types.GET_GRAPH_DATA, getGraphData)
  yield takeEvery(types.GET_GEOGRAPHIC_WORLD_DATA, getGeographicWorldData)
  yield takeEvery(types.GET_GENDER_AGE_DATA, getGenderAgeData)
  yield takeEvery(types.GET_DEVICE_DATA, getDeviceData)
  yield takeEvery(types.GET_TIME_ON_PAGE_DATA, getTimeOnPageData)
  yield takeEvery(types.GET_COUNT_UNIQUE_USERS_DATA, getCountUniqueUsersData)
  yield takeEvery(types.GET_BUY_SURVEY_DATA, getBuySurveyData)
  yield takeEvery(types.GET_LEARN_MORE_SURVEY_DATA, getLearnMoreSurveyData)
  yield takeEvery(types.GET_SHARE_SURVEY_DATA, getShareSurveyData)
}

export default {
  analyticsSaga
}
