import { createStore, combineReducers, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import createSagaMiddleware from 'redux-saga'
import { connectRouter } from 'connected-react-router'

import {
  pendingTasksReducer, // The redux reducer
} from 'react-redux-spinner'

import { localeReducer } from 'react-localize-redux'

import initSaga from './sagas/init'
import authSaga from './sagas/auth'
import causeSaga from './sagas/cause'
import gettySaga from './sagas/gettyApi'
import userSaga from './sagas/user'
import modelSaga from './sagas/model'
import causeUserSaga from './sagas/causeUser'
import templateSaga from './sagas/template'
import productSaga from './sagas/product'
import workroomSaga from './sagas/workroom'
import notificationSaga from './sagas/notification'
import chatSaga from './sagas/chat'
import contentCategorySaga from './sagas/contentCategory'
import dashboardSaga from './sagas/dashboard'
import recommendedSaga from './sagas/recommended'
import withoutEditorSaga from './sagas/withoutEditor'
import accountSaga from './sagas/account'
import profileSaga from './sagas/profile'
import feedbackSaga from './sagas/feedback'
import portfolioSaga from './sagas/portfolio'
import skillsSaga from './sagas/skills'
import workroomGuestSaga from './sagas/workroomGuest'
import genreSaga from './sagas/genre'
import workspaceSaga from './sagas/workspace'
import analyticSaga from './sagas/analytic'
import publicationHistorySaga from './sagas/publicationHistory'
import routerSaga from './sagas/router'
import analyticsSaga from './sagas/analytics'
import vaulttSaga from './sagas/vault'
import amplifyCausePermissionsSaga from './sagas/amplifyCausePermissions'
import amplifyCauseSaga from './sagas/amplifyCause'
import amplifyWorkroomSaga from './sagas/amplifyWorkroom'
import vaultCauseSaga from './sagas/vaultCause'
import buyerCausePermissionsSaga from './sagas/buyerCausePermissions'
import vaultFolderSaga from './sagas/vaultFolder'
import buyerTodoSaga from './sagas/buyerTodos'

import initReducers from './reducers/init'
import authReducers from './reducers/auth'
import causeReducers from './reducers/cause'
import userReducers from './reducers/user'
import modelReducers from './reducers/model'
import gettyReduser from './reducers/gettyApi'
import causeUserReducers from './reducers/causeUser'
import signInReducers from './reducers/signIn'
import signUpReducers from './reducers/signUp'
import templateReducers from './reducers/template'
import productReducers from './reducers/product'
import workroomReducers from './reducers/workroom'
import notificationReducers from './reducers/notification'
import chatReducers from './reducers/chat'
import contentCategoryReducers from './reducers/contentCategory'
import dashboardReducers from './reducers/dashboard'
import recommendedReducers from './reducers/recommended'
import withoutEditorRedusers from './reducers/withoutEditor'
import accountRedusers from './reducers/account'
import profileRedusers from './reducers/profile'
import feedbackRedusers from './reducers/feedback'
import portfolioRedusers from './reducers/portfolio'
import skillsReducers from './reducers/skills'
import workroomGuestReducers from './reducers/workroomGuest'
import genreRedusers from './reducers/genre'
import routReducers from './reducers/route'
import workspaceReducers from './reducers/workspace'
import publicationHistoryReducers from './reducers/publicationHistory'
import analyticsReducers from './reducers/analytics'
import vaultReducers from './reducers/vault'
import amplifyCausePermissionsReducer from './reducers/amplifyCausePermissions'
import amplifyCauseReducers from './reducers/amplifyCause'
import amplifyWorkroomReducers from './reducers/amplifyWorkroom'
import vaultCauseReducers from './reducers/vaultCause'
import buyerCausePermissionsReducer from './reducers/buyerCausePermissions'
import vaultFolderReducer from './reducers/vaultFolder'
import buyerTodoReducer from './reducers/buyerTodos'

// const apiUrl = `${process.env['REACT_APP_API_SCHEME']}://` +
//   `${process.env['REACT_APP_API_HOST']}:${process.env['REACT_APP_API_PORT']}`

const sagaMiddleware = createSagaMiddleware()

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory()

// Build the middleware for intercepting and dispatching navigation actions
const middleware = [
  sagaMiddleware,
  routerMiddleware(history)
]

const appReducer = combineReducers({
  pendingTasks: pendingTasksReducer,
  init: initReducers,
  auth: authReducers,
  router: connectRouter(history),
  locale: localeReducer,
  cause: causeReducers,
  user: userReducers,
  model: modelReducers,
  signIn: signInReducers,
  signUp: signUpReducers,
  causeUser: causeUserReducers,
  template: templateReducers,
  product: productReducers,
  workroom: workroomReducers,
  notification: notificationReducers,
  gettyReduser: gettyReduser,
  chat: chatReducers,
  contentCategory: contentCategoryReducers,
  dashboard: dashboardReducers,
  recommended: recommendedReducers,
  withoutEditor: withoutEditorRedusers,
  account: accountRedusers,
  profile: profileRedusers,
  feedback: feedbackRedusers,
  portfolio: portfolioRedusers,
  skill: skillsReducers,
  workroomGuest: workroomGuestReducers,
  genre: genreRedusers,
  route: routReducers,
  workspace: workspaceReducers,
  publicationHistory: publicationHistoryReducers,
  analytics: analyticsReducers,
  vault: vaultReducers,
  amplifyCausePermissions: amplifyCausePermissionsReducer,
  amplifyCause: amplifyCauseReducers,
  amplifyWorkroom: amplifyWorkroomReducers,
  vaultCause: vaultCauseReducers,
  buyerCausePermissions: buyerCausePermissionsReducer,
  vaultFolder: vaultFolderReducer,
  buyerTodo: buyerTodoReducer
})

const store = applyMiddleware(...middleware)(createStore)(
  appReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

sagaMiddleware.run(initSaga)
sagaMiddleware.run(authSaga.signInSaga)
sagaMiddleware.run(authSaga.signUpSaga)
sagaMiddleware.run(authSaga.resetPasswordSaga)
sagaMiddleware.run(authSaga.updatePasswordSaga)
sagaMiddleware.run(authSaga.verifyUserMailSaga)
sagaMiddleware.run(authSaga.updateUserInfoSaga)
sagaMiddleware.run(authSaga.uploadUserPhotoSaga)
sagaMiddleware.run(authSaga.updateUserPasswordSaga)
sagaMiddleware.run(authSaga.signInAsAdminSaga)

sagaMiddleware.run(productSaga.productSaga)
sagaMiddleware.run(templateSaga.templateSaga)
sagaMiddleware.run(causeSaga.causeSaga)
sagaMiddleware.run(gettySaga.gettySaga)
sagaMiddleware.run(userSaga.userSaga)
sagaMiddleware.run(modelSaga.modelSaga)
sagaMiddleware.run(causeUserSaga.causeUserSaga)
sagaMiddleware.run(workroomSaga.workroomSaga)
sagaMiddleware.run(notificationSaga.notificationSaga)
sagaMiddleware.run(chatSaga.chatSaga)
sagaMiddleware.run(contentCategorySaga.contentCategorySaga)
sagaMiddleware.run(dashboardSaga.dashboardSaga)
sagaMiddleware.run(recommendedSaga.recommendedSaga)
sagaMiddleware.run(withoutEditorSaga.withoutEditorSaga)
sagaMiddleware.run(accountSaga.accountSaga)
sagaMiddleware.run(profileSaga.profileSaga)
sagaMiddleware.run(feedbackSaga.feedbackSaga)
sagaMiddleware.run(portfolioSaga.portfolioSaga)
sagaMiddleware.run(skillsSaga.skillsSaga)
sagaMiddleware.run(workroomGuestSaga.workroomGuestSaga)
sagaMiddleware.run(genreSaga.genreSaga)
sagaMiddleware.run(workspaceSaga.workspaceSaga)
sagaMiddleware.run(analyticSaga.analyticSaga)
sagaMiddleware.run(publicationHistorySaga.publicaionHistorySaga)
sagaMiddleware.run(routerSaga)
sagaMiddleware.run(analyticsSaga.analyticsSaga)
sagaMiddleware.run(vaulttSaga.vaultSaga)
sagaMiddleware.run(amplifyCauseSaga.amplifyCauseSaga)
sagaMiddleware.run(amplifyCausePermissionsSaga.amplifyCausePermissionsSaga)
sagaMiddleware.run(amplifyWorkroomSaga.amplifyWorkroomSaga)
sagaMiddleware.run(vaultCauseSaga.vaultCauseSaga)
sagaMiddleware.run(buyerCausePermissionsSaga.buyerCausePermissionsSaga)
sagaMiddleware.run(vaultFolderSaga.vaultFolderSaga)
sagaMiddleware.run(buyerTodoSaga.buyerTodoSaga)

export {
  store,
  history,
}
