import types from '../constants/workspace'

export const get = (data) => ({
  type: types.WORKSPACE_GET,
  payload: { ...data }
})
  
export const getSuccess = ({ data, count }, page) => ({
  type: types.WORKSPACE_GET_SUCCESS,
  payload: {
    data,
    count,
    page
  }
})
  
export const getFailure = (error) => ({
  type: types.WORKSPACE_GET_FAILURE,
  payload: error
})
  
export const getAmplify = query => ({
  type: types.WORKSPACE_AMPLIFY_GET,
  payload: query
})
  
export const getPublishingSuccess = data => ({
  type: types.WORKSPACE_AMPLIFY_GET_PUBLISHING_SUCCESS,
  payload: data
})

export const getDistributionSuccess = data => ({
  type: types.WORKSPACE_AMPLIFY_GET_DISTRIBUTION_SUCCESS,
  payload: data
})
  
export const getAmplifyFailure = error => ({
  type: types.WORKSPACE_AMPLIFY_GET_FAILURE,
  payload: error
})

export const getWorkspaceUsers = (query, cb = null) => {
  return ({
    type: types.WORKSPACE_GET_USERS,
    payload: { query, cb}
  })
}

export const getWorkspaceUsersSuccess = data => ({
  type: types.WORKSPACE_GET_USERS_SUCCESS,
  payload: data
})
export const getWorkspaceUsersFailure = error => ({
  type: types.WORKSPACE_GET_USERS_FAILURE,
  payload: error
})

// export const setSearchedWorkspace = (value) => ({
//   type: types.SET_SEARCHED_WORKSPACE,
//   payload: value
// })
  