import types from '../constants/vault'

export const get = (query, page, isInfiniteScroll) => {
  return ({
    type: types.GET,
    payload: {
      query,
      page,
      isInfiniteScroll
    }
  })
}

export const getSuccess = data => {
  return ({
    type: types.GET_SUCCESS,
    payload: {
      ...data
    }
  })
}

export const getFailure = error => ({
  type: types.GET_FAILURE,
  payload: error
})

export const addSelectedFile = data => ({
  type: types.ADD_SELECTED_FILE,
  payload: data
})

export const clearList = () => ({
  type: types.CLEAR_LIST
})

export const removeSelectedFile = data => ({
  type: types.REMOVE_SELECTED_FILE,
  payload: data
})

export const remove = (id) => {
  return ({
    type: types.VAULT_REMOVE,
    payload: id
  })
}

export const removeSuccess = (data) => {
  return ({
    type: types.VAULT_REMOVE_SUCCESS,
    payload: data
  })
}

export const removeFailure = (error) => ({
  type: types.VAULT_REMOVE_FAILURE,
  payload: error
})

export const updateFileTags = (id, data) => {
  return ({
    type: types.UPDATE_FILE_TAGS,
    payload: { id, data }
  })
}

export const updateFileTagsSuccess = (data) => {
  return ({
    type: types.UPDATE_FILE_TAGS_SUCCESS,
    payload: data
  })
}

export const updateFileTagsFailure = (error) => {
  return ({
    type: types.UPDATE_FILE_TAGS_FAILURE,
    payload: error
  })
}

export const removeBriefAttachment = (data) => {
  return ({
    type: types.REMOVE_BRIEF_ATTACHMENT,
    payload: data
  })
}

export const clearBriefAttachment = () => {
  return ({
    type: types.CLEAR_BRIEF_ATTACHMENT,
  })
}

// =============

export const attachFile = (params, isCreate) => {
  return {
    type: types.ATTACH,
    payload: {
      params,
      isCreate
    }
  }
}

export const removeAttach = (params, isCreate, isError) => {
  return {
    type: types.REMOVE_FILE,
    payload: {
      params,
      isCreate,
      isError
    }
  }
}

export const resetAttachChanges = (files) => {
  return {
    type: types.RESET_ATTACH_CHANGES,
    payload: files,
  }
}


export const uploadFile = (params, isCreate) => {
  return {
    type: types.UPLOAD,
    payload: {
      params,
      isCreate
    }
  }
}

export const uploadFileSuccess = (data, preview, isCreate) => {

  return {
    type: types.UPLOAD_SUCCESS,
    payload: {
      data,
      preview,
      isCreate
    }
  }

}


export const uploadFileFailure = (error, preview, type, isCreate) => {

  return {
    type: types.UPLOAD_FAILURE,
    payload: {
      error,
      preview,
      isCreate,
      type
    }
  }

}


// export const editAttach = (params, isCreate) => {
//   return {
//     type: types.EDIT_FILE,
//     payload: {
//       params,
//       isCreate
//     }
//   }
// }

// export const editAttachSuccess = (params) => {
//   return {
//     type: types.EDIT_FILE_SUCCESS,
//     payload: {
//       params
//     }
//   }
// }

// export const editAttachFailure = (params) => {
//   return {
//     type: types.EDIT_FILE_FAILURE,
//     payload: {
//       params
//     }
//   }
// }


export const uploadErrorFile = (params, isCreate) => {
  return {
    type: types.UPLOAD_ERROR,
    payload: {
      params,
      isCreate
    }
  }
}

export const uploadErrorFileSuccess = (data, isCreate) => {

  return {
    type: types.UPLOAD_ERROR_SUCCESS,
    payload: {
      data,
      // preview,
      isCreate
    }
  }

}


export const uploadErrorFileFailure = (error, isCreate) => {

  return {
    type: types.UPLOAD_ERROR_FAILURE,
    payload: {
      error,
      // preview,
      isCreate,
      // type
    }
  }

}

export const clearVaultUploadFolder = () => {
  return {
    type: types.CLEAR_VAULT_UPLOAD
  }
}

export const filterVaultFolder = ({ id, page, limit, skip, search }) => {
  return {
    type: types.FILTER_VAULT_FOLDER,
    payload: {
      id,
      page,
      limit,
      skip,
      search
    },
  }
}

export const filterVaultFolderSuccess = (data) => {
  return {
    type: types.FILTER_VAULT_FOLDER_SUCCESS,
    payload: {
      ...data,
    },
  }
}

export const filterVaultFolderFailure = (error) => ({
  type: types.FILTER_VAULT_FOLDER_FAILURE,
  payload: error,
})


export const setSelectedFolder = (folderName) => {
  return ({
    type: types.SET_SELECTED_FOLDER,
    payload: folderName
  })
}

export const getAttachmentBriefList = (id) => {
  return ({
    type: types.GET_ATTACHMENT_BRIEF_LIST,
    payload: {
      id,
    }
  })
}

export const getAttachmentBriefListSuccess = data => {
  return ({
    type: types.GET_ATTACHMENT_BRIEF_LIST_SUCCESS,
    payload: {
      ...data
    }
  })
}

export const getAttachmentBriefListFailure = error => ({
  type: types.GET_ATTACHMENT_BRIEF_LIST_FAILURE,
  payload: error
})


export const updateUploadCount = (countData) => {
  return ({
    type: types.VAULT_UPLOAD_PROGRES,
    payload: countData
  })
}


export const vaultDeleteFile = (data) => {
  return ({
    type: types.VAULT_DELETE_FILE,
    payload: data
  })
}

export const vaultDeleteFileSuccess = (data) => ({
  type: types.VAULT_DELETE_FILE_SUCCESS,
  payload: data
})

export const vaultDeleteFileFailure = (error) => ({
  type: types.VAULT_DELETE_FILE_FAILURE,
  payload: error
})

export const currentUploadingApiCalls = (data) => ({
  type: types.CURRENT_UPLOADING_API_CALLS,
  payload: data
})

export const clearUploadingApiCall = (data) => ({
  type: types.CLEAR_UPLOADING_API_CALLS,
  payload: data
})

export const clearVaultList = () => ({
  type: types.CLEAR_VAULT_LIST
})

export const addVaultModalSelectedFiles = (data) => ({
  type: types.ADD_VAULT_MODAL_SELECTED_FILES,
  payload: data
})
