import types from '../constants/causeUser'
import { validation } from 'helpers/validation'

export const get = (queryParams, page = 0) => {
  return {
    type: types.GET,
    payload: {
      queryParams,
      page
    }
  }
}

export const getSuccess = ({ data, count, isEmpty }, causeId, page) => {
  return {
    type: types.GET_SUCCESS,
    payload: {
      data,
      count,
      isEmpty,
      causeId,
      page
    }
  }

}

export const getFailure = (error) => {
  return {
    type: types.GET_FAILURE,
    payload: error
  }

}

export const getId = (id) => {
  return {
    type: types.GET_ID,
    payload: id
  }

}

export const getIdSuccess = (data) => {
  return {
    type: types.GET_ID_SUCCESS,
    payload: data
  }

}

export const getIdFailure = (error) => {
  return {
    type: types.GET_ID_FAILURE,
    payload: error
  }

}

export const create = (data, user, query) => {
  return {
    type: types.CREATE,
    payload: {
      data,
      user,
      query
    }
  }

}

export const createSuccess = (data) => {
  return {
    type: types.CREATE_SUCCESS,
    payload: data
  }

}

export const createFailure = (error) => {
  return {
    type: types.CREATE_FAILURE,
    payload: error
  }

}

export const remove = (id, user) => {
  return {
    type: types.REMOVE,
    payload: { id, user }
  }

}

export const removeSuccess = ({ data }) => {
  return {
    type: types.REMOVE_SUCCESS,
    payload: data
  }

}

export const removeFailure = (error) => {
  return {
    type: types.REMOVE_FAILURE,
    payload: error
  }

}

export const update = (data, pnotifyText) => {
  return {
    type: types.UPDATE,
    payload: {
      data,
      pnotifyText
    }
  }

}

export const updateSuccess = (data) => {
  return {
    type: types.UPDATE_SUCCESS,
    payload: data
  }

}

export const updateFailure = (error) => {
  return {
    type: types.UPDATE_FAILURE,
    payload: error
  }

}

export const findPitch = query => {
  return {
    type: types.FIND_CAUSE_USER,
    payload: query
  }
}

export const findPitchSuccess = data => {
  return {
    type: types.FIND_CAUSE_USER_SUCCESS,
    payload: data
  }
}

export const findPitchFailure = error => {
  return {
    type: types.FIND_CAUSE_USER_FAILURE,
    payload: error
  }

}

export const setValid = (model, rules, type, isCreate) => {
  const newModel = validation(model, rules)
  return {
    type: types.VALIDATION,
    payload: {
      data: {
        [type]: { ...newModel }
      },
      isCreate
    },
  }
}

export const setFilter = (queryParams) => ({
  type: types.SET_FILTER,
  payload: queryParams
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
  payload: null
})

export const setSort = (params) => ({
  type: types.SET_SORT,
  payload: params
})

export const setPage = (pageNumber) => ({
  type: types.SET_PAGE,
  payload: pageNumber
})

export const resetValidaionEdit = () => ({
  type: types.RESET_VALIDATION_EDIT
})

export const resetValidaionCreate = () => ({
  type: types.RESET_VALIDATION_CREATE
})