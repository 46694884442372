import types from '../constants/portfolio'

export const get = (queryParams) => ({
  type: types.GET,
  payload: {
    queryParams
  }
})

export const getSuccess = ({ data }) => ({
  type: types.GET_SUCCESS,
  payload: {
    data
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const create = (data) => {

  return {
    type: types.CREATE,
    payload: { 
      data
    }
  }
}

export const createSuccess = (data) => {

  return {
    type: types.CREATE_SUCCESS,
    payload: data
  }
}

export const createFailure = (error) => {

  return {
    type: types.CREATE_FAILURE,
    payload: error
  }
}

export const edit = (data) => ({
  type: types.EDIT,
  payload: data,
})

export const remove = (id) => {

  return {
    type: types.REMOVE,
    payload: id
  }
}

export const removeSuccess = (data) => {

  return {
    type: types.REMOVE_SUCCESS,
    payload: data
  }
}

export const removeFailure = (error) => {

  return {
    type: types.REMOVE_FAILURE,
    payload: error
  }
}