import { put, takeEvery } from 'redux-saga/effects'
import { post as postAxios, get as getAxios } from 'store/api'
import _ from 'lodash'
import PNotify from 'pnotify'

import types from '../constants/amplifyWorkroom'
import * as actions from '../actions/amplifyWorkroom'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {
  const query = action.payload
  try {
    const amplifyWorkroomData = yield backend.service('amplify-workroom').find({
      query
    })
    if (_.size(amplifyWorkroomData)) {
      yield put(actions.getSuccess(amplifyWorkroomData))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.getFailure(e))
  }
}

function* update(action) {
  const { payload: { id, data } } = action
  try {
    const updatedAmplifyWorkroom = yield backend.service('amplify-workroom').patch(id, data)
    if (_.size(updatedAmplifyWorkroom)) {
      yield put(actions.updateSuccess(updatedAmplifyWorkroom))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.updateFailure(e))
  }
}

function* uploadFile(action) {
  const { amplifyWorkroomId, uploadedBy, uri, fileName, labelValue, size, isVideo, done, tokenInstance } = action.payload
  try {

    let _uri = uri;
    let formData = new FormData()
    const fileExtensionName = fileName.split('.').pop()

    if (isVideo) {
      formData.append('file', _uri)
    } else {
      const maxSize = process.env['REACT_APP_MAX_FILE_SIZE'] * 1024 * 1024
      if (size > maxSize) throw new Error('Field value too long')
      if (fileName.includes('.csv')) {
        _uri = 'data:text/csv;base64,'  + _uri.split('base64,').pop();
      }
  
      formData.append('uri', _uri)
    }
    formData.append('amplifyWorkroomId', amplifyWorkroomId)
    formData.append('fileName', fileName)
    formData.append('uploadedBy', uploadedBy)
    formData.append('labelValue', labelValue || fileName)
    formData.append('id', fileName)

    let uploadData = null
    let uploadedFile = null
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'File-Type': fileExtensionName,
      },
      cancelToken: tokenInstance[fileName].token,
      onUploadProgress: done
      // onUploadProgress: (progressEvent) => {
      //   var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      //   console.log('percentCompleted=====', percentCompleted)
      //   yield put(actions.updateUploadCount(percentCompleted))
      // }
    }
    if (isVideo) {
      uploadedFile = yield postAxios('amplify-workroom-upload-stream', formData, config)
      uploadedFile.size = uri.size
      uploadedFile.uri = uri
    } else {
     uploadedFile = yield postAxios('amplify-workroom-upload', formData, config)
    }
     if (_.size(uploadedFile)) {
      const duplicateData = yield getAxios(`vault?storeId=media/${uploadedFile.location}`)

      yield put(actions.amplifyWorkroomClearUploadingApiCall(fileName))
      if (duplicateData.length) {
        yield put(actions.uploadFileSuccess(amplifyWorkroomId, {
          ...uploadedFile,
          location: duplicateData[0].storeId || duplicateData[0].location,
          documentId: duplicateData[0].documentId,
          uploadedFromVault: true,
          isDuplicate: true
        }))

      } else {
        yield put(actions.uploadFileSuccess(amplifyWorkroomId, uploadedFile))

      }
      new PNotify({
        addclass: 'bg-teal',
        // text: `The file "${uploadedFile.name}" was uploaded successfully`,
        title: 'Upload complete',
        text: `"${fileName}" uploaded`,
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },       
      })
    }
  } catch (e) {
    yield put(actions.amplifyWorkroomClearUploadingApiCall(fileName))
    if (e.message !== undefined) {
      const message = e.message === 'Field value too long'
        ? `Max file size ${process.env['REACT_APP_MAX_FILE_SIZE']}mb`
        : e.message
  
      new PNotify({
        addclass: 'bg-danger',
        title: 'Error',
        text: message,
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },
      })
      yield put(actions.uploadFileFailure(amplifyWorkroomId, e))
    }
  }
}

function* uploadUrl(action) {
  try {
    const uploadedUrl = yield backend.service('amplify-workroom-attachments').create(action.payload)
    if (_.size(uploadedUrl)) {
      new PNotify({
        addclass: 'bg-teal',
        title: 'Upload complete',
        text: `"${uploadedUrl.name}" uploaded`,
        hide: true,
        buttons: {
          closer: true,
          sticker: true,
        },       
      })
      yield put(actions.uploadUrlSuccess(uploadedUrl.amplifyWorkroomId, uploadedUrl))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.uploadUrlFailure(e))
  }
}

function* downloadFile(action) {
  try {
    const id = action.payload &&  action.payload._id;
    const isGettyImage = action.payload && action.payload.isGettyImage;
    let downloadFileData = null;
    if (isGettyImage) {
      downloadFileData = yield backend.service('download-getty').get(id)
    } else {
      downloadFileData = yield backend.service('download').get(id)
    }

    if (downloadFileData.uri) {

      const aElem = document.createElement('a')

      aElem.setAttribute('href', downloadFileData.uri)
      aElem.setAttribute('download', downloadFileData.id)
      aElem.click()
      
      aElem.remove()

      yield put(actions.downloadFileSuccess())
    }
  } catch (e) {
    renderErrorNotification(e, 'Download file error')
    yield put(actions.downloadFileFailure(e))
  }
}

function* amplifyWorkroomDeleteFile(action) {

  try {
    const { id } = action.payload
    if (id) {
      const deleteFileData = yield backend.service('delete-file').create({ id })
      if (_.size(deleteFileData)) {
        // done()
        new PNotify({
          addclass: 'bg-info custom',
          text: `${id} has been deleted`,
          buttons: {
            closer: true,
            sticker: true,
          },
        })
        yield put(actions.amplifyWorkroomDeleteFileSuccess(deleteFileData))
      }
    }
  } catch (e) {
    renderErrorNotification(e, 'Remove file error')
    yield put(actions.amplifyWorkroomDeleteFileFailure(e))
  }

}

function* removeAmplifyWorkroomAttachment(action) {
  try {
    const { id, name, done } = action.payload
    const removeAttachments = yield backend.service('amplify-workroom-attachments').remove(id)
    if (_.size(removeAttachments)) {

      new PNotify({
        addclass: 'bg-info custom',
        text: `${name} has been removed from the attachments`,
        buttons: {
          closer: true,
          sticker: true,
        },
      })
      done()
      yield put(actions.amplifyWorkroomRemoveAttachmentSuccess(removeAttachments))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.amplifyWorkroomRemoveAttachmentFailure(e))
  }
}


function* amplifyWorkroomVaultUpload(action) {
  try {
    const payload = {
      ...action.payload,
      attachments: action.payload.attachments && action.payload.attachments.map(item => {
        return { ...item, id: item.name}
      })
    }
    const vaultUpload = yield backend.service('amplify-workroom-vault-upload').create(payload)
    if (_.size(vaultUpload)) {
      yield put(actions.amplifyWorkroomVaultUploadSuccess(vaultUpload))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.amplifyWorkroomVaultUploadFailure(e))
  }
}

function* amplifyWorkroomSaga() {
  yield takeEvery(types.AMPLIFY_WORKROOM_GET, get)
  yield takeEvery(types.AMPLIFY_WORKROOM_UPDATE, update)
  yield takeEvery(types.AMPLIFY_WORKROOM_UPLOAD_FILE, uploadFile)
  yield takeEvery(types.AMPLIFY_WORKROOM_UPLOAD_URL, uploadUrl)
  yield takeEvery(types.AMPLIFY_WORKROOM_DOWNLOAD_FILE, downloadFile)
  yield takeEvery(types.AMPLIFY_WORKROOM_DELETE_FILE, amplifyWorkroomDeleteFile)
  yield takeEvery(types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT, removeAmplifyWorkroomAttachment)
  yield takeEvery(types.AMPLIFY_WORKROOM_VAULT_UPLOAD, amplifyWorkroomVaultUpload)
}

export default {
  amplifyWorkroomSaga
}