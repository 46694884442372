import types from '../constants/portfolio'
import _ from 'lodash'


const initialState = {
  newPortfolio: {
    portfolioType: 0,
    link: '',
    linkName: '',
    titleName: ''
  },
  origin: {
  },
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case types.GET_SUCCESS:

  {
    return {
      ...state,
      origin: {
        ...state.origin,
        ..._.keyBy(action.payload.data, 'id')
      }
    }
  }


  case types.GET_FAILURE:

  {
    return {
      ...state,
      error: action.payload,
    }
  }

  case types.EDIT:
  {
    return {
      ...state,
      newPortfolio: {
        ...state.newPortfolio,  
        ...action.payload         
      }
    }
  }

  case types.CREATE:
  {
    return {
      ...state,
    }
  } 

  case types.CREATE_SUCCESS:
  {
    const portfolioItems = {...state.origin}
    portfolioItems[action.payload.id] = action.payload

    return {
      ...state,
      newPortfolio: {
        ...initialState.newPortfolio
      },
      origin: {...portfolioItems},
    }
  } 

  case types.CREATE_FAILURE:
  {
    return {
      ...state,
    }
  }

  case types.REMOVE_SUCCESS:
  {
    const portfolioItems = {...state.origin}
    delete portfolioItems[action.payload.id]

    return {
      ...state,
      origin: {...portfolioItems},
    }
  } 

  default:
    return state
  }

}