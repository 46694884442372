import types from '../constants/template'
import { validation } from 'helpers/validation'


export const get = (queryParams) => ({
  type: types.GET,
  payload: {
    queryParams
  }
})

export const getSuccess = ({ data }) => ({
  type: types.GET_SUCCESS,
  payload: {
    data
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const getId = (id) => ({
  type: types.GET_ID,
  payload: id
})

export const getIdSuccess = (data) => ({
  type: types.GET_ID_SUCCESS,
  payload: data
})

export const getIdFailure = (error) => ({
  type: types.GET_ID_FAILURE,
  payload: error
})

export const remove = (id) => ({
  type: types.REMOVE,
  payload: id
})

export const removeSuccess = (data) => ({
  type: types.REMOVE_SUCCESS,
  payload: data
})

export const removeFailure = (error) => ({
  type: types.REMOVE_FAILURE,
  payload: error
})

export const create = (data) => ({
  type: types.CREATE,
  payload: { ...data }
})

export const createSuccess = (data) => ({
  type: types.CREATE_SUCCESS,
  payload: { ...data }
})

export const createFailure = (error) => ({
  type: types.CREATE_FAILURE,
  payload: error
})

export const addTask = (data, isCreate) => ({
  type: types.ADD_TASK,
  payload: { data, isCreate }
})

export const updateTask = (data, index, isCreate) => ({
  type: types.UPDATE_TASK,
  payload: { data, index, isCreate }
})

export const removeTask = (id, isCreate) => ({
  type: types.REMOVE_TASK,
  payload: { id, isCreate }
})

export const update = (data, attachments) => ({
  type: types.UPDATE,
  payload: {
    ...data,
    attachments
  }
})

export const updateSuccess = (data) => ({
  type: types.UPDATE_SUCCESS,
  payload: data
})

export const updateFailure = (error) => ({
  type: types.UPDATE_FAILURE,
  payload: error
})

export const setValid = (model, rules, type, isCreate) => {
  const newModel = validation(model, rules)

  return {
    type: types.VALIDATION,
    payload: {
      data: {
        [type]: { ...newModel }
      },
      isCreate
    },
  }
}
