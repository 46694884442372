export default {

  INIT:         'SERVER:INIT',
  INIT_SUCCESS: 'INIT_SUCCESS',
  INIT_FAILURE: 'INIT_FAILURE',

  LEGAL: 'SERVER:LEGAL',
  LEGAL_SUCCESS: 'LEGAL_SUCCESS',
  LEGAL_FAILURE: 'LEGAL_FAILURE',

  INIT_AUTH:    'INIT_AUTH',

}
