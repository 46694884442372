import types from '../constants/profile'
import _ from 'lodash'


const initialState = {
  articleBriefs: {},
  nowWorkingBriefs: {},
  clients: {},
  feedbacks: {},
  user: {},
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case types.GET_SUCCESS:

  {
    return {
      ...state,
      articleBriefs: _.keyBy(action.payload.data.articleBriefs, 'id'),
      nowWorkingBriefs: _.keyBy(action.payload.data.nowWorkingBriefs, 'id'),
      clients: _.keyBy(action.payload.data.clients, 'id'),
      feedbacks: _.keyBy(action.payload.data.feedbacks, 'id'),
      user: action.payload.data.userInfo
    }
  }


  case types.GET_FAILURE:

  {
    return {
      ...state,
      error: action.payload,
    }
  }

  default:
    return state
  }

}