import types from 'store/constants/dashboard'

export const get = (params) => ({
  type: types.GET,
  payload: {
    params
  }
})

export const getSuccess = ({ data }) => ({
  type: types.GET_SUCCESS,
  payload: {
    data
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const setFilter = (data) => ({
  type: types.SET_FILTER,
  payload: {
    data
  }
})

export const setSort = (filterName, params) => ({
  type: types.SET_SORT,
  payload: {
    filterName, 
    params
  }
})

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
  payload: null
})

export const resetFilter = (nameFilter) => ({
  type: types.RESET_FILTER,
  payload: nameFilter
})

export const removeBySocket = (data) => ({
  type: types.REMOVE_BY_SOCKET,
  payload: data
})

export const repeatGet = (isValue) => ({
  type: types.IS_REPEAT_GET,
  payload: {
    isValue
  }
})

export const socketCauseCreate = (data) => ({
  type: types.SOCKET_CAUSE_CREATE,
  payload: data
})

export const socketAmplifyCauseCreate = (data) => ({
  type: types.SOCKET_AMPLIFY_CAUSE_CREATE,
  payload: data
})

export const socketAmplifyCauseUpdate = (data) => ({
  type: types.SOCKET_AMPLIFY_CAUSE_UPDATE,
  payload: data
})


export const getCreatorRecommendedBrief = (params) => ({
  type: types.GET_CREATOR_RECOMMENDED_BRIEF,
  payload: {
    params
  }
})

export const getCreatorRecommendedBriefSuccess = ({ data, count }) => ({
  type: types.GET_CREATOR_RECOMMENDED_BRIEF_SUCCESS,
  payload: {
    data,
    count
  }
})

export const getCreatorRecommendedBriefFailure = (error) => ({
  type: types.GET_CREATOR_RECOMMENDED_BRIEF_FAILURE,
  payload: error
})

export const getAmplifyRecommendedBrief = (params) => ({
  type: types.GET_AMPLIFY_RECOMMENDED_BRIEF,
  payload: {
    params
  }
})

export const getAmplifyRecommendedBriefSuccess = ({ data, count }) => ({
  type: types.GET_AMPLIFY_RECOMMENDED_BRIEF_SUCCESS,
  payload: {
    data,
    count
  }
})

export const getAmplifyRecommendedBriefFailure = (error) => ({
  type: types.GET_AMPLIFY_RECOMMENDED_BRIEF_FAILURE,
  payload: error
})

export const getCreatorPitchesBrief = (params) => ({
  type: types.GET_CREATOR_PITCHES_BRIEF,
  payload: {
    params
  }
})

export const getCreatorPitchesBriefSuccess = ({ data, count }) => ({
  type: types.GET_CREATOR_PITCHES_BRIEF_SUCCESS,
  payload: {
    data,
    count
  }
})

export const getCreatorPitchesBriefFailure = (error) => ({
  type: types.GET_CREATOR_PITCHES_BRIEF_FAILURE,
  payload: error
})

export const getEditorWaitingBrief = (params) => ({
  type: types.GET_EDITOR_WAITING_BRIEF,
  payload: {
    params
  }
})

export const getEditorWaitingBriefSuccess = ({ data, count }) => ({
  type: types.GET_EDITOR_WAITING_BRIEF_SUCCESS,
  payload: {
    data,
    count
  }
})

export const getEditorWaitingBriefFailure = (error) => ({
  type: types.GET_EDITOR_WAITING_BRIEF_FAILURE,
  payload: error
})
