import React, { useState, useEffect, useMemo, useCallback } from 'react'

import {
  useSelector,
  useDispatch,
} from 'react-redux'

import Checkbox from 'components/ui/Checkbox'
import Modal from 'components/ui/Modal'
import Button from 'components/ui/Button'

import { LegalButtons } from './LegalButtons'

import { ReactComponent as IconClose } from 'assets/images/close_icon.svg'

import * as authActions from 'store/actions/auth'
import * as userActions from 'store/actions/user'

import moment from 'moment'
import _ from 'lodash'

import './LegalModal.css'

export default (props) => {
  const { } = props

  const legal = useSelector((state) => state.init.legal)
  const user = useSelector((state) => state.auth.user)
  const { isTerms } = user;

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      !!legal
      && _.get(user, 'legalUrl.legalUrl.version', 0) < _.get(legal, 'version', 1)
      && moment(legal.activeDate) <= moment()
      && (isTerms === false)
    ) {
      Modal.showModal('legalModal')
    }
  }, [user, legal])

  const [isAcceptTerms, setIsAcceptTerms] = useState(false)

  const handleContinue = useCallback(() => {
    dispatch(userActions.acceptTerms());
    Modal.close()
  }, [])

  const handleCancel = useCallback(() => {
    if (legal.forceUpdate) {
      dispatch(authActions.signOut());
      Modal.close()
    } else {
      Modal.close()
    }
  }, [legal])

  const rendererContent = useMemo(() => {
    return (
      <>
        <div className="legal-modal-accept-terms-info">
          <p>
            The Fabulate Marketplace Terms and Conditions have been updated. A copy of the updated&nbsp;
            <a
              href={legal.termsLink}
              target="_blank"
              rel="noopener noreferrer">
              terms and conditions is available here
            </a>
            . Please read it carefully. You must agree the updated
            terms and conditions to continue using the Fabulate Platform.
          </p>
          <p>
            For a summary of these changes,&nbsp;
            <a
              href={legal.learnMoreLink}
              target="_blank"
              rel="noopener noreferrer">
              please click here
            </a>
            .
          </p>
          <p>
            By ticking the box and clicking ‘I AGREE’, you acknowledge and agree that:
          </p>
          <ul>
            <li>You have read and agree to the updated terms and conditions;</li>
            <li>
              You are over 18 years of age and have the legal
              capacity to enter into a legally binding agreement with us;
            </li>
          </ul>
          <p>
            If you are agreeing the updated terms and conditions and using our services on behalf
            of a company, your employer, an organisation, government or other legal entity
            (Represented Entity), you are binding the Represented Entity to the updated terms
            and conditions and are authorised to do so.
          </p>
        </div>
        <div className="legal-modal-accept-terms">
          <Checkbox
            onCheck={() => {
              setIsAcceptTerms(!isAcceptTerms)
            }}
            checked={isAcceptTerms}
          />
          <span>I accept the new terms of service</span>
        </div>
      </>
    )
  }, [isAcceptTerms])

  const currentData = useMemo(() => {
    return {
      headerTitle: 'Fabulate Terms and Conditions',
      headerInfo: null,
      content: (
        <>
          <div className="legal-modal-accept-content">{rendererContent}</div>
          <LegalButtons
            titleContinue={'Accept'}
            isContinueCloseModal={!legal.forceUpdate}
            isCancelCloseModal={legal.forceUpdate}
            onClickContinue={handleContinue}
            onClickCancel={handleCancel}
            isDisableContinue={!isAcceptTerms}
          />
        </>
      ),
    }
  }, [rendererContent, isAcceptTerms])
  
  return !!legal ? (
    <Modal
      idModal="legalModal"
      classNameModalDialog="legal-modal-dialog"
    >
      {
        legal.forceUpdate ? null : (
          <Button
            title={<IconClose className="legal-modal-cross-icon" />}
            data-toggle="modal"
            data-target="#legalModal"
            classes={{
              wrapper: 'legal-modal-cross-wrapper',
              button: 'legal-modal-cross',
            }}
          />
        )
      }
      <div className="legal-modal-header">
        <span className="legal-modal-header-title">{currentData.headerTitle}</span>
        {currentData.headerInfo && <span className="legal-modal-header-info">{currentData.headerInfo}</span>}
      </div>
      {currentData.content}
    </Modal>
  ) : null
}