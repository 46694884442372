import React, { Component } from 'react'
import { isMobile, isTablet, isAndroid, isIOS, isMobileOnly, isIPod13, isIPad13, isIPhone13, isIOS13 } from 'react-device-detect';
import _ from 'lodash'
import {clearLocalStorage} from 'helpers/tools'

import App from './App'
import Splash from '../Splash'
import OfflineError from 'components/errors/Offline'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as initActions from 'store/actions/init'
import * as authActions from 'store/actions/auth'
import * as causeActions from 'store/actions/cause'
import * as chatActions from 'store/actions/chat'
import * as productActions from 'store/actions/product'
import * as routerActions from 'store/actions/router'

import { appAnalytics } from '../../../helpers/appAnalytics'

import backend from 'store/api/feathers'
import Mobile from '../Mobile'

class AppContainer extends Component {

  componentDidMount() {
    const {
      initActions,
    } = this.props

    appAnalytics.init()
    
    initActions.init()
    clearLocalStorage()
  }

  componentDidUpdate() {
    const {
      initActions,
      authActions,
      appStatus,
      authStatus,
    } = this.props
    if(appStatus && !authStatus) {
      backend.authenticate().then((payload) => {
        authActions.signIn(null, null, payload.accessToken)
      }).catch(() => {
        authActions.signOut()
        initActions.initAuth()
      })
    }
  }

  render() {
    const {
      appStatus,
      authStatus,
    } = this.props

    if (isMobileOnly) {
      return <Mobile />
    }
    return appStatus && authStatus 
      ? <App {...this.props} /> 
      : (
        appStatus || _.isNull(appStatus) 
          ? <Splash /> 
          : <OfflineError />
      )
  }
}

const mapStateToProps = (state, props) => {
  const currentUser = state.auth && state.auth.user ? state.auth.user : undefined
  const currentWorkspace = _.get(state, 'auth.workspace')
  const hasProducts = !_.isEmpty(state.product.origin)
  return {
    appStatus:   state.init.status,
    authStatus:  state.init.authStatus,
    accessToken: state.auth.accessToken,
    loggedUser:  state.auth.user,
    currentUser: currentUser,
    messagesCount: _.size(state.chat.unreadedMessages),
    hasProducts,
    currentWorkspace
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    initActions: bindActionCreators(initActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    causeActions: bindActionCreators(causeActions, dispatch),
    chatActions: bindActionCreators(chatActions, dispatch),
    productActions: bindActionCreators(productActions, dispatch),
    routerActions: bindActionCreators(routerActions, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer))
