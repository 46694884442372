import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/contentCategory'
import * as actions from '../actions/contentCategory'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const contentCategoryList = yield backend.service('content-category').find({
      query: action.payload.queryParams
    })
    if(_.size(contentCategoryList)) {
      yield put(actions.getSuccess(contentCategoryList))

    }

  } catch (e) {
    renderErrorNotification(e, 'Get content category list error')
    yield put(actions.getFailure(e))
  }
}

function* contentCategorySaga() {
  yield takeEvery(types.GET, get)
}

export default {
  contentCategorySaga,
}
