import types from '../constants/model'

export const getModel = () => ({
  type: types.MODEL,
  payload: null,
})

export const getModelSuccess = data => ({
  type: types.MODEL_SUCCESS,
  payload: data,
})

export const getModelFailure = (err) => ({
  type: types.MODEL_FAILURE,
  payload: err,
})

