import types from '../constants/notification'
import authTypes from '../constants/auth'
import _ from 'lodash'

const initialState = {
  origin: {},
  count: null,
  error: null,
  filter: {
    isNew: undefined,
  },
  page: 1,
  isGetFetching: false
}

export default (state = initialState, action) => {

  switch (action.type) {
  case types.NOTIFICATION_GET:
  { 
    return {
      ...state,
      isGetFetching: true
    }    
  }

  case types.NOTIFICATION_GET_SUCCESS:
  {
    return {
      ...state,
      origin: {
        // ...state.origin,
        ..._.keyBy(action.payload.data, 'id')
      },
      count: action.payload.count,
      isGetFetching: false
    }
  }

  case types.NOTIFICATION_GET_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
      isGetFetching: false      
    }
  }
  
  case types.NOTIFICATION_UPDATE_SUCCESS:
  {
    const data = action.payload
    return {
      ...state,
      origin: {
        ...state.origin,
        [data.id]: {
          ...data
        }
      },
      isGetFetching: false
    }
  }

  case types.NOTIFICATION_REMOVE:
  {
    const { notificationId } = action.payload;

    return {
      ...state,
      origin: {
        ...state.origin,
        [notificationId]: {
          ...state.origin[notificationId],
          isRemoving: true
        }
      }
    }
  }

  case types.NOTIFICATION_REMOVE_SUCCESS:
  {
    const id = action.payload.notificationId

    const filteredNotifications = _.chain(state.origin)
      .filter(item => item.id !== id)
      .keyBy('id')
      .value()

    return {
      ...state,
      origin: {
        ...filteredNotifications,
      },
    }

  }

  case authTypes.SIGNOUT_SUCCESS:
  {
    return {
      ...initialState,
    }
  }

  case types.NOTIFICATION_REMOVE_FAILURE:
  {
    const { error, notificationId } = action.payload

    return {
      ...state,
      origin: {
        ...state.origin,
        [notificationId]: {
          ...state.origin[notificationId],
          isRemoving: false
        }
      },
      error: error,
    }
  }

  case types.NOTIFICATION_SET_PAGE:
    {
      const page = action.payload
      return {
        ...state,
        page
      }
    }

  default:
    return state
  }
}
