import types from '../constants/vaultCause'

const initialState = {
  isGetFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.POST_BRIEF:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }
    case types.POST_BRIEF_SUCCESS:
      {
        // Todo:
        return {
          ...state,
        }
      }
    case types.POST_BRIEF_FAILURE:
      {
        return {
          ...state,
          isGetFetching: false,
        }
      }
    default:
      return {
        ...state
      }
  }
}