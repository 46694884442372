import React, { useCallback } from 'react'

import { Link } from 'react-router-dom'

import moment from 'moment'

import { setSelectedConversation } from 'store/actions/chat'

import avatar from 'assets/images/image.png'
import { useDispatch } from 'react-redux'

export const MessageNotification = props => {
  const {
    conversation: { id: conversationId },
    sender: { photoUrl, name },
    body,
    timestamp
  } = props

  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(setSelectedConversation(conversationId))
  }, [dispatch, conversationId])

  return (
    <li
      className="media"
      onClick={handleClick}
    >
      <Link className="text-default" to="/messages">
        <div className="media-left">
          <img
            src={photoUrl || avatar}
            className="img-circle img-sm"
            alt="Your avatar"
          ></img>
        </div>

        <div className="media-body">
          {`New message from:  ${name} `}
          <div className="body-length">{body}</div>
          <div className="media-annotation">
            {moment(timestamp).format('MM/DD/YYYY HH:mm')}
          </div>
        </div>
      </Link>
    </li>
  )
}
