import types from 'store/constants/dashboard'

import _ from 'lodash'

//flags for check loaded data
const fetching = {
  isGetFetching: false,
  isCreatorRecommendedBriefFetching: false,
  isAmplifyRecommendedBriefFetching: false,
  isCreatorPitchBriefFetching: false,
  isEditorWaitingBriefFetching: false
}

const initialState = {
  origin: {
    amplifyBriefs: {
      rows: [],
      count: 0
    },
    recommendedBriefs: {
      data: [],
      count: 0,
    }
  },
  error: null,
  ...fetching,
  filters: {
    recommendedFilter: {
      sort: undefined
    },
    pitchesFilter: {
      sort: undefined
    },
    waitingFilter: {
      sort: undefined
    },
    amplifyBriefsFilter: {
      sort: 'createdAt',
      briefsPage: 0,
      isAccepted: true
    }
  },
  isRepeatGet: false
}

export default (state = initialState, action) => {
  switch (action.type) {

    case types.GET:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.GET_SUCCESS:
      {
        return {
          ...state,
          origin: {
            ...state.origin,
            ...action.payload.data,
            userBriefs: {
              ...state.origin.userBriefs,
              ...action.payload.data.userBriefs,
              data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            }
          },
          isGetFetching: false
        }
      }

    case types.SET_FILTER:
      {
        return {
          ...state,
          filters: {
            ...state.filters,
            ...action.payload.data,
          },
        }
      }

    case types.RESET_FILTERS:
      {
        return {
          ...state,
          filters: { ...initialState.filters },
        }
      }

    case types.RESET_FILTER:
      {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload]: initialState.filters[action.payload]
          }
        }
      }

    case types.SET_SORT:
      {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload.filterName]: {
              ...state.filters[action.payload.filterName],
              sort: action.payload.params
            }
          }
        }
      }

    case types.REMOVE_BY_SOCKET:
      {
        const deletedBrief = action.payload
        let newOrigin = state.origin

        if (!_.isNil(deletedBrief)) {
          if (!_.isNil(newOrigin.userBriefs)) {
            newOrigin.userBriefs.data = _.filter(newOrigin.userBriefs.data, item => item.id !== deletedBrief.id)
          }
          if (!_.isNil(newOrigin.recommendedBriefs)) {
            newOrigin.recommendedBriefs.data = _.filter(newOrigin.recommendedBriefs.data, item => item.id !== deletedBrief.id)
          }

          return {
            ...state,
            origin: {
              ...newOrigin
            }
          }
        }

        return state
      }

    case types.IS_REPEAT_GET:
      {
        const { isValue } = action.payload
        return { ...state, isRepeatGet: isValue }

      }

    case types.SOCKET_CAUSE_CREATE:
      {
        const currentRecomendedBriefs = _.get(state, 'origin.recommendedBriefs.data')
        if (currentRecomendedBriefs.length > 5) currentRecomendedBriefs.pop()

        return {
          ...state,
          origin: {
            ...state.origin,
            recommendedBriefs: {
              ...state.origin.recommendedBriefs,
              data: [action.payload, ...currentRecomendedBriefs]
            }
          }
        }
      }

    case types.SOCKET_AMPLIFY_CAUSE_CREATE:
      {
        const currentRecomendedBriefs = _.get(state, 'origin.recommendedBriefs.rows')
        if (currentRecomendedBriefs.length > 5) currentRecomendedBriefs.pop()

        return {
          ...state,
          origin: {
            ...state.origin,
            recommendedBriefs: {
              ...state.origin.recommendedBriefs,
              rows: [action.payload, ...currentRecomendedBriefs]
            }
          }
        }
      }

    case types.SOCKET_AMPLIFY_CAUSE_UPDATE:
      {
        const currentRecomendedBriefs = _.get(state, 'origin.recommendedBriefs.rows')
        const index = _.findIndex(currentRecomendedBriefs, brief => brief.id === action.payload.id)

        if (!(index === -1)) {
          return {
            ...state,
            origin: {
              ...state.origin,
              recommendedBriefs: {
                ...state.origin.recommendedBriefs,
                rows: {
                  ...state.origin.recommendedBriefs.rows,
                  [index]: {
                    ...action.payload
                  }
                }
              }
            }
          }
        }

        return state
      }

    case types.GET_CREATOR_RECOMMENDED_BRIEF:
      {
        return {
          ...state,
          isCreatorRecommendedBriefFetching: true
        }
      }

    case types.GET_CREATOR_RECOMMENDED_BRIEF_SUCCESS:
      {
        return {
          ...state,
          origin: {
            ...state.origin,
            ...action.payload.data,
            recommendedBriefs: {
              ...state.origin.recommendedBriefs,
              data: action.payload.data,
              count: action.payload.count
              // data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            }
            // userBriefs: {
            //   ...state.origin.userBriefs,
            //   ...action.payload.data.userBriefs,
            //   data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            // }
          },
          isCreatorRecommendedBriefFetching: false
        }
      }

    case types.GET_CREATOR_RECOMMENDED_BRIEF_FAILURE:
      {
        return {
          ...state,
          isCreatorRecommendedBriefFetching: false,
          error: action.payload
        }
      }

    case types.GET_AMPLIFY_RECOMMENDED_BRIEF:
      {
        return {
          ...state,
          isAmplifyRecommendedBriefFetching: true
        }
      }

    case types.GET_AMPLIFY_RECOMMENDED_BRIEF_SUCCESS:
      {
        return {
          ...state,
          origin: {
            ...state.origin,
            ...action.payload.data,
            recommendedBriefs: {
              ...state.origin.recommendedBriefs,
              rows: action.payload.data,
              count: action.payload.count
              // data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            }
            // userBriefs: {
            //   ...state.origin.userBriefs,
            //   ...action.payload.data.userBriefs,
            //   data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            // }
          },
          isAmplifyRecommendedBriefFetching: false
        }
      }

    case types.GET_AMPLIFY_RECOMMENDED_BRIEF_FAILURE:
      {
        return {
          ...state,
          isAmplifyRecommendedBriefFetching: false,
          error: action.payload
        }
      }

    case types.GET_CREATOR_PITCHES_BRIEF:
      {
        return {
          ...state,
          isCreatorPitchBriefFetching: true
        }
      }

    case types.GET_CREATOR_PITCHES_BRIEF_SUCCESS:
      {
        return {
          ...state,
          origin: {
            ...state.origin,
            ...action.payload.data,
            userPitches: {
              ...state.origin.userPitches,
              data: action.payload.data,
              count: action.payload.count
              // data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            }
            // userBriefs: {
            //   ...state.origin.userBriefs,
            //   ...action.payload.data.userBriefs,
            //   data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            // }
          },
          isCreatorPitchBriefFetching: false
        }
      }

    case types.GET_CREATOR_PITCHES_BRIEF_FAILURE:
      {
        return {
          ...state,
          isCreatorPitchBriefFetching: false,
          error: action.payload
        }
      }

    case types.GET_EDITOR_WAITING_BRIEF:
      {
        return {
          ...state,
          isEditorWaitingBriefFetching: true
        }
      }

    case types.GET_EDITOR_WAITING_BRIEF_SUCCESS:
      {
        return {
          ...state,
          origin: {
            ...state.origin,
            ...action.payload.data,
            waitingBriefs: {
              ...state.origin.waitingBriefs,
              data: action.payload.data,
              count: action.payload.count
              // data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            }
            // userBriefs: {
            //   ...state.origin.userBriefs,
            //   ...action.payload.data.userBriefs,
            //   data: _.keyBy(_.get(action.payload.data.userBriefs, 'data'), 'id')
            // }
          },
          isEditorWaitingBriefFetching: false
        }
      }

    case types.GET_EDITOR_WAITING_BRIEF_FAILURE:
      {
        return {
          ...state,
          isEditorWaitingBriefFetching: false,
          error: action.payload
        }
      }

    default:
      return state
  }
}
