import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from 'store/constants/amplifyCause'
import * as actions from 'store/actions/amplifyCause'
import { renderErrorNotification } from 'helpers/tools'
import backend from 'store/api/feathers'

import 'pnotify/dist/pnotify.buttons'

function* get(action) {
  const { query, page } = action.payload
  try {
    const amplifyCauseData = yield backend.service('amplify-cause').find({
      query: {
        ...query,
        page
      }
    })
    if (_.size(amplifyCauseData)) {
      yield put(actions.getSuccess(amplifyCauseData, page))
    }
  } catch(e) {
    yield put(actions.getFailure(e))
  }
}

function* getId(action) {
  const { payload: id } = action
  try {
    const amplifyCauseItem = yield backend.service('amplify-cause').get(id)
    if (_.size(amplifyCauseItem)) {
      yield put(actions.getIdSuccess(amplifyCauseItem))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.getIdFailure(e))
  }
}

function* updateAmplifyCause(action) {
  const { payload: { id, data, done } } = action
  try {
    const updatedAmplifyCause = yield backend.service('amplify-cause').patch(id, data)
    if (_.size(updatedAmplifyCause)) {
      done && done()
      yield put(actions.updateSuccess(updatedAmplifyCause))
    }
  } catch (e) {
    renderErrorNotification(e)
    yield put(actions.updateFailure(e))
  }
}

function* amplifyCauseSaga() {
  yield takeEvery(types.AMPLIFY_CAUSE_UPDATE, updateAmplifyCause)
  yield takeEvery(types.AMPLIFY_CAUSE_GET_ID, getId)
  yield takeEvery(types.AMPLIFY_CAUSE_GET, get)
}

export default {
  amplifyCauseSaga
}