import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import * as actions from '../actions/feedback'
import types from '../constants/feedback'
import { renderErrorNotification } from 'helpers/tools'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'

import backend from 'store/api/feathers'

function* getId(action) {

  try {
    const queryParams = action.payload.queryParams

    const feedbackList = yield backend.service('feedback').find({
      query: {
        ...queryParams,
      }
    })
    const result = _.size(feedbackList.data) ? feedbackList.data[0] : {}
    yield put(actions.getIdSuccess(result))

  } catch (e) {
    renderErrorNotification(e, 'Get feedback by id error')    
    yield put(actions.getIdFailure(e))
  }
    
}

function* create(action) {

  try {
    const data = action.payload.data

    const feedback = yield backend.service('feedback').create({...data})
    if(_.size(feedback)) {
      yield put(actions.createSuccess(feedback))
    }

    new PNotify({
      addclass: 'bg-teal',
      text: 'Your feedback was successfully created.',
      hide: true,
      buttons: {
        closer: true,
        sticker: true,
      },       
    })
  
  } catch (e) {
    renderErrorNotification(e, 'Create feedback error')
    yield put(actions.createFailure(e))
  }
  
}
    
function* feedbackSaga() {
  yield takeEvery(types.GET_ID, getId)
  yield takeEvery(types.CREATE, create)
}
    
export default {
  feedbackSaga,
}
    