import React, { memo, useMemo, useCallback } from 'react'

import _ from 'lodash'

import RadioButton from 'components/ui/Radiobutton'
import Select from 'components/ui/Select'

import { buyerCausePermissions } from 'helpers/params'

import avatar from 'assets/images/image.png'
import { ReactComponent as RedCrossIcon } from 'assets/images/icons/red-cross-icon.svg'

export const User = memo((props) => {
  const { 
    userName,
    image,
    userPermission,
    onChangeUserPermission,
    userId,
    onChooseUser,
  } = props

  const isNilPermission = _.isNil(userPermission)
  const isActive = _.get(userPermission, 'isActive')

  const handleChoose = useCallback(() => {
    if (!userPermission) {
      onChooseUser(userId, 0)
    } else {
      onChooseUser(userId, userPermission)
    }
  }, [onChooseUser, userId, userPermission])

  const handleChangePermission = useCallback(({value}) => {
    onChangeUserPermission(userId, userPermission, {
      permission: value
    })
  }, [onChangeUserPermission, userId, userPermission])

  const handleDeletePermission = useCallback(() => {
    onChangeUserPermission(userId, userPermission, {
      isActive: false
    })
  }, [onChangeUserPermission,userId, userPermission])

  const rendererSelectPermission = useMemo(() => {
    const currentValue = !isNilPermission ? _.get(buyerCausePermissions, `${userPermission.permission}.name`) : null

    if(!isNilPermission && isActive) {
      return <Select
        cantReset
        items={buyerCausePermissions}
        onSelect={handleChangePermission}
        value={currentValue} />
    } else {
      return null
    }
  }, [isNilPermission, isActive, handleChangePermission, userPermission])

  return (
    <div className="manage-users-modal-users-user">
      <div className="manage-users-modal-users-user-info">
        <img
          src={image || avatar}
          className="manage-users-modal-users-user-avatar"
          alt={userName}
        />
        <span className="manage-users-modal-users-user-name">{userName}</span>
      </div>
      <div className="manage-users-modal-users-actions">
        {rendererSelectPermission}
        {isNilPermission || !isActive ? 
          <RadioButton onCheck={handleChoose} checked={false} />
          : <RedCrossIcon onClick={handleDeletePermission} />}
      </div>
    </div>
  )
})
