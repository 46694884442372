import types from '../constants/contentCategory'
import _ from 'lodash'


const initialState = {
  origin: {},
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case types.GET_SUCCESS:
  {
    return {
      ...state,
      origin: _.keyBy(action.payload.data, 'id'),
    }
  }

  case types.GET_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
    }
  }

  default:
    return state
  }

}