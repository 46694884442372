export default {

  WORKROOM_GET_ID:                 'SERVER:WORKROOM_GET_ID',
  WORKROOM_GET_ID_SUCCESS:         'WORKROOM_GET_ID_SUCCESS',
  WORKROOM_GET_ID_FAILURE:         'WORKROOM_GET_ID_FAILURE',

  WORKROOM_UPDATE:                 'SERVER:WORKROOM_UPDATE',
  WORKROOM_UPDATE_SUCCESS:         'WORKROOM_UPDATE_SUCCESS',
  WORKROOM_UPDATE_FAILURE:         'WORKROOM_UPDATE_FAILURE',

  WORKROOM_EDIT:                   'WORKROOM_EDIT',
  VALIDATION:                      'WORKROOM_VALIDATION',

  ATTACH:                          'WORKROOM_ATTACH',

  UPLOAD:                          'SERVER:WORKROOM_UPLOAD',
  UPLOAD_SUCCESS:                  'WORKROOM_UPLOAD_SUCCESS',
  UPLOAD_FAILURE:                  'WORKROOM_UPLOAD_FAILURE',

  UPLOAD_DESIGN:                   'SERVER:UPLOAD_DESIGN',
  UPLOAD_DESIGN_SUCCESS:           'UPLOAD_DESIGN_SUCCESS',
  UPLOAD_DESIGN_FAILURE:           'UPLOAD_DESIGN_FAILURE',

  DOWNLOAD_FILE:                    'SERVER:WORKROOM_DOWNLOAD_FILE',
  DOWNLOAD_FILE_SUCCESS:            'WORKROOM_DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE:            'WORKROOM_DOWNLOAD_FILE_FAILURE',

  INVITE_PERSON:                    'SERVER:INVITE_PERSON',
  INVITE_PERSON_SUCCESS:            'WORKROOM_INVITE_PERSON_SUCCESS',
  INVITE_PERSON_FAILURE:            'WORKROOM_INVITE_PERSON_FAILURE',

  SKIP_STEPS:                       'SERVER:WORKROOM_SKIP_STEPS',
  SKIP_STEPS_SUCCESS:               'WORKROOM_SKIP_STEPS_SUCCESS',
  SKIP_STEPS_FAILURE:               'WORKROOM_SKIP_STEPS_FAILURE',
  UPDATE_WORKROOM_ENTITY:           'SERVER:UPDATE_WORKROOM_ENTITY',
  UPDATE_WORKROOM_ENTITY_SUCCESS:   'UPDATE_WORKROOM_ENTITY_SUCCESS',

  WORKROOM_SELECTED_ARTICLE:        'WORKROOM_SELECTED_ARTICLE',

  WORKROOM_UPLOAD_PROGRES: 'WORKROOM_UPLOAD_PROGRES',

  WORKROOM_DELETE_FILE: 'WORKROOM_DELETE_FILE',
  WORKROOM_DELETE_FILE_SUCCESS: 'WORKROOM_DELETE_FILE_SUCCESS',
  WORKROOM_DELETE_FILE_FAILURE: 'WORKROOM_DELETE_FILE_FAILURE',

  CURRENT_UPLOADING_API_CALLS: 'CURRENT_UPLOADING_API_CALLS',
  CLEAR_UPLOADING_API_CALLS: 'CLEAR_UPLOADING_API_CALLS',

  ADD_VAULT_FILES_WORKROOM: 'ADD_VAULT_FILES_WORKROOM',

  GET_GETTY_IMAGE_DOWNLOAD_COUNT: 'GET_GETTY_IMAGE_DOWNLOAD_COUNT',
  GET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS: 'GET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS',
  GET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE: 'GET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE',

  SET_GETTY_IMAGE_DOWNLOAD_COUNT: 'SET_GETTY_IMAGE_DOWNLOAD_COUNT',
  SET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS: 'SET_GETTY_IMAGE_DOWNLOAD_COUNT_SUCCESS',
  SET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE: 'SET_GETTY_IMAGE_DOWNLOAD_COUNT_FAILURE'
}
