import types from '../constants/profile'

export const get = (params) => ({
  type: types.GET,
  payload: {
    params
  }
})

export const getSuccess = ({ data }) => ({
  type: types.GET_SUCCESS,
  payload: {
    data
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})