import types from '../constants/cause'
import userTypes from '../constants/user'
import authTypes from '../constants/auth'
import _ from 'lodash'

const filterAttachments = (list) => {
  const systemAttachmentList = []
  const vaultBrandGuidelinesList = []
  const vaultRelevantFilesList = []

  list && list.map(item => {
    if (item.fieldType === 1 || item.fieldType === 2) {
      systemAttachmentList.push(item)
    } else if (item.fieldType === 3) {
      vaultBrandGuidelinesList.push(item)
    } else if (item.fieldType === 4) {
      vaultRelevantFilesList.push(item)
    }
  })

  return { systemAttachmentList, vaultBrandGuidelinesList, vaultRelevantFilesList }
}

//NOTE: validation model
export const validationParams = {
  id: {
    value: undefined,
    isValid: undefined,
  },
  ownerId: {
    value: undefined,
    isValid: undefined,
  },
  editorId: {
    value: undefined,
    isValid: undefined,
    permissions: [4]
  },
  causeName: {
    value: null,
    isValid: undefined,
  },
  causeDueDate: {
    value: null,
    isValid: undefined,
  },
  causePitchDueDate: {
    value: null,
    isValid: undefined,
  },
  causeBrand: {
    value: null,
    isValid: undefined,
  },
  causeBrandURL: {
    value: null,
    isValid: undefined,
  },
  causeBrandAbout: {
    value: '',
    isValid: undefined
  },
  causeIndustry: {
    value: null,
    isValid: undefined,
  },
  causeContent: {
    value: null,
    isValid: undefined,
  },
  causePurpose: {
    value: null,
    isValid: undefined,
  },
  causeWorkspaceObjective: {
    value: null,
    isValid: undefined,
  },
  causeContentIdea: {
    value: null,
    isValid: undefined,
  },
  causeMarketing: {
    value: null,
    isValid: undefined,
  },
  causeContentType: {
    value: null,
    isValid: undefined,
  },
  causeAudienceGender: {
    value: [],
    isValid: undefined,
  },
  causeAudienceAge: {
    value: [],
    isValid: undefined,
  },
  causeAudienceOtherAge: {
    value: null,
    isValid: undefined
  },
  causeAudienceDetail: {
    value: null,
    isValid: undefined,
  },
  causeAudienceLocation: {
    value: [],
    isValid: undefined,
  },
  causeAudienceLocationCountry: {
    value: 12,
    isValid: undefined,
  },
  causeAudienceFeel: {
    value: null,
    isValid: undefined,
  },
  causeAudienceKeyMessage: {
    value: null,
    isValid: undefined,
  },

  causeContentShould: {
    value: null,
    isValid: undefined,
  },
  causeContentNever: {
    value: null,
    isValid: undefined,
  },
  causeContentTraditional: {
    value: null,
    isValid: undefined,
  },
  causeContentMature: {
    value: null,
    isValid: undefined,
  },
  causeContentFeminine: {
    value: null,
    isValid: undefined,
  },
  causeContentPlayful: {
    value: null,
    isValid: undefined,
  },
  causePutches: {
    value: null,
    isValid: undefined,
  },
  causeDwellTime: {
    value: null,
    isValid: undefined,
  },
  causePageImpressions: {
    value: null,
    isValid: undefined,
  },
  causeTargetNewCustomer: {
    value: null,
    isValid: undefined,
  },
  causeEngagement: {
    value: null,
    isValid: undefined,
  },
  causeIncrease: {
    value: null,
    isValid: undefined,
  },
  causeBrandConsideration: {
    value: null,
    isValid: undefined,
  },
  causeLaunchProduct: {
    value: null,
    isValid: undefined,
  },
  causeOtherSuccess: {
    value: null,
    isValid: undefined,
  },
  causeFormat: {
    value: null,
    isValid: undefined,
  },
  causeFavoriteExamples: {
    value: null,
    isValid: undefined,
  },
  causeFormatQty: {
    value: null,
    isValid: undefined,
  },
  causeOtherServices: {
    value: null,
    isValid: undefined,
  },
  causeOtherServicesQty: {
    value: null,
    isValid: undefined,
  },
  causeContentBoosterBundle: {
    value: false,
    isValid: undefined,
  },
  causeEcpertHelp: {
    value: false,
    isValid: undefined,
  },
  causeGetSooner: {
    value: false,
    isValid: undefined,
  },
  causeNonDisclosure: {
    value: false,
    isValid: undefined,
  },
  causeStatus: {
    value: null,
    isValid: undefined,
  },
  currentMilestoneTask: {
    value: null,
    isValid: undefined,
    permissions: [3]
  },
  currentMilestoneStatus: {
    value: null,
    isValid: undefined,
    permissions: [3]
  },
  products: {
    value: [],
    isValid: undefined,
  },
  experienceContractor: {
    value: 1,
    isValid: undefined,
  },
  causeSum: {
    value: null,
    isValid: undefined,
  },
  xeroInvoicesId: {
    value: null,
    isValid: undefined,
  },
  xeroBillId: {
    value: null,
    isValid: undefined,
  },
  xeroSumBuyer: {
    value: null,
    isValid: undefined,
  },
  xeroSumContractor: {
    value: null,
    isValid: undefined,
  },
  causePO: {
    value: null,
    isValid: undefined,
  },
  userEmail: {
    value: null,
    isValid: undefined
  },
  causePublishedDestination: {
    value: null,
    isValid: undefined
  },
  causeBiline: {
    value: false,
    isValid: true

  },
  causeWriter: {
    value: null,
    isValid: undefined
  },
  causeSeo: {
    value: null,
    isValid: undefined
  },
  causeFurtherUrl: {
    value: null,
    isValid: undefined
  },
  isBuyerUnlimited: {
    value: null,
    isValid: undefined
  },
  genre: {
    value: null,
    isValid: undefined
  },
  workType: {
    value: null,
    isValid: undefined
  },
  product: {
    value: null,
    isValid: undefined
  },
  causeType: {
    value: 'public',
    isValid: undefined,
  },
  causeWorkroomType: {
    value: 0,
    isValid: undefined,
  },
  amplifyCauses: {
    value: null,
    isValid: undefined
  }
}

//NOTE: flags for check loaded data
const fetching = {
  isGetFetching: false,
  isGetIdFetching: false,
  isGetDeletingFile: false
}

const initialState = {
  origin: {},
  list: {
    origin: {},
    page: 0,
    count: 0,
  },
  totalBriefsImpressions: {},
  error: null,
  edit: {},
  requestContractor: {
    bid: null,
    question: null,
  },
  validationEdit: {
    ...validationParams
  },
  validationCreate: {
    ...validationParams
  },
  search: {
    selectedCreate: {
      template: {}
    },
    selectedEdit: {
      template: {}
    },
    template: []
  },
  filter: {
    causeStatus: [],
    causeType: [],
    invited: undefined,
    hire: undefined,
    page: 0
  },
  sort: 'createdAt',
  attachmentsCreate: [],
  attachmentsEdit: [],

  //NOTE: dashboard
  active: {},
  inprogress: {},
  closed: {},

  newCause: {},
  pitchCause: {},
  progressCause: {},

  selectedMilestone: null,
  taskInfo: {},

  uploadCountList: [],

  deletedFile: {},
  currentUploadingApiCallData: [],

  vaultUploadFiles: {
    brandGuidelines: [],
    relevantFiles: []
  },

  causeEditDeletedFiles: [],

  ...fetching
}

export default (state = initialState, action) => {

  switch (action.type) {

    case types.SET_CAUSE_BRAND_INFORMATION: {
      const data = action.payload || {}
      return {
        ...state,
        validationCreate: {
          ...state.validationCreate,
          causeBrand: {
            value: data.causeBrand,
            isValid: undefined
          },
          causeBrandURL: {
            value: data.causeBrandURL,
            isValid: undefined
          },
          causeIndustry: {
            value: data.causeIndustry,
            isValid: undefined
          }
        }
      }
    }

    case types.SET_CAUSE_TARGET_INFORMATION: {
      const data = action.payload || {}

      return {
        ...state,
        validationCreate: {
          ...state.validationCreate,
          causeContentShould: {
            value: data.causeContentShould,
            isValid: undefined
          },
          causeContentNever: {
            value: data.causeContentNever,
            isValid: undefined
          },
          causeBiline: {
            value: data.causeBiline,
            isValid: undefined
          },
          causeContentTraditional: {
            value: data.causeContentTraditional,
            isValid: undefined,
          },
          causeContentMature: {
            value: data.causeContentMature,
            isValid: undefined,
          },
          causeContentFeminine: {
            value: data.causeContentFeminine,
            isValid: undefined,
          },
          causeContentPlayful: {
            value: data.causeContentPlayful,
            isValid: undefined,
          },
        }
      }
    }

    case types.CAUSE_GET:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.CAUSE_GET_SUCCESS:
      {
        return {
          ...state,
          list: {
            ...state.list,
            origin: _.keyBy(action.payload.data, 'id'),
            count: action.payload.count
          },
          isGetFetching: false
        }
      }

    case types.CAUSE_GET_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetFetching: false
        }
      }

    case types.CAUSE_GET_DASHBOARD_SUCCESS:
      {
        //NOTE: set data for dashboard
        let dashboard = {}
        if (action.payload.subName) {
          dashboard = {
            [action.payload.subName]: {
              ...state[action.payload.subName],
              ..._.keyBy(action.payload.data, 'id')
            }
          }
        }

        return {
          ...state,
          ...dashboard,
          isGetFetching: false
        }
      }

    case types.CAUSE_GET_TASK_SUCCESS:
      {
        return {
          ...state,
          taskInfo: { ...action.payload }
        }
      }

    case types.CAUSE_CREATE:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.GET_TOTAL_VIEW_SUCCESS:
      {
        const payload = action.payload
        return {
          ...state,
          totalBriefsImpressions: {
            ...state.totalBriefsImpressions,
            [payload.id]: payload.value,
          }
        }
      }

    case types.CAUSE_CREATE_CLEAR:
      {
        return {
          ...initialState
        }
      }

    case types.CAUSE_REMOVE_SUCCESS:
      {
        const payload = action.payload
        const newOrigin = { ...state.list.origin }
        delete newOrigin[payload.id]
        return {
          ...state,
          list: {
            ...state.list,
            origin: { ...newOrigin }
          }
        }
      }

    case types.CAUSE_CREATE_SUCCESS:
      {
        const payload = action.payload
        return {
          ...state,
          list: {
            ...state.list,
            origin: {
              ...state.list.origin,
              [payload.id]: payload
            },
          },
          search: {
            ...state.search,
            selectedCreate: {
              ...initialState.search.selectedCreate
            }
          },
          validationCreate: {
            ...initialState.validationCreate
          },
          attachmentsCreate: initialState.attachmentsCreate,
          isGetFetching: false
        }
      }

    case types.CAUSE_REMOVE_SUCCESS:
      {
        const payload = action.payload
        const newOrigin = { ...state.list.origin }
        delete newOrigin[payload.id]
        return {
          ...state,
          list: {
            ...state.list,
            origin: { ...newOrigin }
          }
        }
      }

    case types.CAUSE_UPDATE:
      {
        return {
          ...state,
          isGetFetching: true
        }
      }

    case types.CAUSE_UPDATE_SUCCESS:
      {
        const payload = action.payload.data

        const newEditData = _.mapValues(state.edit, (item, key) => {
          const newValueEdit = _.get(payload, key)
          return newValueEdit || item
        })

        return {
          ...state,
          list: {
            ...state.list,
            origin: {
              ...state.list.origin,
              [payload.id]: {
                ...state.list.origin[payload.id],
                ...payload
              }
            },
          },
          edit: {
            ...state.edit,
            ...newEditData,
            hiredUserId: payload.hiredUserId
          },
          isGetFetching: false,
        }
      }

    case types.SELECT_MILESTONE:
    case types.UPDATE_SELECT_MILESTONE:
      {
        return {
          ...state,
          selectedMilestone: action.payload
        }
      }

    case types.CAUSE_GET_ID: {
      return {
        ...state,
        edit: {
          ...initialState.edit
        },
        search: {
          ...initialState.search
        },
        validationEdit: {
          ...initialState.validationEdit
        },
        attachmentsEdit: initialState.attachmentsEdit,
        isGetIdFetching: true
      }
    }

    case types.CAUSE_GET_ID_SUCCESS:
      {

        const payload = action.payload.data
        const buyerId = payload.userCause ? payload.userCause.id : null
        const causeUserCause = payload.causeUserCause ? payload.causeUserCause : null

        //NOTE: data coercion to a validation object
        const toArr = Object.entries(validationParams)
        const nextValidModel = toArr.reduce((obj, item) => {
          const key = item[0]
          obj[key] = {
            ...validationParams[key],
            value: !_.isNil(payload[key]) ? payload[key] : validationParams[key].value,
            isValid: true
          }

          return obj
        }, {})

        const { systemAttachmentList, vaultBrandGuidelinesList, vaultRelevantFilesList } = filterAttachments(payload.assetCause && payload.assetCause.mediaAsset || [])

        return {
          ...state,
          origin: {
            ...state.origin,
            [payload.id]: payload
          },
          edit: {
            id: payload.id,
            milestoneCause: payload.milestoneCause,
            hiredUserId: payload.hiredUserId,
            hiredUserCause: payload.hiredUserCause,
            editorId: payload.editorId,
            editorCause: payload.editorCause,
            causeFormat: payload.causeFormat,
            causeFormatQty: payload.causeFormatQty,
            buyerId,
            causeUserCause,
            causeProduct: payload.causeProduct,
            causeStatus: payload.causeStatus,
            feedbackCause: payload.feedbackCause,
            amplifyCause: payload.amplifyCause
          },
          validationEdit: {
            id: {
              value: payload.id
            },
            ...validationParams,
            ...nextValidModel
          },
          // attachmentsEdit: payload.assetCause && payload.assetCause.mediaAsset
          //   ? payload.assetCause.mediaAsset
          //   : [],
          // attachm
          attachmentsEdit: systemAttachmentList,
          vaultUploadFiles: {
            brandGuidelines: vaultBrandGuidelinesList || [],
            relevantFiles: vaultRelevantFilesList || []
          },
          isGetIdFetching: false
        }
      }

    case types.CAUSE_GET_ID_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetIdFetching: false
        }
      }

    case types.CAUSE_EDIT:
      {
        return {
          ...state,
          edit: { ...state.edit, ...action.payload }
        }
      }

    case types.VALIDATION:
      {
        const validType = action.payload.isCreate ? 'validationCreate' : 'validationEdit'
        return {
          ...state,
          [validType]: {
            ...state[validType],
            ...action.payload.data
          }
        }

      }

    case types.CAUSE_SEARCH_SUCCESS:
      {
        const payload = action.payload

        return {
          ...state,
          search: {
            ...state.search,
            ...payload
          }
        }
      }

    case types.CAUSE_SEARCH_SELECT:
      {
        const payload = action.payload.data
        const isCreate = action.payload.isCreate
        const selectName = isCreate ? 'selectedCreate' : 'selectedEdit'
        return {
          ...state,
          search: {
            ...state.search,
            [selectName]: {
              ...state.search.selected,
              ...payload
            }
          }
        }
      }

    case types.CAUSE_SEARCH_SELECT_RESET:
      {
        const isCreate = action.payload.data
        const selectName = isCreate ? 'Create' : 'Edit'
        let newState

        newState = {
          [`validation${selectName}`]: {
            ...initialState.validationCreate,
          },
          search: {
            ...state.search,
            [`selected${selectName}`]: {
              ...initialState.search[`selected${selectName}`],
            }
          },
          [`attachments${selectName}`]: []
        }

        return {
          ...state,
          ...newState
        }
      }

    case types.CAUSE_SET_FILTER:
      {
        const list = action.payload.dateFilter === 'custom'
          || action.payload.dateTo
          || action.payload.dateFrom
          ? {
            ...state.list,
            page: 0,
            isAll: false
          }
          : { ...initialState.list }
        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
          list: {
            ...list
          }
        }
      }

    case types.CAUSE_SET_SORT:
      {
        return {
          ...state,
          sort: action.payload,
          list: {
            ...initialState.list
          }
        }
      }

    case types.RESET_FILTERS:
      {
        return {
          ...state,
          filter: { ...initialState.filter },
          sort: initialState.sort
        }
      }

    case types.UPLOAD:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'
        const prevAttachments = state[attachments].slice()
        const params = action.payload.params
        const index = prevAttachments.findIndex((element, index) => {
          return element.preview === params.preview
        })
        prevAttachments[index] = {
          name: prevAttachments[index] && prevAttachments[index].name || '',
          preview: prevAttachments[index].preview,
          fieldType: prevAttachments[index].fieldType,
          isFetching: true,
          isError: false
        }

        return {
          ...state,
          [attachments]: prevAttachments
        }

      }
    case types.UPLOAD_SUCCESS:
      {
        const attachments = action.payload.isCreate
          ? 'attachmentsCreate'
          : 'attachmentsEdit'
        const prevAttachments = state[attachments].slice()
        const preview = action.payload.preview
        const index = prevAttachments.findIndex(element => {
          return element.preview === preview
        })

        if (prevAttachments[index]) {
          prevAttachments[index].isFetching = false
          prevAttachments[index].isError = false
          prevAttachments[index].storeId = action.payload.data.id
          prevAttachments[index].size = action.payload.data.size || 0
        }

        if (action.payload.data && action.payload.data.file && action.payload.data.file.isDuplicate) {
          prevAttachments[index].documentId = action.payload.data.file.documentId
          prevAttachments[index].uploadedFromVault = true
        }

        return {
          ...state,
          [attachments]: prevAttachments
        }

      }
    case types.UPLOAD_FAILURE:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'
        const prevAttachments = state[attachments].slice()
        const preview = action.payload.preview
        const index = prevAttachments.findIndex((element, index) => {
          return element.preview === preview
        })

        if (prevAttachments[index]) {
          prevAttachments[index].isFetching = false
          prevAttachments[index].isError = true
        }

        const copyPrevAttachments = _.filter(prevAttachments, item => (item.isError === false) || (item.storeId))

        return {
          ...state,
          [attachments]: copyPrevAttachments
        }
      }

    case types.ATTACH:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'
        const prevAttachments = state[attachments]
        const params = action.payload.params
        return {
          ...state,
          [attachments]: prevAttachments.concat(params)
        }
      }

    case types.REMOVE_FILE:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'
        const prevAttachments = state[attachments].slice()
        const params = action.payload.params
        const index = prevAttachments.findIndex((element, index) => {
          return element.preview === params || element.id === params
        })

        prevAttachments.splice(index, 1)

        return {
          ...state,
          [attachments]: prevAttachments
        }
      }

    case types.RESET_ATTACH_CHANGES:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'

        return {
          ...state,
          [attachments]: action.payload
        }
      }
    case types.EDIT_FILE:
      {
        const attachments = action.payload.isCreate ? 'attachmentsCreate' : 'attachmentsEdit'
        const prevAttachments = state[attachments].slice()
        const params = action.payload.params
        const index = prevAttachments.findIndex((element, index) => {
          return element.id === params
        })

        prevAttachments.splice(index, 1)

        return {
          ...state,
          [attachments]: prevAttachments
        }
      }

    case types.CAUSE_CREATE_REQUEST_SUCCESS:
      {
        return {
          ...state,
          requestContractor: {
            ...action.payload
          },
        }
      }

    case types.CAUSE_EDIT_REQUEST:
      {
        return {
          ...state,
          requestContractor: {
            ...state.requestContractor,
            ...action.payload
          },
        }
      }

    case userTypes.UPDATE_USER_BY_SOCKET:
      {
        const user = action.payload

        //NOTE: update dashboard brief user(contractor/buyer) ***start***
        let dashboard = {
          active: {
            ...state.active
          },
          inprogress: {
            ...state.inprogress
          },
          closed: {
            ...state.closed
          },
        }

        const active = { ...state.active }
        const nextActive = _.map(active, itemActive => {
          let resultActive = { ...itemActive }
          if (_.has(itemActive, 'userCause.id') && itemActive.userCause.id === user.id) {
            resultActive = {
              ...itemActive,
              userCause: {
                ...itemActive.userCause,
                ...user
              }
            }
          }

          if (_.has(itemActive, 'hiredUserCause.id') && itemActive.hiredUserCause.id === user.id) {
            resultActive = {
              ...itemActive,
              hiredUserCause: {
                ...itemActive.hiredUserCause,
                ...user
              }
            }
          }

          return resultActive
        })

        const inprogress = { ...state.inprogress }
        const nextInprogress = _.map(inprogress, itemInprogress => {
          let resultInprogress = { ...itemInprogress }
          if (_.has(itemInprogress, 'userCause.id') && itemInprogress.userCause.id === user.id) {
            resultInprogress = {
              ...itemInprogress,
              userCause: {
                ...itemInprogress.userCause,
                ...user
              }
            }
          }

          if (_.has(itemInprogress, 'hiredUserCause.id') && itemInprogress.hiredUserCause.id === user.id) {
            resultInprogress = {
              ...itemInprogress,
              hiredUserCause: {
                ...itemInprogress.hiredUserCause,
                ...user
              }
            }
          }

          return resultInprogress
        })

        const closed = { ...state.closed }
        const nextClosed = _.map(closed, itemClosed => {
          let resultClosed = { ...itemClosed }
          if (_.has(itemClosed, 'userCause.id') && itemClosed.userCause.id === user.id) {
            resultClosed = {
              ...itemClosed,
              userCause: {
                ...itemClosed.userCause,
                ...user
              }
            }
          }

          if (_.has(itemClosed, 'hiredUserCause.id') && itemClosed.hiredUserCause.id === user.id) {
            resultClosed = {
              ...itemClosed,
              hiredUserCause: {
                ...itemClosed.hiredUserCause,
                ...user
              }
            }
          }

          return resultClosed
        })

        dashboard = {
          ...dashboard,
          active: {
            ...nextActive
          },
          inprogress: {
            ...nextInprogress
          },
          closed: {
            ...nextClosed
          }
        }
        //NOTE: update dashboard ***end***

        //NOTE: update origin subStore ***start***
        const origin = {
          ...state.list.origin
        }

        const nextOrigin = _.map(origin, itemOrigin => {
          let resultOrigin = { ...itemOrigin }
          if (_.has(itemOrigin, 'userCause.id') && itemOrigin.userCause.id === user.id) {
            resultOrigin = {
              ...itemOrigin,
              userCause: {
                ...itemOrigin.userCause,
                ...user
              }
            }
          }

          if (_.has(itemOrigin, 'hiredUserCause.id') && itemOrigin.hiredUserCause.id === user.id) {
            resultOrigin = {
              ...itemOrigin,
              hiredUserCause: {
                ...itemOrigin.hiredUserCause,
                ...user
              }
            }
          }

          return resultOrigin
        })


        //NOTE: update origin subStore ***end***

        //NOTE: update edit subStore ***end***
        let nextEdit = {
          edit: {
            ...state.edit
          }
        }
        if (_.has(nextEdit.edit, 'hiredUserCause.id') && nextEdit.edit.hiredUserCause.id === user.id) {
          nextEdit = {
            edit: {
              ...state.edit,
              hiredUserCause: {
                ...state.edit.hiredUserCause,
                ...user
              }
            }
          }
        }

        //NOTE: update edit subStore ***end***

        return {
          ...state,
          ...dashboard,
          ...nextEdit,
          origin: {
            ...nextOrigin
          }
        }
      }

    case types.CAUSE_HIRE_SUCCESS:
      {
        const payload = action.payload.data

        const newEditData = _.mapValues(state.edit, (item, key) => {
          const newValueEdit = _.get(payload, key)
          return newValueEdit || item
        })

        return {
          ...state,
          list: {
            ...state.list,
            origin: {
              ...state.list.origin,
              [payload.id]: {
                ...state.list.origin[payload.id],
                ...payload
              }
            },
          },
          edit: {
            ...state.edit,
            ...newEditData,
            hiredUserId: payload.hiredUserId
          }
        }
      }

    case types.CLEAR_EDIT_DATA:
      {
        return {
          ...state,
          edit: initialState
        }
      }

    case types.CLEAR_ORIGIN: {
      return {
        ...state,
        origin: {}
      }
    }

    case types.CAUSE_REMOVE_FAILURE:
    case types.CAUSE_UPDATE_FAILURE:
    case types.CAUSE_CREATE_ASSET_FAILURE:
    case types.CAUSE_GET_REQUEST_FAILURE:
    case types.CAUSE_UPDATE_REQUEST_FAILURE:
    case types.CAUSE_HIRE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
        }
      }

    case authTypes.SIGNOUT_SUCCESS:
      {
        return {
          ...initialState,
        }
      }

    case types.SET_PAGE:
      {
        return {
          ...state,
          list: {
            ...state.list,
            page: action.payload
          }
        }
      }

    case types.CLEAR_DROPZONE_ATTACHMENTS:
      {
        return {
          ...state,
          attachmentsCreate: initialState.attachmentsCreate,
          attachmentsEdit: initialState.attachmentsEdit
        }
      }

    case types.CAUSE_UPLOAD_PROGRES: {
      const _uploadCountList = state.uploadCountList
      const isDataInList = _uploadCountList.findIndex(obj => obj && Object.keys(obj)[0] == Object.keys(action.payload)[0]);
      if (isDataInList > -1) {
        _uploadCountList[isDataInList][Object.keys(action.payload)[0]] = Object.values(action.payload)[0]
      } else {
        _uploadCountList.push(action.payload)
      }
      return {
        ...state,
        uploadCountList: [ ..._uploadCountList ]
      }
    }

    case types.CAUSE_DELETE_FILE:
      {
        return {
          ...state,
          isGetDeletingFile: true
        }
      }

    case types.CAUSE_DELETE_FILE_SUCCESS:
      {
        return {
          ...state,
          deletedFile: action.payload,
          isGetDeletingFile: false
        }
      }

    case types.CAUSE_DELETE_FILE_FAILURE:
      {
        return {
          ...state,
          error: action.payload,
          isGetDeletingFile: false
        }
      }

      case types.CAUSE_CURRENT_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        currentUploadingData.push(action.payload)
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }
  
      case types.CAUSE_CLEAR_UPLOADING_API_CALLS: {
        const currentUploadingData = state.currentUploadingApiCallData
        const currentIndex = currentUploadingData && currentUploadingData.findIndex(item => item && Object.keys(item) && Object.keys(item)[0] === action.payload)
        delete currentUploadingData[currentIndex]
        return {
          ...state,
          currentUploadingApiCallData: currentUploadingData
        }
      }

      case types.ADD_VAULT_FILES_CAUSE: {
        return {
          ...state,
          vaultUploadFiles: action.payload
        }
      }

      case types.CAUSE_EDIT_DELETED_FILE: {
        const list = [...state.causeEditDeletedFiles]
        list.push(action.payload)
        
        return {
          ...state,
          causeEditDeletedFiles: list
        }
      }

      case types.CAUSE_CLEAR_EDIT_DELETED_FILE: {
        return {
          ...state,
          causeEditDeletedFiles: []
        }
      }

      case types.CLEAR_VAULT_UPLOAD_FILES: {
        return {
          ...state,
          vaultUploadFiles: initialState.vaultUploadFiles
        }
      }

    default:
      return state
  }
}
