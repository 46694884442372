export default {
  
  GET_GENDER_AGE_DATA: 'SERVER:ANALYTICS_GET_GENDER_AGE_DATA',
  GET_GENDER_AGE_DATA_SUCCESS: 'ANALYTICS_GET_GENDER_AGE_DATA_SUCCESS',
  GET_GENDER_AGE_DATA_FAILURE: 'ANALYTICS_GET_GENDER_AGE_DATA_FAILURE',

  GET_GRAPH_DATA: 'SERVER:ANALYTICS_GET_GRAPH_DATA',
  GET_GRAPH_DATA_SUCCESS: 'ANALYTICS_GET_GRAPH_DATA_SUCCESS',
  GET_GRAPH_DATA_FAILURE: 'ANALYTICS_GET_GRAPH_DATA_FAILURE',

  SET_LOCATION_DATA: 'ANALYTICS_SET_LOCATION_DATA',

  GET_GEOGRAPHIC_WORLD_DATA: 'SERVER:ANALYTICS_GET_GEOGRAPHIC_WORLD_DATA',
  GET_GEOGRAPHIC_WORLD_DATA_SUCCESS: 'ANALYTICS_GET_GEOGRAPHIC_WORLD_DATA_SUCCESS',
  GET_GEOGRAPHIC_WORLD_DATA_FAILURE: 'ANALYTICS_GET_GEOGRAPHIC_WORLD_DATA_FAILURE',

  GET_DEVICE_DATA: 'SERVER:ANALYTICS_GET_DEVICE_DATA',
  GET_DEVICE_DATA_SUCCESS: 'ANALYTICS_GET_DEVICE_DATA_SUCCESS',
  GET_DEVICE_DATA_FAILURE: 'ANALYTICS_GET_DEVICE_DATA_FAILURE',

  GET_DEVICE_TIME_ON_PAGE_DATA: 'SERVER:ANALYTICS_GET_DEVICE_TIME_ON_PAGE_DATA',
  GET_DEVICE_TIME_ON_PAGE_DATA_SUCCESS: 'ANALYTICS_GET_DEVICE_TIME_ON_PAGE_DATA_SUCCESS',
  GET_DEVICE_TIME_ON_PAGE_DATA_FAILURE: 'ANALYTICS_GET_DEVICE_TIME_ON_PAGE_DATA_FAILURE',

  GET_TIME_ON_PAGE_DATA: 'SERVER:ANALYTICS_GET_TIME_ON_PAGE_DATA',
  GET_TIME_ON_PAGE_DATA_SUCCESS: 'ANALYTICS_GET_TIME_ON_PAGE_DATA_SUCCESS',
  GET_TIME_ON_PAGE_DATA_FAILURE: 'ANALYTICS_GET_TIME_ON_PAGE_DATA_FAILURE',

  GET_COUNT_UNIQUE_USERS_DATA: 'SERVER:ANALYTICS_GET_COUNT_UNIQUE_USERS_DATA',
  GET_COUNT_UNIQUE_USERS_DATA_SUCCESS: 'ANALYTICS_GET_COUNT_UNIQUE_USERS_DATA_SUCCESS',
  GET_COUNT_UNIQUE_USERS_DATA_FAILURE: 'ANALYTICS_GET_COUNT_UNIQUE_USERS_DATA_FAILURE',

  GET_BUY_SURVEY_DATA: 'SERVER:GET_BUY_SURVEY_DATA',
  GET_BUY_SURVEY_DATA_SUCCESS: 'GET_BUY_SURVEY_DATA_SUCCESS',
  GET_BUY_SURVEY_DATA_FAILURE: 'GET_BUY_SURVEY_DATA_FAILURE',
  
  GET_LEARN_MORE_SURVEY_DATA: 'SERVER:GET_LEARN_MORE_SURVEY_DATA',
  GET_LEARN_MORE_SURVEY_DATA_SUCCESS: 'GET_LEARN_MORE_SURVEY_DATA_SUCCESS',
  GET_LEARN_MORE_SURVEY_DATA_FAILURE: 'GET_LEARN_MORE_SURVEY_DATA_FAILURE',

  GET_SHARE_SURVEY_DATA: 'SERVER:GET_SHARE_SURVEY_DATA',
  GET_SHARE_SURVEY_DATA_SUCCESS: 'GET_SHARE_SURVEY_DATA_SUCCESS',
  GET_SHARE_SURVEY_DATA_FAILURE: 'GET_SHARE_SURVEY_DATA_FAILURE',
}