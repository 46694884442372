export default {
  WORKSPACE_GET:         'SERVER:WORKSPACE_GET',
  WORKSPACE_GET_SUCCESS: 'WORKSPACE_GET_SUCCESS',
  WORKSPACE_GET_FAILURE: 'WORKSPACE_GET_FAILURE',

  WORKSPACE_AMPLIFY_GET:         'SERVER:WORKSPACE_AMPLIFY_GET',
  WORKSPACE_AMPLIFY_GET_PUBLISHING_SUCCESS: 'WORKSPACE_AMPLIFY_GET_PUBLISHING_SUCCESS',
  WORKSPACE_AMPLIFY_GET_DISTRIBUTION_SUCCESS: 'WORKSPACE_AMPLIFY_GET_DISTRIBUTION_SUCCESS',
  WORKSPACE_AMPLIFY_GET_FAILURE: 'WORKSPACE_AMPLIFY_GET_FAILURE',

  WORKSPACE_GET_USERS: 'SERVER:WORKSPACE_GET_USERS',
  WORKSPACE_GET_USERS_SUCCESS: 'WORKSPACE_GET_USERS_SUCCESS',
  WORKSPACE_GET_USERS_FAILURE: 'WORKSPACE_GET_USERS_FAILURE',
  // SET_SEARCHED_WORKSPACE: 'SET_SEARCHED_WORKSPACE'
}