export default {
  AMPLIFY_CAUSE_PERMISSIONS_FIND: 'SERVER:AMPLIFY_CAUSE_PERMISSIONS_FIND',
  AMPLIFY_CAUSE_PERMISSIONS_FIND_SUCCESS: 'AMPLIFY_CAUSE_PERMISSIONS_FIND_SUCCESS',
  AMPLIFY_CAUSE_PERMISSIONS_FIND_ERROR: 'AMPLIFY_CAUSE_PERMISSIONS_FIND_ERROR',

  AMPLIFY_CAUSE_PERMISSIONS_CREATE: 'SERVER:AMPLIFY_CAUSE_PERMISSIONS_CREATE',
  AMPLIFY_CAUSE_PERMISSIONS_CREATE_SUCCESS: 'AMPLIFY_CAUSE_PERMISSIONS_CREATE_SUCCESS',
  AMPLIFY_CAUSE_PERMISSIONS_CREATE_ERROR: 'AMPLIFY_CAUSE_PERMISSIONS_CREATE_ERROR',

  AMPLIFY_CAUSE_PERMISSIONS_UPDATE: 'SERVER:AMPLIFY_CAUSE_PERMISSIONS_UPDATE',
  AMPLIFY_CAUSE_PERMISSIONS_UPDATE_SUCCESS: 'AMPLIFY_CAUSE_PERMISSIONS_UPDATE_SUCCESS',
  AMPLIFY_CAUSE_PERMISSIONS_UPDATE_ERROR: 'AMPLIFY_CAUSE_PERMISSIONS_UPDATE_ERROR',

  AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID: 'AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID',
  AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID: 'AMPLIFY_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID'
}