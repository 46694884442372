import types from '../constants/user'

export const get = (queryParams) => ({
  type: types.GET,
  payload: {
    queryParams
  }
})

export const getSuccess = ({ data, count }, isBuyer, query) => ({
  type: types.GET_SUCCESS,
  payload: {
    data,
    count,
    isBuyer,
    query
  }
})

export const getFailure = (error) => ({
  type: types.GET_FAILURE,
  payload: error
})

export const getId = (id) => ({
  type: types.GET_ID,
  payload: id
})

export const getIdSuccess = (data, isBuyer) => ({
  type: types.GET_ID_SUCCESS,
  payload: {
    data,
    isBuyer
  }
})

export const getIdFailure = (error) => ({
  type: types.GET_ID_FAILURE,
  payload: error
})

export const setFilter = (data) => ({
  type: types.SET_FILTER,
  payload: data,
})

export const resetFilter = () => ({
  type: types.RESET_FILTER,
})

export const update = (data, isBuyer, isSocket) => ({
  type: types.UPDATE,
  payload: {
    data,
    isBuyer,
    isSocket
  }
})

export const selectContractor = (data) => ({
  type: types.SELECT_CONTRACTOR,
  payload: data
})

export const updateUserBySocket = data => ({
  type: types.UPDATE_USER_BY_SOCKET,
  payload: data
})

export const acceptTerms = () => ({
  type: types.ACCEPT_TERMS,
  payload: null,
})

export const acceptTermsSuccess = () => ({
  type: types.ACCEPT_TERMS_SUCCESS,
  payload: null,
})

export const acceptTermsFailure = (err) => ({
  type: types.ACCEPT_TERMS_FAILURE,
  payload: err,
})