export default {
    AMPLIFY_WORKROOM_GET:                    'SERVER:AMPLIFY_WORKROOM_GET',
    AMPLIFY_WORKROOM_GET_SUCCESS:            'AMPLIFY_WORKROOM_GET_SUCCESS',
    AMPLIFY_WORKROOM_GET_FAILURE:            'AMPLIFY_WORKROOM_GET_FAILURE',

    // AMPLIFY_CAUSE_GET_ID:                 'SERVER:AMPLIFY_CAUSE_GET_ID',
    // AMPLIFY_CAUSE_GET_ID_SUCCESS:         'AMPLIFY_CAUSE_GET_ID_SUCCESS',
    // AMPLIFY_CAUSE_GET_ID_FAILURE:         'AMPLIFY_CAUSE_GET_ID_FAILURE',

    AMPLIFY_WORKROOM_ATTACHMENT_PERMISSION: 'AMPLIFY_WORKROOM_ATTACHMENT_PERMISSION',

    AMPLIFY_WORKROOM_UPDATE:                 'SERVER:AMPLIFY_WORKROOM_UPDATE',
    AMPLIFY_WORKROOM_UPDATE_SUCCESS:         'AMPLIFY_WORKROOM_UPDATE_SUCCESS',
    AMPLIFY_WORKROOM_UPDATE_FAILURE:         'AMPLIFY_WORKROOM_UPDATE_FAILURE',

    AMPLIFY_WORKROOM_UPLOAD_FILE:            'SERVER:AMPLIFY_WORKROOM_UPLOAD_FILE',
    AMPLIFY_WORKROOM_UPLOAD_FILE_SUCCESS:    'AMPLIFY_WORKROOM_UPLOAD_FILE_SUCCESS',
    AMPLIFY_WORKROOM_UPLOAD_FILE_FAILURE:    'AMPLIFY_WORKROOM_UPLOAD_FILE_FAILURE',

    AMPLIFY_WORKROOM_UPLOAD_URL:            'SERVER:AMPLIFY_WORKROOM_UPLOAD_URL',
    AMPLIFY_WORKROOM_UPLOAD_URL_SUCCESS:    'AMPLIFY_WORKROOM_UPLOAD_URL_SUCCESS',
    AMPLIFY_WORKROOM_UPLOAD_URL_FAILURE:    'AMPLIFY_WORKROOM_UPLOAD_URL_FAILURE',

    AMPLIFY_WORKROOM_DOWNLOAD_FILE: 'SERVER:AMPLIFY_WORKROOM_DOWNLOAD_FILE',
    AMPLIFY_WORKROOM_DOWNLOAD_FILE_SUCCESS: 'AMPLIFY_WORKROOM_DOWNLOAD_FILE_SUCCESS',
    AMPLIFY_WORKROOM_DOWNLOAD_FILE_FAILURE: 'AMPLIFY_WORKROOM_DOWNLOAD_FILE_FAILURE',

    AMPLIFY_WORKROOM_UPLOAD_PROGRES: 'AMPLIFY_WORKROOM_UPLOAD_PROGRES',

    AMPLIFY_WORKROOM_DELETE_FILE: 'AMPLIFY_WORKROOM_DELETE_FILE',
    AMPLIFY_WORKROOM_DELETE_FILE_SUCCESS: 'AMPLIFY_WORKROOM_DELETE_FILE_SUCCESS',
    AMPLIFY_WORKROOM_DELETE_FILE_FAILURE: 'AMPLIFY_WORKROOM_DELETE_FILE_FAILURE',  

    CURRENT_UPLOADING_API_CALLS: 'CURRENT_UPLOADING_API_CALLS',
    CLEAR_UPLOADING_API_CALLS: 'CLEAR_UPLOADING_API_CALLS',

    AMPLIFY_WORKROOM_UPDATE_ATTACHMENT: 'AMPLIFY_WORKROOM_UPDATE_ATTACHMENT',

    AMPLIFY_WORKROOM_REMOVE_ATTACHMENT: 'AMPLIFY_WORKROOM_REMOVE_ATTACHMENT',
    AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_SUCCESS: 'AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_SUCCESS',
    AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_FAILURE: 'AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_FAILURE',

    AMPLIFY_WORKROOM_VAULT_UPLOAD: 'AMPLIFY_WORKROOM_VAULT_UPLOAD',
    AMPLIFY_WORKROOM_VAULT_UPLOAD_SUCCESS: 'AMPLIFY_WORKROOM_VAULT_UPLOAD_SUCCESS',
    AMPLIFY_WORKROOM_VAULT_UPLOAD_FAILURE: 'AMPLIFY_WORKROOM_VAULT_UPLOAD_FAILURE',

    ADD_VAULT_FILE_AMPLIFY_WORKROOM: 'ADD_VAULT_FILE_AMPLIFY_WORKROOM',
    CLEAR_VAULT_FILE_AMPLIFY_WORKROOM: 'CLEAR_VAULT_FILE_AMPLIFY_WORKROOM'
}