import { put, takeEvery } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/genre'
import * as actions from '../actions/genre'

import backend from 'store/api/feathers'

function* get(action) {
  try {
    const genreList = yield backend.service('genre').find({
      query: {
        isAllPage: true
      }
    })
    if(_.size(genreList)) {
      yield put(actions.getSuccess(genreList))

    }

  } catch (e) {

    yield put(actions.getFailure(e))
  }
}

function* genreSaga() {
  yield takeEvery(types.GET, get)
}

export default {
  genreSaga,
}
