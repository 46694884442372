import types from '../constants/signIn'
import authTypes from '../constants/auth'

const initialState = {
  validation: {
    email: {
      value: '',
      isValid: undefined,
    },
    password: {
      value: '',
      isValid: undefined,
    },
    agreement: {
      value: false,
      isValid: undefined
    },
    keepSigned: {
      value: false
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.VALIDATION: {
    return {
      ...state,
      validation: {
        ...state.validation,
        ...action.payload
      }
    }

  }

  case authTypes.SIGNOUT_SUCCESS: {
    return {
      ...state,
      validation: {
        ...initialState.validation,
        agreement: state.validation.agreement,
        keepSigned: state.validation.keepSigned
      }
    }
  }

  default:
    return state
  }
}
