import { put, takeEvery } from 'redux-saga/effects'

import types from '../constants/notification'
import * as actions from '../actions/notification'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const queryParams = action.payload.queryParams;

    const notificaiontList = yield backend.service('notification').find({
      query: {
        ...queryParams,
      },
    })

    yield put(actions.getSuccess(notificaiontList))

  } catch (e) {
    renderErrorNotification(e, 'Get notification list error')
    yield put(actions.getFailure(e))

  }

}

function* update(action) {
  try {
    const data = action.payload.data
    const notification = yield backend.service('notification-user').patch(data.id, data)

    yield put(actions.updateSuccess(notification.data))
  } catch (e) {
    renderErrorNotification(e, 'Update notification error')
    yield put(actions.updateFailure(e))

  }

}

function* remove(action) {

  try {
    const { notificationUserId } = action.payload
    const removedNotification = yield backend.service('notification-user').remove(notificationUserId)

    yield put(actions.removeSuccess(removedNotification.result))

  } catch (e) {
    const { notificationId } = action.payload
    renderErrorNotification(e, 'Remove notification error')
    yield put(actions.removeFailure(e, notificationId))
  }

}

function* notificationSaga() {
  yield takeEvery(types.NOTIFICATION_GET, get)
  yield takeEvery(types.NOTIFICATION_UPDATE, update)
  yield takeEvery(types.NOTIFICATION_REMOVE, remove)
}

export default {
  notificationSaga,
}
