import React from 'react'
import classNames from 'classnames'

import { ReactComponent as Icon } from 'assets/images/icons/notifications-navigation.svg'

import './IconNotifications.css'

const IconNotification = props => {
  const { isHasNewNotifications } = props

  return (
    <div className="ui-icon-notifications-wrapper">
      <Icon
        className={classNames('ui-icon-notifications', {
          'ui-icon-notifications-has-new-notification': isHasNewNotifications
        })}
      />
    </div>
  )
}

IconNotification.defaultProps = {
  isHasNewNotifications: false
}

export default IconNotification



