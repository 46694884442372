import React, { useMemo } from 'react'
import classNames from 'classnames'

import _ from 'lodash'

import { Link } from 'react-router-dom'

import moment from 'moment'

import notificationRender from 'components/pageComponents/notificationsList/notificationRender'

import { userPermission } from 'helpers/params'

import './DefaultNotification.css'

export const DefaultNotification = props => {
  const {
    id,
    currentUser,
    linkId,
    notificationUser,
    createdAt,
    isRemoving,
    onDelete,
    onRead
  } = props
  
  const isAmplify = currentUser.permissions === userPermission.amplify
  
  const modelNotification = notificationRender(props, currentUser)
  
  const currentNotificationUser = useMemo(
    () =>
      _.find(notificationUser, {
        userId: currentUser.id
      }),
    [notificationUser, currentUser.id]
  )

  const classNamesBtn = useMemo(() => {
    const isNewIcon = _.get(currentNotificationUser, 'isNew', false)
    return classNames('btn btn-icon btn-xs legitRipple btn-rounded', {
      'bg-success-400': isNewIcon,
      'bg-grey-300': !isNewIcon
    })
  }, [currentNotificationUser])

  const rendererRemoveButton = useMemo(() => {
    return isRemoving ? <div className="notifications-list-item-removing-overlay" /> : (
      <button
        className="media-delete-notification"
        onClick={onDelete(currentNotificationUser.id, id)}
      >
        <i className="icon-cross"></i>
      </button>
    ) 
  }, [onDelete, currentNotificationUser, id, isRemoving])
 

   const isMozillaFireFox = navigator.userAgent.search(/Firefox/) > 0 

  const linkedCauseHref = () => {

    if (modelNotification.linkTo) {
      return `/${modelNotification.linkTo}`
    }

    if(!linkId) return '/brief/'

    return isAmplify ? `/amplify-brief-show/${linkId}` : `/brief-show/${linkId}`
  }

  return (
    <li className="media d-flex_nowrap_space-between">
      <Link
        className="text-default"
        to={linkedCauseHref()}
        onClick={onRead(currentNotificationUser.id)}
      >
        <div className="media-left">
          <span className={classNamesBtn}>
            <i className={modelNotification.icon}></i>
          </span>
        </div>
        <div
          className={classNames('media-body body-length', {
            'notification-body-block-size': isMozillaFireFox,
          })}
          title={modelNotification.body}
        >
          {modelNotification.body}
          <div className="media-annotation">
            {moment(createdAt).format('MM/DD/YYYY HH:mm')}
          </div>
        </div>
      </Link>
      {rendererRemoveButton}
    </li>
  )
}
