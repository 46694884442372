import types from '../constants/init'

import {
  pendingTask, // The action key for modifying loading state
  begin, // The action value if a "long" running task begun
  end, // The action value if a "long" running task ended
} from 'react-redux-spinner'

export const init = () => {

  return {
    type: types.INIT,
    payload: null,
    [ pendingTask ]: begin,
  }

}

export const initAuth = () => {

  return {
    type: types.INIT_AUTH,
    payload: null,
  }

}

export const initSuccess = (appStatus) => {

  return {
    type: types.INIT_SUCCESS,
    payload: appStatus,
    [ pendingTask ]: end,
  }

}

export const initFailure = (err) => {

  return {
    type: types.INIT_FAILURE,
    payload: err,
    [ pendingTask ]: end,
  }

}

export const legal = () => ({
  type: types.LEGAL,
  payload: null,
})

export const legalSuccess = legalUrl => ({
  type: types.LEGAL_SUCCESS,
  payload: legalUrl,
})

export const legalFailure = err => ({
  type: types.LEGAL_FAILURE,
  payload: err,
})