/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import PNotify from 'pnotify'
import 'pnotify/dist/pnotify.buttons'
import * as Talk from 'talkjs'

import limitless from 'limitless/app.js'
import nicescroll from 'limitless/nicescroll'

import Logout from 'components/ui/Logout'
import InputSearch from 'components/ui/InputSearch'

import IconMessagesNavigation from 'components/ui/IconMessages'

import BriefModalSelectType from 'components/pageComponents/cause/ModalSelectType'

import socket from 'store/realtime'

import { userPermission } from 'helpers/params'
import { renderNotification } from 'helpers/tools'
import PostBriefModal from 'components/ui/PostBriefModal'
import PostJob from 'components/pageComponents/cause/HeaderBriefs/PostJob'

import avatar from 'assets/images/image.png'
import { ReactComponent as IconCalendar } from 'assets/images/icons/calendar-navigation.svg'
import { ReactComponent as IconAddNavigation } from 'assets/images/icons/add-navigation.svg'
import { ReactComponent as IconDashboard } from 'assets/images/icons/dashboard.svg'
import { ReactComponent as IconMessages } from 'assets/images/icons/messages.svg'
import { ReactComponent as IconHelp } from 'assets/images/icons/help.svg'
import { ReactComponent as IconYourBriefs } from 'assets/images/icons/your-briefs.svg'
import { ReactComponent as IconVault } from 'assets/images/icons/vault.svg'
import { ReactComponent as IconAnalytics } from 'assets/images/icons/analytics.svg'
import { ReactComponent as IconAccount } from 'assets/images/icons/account.svg'
import { ReactComponent as IconMenu } from 'assets/images/icons/menu.svg'
import { ReactComponent as IconProfileDropdown } from 'assets/images/icons/profile-dropdown.svg'
import { ReactComponent as IconAccountDropdown } from 'assets/images/icons/account-dropdown.svg'


import NavButtons from './NavButtons'
import { Notifications } from './Notifications'

import './App.css'

import { AmplifyManageUsers } from 'components/pageComponents/amplifyManageUsers'
import { BuyerManageUsers } from 'components/pageComponents/buyerManageUsers'

const headerNavigation = {
  first: [
    {
      text: 'Dashboard',
      link: '/dashboard',
      Icon: IconDashboard
    },
    {
      text: 'Messages',
      link: '/messages',
      Icon: IconMessages
    },
  ],
  last: [
    {
      text: 'Help',
      link: '/help',
      Icon: IconHelp
    }
  ],
  // vault: [
  //   {
  //     text: 'Vault',
  //     link: '/vault',
  //     Icon: IconVault
  //   }
  // ]
}
const headerByPermission = {
  //NOTE: buyer permission = 2
  2: [
    {
      text: 'Your Briefs',
      Icon: IconYourBriefs,
      subItems: [
        { text: 'Content Briefs', link: '/brief' },
        { text: 'Distribution Briefs', link: '/brief-distribution' },
      ]
    },
    {
      text: (
        <div className="nav-button-analytics">
          Vault
          {/* <span className="nav-button-analytics-beta">beta</span> */}
        </div>
      ),
      link: '/vault',
      Icon: IconVault
    },
    {
      text: 'Analytics',
      link: '/analytics',
      Icon: IconAnalytics
    },
    {
      text: 'Account',
      link: '/account',
      Icon: IconAccount
    },
  ],
  //NOTE: contractor permission = 3
  3: [
    {
      text: 'Briefs',
      link: '/brief',
      icon: 'icon-stack-text'
    },
    {
      text: 'New Briefs',
      link: '/new-briefs',
      icon: 'icon-stack-star'
    },
    {
      text: 'My Pitches',
      link: '/pitch',
      icon: 'icon-git-merge'
    },
    {
      text: 'Account',
      link: '/account',
      icon: 'icon-coins'
    },
  ],
  //NOTE: editor permission = 4
  4: [
    {
      text: 'Need editor',
      link: '/without-editor',
      icon: 'icon-compose'
    }
  ],
  //NOTE: guest permission = 4
  5: [
    {
      text: 'Shared products',
      link: '/shared-products',
      icon: 'icon-quill4'
    }
  ],
  6: [
    {
      text: 'Your Briefs',
      link: '/brief',
      Icon: IconYourBriefs,
    },
    // {
    //   text: 'Vault',
    //   link: '/vault',
    //   Icon: IconVault
    // },
    //{
    //  text: <div className="nav-button-analytics">
    //    Analytics
    //  <span className="nav-button-analytics-beta">beta</span>
    //  </div>,
    //  link: '/analytics',
    //  Icon: IconAnalytics
    //},
    {
      text: 'Account',
      link: '/account',
      Icon: IconAccount
    },
  ],
}

class AppLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  componentDidMount() {
    const {
      currentUser,
      history,
      chatActions
    } = this.props

    socket(currentUser, history)

    limitless.init()

    nicescroll.init()

    Talk.ready
      .then(() => {
        const me = new Talk.User({
          id: currentUser.id,
          name: currentUser.userName,
          email: currentUser.email,
          photoUrl: currentUser.image || null,
        })

        window.talkSession = new Talk.Session({
          appId: 't6dL83gf',
          me: me
        })
        window.talkSession.unreads.on('change', function (conversationIds) {
          chatActions.setMessages(conversationIds)
          _.forEach(conversationIds, message => {
            const notice = new PNotify({
              addclass: 'alert alert-styled-left alert-styled-custom alert-arrow-left alpha-teal text-teal-800',
              title: _.has(message, 'lastMessage.sender.name')
                ? message.lastMessage.sender.name
                : '',
              text: _.has(message, 'lastMessage.body')
                ? message.lastMessage.body
                : '',
              hide: true,
              buttons: {
                closer: true,
                sticker: true,
              },
            })

            notice.get().on('click', e => {
              if ((_.has(message, 'lastMessage.conversation.custom.causeId') && e.target.lastChild !== null)) {
                chatActions.setSelectedConversation(message.lastMessage.conversation.id)
                history.push('/messages')
              }
            })
          })
        })

      })
      .catch(e => console.error(e))

  }

  UNSAFE_componentWillUpdate() {

    limitless.reset()

    nicescroll.reset()

  }

  componentDidUpdate() {

    limitless.update()

    nicescroll.init()

  }

  renderNavButtons = ({ link, text, icon }, index) => {
    const path = this.props.history.location.pathname
    const isCurrentRoute = classNames({
      'oval': _.includes(path, link) || (path === '/' && text === 'Dashboard'),
      'd-none': !(_.includes(path, link) || (path === '/' && text === 'Dashboard'))
    })
    return (
      <li key={`navigate_panel_${link}_index_${index}`}>
        <Link to={link} className="oval-position">
          <div className={icon} />
          <i className={isCurrentRoute} title={text}></i>
          <span>{text}</span>
        </Link>
      </li>
    )
  }

  renderMessagesIcon = () => {
    const { messagesCount } = this.props

    const isHasNewMessages = !_.isEqual(0, _.defaultTo(messagesCount, 0))

    return <IconMessagesNavigation isHasNewMessages={isHasNewMessages} />
  }

  renderButtons = () => {
    return (
      <Fragment>
        <li>
          <Link to="/calendar">
            <IconCalendar className="calendar-icon" />
          </Link>
        </li>
        <li>
          <Link to="/messages">
            {this.renderMessagesIcon()}
          </Link>
        </li>
        <Notifications />
      </Fragment>
    )
  }

  renderManageUsersModal = () => {
    const { currentUser } = this.props;

    const isAmplifyUser = _.isEqual(currentUser.permissions, userPermission.amplify)
    const isBuyerUser = _.isEqual(currentUser.permissions, userPermission.buyer)

    if (isAmplifyUser) {
      return <AmplifyManageUsers />
    }
     else if (isBuyerUser) {
      return <BuyerManageUsers />
    }
    else {
      return null
    }
  }

  setShow = () => {
    const { show } = this.state
    this.setState((prevState) => ({
      ...prevState,
      show: !prevState.show
    }));
  }
  handleContentBrief = () => {
    this.setShow()
    const { routerActions } = this.props
    routerActions.push('/create-brief')
  }

  handleDistributionBrief = () => {
    this.setShow()
    const { routerActions } = this.props
    routerActions.push('/post-brief')
  }

  render() {
    const {
      currentUser,
      hasProducts,
      currentWorkspace
    } = this.props
    const { show } = this.state

    const userIsntGuest = currentUser.permissions !== userPermission.guest
    const userIsntEditor = currentUser.permissions !== userPermission.editor
    const isUnlimitedWorkspace = _.get(currentWorkspace, 'isUnlimited', false)
    const xeroId = _.get(currentWorkspace, 'xeroId', null)
    const isXeroValid = xeroId && xeroId !== '00000000-0000-0000-0000-000000000000'
    const renderCreateBriefLink = () => {
      const linkType = () => {
        return isUnlimitedWorkspace ? (
          <li
          // data-toggle="modal" data-target="#createBriefModal"
          >
            <div className="icon-add-navigation header-post-brief">
              {/* <IconAddNavigation /> */}
              {/* <div onClick={() => this.setShow()}><IconAddNavigation /></div> */}
              <PostJob />
              {/* {
              show && (
                <PostBriefModal
                  show={show}
                  setShow={this.setShow}
                  handleContentBrief={this.handleContentBrief}
                  propsWrapper={{}}
                  handleDistributionBrief={this.handleDistributionBrief}
                />
              )
            } */}
              <span className="visible-xs-inline-block position-right">
                Create brief
              </span>
            </div>
          </li>
        ) : (

            <div className="icon-add-navigation">
              {/* <Link to='/create-brief'>
                <IconAddNavigation />
              </Link> */}
              <div onClick={() => this.setShow()}><IconAddNavigation /></div>
             {
              show && (
                <PostBriefModal
                  show={show}
                  setShow={this.setShow}
                  handleContentBrief={this.handleContentBrief}
                  propsWrapper={{}}
                  handleDistributionBrief={this.handleDistributionBrief}
                />
              )
            }             
            </div>

          )
      }

      return hasProducts && isXeroValid
        ? linkType()
        : <li>
          <a onClick={() => renderNotification(
            'error',
            'Your account setup is incomplete, please contact support@fabulate.com.au'
          )}>
            <i className="icon-plus3" title="Create new brief"></i>
            <span className="visible-xs-inline-block position-right">Create brief</span>
          </a>
        </li>
    }

    return (
      <>
        {/* Main navbar */}
        <BriefModalSelectType />
        {this.renderManageUsersModal()}
        <div className="navbar navbar-default navbar-fixed-top app-navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav pull-right visible-xs-block">
              <li>
                <a data-toggle="collapse" data-target="#navbar-mobile">
                  <i className="icon-tree5"></i>
                </a>
              </li>
              <li>
                <a className="sidebar-mobile-main-toggle">
                  <i className="icon-paragraph-justify3"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="navbar-collapse collapse" id="navbar-mobile">
            <ul className="nav navbar-nav">
              <li>
                <a className="sidebar-control sidebar-main-toggle hidden-xs">
                  <IconMenu className="navbar-icon-menu" />
                </a>
              </li>
              <li className="navigation-search">
                <InputSearch />
              </li>
            </ul>

            <ul className="nav navbar-nav navbar-right">

              {userIsntGuest && this.renderButtons()}

              {currentUser.permissions === userPermission.buyer && (
                renderCreateBriefLink()
              )}

              <li className="dropdown dropdown-user">
                <a className="dropdown-toggle" data-toggle="dropdown">
                  {userIsntGuest && (
                    <img src={currentUser.image || avatar} className="img-circle img-sm" alt="Your avatar" />
                  )}
                  <span className="text-black-blue fw-500">{currentUser.userName ? currentUser.userName.split(' ')[0] : 'Guest'}</span>
                  <i className="caret text-black-blue"></i>
                </a>

                <ul className="dropdown-menu dropdown-menu-right">
                  <li>
                    <Link to='/user-profile'>
                      <IconProfileDropdown />
                      My profile
                    </Link>
                  </li>

                  <li className="divider"></li>
                  {userIsntGuest && (
                    <li>
                      <Link to='/messages'>
                        <div className="dropdown-user-messages-icon">{this.renderMessagesIcon()}</div> Messages
                      </Link>
                    </li>
                  )}

                  <li className="divider"></li>
                  {userIsntGuest && userIsntEditor && (
                    <li>
                      <Link to='/account'>
                        <IconAccountDropdown />
                        Account
                      </Link>
                    </li>
                  )}

                  <li>
                    <Logout />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        {/* /main navbar */}

        {/* Page container */}
        <div className="page-container">

          {/* Page content */}
          <div className="page-content">

            {/* Main sidebar */}
            <div className="sidebar sidebar-main sidebar-fixed left-sidebar-color">
              <div className="sidebar-logo">
                {userIsntGuest ? (
                  <Link to="/dashboard" className="sidebar-brand">
                    <img src="/i/logo.png" className="mt-5 logo-size" alt="Dashboard" />
                  </Link>
                ) : (
                    <Link to="/shared-products" className="sidebar-brand">
                      <img src="/i/logo.png" className="mt-5 logo-size" alt="Shared products" />
                    </Link>
                  )}
              </div>
              <div className="sidebar-content">

                {/* Main navigation */}
                <div className="sidebar-category sidebar-category-visible">
                  <div className="category-content no-padding">
                    <ul className="navigation navigation-main navigation-accordion">
                      {/* Main */}
                      {userIsntGuest && <NavButtons items={headerNavigation.first} />}
                      <NavButtons items={headerByPermission[currentUser.permissions || userPermission.contractor]} />
                      {userIsntGuest && <NavButtons items={headerNavigation.last} />}
                      {userIsntGuest && <NavButtons items={headerNavigation.vault} />}



                      {/* /main */}
                    </ul>
                  </div>
                </div>
                {/* /main navigation */}

              </div>
            </div>
            {/* /main sidebar */}


            {/* Main content */}
            <div className="content-wrapper navbar-top">

              {/* /page header */}

              {/* Content area */}
              <div className="app-content" >
                {this.props.children}


              </div>
              {/* /content area */}
              {/* Footer */}
              <div className="text-muted app-content-footer">
                <span>©Fabulate, {moment().year()}. </span>
                <a className="text-muted" href="https://www.fabulate.com.au/terms-and-conditions/" target="_blank" rel="noopener noreferrer"> &nbsp;Terms and Conditions. </a>
                <a className="text-muted" href="https://www.fabulate.com.au/privacy-policy" target="_blank" rel="noopener noreferrer"> &nbsp;Privacy Policy</a>

                {/* 
              <a>Fabulate</a> 
              by&nbsp;
              <a href="https://www.web-temple.com" target="_blank" rel="noopener noreferrer">
                Webtemple
              </a> */}
              </div>
              {/* /footer */}
            </div>
            {/* /main content */}

          </div>
          {/* /page content */}

        </div>
        {/* /page container */}

      </>
    )
  }

}

export default AppLayout
