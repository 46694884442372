import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import _ from 'lodash'

import * as routerActions from 'store/actions/router'

import PostJob from './PostJob'

const mapStateToProps = state => {
  const currentWorkspace = _.get(state, 'auth.workspace')
  const isUnlimitedWorkspace = _.get(currentWorkspace, 'isUnlimited', false)
  const hasProducts = !_.isEmpty(state.product.origin)
  const xeroId = _.get(currentWorkspace, 'xeroId', '00000000-0000-0000-0000-000000000000')
  const isXeroValid = xeroId !== '00000000-0000-0000-0000-000000000000'

  const isAccountComplete = isXeroValid && hasProducts

  return {
    isUnlimitedWorkspace,
    isAccountComplete
  }
}

const mapDispatchToProps = dispatch => ({
  routerActions: bindActionCreators(routerActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PostJob)