import { put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'

import types from '../constants/workroomGuest'
import * as actions from '../actions/workroomGuest'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const queryParams = action.payload.queryParams

    const workroomList = yield backend.service('workroom-guest').find({
      query: {
        email: queryParams
      },
    })

    yield put(actions.getSuccess(workroomList))

  } catch (e) {
    renderErrorNotification(e, 'Get workroom list error')
    yield put(actions.getFailure(e))
  }
}

function* getId(action) {

  try {
    const workroomGuest = yield backend.service('workroom-guest').get(action.payload)
    if (_.size(workroomGuest.data)) {
      yield put(actions.getIdSuccess(workroomGuest))
    }

  } catch (e) {
    renderErrorNotification(e, 'Get workroom error')
    yield put(actions.getIdFailure(e))
  }

}

function* submitComment(action) {

  try {
    const { id } = action.payload
    const sharedProductGuestUpdate = yield backend.service('workroom-guest').patch(
      id,
      action.payload
    )
    if (_.size(sharedProductGuestUpdate)) {
      yield put(actions.submitCommentSuccess(sharedProductGuestUpdate))
    }

  } catch (e) {
    renderErrorNotification(e, 'Submit comment error')
    yield put(actions.submitCommentFailure(e))
  }

}

function* workroomGuestSaga() {

  yield takeEvery(types.GET, get)

  yield takeEvery(types.GET_ID, getId)

  yield takeEvery(types.SUBMIT_COMMENT, submitComment)

}

export default {
  workroomGuestSaga
}
