const inspectlet = {
  init() {
    window.__insp = window.__insp || []
    window.__insp.push(['wid', 1090999614])
    ;(function () {
      function ldinsp() {
        var insp = document.createElement('script')
        insp.type = 'text/javascript'
        insp.async = true
        insp.id = 'inspsync'
        insp.src =
          ('https:' == document.location.protocol ? 'https' : 'http') +
          '://cdn.inspectlet.com/inspectlet.js?wid=1090999614&r=' +
          Math.floor(new Date().getTime() / 3600000)
        var x = document.getElementsByTagName('script')[0]
        x.parentNode.insertBefore(insp, x)
      }
      document.readyState != 'complete'
        ? window.attachEvent
          ? window.attachEvent('onload', ldinsp)
          : window.addEventListener('load', ldinsp, false)
        : ldinsp()
    })()
  },
  identifyUser(userId, userInfo) {
    window.__insp.push(['identify', userId])
    this.track(userInfo)
  },
  track(event, eventInfo = {}) {
    let _event = event
    const info = eventInfo.causeId || eventInfo.err || eventInfo.articleName
    if (info) {
      _event = {
        [event]: info,
      }
    }
    window.__insp.push(['tagSession', _event])
  },
}

export default inspectlet
