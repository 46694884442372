import React, { useCallback } from 'react'

const RadioButton = props => {
  const { name, checked, required, onCheck, onChange } = props

  const handleCheck = useCallback(
    e => {
      e.stopPropagation()
      onCheck(e)
    },
    [onCheck]
  )

  const handleChange = useCallback(
    e => {
      onChange(e)
    },
    [onChange]
  )

  return (
    <div className="radio pl-20">
      <div className="choice">
        <span className={checked ? 'checked' : 'nonchecked'}>
          <input
            name={name}
            type="radio"
            onClick={handleCheck}
            checked={checked}
            onChange={handleChange}
            required={required}
          />
        </span>
      </div>
    </div>
  )
}

RadioButton.defaultProps = {
  onCheck: () => null,
  onChange: () => null,
  required: false
}

export default RadioButton
