// PostBriefModal
import React from 'react'
import { Modal } from 'react-bootstrap'
import { ReactComponent as IconPostBrief } from 'assets/images/icons/post-brief.svg'
import { ReactComponent as CloseIcon } from 'assets/images/close_icon.svg'
import { ReactComponent as IconContentDistribution } from 'assets/images/icons/content-distribution-brief.svg'
import { ReactComponent as IconDistribution } from 'assets/images/icons/distribution-brief.svg'
import { ReactComponent as IconContent } from 'assets/images/icons/content-brief.svg'
import './PostBriefModal.css'

const PostBriefModal = (props) => {

  const { show, setShow, handleContentBrief, handleDistributionBrief, propsWrapper } = props
  return (
    <Modal
      show={show}
      onHide={() => {
        console.log('on hide', show)
        setShow(false)
      }}
      className="post-brief-modal"
    >
      <Modal.Body>
        <div className="modal-header">
          <button
            type="button"
            className="close"
            onClick={() => {
              setShow(false)
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="post-brief-modal">
          <div className="post-brief-modal-heading">Post a project</div>
          {/* <div className="post-brief-modal-sub-heading">Which type of brief you want to post?</div> */}
          <div className="">
            <div className="post-brief-modal-icon-container"><IconPostBrief /></div>
            <div className="post-brief-modal-btn-container">
              <button className="btn btn-primary legitRipple" onClick={handleContentBrief} {...propsWrapper}>
                <IconContentDistribution className="post-brief-modal-btn-icon" />
                <span>Content and distribution project</span>
              </button>
              <button className="btn btn-primary legitRipple" onClick={handleDistributionBrief}>
                <IconDistribution className="post-brief-modal-btn-icon" />
                <span>Distribution only project</span>
              </button>
              <button className="btn btn-primary legitRipple" onClick={handleContentBrief} {...propsWrapper}>
                <IconContent className="post-brief-modal-btn-icon" />
                <span>Content only project</span>
              </button>
            </div>
            <div className="post-brief-modal-description">
              Selecting an option above will launch a tailored briefing form.
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PostBriefModal
