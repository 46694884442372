import $ from 'jquery'
import 'jquery.nicescroll'

export default {

  init: () => {
    $('.sidebar-fixed .sidebar-content').niceScroll({
      mousescrollstep: 100,
      cursorcolor: '#ccc',
      cursorborder: '',
      cursorwidth: 3,
      hidecursordelay: 100,
      autohidemode: 'scroll',
      horizrailenabled: false,
      preservenativescrolling: false,
    })

  },

  reset: () => {
    $('.sidebar-fixed .sidebar-content').getNiceScroll().remove()
    $('.sidebar-fixed .sidebar-content').removeAttr('style').removeAttr('tabindex')
  }

}
