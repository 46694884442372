import types from '../constants/gettyApi'

export const searchGettyImage = (imageName) => ({
  type: types.SEARCH_GETTY_IMAGE,
  payload: imageName
})
  
export const searchGettyImageSucces = (foundImages) => ({
  type: types.SEARCH_GETTY_IMAGE_SUCCESS,
  payload: foundImages
})
  
export const searchGettyImageFailure = (error) => ({
  type: types.SEARCH_GETTY_IMAGE_FAILURE,
  payload: error
})
  
export const downloadImage = (gettyData, cb) => ({
  type: types.DOWNLOAD_GETTY_IMAGE,
  payload: {
    gettyData,
    cb
  }
})
  
export const clearGettyData = () => ({
  type: types.CLEAR_GETTY_DATA,
})
  
export const downloadGettyImageSucces = (imagesInfo) => ({
  type: types.DOWNLOAD_GETTY_IMAGE_SUCCESS,
  payload: imagesInfo
})
  
export const downloadGettyImageFailure = () => ({
  type: types.DOWNLOAD_GETTY_IMAGE_FAILURE,
})


export const setImageLimit = (limit) => ({
  type: types.SET_IMAGE_LIMIT,
  payload: limit,
})

export const getGettyLimit = (action) => ({
  type: types.GET_GETTY_LIMIT,
  action,
})

export const saveGettyLimit = (limit) => ({
  type: types.SAVE_GETTY_LIMIT,
  payload: limit,
})