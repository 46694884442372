export default {
  GET: 'SERVER:ACCOUNT_GET',
  GET_SUCCESS: 'ACCOUNT_GET_SUCCESS',
  GET_FAILURE: 'ACCOUNT_GET_FAILURE',
  
  EDIT_WORKSPACE: 'ACCOUNT_EDIT_WORKSPACE',
  
  UPDATE_WORKSPACE: 'SERVER:ACCOUNT_UPDATE_WORKSPACE',
  UPDATE_WORKSPACE_SUCCESS: 'ACCOUNT_UPDATE_WORKSPACE_SUCCESS',
  UPDATE_WORKSPACE_FAILURE: 'ACCOUNT_UPDATE_WORKSPACE_FAILURE',

  DOWNLOAD_INVOICE: 'SERVER:DOWNLOAD_INVOICE',
  DOWNLOAD_INVOICE_SUCCESS: 'SERVER:DOWNLOAD_INVOICE_SUCCESS',
  DOWNLOAD_INVOICE_FAILURE: 'SERVER:DOWNLOAD_INVOICE_FAILURE',

  SET_PAGE: 'ACCOUNT_SET_PAGE',
  SET_ACCOUNT_FILTER: 'SET_ACCOUNT_FILTER',
  RESET_ACCOUNT_FILTER: 'RESET_ACCOUNT_FILTER'
}