import { put, takeEvery, select } from 'redux-saga/effects'

import _ from 'lodash'

import types from '../constants/user'
import * as actions from '../actions/user'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get({ payload }) {

  try {
    const { causeId, permissions, ...query } = payload.queryParams
    const isBuyer = permissions === 2
    const usersList = yield backend.service('user').find({
      query: payload.queryParams
    })
    if(_.size(usersList)) {
      yield put(actions.getSuccess(usersList, isBuyer, query))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get users list error')
    yield put(actions.getFailure(e))
  }

}

function* getId(action) {

  try {
    const user = yield backend.service('user').get(action.payload)
    if(_.size(user)) {
      yield put(actions.getIdSuccess(user))
    }
  } catch (e) {
    renderErrorNotification(e, 'Get user error')
    yield put(actions.getIdFailure(e))
  }

}

function* acceptTerms() {
  const getUser = (state) => state.auth.user
  const user = yield select(getUser)

  const getLegal = (state) => state.init.legal
  const legal = yield select(getLegal)

  const userLegalUrl = {
    userId: user.id,
    legalUrlId: legal.id,
  }

  try {
    const newLegalUrl = yield backend.service('user-legal-url').create(userLegalUrl)

    console.log(newLegalUrl)

    if (_.size(newLegalUrl)) {
      yield put(actions.acceptTermsSuccess())
    }
  } catch (e) {
    renderErrorNotification(e, 'Accept terms error')
    yield put(actions.acceptTermsFailure(e))
  }

}

function* userSaga() {
  yield takeEvery(types.GET, get)
  yield takeEvery(types.GET_ID, getId)
  yield takeEvery(types.ACCEPT_TERMS, acceptTerms)
}

export default {
  userSaga,
}
