import types from '../constants/amplifyWorkroom'

export const get = query => ({
  type: types.AMPLIFY_WORKROOM_GET,
  payload: query
})

export const getSuccess = data => ({
  type: types.AMPLIFY_WORKROOM_GET_SUCCESS,
  payload: data
})

export const getFailure = error => ({
  type: types.AMPLIFY_WORKROOM_GET_FAILURE,
  payload: error
})

export const update = data => ({
  type: types.AMPLIFY_WORKROOM_UPDATE,
  payload: data
})

export const updateSuccess = data => ({
  type: types.AMPLIFY_WORKROOM_UPDATE_SUCCESS,
  payload: data
})

export const updateFailure = error => ({
  type: types.AMPLIFY_WORKROOM_UPDATE_FAILURE,
  payload: error
})

export const uploadFile =  data => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_FILE,
  payload: data
})

export const uploadFileSuccess = (amplifyWorkroomId, newAttachment) => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_FILE_SUCCESS,
  payload: {
    amplifyWorkroomId,
    newAttachment
  }
})

export const uploadFileFailure = (amplifyWorkroomId, error) => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_FILE_FAILURE,
  payload: {
    amplifyWorkroomId,
    error
  }
})

export const uploadUrl =  data => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_URL,
  payload: data
})

export const uploadUrlSuccess = (amplifyWorkroomId, newAttachment) => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_URL_SUCCESS,
  payload: {
    amplifyWorkroomId,
    newAttachment
  }
})

export const uploadUrlFailure = error => ({
  type: types.AMPLIFY_WORKROOM_UPLOAD_URL_FAILURE,
  payload: error
})

export const downloadFile = (id) => ({
  type: types.AMPLIFY_WORKROOM_DOWNLOAD_FILE,
  payload: id
})

export const downloadFileSuccess = () => ({
  type: types.AMPLIFY_WORKROOM_DOWNLOAD_FILE_SUCCESS,
})

export const downloadFileFailure = error => ({
  type: types.AMPLIFY_WORKROOM_DOWNLOAD_FILE_FAILURE,
  payload: error
})

export const changeAttachmentPermission = ({ changedPermissions, workroomId }) => ({
  type: types.AMPLIFY_WORKROOM_ATTACHMENT_PERMISSION,
  payload: {
    changedPermissions,
    workroomId
  }
})

export const updateUploadCount = (countData) => {
  return ({
    type: types.AMPLIFY_WORKROOM_UPLOAD_PROGRES,
    payload: countData
  })
}

export const amplifyWorkroomDeleteFile = (data) => ({
  type: types.AMPLIFY_WORKROOM_DELETE_FILE,
  payload: data
})

export const amplifyWorkroomDeleteFileSuccess = (data) => ({
  type: types.AMPLIFY_WORKROOM_DELETE_FILE_SUCCESS,
  payload: data
})

export const amplifyWorkroomDeleteFileFailure = (error) => ({
  type: types.AMPLIFY_WORKROOM_DELETE_FILE_FAILURE,
  payload: error
})

export const amplifyWorkroomCurrentUploadingApiCalls = (data) => ({
  type: types.CURRENT_UPLOADING_API_CALLS,
  payload: data
})

export const amplifyWorkroomClearUploadingApiCall = (data) => ({
  type: types.CLEAR_UPLOADING_API_CALLS,
  payload: data
})

export const amplifyWorkroomUpdateAttachments = (data) => ({
  type: types.AMPLIFY_WORKROOM_UPDATE_ATTACHMENT,
  payload: data
})

export const amplifyWorkroomRemoveAttachment = data => ({
  type: types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT,
  payload: data
})

export const amplifyWorkroomRemoveAttachmentSuccess = data => ({
  type: types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_SUCCESS,
  payload: data
})

export const amplifyWorkroomRemoveAttachmentFailure = error => ({
  type: types.AMPLIFY_WORKROOM_REMOVE_ATTACHMENT_FAILURE,
  payload: error
})

export const amplifyWorkroomVaultUpload = query => ({
  type: types.AMPLIFY_WORKROOM_VAULT_UPLOAD,
  payload: query
})

export const amplifyWorkroomVaultUploadSuccess = data => ({
  type: types.AMPLIFY_WORKROOM_VAULT_UPLOAD_SUCCESS,
  payload: data
})

export const amplifyWorkroomVaultUploadFailure = error => ({
  type: types.AMPLIFY_WORKROOM_VAULT_UPLOAD_FAILURE,
  payload: error
})

export const addVaultFileAmplifyWorkroom = data => ({
  type: types.ADD_VAULT_FILE_AMPLIFY_WORKROOM,
  payload: data
})

export const clearVaultFileAmplifyWorkroom = data => ({
  type: types.CLEAR_VAULT_FILE_AMPLIFY_WORKROOM
})
