import types from '../constants/buyerTodos'

const initialState = {
    origin: {},
    todos: {
        list: {},
        page: 0,
        count: 0,
        pageSize: 20
    },
    filter: {

    },
    isGetFetching: false,
}

export default (state = initialState, action) => {
    switch (action.type) {

        case types.GET:
            {
                return {
                    ...state,
                    isGetFetching: true
                }
            }

        case types.GET_SUCCESS:
            {
                const { data, count, } = action.payload
                return {
                    ...state,
                    isGetFetching: false,
                    todos: {
                        ...state.todos,
                        count,
                        list: data
                    },
                }
            }

        case types.GET_FAILURE:
            {
                return {
                    ...state,
                    isGetFetching: false,
                    error: action.payload,
                }
            }

        case types.SET_FILTER:
            {
                return {
                    ...state,
                    filter: { ...action.payload }
                }
            }

        case types.SET_PAGE:
            {
                return {
                    ...state,
                    isGetFetching: false,
                    todos: {
                        ...state.todos,
                        page: action.payload
                    },
                }
            }
        case types.RESET_FILTER:
            {
                return {
                    ...state,
                    filter: {},
                }
            }

        default:
            return state
    }

}