import React, { memo } from 'react'
import classNames from 'classnames'

import { ReactComponent as Icon } from 'assets/images/icons/message-navigation.svg'

import './IconMessages.css'

const IconMessages = memo(props => {
  const { isHasNewMessages } = props
  return (
    <div className="ui-icon-messages-wrapper">
      <Icon
        className={classNames('ui-icon-messages', {
          'ui-icon-messages-has-messages': isHasNewMessages
        })}
      />
    </div>
  )
})

IconMessages.defaultProps = {
  isHasNewMessages: false
}

export default IconMessages
