/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'

import './styles.css'
import footerLogo from 'assets/images/logo/Fabulate_white_transparent.png'

const Footer = () => (
  <footer className="footer__wrapper">
    <div className="footer__box">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-3">
            <div className="footer__logo">
              <img src={footerLogo} alt="" />
            </div>
            <div className="white-color pt-20">Quality content made easy.</div>
            <div className="text-semibold pt-20">
              <a className="white-color" href="mailto:support@fabulate.com.au">support@fabulate.com.au</a>
            </div>
          </div>
          <div className="col-sm-12 col-md-3">
            <ul className="footer__item">
              <li className="mb-36"><a><span className="text-semibold">navigation:</span></a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/how-it-works/">HOW IT WORKS</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/customers/">CUSTOMERS</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/creators/">CREATORS</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/insights/">INSIGHTS</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/contact-us/">CONTACT US</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/faq/">FAQ</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/sign-in/">LOGIN</a></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3">
            <ul className="footer__item">
              <li className="mb-36"><a><span className="text-semibold">who we are:</span></a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/about-us">ABOUT US</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/privacy-policy/">PRIVACY POLICY</a></li>
              <li><i className="icon-arrow-right22" /><a href="https://fabulate.com.au/terms-and-conditions/">TERMS & CONDITIONS</a></li>
            </ul>
          </div>
          <div className="col-sm-12 col-md-3">
            <ul className="footer__item">
              <li><a><span className="text-semibold">Copyright © Fabulate {moment(Date.now()).year()}</span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
