import types from '../constants/withoutEditor'
import _ from 'lodash'

const initialState = {
  origin: {},
  list: {
    origin: {},
    page: 0,
    count: 0
  },  
  error: null,
  filter: {
    editorId: null,
    causeStatus: 2,
  },
  sort: 'createdAt'
}

export default (state = initialState, action) => {
  switch (action.type) {
        
  case types.GET:
  {
    return {
      ...state,
      isGetFetching: true
    }
  }

  case types.GET_SUCCESS:
  {
    return {
      ...state,
      list: {
        ...state.list,
        origin: _.keyBy(action.payload.data, 'id'),
        count: action.payload.count,          
      },
      isGetFetching: false
    }
  }

  case types.GET_FAILURE: 
  {
    return {
      ...state,
      error: action.payload,
    }
  }

  case types.SET_FILTER:
  {
    const list = action.payload.dateFilter === 'custom'
            || action.payload.dateTo
            || action.payload.dateFrom
      ? {
        ...state.list,
      }
      : {...initialState.list}
    return {
      ...state,
      filter: {
        ...state.filter,
        ...action.payload 
      },
      list: {
        ...list
      }
    }
  }

  case types.RESET_FILTER:  
  {
    return {
      ...state,
      filter: {...initialState.filter}
    }
  }

  case types.SET_PAGE: 
  {
    return {
      ...state,
      list: {
        ...state.list,
        page: action.payload
      }
    }
  }

  default:
    return state
  }
}