export default {

  CREATE: 'SERVER:TEMPLATE_CREATE',
  CREATE_SUCCESS: 'TEMPLATE_CREATE_SUCCESS',
  CREATE_FAILURE: 'TEMPLATE_CREATE_FAILURE',

  GET: 'SERVER:TEMPLATE_GET',
  GET_SUCCESS: 'TEMPLATE_GET_SUCCESS',
  GET_FAILURE: 'TEMPLATE_GET_FAILURE',
  
  REMOVE: 'SERVER:TEMPLATE_REMOVE',
  REMOVE_SUCCESS: 'TEMPLATE_REMOVE_SUCCESS',
  REMOVE_FAILURE: 'TEMPLATE_REMOVE_FAILURE',

  GET_ID: 'SERVER:TEMPLATE_GET_ID',
  GET_ID_SUCCESS: 'TEMPLATE_GET_ID_SUCCESS',
  GET_ID_FAILURE: 'TEMPLATE_GET_ID_FAILURE', 
  
  UPDATE: 'SERVER:TEMPLATE_UPDATE',
  UPDATE_SUCCESS: 'TEMPLATE_UPDATE_SUCCESS',
  UPDATE_FAILURE: 'TEMPLATE_UPDATE_FAILURE', 

  ADD_TASK: 'TEMPLATE_ADD_TASK',
  UPDATE_TASK: 'TEMPLATE_UPDATE_TASK',
  REMOVE_TASK: 'TEMPLATE_REMOVE_TASK',
  VALIDATION: 'TEMPLATE_VALIDATION',
  

}
