export default {
    GET_FOLDER: 'SERVER:GET_VAULT_FOLDER',
    GET_FOLDER_SUCCESS: 'GET_VAULT_FOLDER_SUCCESS',
    GET_FOLDER_FAILURE: 'GET_VAULT_FOLDER_FAILURE',

    GET_ATTACHMENTS: 'SERVER:GET_VAULT_FOLDER_ATTACHMENTS',
    GET_ATTACHMENTS_SUCCESS: 'GET_VAULT_FOLDER_ATTACHMENTS_SUCCESS',
    GET_ATTACHMENTS_FAILURE: 'GET_VAULT_FOLDER_ATTACHMENTS_FAILURE',

    FOLDER_EXIST: 'SERVER:FOLDER_EXIST',
    FOLDER_EXIST_SUCCESS: 'FOLDER_EXIST_SUCCESS',
    FOLDER_EXIST_FAILURE: 'FOLDER_EXIST_FAILURE',
    CLEAR_FOLDER_EXIST: 'CLEAR_FOLDER_EXIST',

    CREATE_FOLDER: 'SERVER:CREATE_FOLDER',
    CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
    CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',

    CLEAR_VAULT_FOLDER: 'CLEAR_VAULT_FOLDER',
    VAULT_UPLOAD_SECOND_STEP_DATA: 'VAULT_UPLOAD_SECOND_STEP_DATA'
}  