import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import Button from 'components/ui/Button'
import InputSearch from 'components/ui/InputSearch'
import Modal from 'components/ui/Modal'
// import { Modal } from 'react-bootstrap'

import SpinnerLoadData from 'components/ui/SpinnerLoadData'

import * as workspaceActions from 'store/actions/workspace'
import * as buyerCausePermissionsActions from 'store/actions/buyerCausePermissions'

import { useCallbackWithTimer } from 'helpers/hooks/useCallbackWithTimer'
import { userPermission } from 'helpers/params'
// import { notFound } from 'helpers/tools'

import { User } from './User'

import { ReactComponent as IconClose } from 'assets/images/icons/icon-close-manage-users.svg'
import { ReactComponent as ManageIcon } from 'assets/images/icons/manage-icon.svg'

import './ManageUsers.css'

export const BuyerManageUsers = (props) => {
  // const { show, setShow } = props
  const dispatch = useDispatch()

  const dialogModalRef = useRef()

  const currentWorkspace = useSelector((state) =>
    _.get(state, 'auth.workspace', {})
  )
  const isGetFetchingWorkspaceUsers = useSelector((state) =>
    _.get(state, 'workspace.users.isGetFetching')
  )
  const workspaceUsers = useSelector((state) =>
    _.get(state, 'workspace.users.items')
  )

  // const searchedWorkspace = useSelector((state) => 
  // _.get(state, 'workspace.searchedWorkspace'))
  const isGetFetchingBuyerCausePermissions = useSelector((state) =>
    _.get(state, 'buyerCausePermissions.isGetFetching')
  )
  const buyerCausePermissions = useSelector((state) =>
    _.get(state, 'buyerCausePermissions.items')
  )
  const currentUser = useSelector((state) =>
    _.get(state, 'auth.user', null)
  )
  const buyerCauseId = useSelector((state) =>
    _.get(state, 'buyerCausePermissions.manageUsersModal.buyerCauseId')
  )
  const currentBuyerBrief = useSelector((state) =>
    _.get(_.values(_.get(state, 'cause.origin'))[0], 'id')
  )
  const showUsers = _.filter(
    workspaceUsers,
    user => _.isEqual(user.permissions, userPermission.buyer) && user.id !== currentUser.id
  )

  const currentCauseOwnerId = useSelector((state) =>
    _.get(_.values(_.get(state, 'cause.origin'))[0], 'ownerId')
  )

  const [searchValueUser, setSearchValueUser] = useState(undefined)
  const [newPermissions, setNewPermissions] = useState({})
  const [changedPermissions, setChangedPermissions] = useState([])

  useEffect(() => {
    if (buyerCauseId) {
      dispatch(
        buyerCausePermissionsActions.getBuyerCausePermissions({
          causeId: buyerCauseId,
        })
      )
    }
  }, [dispatch, buyerCauseId])

  useEffect(() => {
    handleGetWorkspaceUsers()
    if (currentBuyerBrief) {
      dispatch(
        buyerCausePermissionsActions.setCauseIdManageUserModal(currentBuyerBrief)
      )
    }
  }, [dispatch, currentBuyerBrief])


  const handleGetWorkspaceUsers = useCallback(
    (_query) => {
      const query = {
        workspaceId: currentWorkspace.id,
        ..._query,
      }
      dispatch(
        workspaceActions.getWorkspaceUsers(query)
      )
    },
    [dispatch, currentWorkspace]
  )

  const handleCloseModal = useCallback(() => {
    if (buyerCauseId) {
      setNewPermissions({})
      setChangedPermissions({})
      // handleChangeSearchUsers({target: { value: ''}})
      // setShow(false)
    }
  }, [buyerCauseId, dispatch])

  const handleUpdatePermissions = useCallback(() => {
    _.size(newPermissions) &&
      dispatch(
        buyerCausePermissionsActions.createBuyerCausePermissions(
          newPermissions
        )
      )

    _.size(changedPermissions) &&
      dispatch(
        buyerCausePermissionsActions.updateBuyerCausePermissions(
          changedPermissions
        )
      )

    handleCloseModal()
  }, [dispatch, newPermissions, changedPermissions, handleCloseModal])

  const handleSearch = useCallbackWithTimer((value) => {
    handleGetWorkspaceUsers(!_.isEmpty(value) ? { userName: value } : null)
  }, [])

  const handleChangeSearchUsers = useCallback(
    ({ target: { value } }) => {
      setSearchValueUser(value)
      // dispatch(workspaceActions.setSearchedWorkspace(value))
      handleSearch(value)
    },
    [handleSearch]
  )

  const handleChooseUser = useCallback(
    (userId, userPermission) => {
      if (!userPermission) {
        setNewPermissions({
          ...newPermissions,
          [userId]: {
            userId,
            grantedBy: currentUser.id,
            permission: 0,
            causeId: buyerCauseId,
            isActive: true,
          },
        })
      } else {
        setChangedPermissions({
          ...changedPermissions,
          [userId]: {
            ...userPermission,
            isActive: true,
          },
        })
      }
    },
    [newPermissions, currentUser, buyerCauseId, changedPermissions]
  )

  const handleChangeUserPermission = useCallback(
    (userId, userPermission, newValues) => {
      const isNewPermission = _.has(newPermissions, userId)

      if (isNewPermission) {
        const isActiveFalse = _.isEqual(newValues.isActive, false)

        setNewPermissions(
          isActiveFalse
            ? _.omit(newPermissions, userId)
            : {
              ...newPermissions,
              [userId]: {
                ...userPermission,
                ...newValues,
              },
            }
        )
      } else {
        setChangedPermissions({
          ...changedPermissions,
          [userId]: {
            ...userPermission,
            ...newValues,
          },
        })
      }
    },
    [newPermissions, changedPermissions]
  )

  const rendererUsers = useMemo(() => {
    const usersIsNotEmpty = !_.includes(
      [_.isEmpty(showUsers), _.isNil(showUsers)],
      true
    )

    // const _showUsers = showUsers.sort((a, b) => a.userName.localeCompare(b.userName))

    if (isGetFetchingWorkspaceUsers || isGetFetchingBuyerCausePermissions) {
      return <SpinnerLoadData timeDelay="0" isAbsolute={false} />
    }
    // else if (showUsers.length === 0) {
    //   return <span className="buyer-manage-users-no-data">{notFound()}</span>
    // } 
    else {
      return usersIsNotEmpty
        ? _.map(showUsers, (user) => {
          const userPermission = _.find(
            {
              ...buyerCausePermissions,
              ...newPermissions,
              ...changedPermissions,
            },
            (itemPremission) => _.isEqual(itemPremission.userId, user.id)
          )

          return (
            currentCauseOwnerId !== user.id &&
            <User
              key={user.id}
              userId={user.id}
              userName={user.userName}
              image={user.image}
              onChooseUser={handleChooseUser}
              userPermission={userPermission}
              onChangeUserPermission={handleChangeUserPermission}
            />
          )
        })
        : null
    }
  }, [
    isGetFetchingBuyerCausePermissions,
    isGetFetchingWorkspaceUsers,
    showUsers,
    buyerCausePermissions,
    handleChooseUser,
    newPermissions,
    changedPermissions,
    handleChangeUserPermission,
  ])

  return (
    <Modal
      idModal="manageUsersModal"
      classNameModalDialog="manage-user-modal-dialog"
      dialogRef={dialogModalRef}
      className="manage-user-modal-dialog"
      // className="manage-user-modal"
      // show={show}
      // onHide={handleCloseModal}
    >
      <IconClose
        data-toggle="modal"
        data-target="#manageUsersModal"
        className="manage-user-modal-cross-icon"
        onClick={handleCloseModal}
      />
      <div className="manage-users-modal-header">
        <span className="manage-users-modal-header-title">Manage Users</span>
        <span className="manage-users-modal-header-info">
          Inviting people to the project will allow them to see all
          communication
        </span>
        <InputSearch
          value={searchValueUser}
          onChange={handleChangeSearchUsers}
          standartIcon={false}
          classes={{
            wrapper: 'manage-users-modal-header-search-wrapper',
            inputWrapper: 'manage-users-modal-header-search-input-wrapper',
            input: 'manage-users-modal-header-search-input',
            icon: 'manage-users-modal-header-search-input-icon',
          }}
        />
      </div>
      <div className="manage-users-modal-body">
        <div className="manage-users-modal-mediacom">
          <ManageIcon />
          <span className="manage-users-modal-mediacom-text">{currentWorkspace.name}</span>
        </div>
        <div className="manage-users-modal-users">{rendererUsers}</div>
      </div>
      <div className="manage-user-modal-footer">
        <div className="manage-user-modal-footer-buttons">
          <Button
            data-toggle="modal"
            data-target="#manageUsersModal"
            title="close"
            classes={{
              wrapper: 'manage-user-modal-footer-button-wrapper',
              button: 'manage-user-modal-footer-button',
            }}
            onClick={handleCloseModal}
          />
          <Button
            title="update"
            data-toggle="modal"
            data-target="#manageUsersModal"
            onClick={handleUpdatePermissions}
            classes={{
              wrapper: 'manage-user-modal-footer-button-wrapper',
              button:
                'manage-user-modal-footer-button manage-user-modal-footer-button-update',
            }}
          />
        </div>
      </div>
    </Modal>
  )
}
