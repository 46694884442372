import types from '../constants/recommended'
import _ from 'lodash'


const initialState = {
  origin: {},
  list: {
    origin: {},
    page: 0,
    count: 0
  },  
  filter: {
    product_ids: null,
    causeName: undefined,
    dateFilter: undefined,
    causeContent: undefined
  },
  error: null,
}

export default (state = initialState, action) => {
  switch (action.type) {

  case types.GET:
  {
    return {
      ...state,
      isGetFetching: true
    }
  }

  case types.GET_SUCCESS:
  {
    return {
      ...state,
      list: {
        ...state.list,
        origin: _.keyBy(action.payload.data, 'id'),
        count: action.payload.count
      },          
      isGetFetching: false
    }
  }


  case types.GET_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
      isGetFetching: false          
    }
  }

  case types.SET_FILTER:
  {
    const list = action.payload.dateFilter === 'custom'
      || action.payload.dateTo
      || action.payload.dateFrom
      ? {
        ...state.list,
        page: 0,
        isAll: false
      }
      : {...initialState.list}

    return {
      ...state,
      filter: {
        ...state.filter,
        ...action.payload
      },
      list: {
        ...list
      }
    }
  }  
      
  case types.RESET_FILTERS:
  {
    return {
      ...state,
      filter: {
        ...initialState.filter
      }
    }
  }    
    
  case types.SET_PAGE: 
  {
    return {
      ...state,
      list: {
        ...state.list,
        page: action.payload
      }
    }
  }

  case types.SOCKET_CAUSE_CREATE:
  {
    const currentCauses = _.sortBy(_.values(state.list.origin), cause => cause.createdAt).reverse()
    if (currentCauses.length > 5) currentCauses.pop()
    const updatedCauses = [action.payload, ...currentCauses]
    const causesObject = {}
    _.forEach(updatedCauses, cause => {
      causesObject[cause.id] = cause
    })
    
    return {
      ...state,
      list: {
        ...state.list,
        origin: causesObject
      }
    }
  }

  case types.RECOMMENDED_SOCKET_CAUSE_REMOVE:
  {
    const deletedBrief = action.payload
    const newList= state.list

    if (!_.isNil(deletedBrief) && newList.count) {
      newList.origin = _.filter(newList.origin, item => item.id !== deletedBrief.id)
      newList.count = --newList.count

      return {
        ...state,
        list: {
         ...newList
        }
      }
    }

    return state
  }

  default:
    return state
  }

}