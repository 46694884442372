import React, {
  Fragment,
  useMemo,
  useState,
  useCallback,
  useEffect,
  memo,
} from 'react'
import { Link } from 'react-router-dom'

import _ from 'lodash'
import classNames from 'classnames'

import './style.css'

const ButtonSubItem = memo((props) => {
  const { children, ...otherProps } = props

  return <div {...otherProps}>{children}</div>
})

const NavButton = (props) => {
  const { link, text, icon, Icon, subItems, path, onClick } = props

  const [isOpenSubMenu, setIsOpenSubMenu] = useState()

  const isOpenSubItems = useMemo(
    () => _.includes(_.map(subItems, 'link'), path),
    [path, subItems]
  )

  useEffect(() => {
    setIsOpenSubMenu(isOpenSubItems)
  }, [isOpenSubItems])

  const isNotEmptySubItems = useMemo(
    () => !_.isNil(subItems) && !_.isEmpty(subItems),
    [subItems]
  )
  const isCurrentRoute = useMemo(() => {
    if (isNotEmptySubItems) {
      return isOpenSubItems
    } else {
      return _.includes(path, link)
    }
  }, [path, link, isNotEmptySubItems, isOpenSubItems])

  const CurrentWrapper = useMemo(
    () => (isNotEmptySubItems ? ButtonSubItem : Link),
    [isNotEmptySubItems]
  )

  const handleClick = useCallback(() => {
    onClick && onClick()
    isNotEmptySubItems && setIsOpenSubMenu(true)
  }, [isNotEmptySubItems, onClick])

  const rendererSubItems = useMemo(() => {
    return isNotEmptySubItems && isOpenSubMenu ? (
      <div className="navigation-item-sub-items">
        {_.map(subItems, (itemSubItems, index) => {
          return (
            <Link
              key={`sub-item_${itemSubItems.link}_index_${index}`}
              to={itemSubItems.link}
              className="navigation-item-sub-items-item"
            >
              {itemSubItems.text}
            </Link>
          )
        })}
      </div>
    ) : null
  }, [isNotEmptySubItems, subItems, isOpenSubMenu])

  return (
    <li>
      <CurrentWrapper
        to={link}
        className={classNames(
          'oval-position navigation-item navigation-item-content',
          {
            'navigation-item-active-route': isCurrentRoute,
          }
        )}
        onClick={handleClick}
      >
        <span className="navigation-item-title">
          <div className={classNames('navigation-item-icon-wrapper', icon)}>
            {Icon && <Icon className="navigation-item-icon" />}
          </div>
          <span className="navigation-item-text">{text}</span>
        </span>

        {rendererSubItems}
      </CurrentWrapper>
    </li>
  )
}

const NavButtons = (props) => {
  const {
    history,
    items,
    causeUserActions,
    causeActions,
    recommendedActions,
    vaultActions,
  } = props

  const path = history.location.pathname

  const handleClicksLink = useMemo(() => {
    return {
      '/dashboard': () => {
        causeActions.resetFilters()
        recommendedActions.resetFilters()
        causeUserActions.resetFilters()
      },
      '/brief': () => {
        causeActions.resetFilters()
      },
      '/pitch': () => {
        causeUserActions.resetFilters()
      },
      '/new-briefs': () => {
        causeActions.resetFilters()
      },
      '/vault': () => {
        vaultActions.setSelectedFolder('')
      }
    }
  }, [causeActions, recommendedActions, causeUserActions])

  return (
    <Fragment>
      {_.map(items, (itemNavigation, index) => {
        const currentHandleClick = _.get(
          handleClicksLink,
          itemNavigation.link,
          null
        )
        return (
          <NavButton
            key={`navigate_panel_${itemNavigation.link}_index_${index}`}
            onClick={currentHandleClick}
            path={path}
            {...itemNavigation}
          />
        )
      })}
    </Fragment>
  )
}

export default NavButtons
