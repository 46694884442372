import { put, takeEvery } from 'redux-saga/effects'

import types from 'store/constants/dashboard'
import * as actions from 'store/actions/dashboard'
import { renderErrorNotification } from 'helpers/tools'

import backend from 'store/api/feathers'

function* get(action) {

  try {
    const { page, pageSize, filters, userId, userType, isMarketplace } = action.payload.params
    const causeList = yield backend.service(`briefs?page=${page}&pageSize=${pageSize}`).create({ filters, userId })
    if (isMarketplace) {
      const payload = {
        data: causeList.data && causeList.data.userBriefs && causeList.data.userBriefs.data,
        count: causeList.data && causeList.data.userBriefs && causeList.data.userBriefs.count
      }

      yield put(actions.getAmplifyRecommendedBriefSuccess(payload))
    } else {
      yield put(actions.getSuccess(causeList))
    }
    // const causeList = yield backend.service('dashboard').create({...action.payload.params})
  } catch (e) {
    renderErrorNotification(e, 'Get dashboard error')
    yield put(actions.getFailure(e))

  }

}

function* getCreatorRecommendedBriefs(action) {

  try {
    const { page, pageSize, filters, userId, userType } = action.payload.params
    const creatorRecommendedCauseList = yield backend.service(`briefs/recommended?page=${page}&pageSize=${pageSize}`).create({ filters, userId })

    yield put(actions.getCreatorRecommendedBriefSuccess(creatorRecommendedCauseList))
  } catch (e) {
    renderErrorNotification(e, 'Get creator recommended briefs error')
    yield put(actions.getCreatorRecommendedBriefFailure(e))

  }

}

function* getAmplifyRecommendedBriefs(action) {

  try {
    const { page, pageSize, filters, userId, userType } = action.payload.params
    const amplifyRecommendedCauseList = yield backend.service(`briefs/recommended?page=${page}&pageSize=${pageSize}`).create({ filters, userId })

    yield put(actions.getAmplifyRecommendedBriefSuccess(amplifyRecommendedCauseList))
  } catch (e) {
    renderErrorNotification(e, 'Get amplify recommended brief error')
    yield put(actions.getAmplifyRecommendedBriefFailure(e))

  }

}

function* getCreatorPitchesBriefs(action) {

  try {
    const { page, pageSize, filters, userId, userType } = action.payload.params
    const creatorPitchesCauseList = yield backend.service(`creator/pitches?page=${page}&pageSize=${pageSize}`).create({ filters, userId })

    yield put(actions.getCreatorPitchesBriefSuccess(creatorPitchesCauseList))
  } catch (e) {
    renderErrorNotification(e, 'Get creator pitches brief error')
    yield put(actions.getCreatorPitchesBriefFailure(e))

  }

}

function* getEditorWaitingBriefs(action) {

  try {
    const { page, pageSize, filters, userId, userType } = action.payload.params
    const editorWaitingCauseList = yield backend.service(`editor/waiting-briefs?page=${page}&pageSize=${pageSize}`).create({ filters, userId })

    yield put(actions.getEditorWaitingBriefSuccess(editorWaitingCauseList))
  } catch (e) {
    renderErrorNotification(e, 'Get editor waiting brief error')
    yield put(actions.getEditorWaitingBriefFailure(e))
  }

}


function* dashboardSaga() {
  yield takeEvery(types.GET, get)
  yield takeEvery(types.GET_CREATOR_PITCHES_BRIEF, getCreatorPitchesBriefs)
  yield takeEvery(types.GET_AMPLIFY_RECOMMENDED_BRIEF, getAmplifyRecommendedBriefs)
  yield takeEvery(types.GET_EDITOR_WAITING_BRIEF, getEditorWaitingBriefs)
  yield takeEvery(types.GET_CREATOR_RECOMMENDED_BRIEF, getCreatorRecommendedBriefs)
}

export default {
  dashboardSaga
}
