export default {
  BUYER_CAUSE_PERMISSIONS_FIND: 'SERVER:BUYER_CAUSE_PERMISSIONS_FIND',
  BUYER_CAUSE_PERMISSIONS_FIND_SUCCESS: 'BUYER_CAUSE_PERMISSIONS_FIND_SUCCESS',
  BUYER_CAUSE_PERMISSIONS_FIND_ERROR: 'BUYER_CAUSE_PERMISSIONS_FIND_ERROR',

  BUYER_CAUSE_PERMISSIONS_CREATE: 'SERVER:BUYER_CAUSE_PERMISSIONS_CREATE',
  BUYER_CAUSE_PERMISSIONS_CREATE_SUCCESS: 'BUYER_CAUSE_PERMISSIONS_CREATE_SUCCESS',
  BUYER_CAUSE_PERMISSIONS_CREATE_ERROR: 'BUYER_CAUSE_PERMISSIONS_CREATE_ERROR',

  BUYER_CAUSE_PERMISSIONS_UPDATE: 'SERVER:BUYER_CAUSE_PERMISSIONS_UPDATE',
  BUYER_CAUSE_PERMISSIONS_UPDATE_SUCCESS: 'BUYER_CAUSE_PERMISSIONS_UPDATE_SUCCESS',
  BUYER_CAUSE_PERMISSIONS_UPDATE_ERROR: 'BUYER_CAUSE_PERMISSIONS_UPDATE_ERROR',

  BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID: 'BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_SET_CAUSE_ID',
  BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID: 'BUYER_CAUSE_PERMISSIONS_MANAGE_USER_MODAL_CLEAR_CAUSE_ID'
}