import types from '../constants/genre'
import _ from 'lodash'


const initialState = {

}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case types.GET_SUCCESS:
  {
    return _.keyBy(action.payload.data, 'id')
  }

  case types.GET_FAILURE:
  {
    return {
      ...state,
      error: action.payload,
    }
  }
  
  default:
    return state
  }

}