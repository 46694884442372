import { createSelector } from 'reselect'
import _ from 'lodash'

export const getModel = (state) => state && state.model

export const getField = (state, fieldNames) => createSelector(
  [getModel],
  (model) => {
    let fields = {}
    if(fieldNames && fieldNames.length > 0) {
      fields = fieldNames.reduce((obj, field) => {
        if(model && model[field]) {
          if(model[field].validate) {
            obj[field] = {
              ...model[field].validate
            }
          }

          if(_.has(model[field], 'type._length') && parseInt(model[field].type._length, 10)) {
            obj[field] = {
              ...obj[field],
              length: {
                value: parseInt(model[field].type._length, 10),
                msg: `Max length is ${parseInt(model[field].type._length, 10)} characters`
              }
            }
          }          
        }  
        return obj
      }, {})
    }

    return fields
  }
)(state)