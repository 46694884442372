import inspectlet from '../inspectlet'

export const EVENTS = {
  LANDING_PAGE: 'LANDING_PAGE',
  API_ERROR: 'API_ERROR',
  BRIEF_POST_REQUEST: 'BRIEF_POST_REQUEST',
  BRIEF_POST_SUCCESS: 'BRIEF_POST_SUCCESS',
  PITCH_POST_REQUEST: 'PITCH_POST_REQUEST',
  PITCH_POST_SUCCESS: 'PITCH_POST_SUCCESS',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  BRIEF_AWARD_SUCCESS: 'BRIEF_AWARD_SUCCESS',
  BRIEF_CANCEL_SUCCESS: 'BRIEF_CANCEL_SUCCESS',
  BRIEF_DELETE_SUCCESS: 'BRIEF_DELETE_SUCCESS',
  AMPLIFY_BRIEF_POST_SUCCESS: 'AMPLIFY_BRIEF_POST_SUCCESS',
  CONTENT_WORKROOM_COMPLETED: 'CONTENT_WORKROOM_COMPLETED',
  AMPLIFY_WORKROOM_COMPLETED: 'AMPLIFY_WORKROOM_COMPLETED',
  AMPLIFY_BRIEF_ACCEPT: 'AMPLIFY_BRIEF_ACCEPT',
  AMPLIFY_BRIEF_DECLINE: 'AMPLIFY_BRIEF_DECLINE',
  VAULT_BRIEF_POST_REQUEST: 'VAULT_BRIEF_POST_REQUEST',
  VAULT_BRIEF_POST_SUCCESS: 'VAULT_BRIEF_POST_SUCCESS',
}

class Analytics {
  constructor(analyticsService) {
    this.analyticsService = analyticsService
    this.init = this.init.bind(this)
    this.identifyUser = this.identifyUser.bind(this)
    this.verifyEvent = this.verifyEvent.bind(this)
    this.track = this.track.bind(this)
  }

  /**
   * Initializes the analytics service
   */
  init() {
    this.analyticsService.init()
  }

  /**
   * Identifies the authenticated user.
   * @param {String} userId ID of the authenticated user.
   * @param {Object} userInfo Info about the user.
   */
  identifyUser(userId, userInfo) {
    this.analyticsService.identifyUser(userId, userInfo)
  }

  /**
   * Validates the event.
   * @param {String} event Name of the event.
   */
  verifyEvent(event) {
    if (!event)
      throw new Error(
        'Analytics -> event cannot be null. Please pass the event'
      )

    if (!EVENTS[event])
      throw new Error(
        'Analytics -> Invalid value of event. You missed adding the event in EVENTS'
      )
  }

  /**
   * Tracks the event.
   * @param {String} event Name of the event.
   * @param {Object} eventData Info about the event.
   */
  track(event, eventData = {}) {
    this.verifyEvent(event)
    try {
      this.analyticsService.track(event, eventData)
    } catch (error) {
      console.log(
        '🚀 ~ file: index.js ~ line 70 ~ Analytics ~ track ~ error',
        error
      )
    }
  }
}

export const appAnalytics = new Analytics(inspectlet)
