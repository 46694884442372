export default {

  GET:              'SERVER:PORTFOLIO_GET',
  GET_SUCCESS:      'PORTFOLIO_GET_SUCCESS',
  GET_FAILURE:      'PORTFOLIO_GET_FAILURE',

  CREATE:           'SERVER:PORTFOLIO_CREATE',
  CREATE_SUCCESS:   'PORTFOLIO_CREATE_SUCCESS',
  CREATE_FAILURE:   'PORTFOLIO_CREATE_FAILURE',

  EDIT:             'PORTFOLIO_EDIT_FORM',

  REMOVE:           'SERVER:PORTFOLIO_REMOVE',
  REMOVE_SUCCESS:   'PORTFOLIO_REMOVE_SUCCESS',
  REMOVE_FAILURE:   'PORTFOLIO_REMOVE_FAILURE',
}